import {
  all,
  fork,
  takeEvery,
  put,
  select,
  call,
  cancel,
} from "redux-saga/effects";
import {
  saveSegmentationError,
  updateSegmentationError,
  setDrawingSegmentation,
  drawSegmentationByEngineError,
  setIsUnsaveSegmentation,
} from "redux/segmentation/actions";
import {
  saveSegmentationAPI,
  updateSegmentationAPI,
  fetchDataBase64,
  drawSegmentationByEngineAPI,
  getSessionIDSegmentationByEngineAPI,
  confirmSmartToolSuccesfulRequestAPI,
} from "api/segmentation";
import { selectCurrLabel } from "redux/points/selectors";
import {
  SAVE_SEGMENTATION,
  UPDATE_SEGMENTATION,
  DRAW_SEGMENTATION_BY_ENGINE,
  RESET_SEGMENTATION_MODE,
  CONFIRM_SMART_TOOL_REQUEST,
} from "./constants";
import { selectImageId } from "../image/selectors";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { setDialogOpen } from "redux/app/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import { addShapesSuccess, updateShapesSuccess } from "redux/shape/actions";
import { selectToolSuccess } from "redux/tools/actions";
import { limitationSegmentationTool } from "redux/tools/actions";

import {
  setCurrentSegmentationMode,
  setOpenSegmentationLimitationModal,
} from "redux/segmentation/actions";
import { segMode } from "./enums";
import { setCurrentShapeSuccess } from "redux/shape/actions";
import { getSegmentationEnginesName } from "redux/segmentation/selector";
import get from "lodash.get";
import { selectLimitationSegmentationTool } from "redux/tools/selectors";

// import { endCounting } from "redux/timing/actions";

function* watchSaveSegmentation() {
  yield takeEvery(SAVE_SEGMENTATION, function* ({ payload, callback }) {
    const imageId = yield select(selectImageId);
    const label = yield select(selectCurrLabel);
    const { x, y, width, height, url, pointArr } = payload;

    const response = yield call(saveSegmentationAPI, {
      sourceId: imageId,
      labelId: label.id,
      base64Data: url,
      pointArr: pointArr,
      x,
      y,
      width,
      height,
    });
    if (response.success) {
      yield put(
        showNotification({
          type: "success",
          msg: "Save segmentation success.",
          msgId: "notification.save-segmentation-success",
        })
      );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
      const base64Data = yield call(
        fetchDataBase64,
        response.data.dataPoint.url
      );
      yield put(
        addShapesSuccess({
          ...response.data,
          id: response.data.labelPointId,
          classesTypeCode: "segmentation-image",
          labelName: label && label.label,
          color: label && label.color,
          dataPoint: {
            ...response.data.dataPoint,
            base64Data,
          },
        })
      );
      yield put(setIsUnsaveSegmentation(false));
      yield put(setDrawingSegmentation(null));
    } else {
      yield put(saveSegmentationError({msg: "Save segmentation error!", msgId: "notification.save-segmentation-error"}));
    }
    // yield put(endCounting());
    if (callback) callback();
  });
}

function* watchResetSegmentationMode() {
  yield takeEvery(RESET_SEGMENTATION_MODE, function* () {
    yield put(selectToolSuccess(""));
  });
}

const getSegmentationBySessionID = (data) =>
  new Promise((resolve) => {
    const { sessionId } = data;
    const timer = setInterval(async () => {
      const request = await drawSegmentationByEngineAPI(sessionId);
      if (request) {
        const statusProcessing = get(request, "data.process.status", "");
        if (statusProcessing === "finished") {
          resolve(request);
          clearInterval(timer);
        }
      }
    }, 2000);
    const wait = setTimeout(() => {
      clearTimeout(wait);
      clearInterval(timer);
      resolve({
        status: "false",
      });
    }, 10000);
  });

function* watchDrawSegmentationByEngine() {
  yield takeEvery(DRAW_SEGMENTATION_BY_ENGINE, function* ({
    payload,
    callback,
  }) {
    yield put(limitationSegmentationTool());
    const limitSegmentation = yield select(selectLimitationSegmentationTool);
    if (limitSegmentation === 0) {
      yield put(setOpenSegmentationLimitationModal(true));
      yield cancel();
    }

    yield put(setDialogOpen(true));
    const { pointArr, fileUrl, boundingBox } = payload;
    const modelName = yield select(getSegmentationEnginesName);
    const sessionResponse = yield call(getSessionIDSegmentationByEngineAPI, {
      mediaType: "imageFile",
      clicks: pointArr,
      fileUrl,
      boundingBox,
      modelName,
    });
    const sessionId = get(sessionResponse, "data.sessionId", "");
    if (sessionResponse.status === "success") {
      const sessionProcessStatus = get(
        sessionResponse,
        "data.process.status",
        ""
      );
      if (sessionProcessStatus === "finished") {
        const result = get(sessionResponse, "data.results[0]", {});
        if (callback) callback({ ...result, sessionId });
      } else {
        const segmentationResponse = yield call(
          getSegmentationBySessionID,
          sessionResponse.data
        );

        if (segmentationResponse.status === "success") {
          const segmentationResponseProcessStatus = get(
            segmentationResponse,
            "data.process.status",
            ""
          );
          if (segmentationResponseProcessStatus === "finished") {
            const segmentationResponseResult = get(
              segmentationResponse,
              "data.results[0]",
              {}
            );
            if (callback)
              callback({ ...segmentationResponseResult, sessionId });
          } else {
            yield put(
              drawSegmentationByEngineError(
                {
                  msg: "GPU is not available. Please contact your admin for support!",
                  msgId: "notification.gpu-not-available"
                }
              )
            );
          }
        } else {
          yield put(
            drawSegmentationByEngineError(
              {
                msg: "GPU is not available. Please contact your admin for support!",
                msgId: "notification.gpu-not-available"
              }
            )
          );
        }
      }
    } else {
      if (sessionResponse.detail?.data?.usage?.remainRequest === 0) {
        yield put(setOpenSegmentationLimitationModal(true));
      } else {
        yield put(
          drawSegmentationByEngineError(
            {
              msg: "GPU is not available. Please contact your admin for support!",
              msgId: "notification.gpu-not-available"
            }
          )
        );
      }
    }

    yield put(setDialogOpen(false));
    yield put(setCurrentSegmentationMode(segMode.SMART_MODE));
  });
}

function* watchUpdateSegmentation() {
  yield takeEvery(UPDATE_SEGMENTATION, function* ({ payload }) {
    const imageId = yield select(selectImageId);
    const base64Data = get(payload, "payload.url", "");
    const pointArr = get(payload, "payload.pointArr", []);
    const x = get(payload, "payload.x", "");
    const y = get(payload, "payload.y", "");
    const width = get(payload, "payload.width", "");
    const height = get(payload, "payload.height", "");

    const response = yield call(updateSegmentationAPI, {
      id: payload.id,
      sourceId: imageId,
      base64Data,
      pointArr,
      x,
      y,
      width,
      height,
    });
    if (response.success) {
      const label = yield select(selectCurrLabel);

      yield put(
        showNotification({
          type: "success",
          msg: "Segmentation updated successfully.",
          msgId: "notification.segmentation-updated-success",
        })
      );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
      const base64Data = yield call(
        fetchDataBase64,
        response.data.dataPoint.url
      );
      yield put(
        updateShapesSuccess({
          ...response.data,
          id: response.data.labelPointId,
          classesTypeCode: "segmentation-image",
          labelName: label && label.label,
          color: label && label.color,
          dataPoint: {
            ...response.data.dataPoint,
            base64Data,
          },
        })
      );
      yield put(setIsUnsaveSegmentation(false));
      yield put(setDrawingSegmentation(null));
      yield put(setCurrentShapeSuccess(null));
    } else {
      yield put(updateSegmentationError({msg:"Update segmentation error!", msgId: "notification.update-segmentation-error"}));
    }
  });
}

function* watchConfirmSuccessfulRequest() {
  yield takeEvery(CONFIRM_SMART_TOOL_REQUEST, function* ({ payload }) {
    yield call(confirmSmartToolSuccesfulRequestAPI, payload);
  });
}

export default function* boxSaga() {
  yield all([
    fork(watchSaveSegmentation),
    fork(watchUpdateSegmentation),
    fork(watchDrawSegmentationByEngine),
    fork(watchResetSegmentationMode),
    fork(watchConfirmSuccessfulRequest),
  ]);
}
