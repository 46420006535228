import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  paper: {
    position: "fixed",
    bottom: "6%",
    zIndex: "1000",
    right: "1%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    borderStyle: "none",
    width: "325px",
    backgroundColor: "#fff",
    "&:focus": {
      outline: "none",
    },
  },
  header: {
    padding: "6px 16px",
    borderRadius: "8px 8px 0px 0px",
    backgroundColor: "#FA7595",
    color: "#fff",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
  },

  image: {},
  introText: {
    marginLeft: 16,
    fontWeight: 600,
  },
  content: {
    padding: 16,
    display: "flex",
    paddingBottom: 16,
  },
  robotBody: {
    marginRight: 16,
  },
  questionText: {
    fontSize: "14px",
    padding: "16px",
    backgroundColor: "#F5F8F9",
    borderRadius: "8px",
    color: "#555F7C",
    margin: 0,
  },
  footer: {
    marginLeft: "52px",
    marginBottom: "18px",
    padding: "0px 16px",
  },
  buttonReject: {
    background: "#F2F5F9",
    textTransform: "none",
    color: "#9CA8B4",
    marginRight: 8,
    padding: "8px 16px",
  },
  buttonAccept: {
    background: "#FA7595",
    textTransform: "none",
    color: "#FFFFFF",
    padding: "8px 16px",
    "&:hover": {
      background: "#FA7595",
    },
  },
  footerButton: {
    marginTop: "16px",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
