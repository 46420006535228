export const FETCH_INPUT_DATA = "image/FETCH_INPUT_DATA";
export const FETCH_INPUT_DATA_SUCCESS = "image/FETCH_INPUT_DATA_SUCCESS";
export const NO_MORE_TO_FETCH = "image/NO_MORE_TO_FETCH";
export const SELECT_ONE_SOURCE = "image/SELECT_ONE_SOURCE";
export const SELECT_ONE_SOURCE_SUCCESS = "image/SELECT_ONE_SOURCE_SUCCESS";

export const GET_SOURCE_CSV_FILE = "image/GET_SOURCE_CSV_FILE";
export const GET_SOURCE_CSV_FILE_SUCCESS = "image/GET_SOURCE_CSV_FILE_SUCCESS";
export const GET_SOURCE_CSV_FILE_ERROR = "image/GET_SOURCE_CSV_FILE_ERROR";

export const SUBMIT_IMAGE = "image/SUBMIT_IMAGE";
export const SUBMIT_IMAGE_SUCCESS = "image/SUBMIT_IMAGE_SUCCESS";
export const SUBMIT_IMAGE_ERROR = "image/SUBMIT_IMAGE_ERROR";

export const FIT_IMAGE_SIZE = "image/FIT_IMAGE_SIZE";
export const GET_NEXT_IMAGE_ID = "image/GET_NEXT_IMAGE_ID";

export const SAVE_EDITED_IMAGE = "image/SAVE_EDITED_IMAGE";
export const SAVE_EDITED_IMAGE_SUCCESS = "image/SAVE_EDITED_IMAGE_SUCCESS";
export const RESET_EDITED_IMAGE = "image/RESET_EDITED_IMAGE";
export const RESET_EDITED_IMAGE_SUCCESS = "image/RESET_EDITED_IMAGE_SUCCESS";

export const CLEAN_SELECTED_IMAGE = "image/CLEAN_SELECTED_IMAGE";

export const REFETCH_INPUT_DATA = "image/REFETCH_INPUT_DATA";
export const UPDATE_CURRENT_PAGE = "image/UPDATE_CURRENT_PAGE";

export const START_FETCHING_MORE = "image/START_FETCHING_MORE";

export const CHANGE_FILTER_BTN_STATUS = "image/CHANGE_FILTER_BTN_STATUS";

export const ROTATE_IMAGE = "image/ROTATE_IMAGE";
export const ROTATE_IMAGE_FINISH = "image/ROTATE_IMAGE_FINISH";

export const MARK_BENCHMARK = "image/MARK_BENCHMARK";
export const MARK_BENCHMARK_SUCCESS = "image/MARK_BENCHMARK_SUCCESS";
export const MARK_BENCHMARK_FAILURE = "image/MARK_BENCHMARK_FAILURE";

export const SCROLL_IMAGE = "image/SCROLL_IMAGE";

export const SEARCH_PROJECT_TAG_BY_TEXT = "image/SEARCH_PROJECT_TAG_BY_TEXT";
export const CREATE_TAG_AND_ADD_TO_SOURCE =
  "image/CREATE_TAG_AND_ADD_TO_SOURCE";
export const DELETE_TAG_OF_SOURCE = "image/DELETE_TAG_OF_SOURCE";
export const ADD_TAG_TO_SOURCE = "image/ADD_TAG_TO_SOURCE";
