/* eslint-disable no-case-declarations */
import React, { useRef, useState, useEffect, Fragment } from "react";
import debounce from "lodash.debounce";
import { Stage, Layer, Rect } from "react-konva";
import {
  connect,
  ReactReduxContext,
  Provider,
  useSelector,
  useDispatch,
} from "react-redux";
import Draggable from "react-draggable";
import CircularProgress from "@material-ui/core/CircularProgress";
import isEmpty from "lodash.isempty";
import {
  objectOf,
  oneOfType,
  arrayOf,
  object,
  string,
  func,
  number,
  shape,
  bool,
  any,
} from "prop-types";
import IrisCirclesLayer from "components/Landmarks/IrisCirclesLayer";
import { selectPermissionComponent } from "redux/app/selectors";
import {
  setPoints,
  autoFillPointIndex,
  dispatchIrisRadius,
} from "redux/points/actions";
import { selectImageId } from "redux/image/selectors";
import {
  selectPoints,
  makeSelectedPoint,
  selectDisplayingRegions,
  selectSelectedPointIds,
  selectRightRadius,
  selectLeftRadius,
  selectCurrLabel,
} from "redux/points/selectors";
import {
  selectIsMoveMode,
  selectIsBoxMode,
  selectIsPolygonMode,
  selectIsPolylineMode,
  selectIsCuboidMode,
  makeCurrTool,
  selectIsOCRMode,
} from "redux/tools/selectors";
import {
  selectImageMetadata,
  selectImageUrl,
  selectFitCounter,
  selectImageAnnotationType,
  selectImageRotating,
} from "redux/image/selectors";
import { saveBox } from "redux/box/actions";
import { saveOCR } from "redux/ocr/actions";
import {
  getShapes,
  setCopyShapeMode,
  setCopyShape,
  setCurrentShape,
} from "redux/shape/actions";
import { openIssueForm } from "redux/issues/actions";
import {
  selectIsKeyPointShown,
  selectDrawCount,
  selectImageRatio,
  selectPointsCanvas,
  selectPointsCanvasByRegion,
  selectIsIrisCirclesShown,
} from "redux/canvas/selectors";
import { usersSelector } from "redux/users/selector";
import { changeDrawCount } from "redux/canvas/actions";
import { savePolygon } from "redux/polygon/actions";
import { savePolyline } from "redux/polyline/actions";
import PointsLayer from "components/Landmarks/PointsLayer";
import Polygon from "components/Polygon";
import SegmentationLayer from "components/SegmentationLayer";
import { setDrawingSegmentation } from "redux/segmentation/actions";
import {
  getSegmentations,
  getDrawingSegmentations,
  checkDrawSegmentationArea,
} from "redux/segmentation/selector";
import {
  setCurrentShapeSuccess,
  setDrawingPolygon as setPolygon,
} from "redux/shape/actions";
import {
  getCurrentShapes,
  selectCopyShapeMode,
  getCopyShape,
  selectCheckAddShape,
  selectDrawingPolygon,
} from "redux/shape/selector";
import { saveCuboid } from "redux/cuboid/actions";
import ShapeLayer from "components/ShapeLayer";
import { initialState, getMinHeightMaxWidth } from "./utils";
import ObjectAttribute from "components/ObjectAttribute";
import { sendMousePosition } from "redux/mouse/actions";
import ShapeLabelForm from "components/ShapeLabelForm";
import SelectedZone from "components/SelectedZone";
import MainImage from "components/MainImage";
import DrawingRectangle from "components/DrawingRectangle";
// import Mouse from "components/Mouse";
import style from "./style.module.scss";
import { startCounting } from "redux/timing/actions";
import { selectCountingTime } from "redux/timing/selectors";
import { selectCtrlIsPressing } from "redux/keyboard/selectors";
import EditableTextInput from "../TextAreaKonvas/EditableTextInput";

function ImageCanvas({
  pointsCanvas,
  points,
  setPoints,
  metadata,
  selectedPoints,
  setSelectedPoints,
  isMoveMode,
  ratio,
  imgUrl,
  drawCount,
  changeDrawCount,
  brightness,
  contrast,
  opacity,
  isReviewer,
  openIssueForm,
  autoFillPointIndex,
  fitCounter,
  displayingRegions,
  linesData,
  isIrisCirclesShown,
  isKeyPointShown,
  dispatchIrisRadius,
  isBoxMode,
  selectedPointIds,
  leftRadius,
  rightRadius,
  currLabel,
  currImageId,
  saveBox,
  currTool,
  isPolygonMode,
  isPolylineMode,
  savePolygon,
  savePolyline,
  setCurrentShapeSuccess,
  currentShape,
  isCuboidMode,
  saveCuboid,
  annotationType,
  saveOCR,
  isOCRMode,
  drawingSegmentation,
  isDrawingSegmentationArea,
  setDrawingSegmentation,
  imageRotating,
  setPolygon,
}) {
  const dispatch = useDispatch();
  const copyShapeMode = useSelector(selectCopyShapeMode);
  const checkAddCopyShape = useSelector(selectCheckAddShape);
  const { users } = useSelector(usersSelector);
  const copyShape = useSelector(getCopyShape);
  const [copyBox, setCopyBox] = useState(copyShape);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [leftIrisRadius, setLeftIrisRadius] = useState(leftRadius);
  const [rightIrisRadius, setRightIrisRadius] = useState(rightRadius);
  const polygon = useSelector(selectDrawingPolygon);
  const [checkMouseUp, setCheckMouseUp] = useState(false);
  const isCtrlPressing = useSelector(selectCtrlIsPressing);

  const [cssSetting, setCSSSetting] = useState({
    fill: currLabel && currLabel.color ? currLabel.color : "red",
    label: currLabel && currLabel.label ? currLabel.label : "",
    labelId: currLabel && currLabel.id ? currLabel.id : null,
  });

  const [imgPosition, setImgPosition] = useState(initialState.imgPosition);

  const [originalRetanglePoint, setOriginalRetanglePoint] = useState(
    initialState.originalRetanglePoint
  );
  const [rectangleProps, setRectangleProps] = useState(
    initialState.rectangleProps
  );

  const [selectedRect, setSelectedRectangle] = useState(
    initialState.selectedRect
  );
  const [isRectSelecting, setIsRectSelecting] = useState(false);

  const [curMousePos, setCurMousePos] = useState(null);
  const [centerRuler, setCenterRuler] = useState(null);

  const rectRef = useRef();
  const layerRef = useRef();
  const imageWrapper = useRef();

  const [formPosition, setFormPosition] = useState({ left: 0, top: 0 });

  useEffect(() => {
    if (!polygon?.points?.length > 0) {
      setCurMousePos(null);
    }
  }, [polygon?.points?.length]);

  useEffect(() => {
    setLeftIrisRadius(30);
    setRightIrisRadius(30);
  }, [imgUrl]);

  useEffect(() => {
    setLeftIrisRadius(leftRadius);
    setRightIrisRadius(rightRadius);
  }, [imgUrl, leftRadius, rightRadius]);

  useEffect(() => {
    setImgPosition(initialState.imgPosition);
  }, [imgUrl, fitCounter]);

  useEffect(() => {
    // force layer to draw
    layerRef.current && layerRef.current.draw();
  }, [drawCount]);
  useEffect(() => {
    if (copyShape) {
      setCopyBox({
        ...copyShape,
        dataPoint: {
          ...copyShape.dataPoint,
          x: mousePos.x / ratio - copyShape.dataPoint.width / 2,
          y: mousePos.y / ratio - copyShape.dataPoint.height / 2,
        },
      });
      return;
    }
    setCopyBox(null);
  }, [copyShape]);
  useEffect(() => {
    if (copyShapeMode && copyBox) {
      setCopyBox({
        ...copyBox,
        dataPoint: {
          ...copyBox.dataPoint,
          x: mousePos.x / ratio - copyBox.dataPoint.width / 2,
          y: mousePos.y / ratio - copyBox.dataPoint.height / 2,
        },
      });
    }
  }, [copyShapeMode]);

  const resetToInitDrawingState = () => {
    // reset state when change tool
    if (polygon?.points?.length > 0) {
      setPolygon(initialState.polygon);
    }
    setRectangleProps(initialState.rectangleProps);
    setSelectedRectangle(initialState.selectedRect);
    setCurMousePos(null);
    setCenterRuler(null);
    setOriginalRetanglePoint(initialState.originalRetanglePoint);
    document.body.style.cursor = "default";
  };
  useEffect(() => {
    resetToInitDrawingState();
  }, [currTool, checkAddCopyShape]);

  useEffect(() => {
    if (imageWrapper.current) {
      window.imageWrapper = imageWrapper.current;
    }
  }, [imageWrapper.current]);

  useEffect(() => {
    setCSSSetting({
      fill: currLabel && currLabel.color ? currLabel.color : "red",
      label: currLabel && currLabel.label ? currLabel.label : "",
      labelId: currLabel && currLabel.id ? currLabel.id : null,
    });
  }, [currLabel]);

  useEffect(() => {
    setRectangleProps({
      ...rectangleProps,
      id: `rect${0}-${currImageId}`,
    });
    setPolygon(initialState.polygon);
  }, [currImageId]);

  useEffect(() => {
    setFormPosition(getMinHeightMaxWidth(currentShape, metadata, ratio));
  }, [currentShape, ratio]);

  function onDragDraggable(event, data) {
    const { x, y } = data;
    if (isMoveMode) {
      document.body.style.cursor = "grabbing";
      setImgPosition({ x, y });
    }
  }

  function onStopDragging() {
    if (isMoveMode) document.body.style.cursor = "grab";
  }

  function handleMouseOver() {
    if (isMoveMode) {
      document.body.style.cursor = "grab";
      return;
    }
    if (isBoxMode || isCuboidMode || isOCRMode || isDrawingSegmentationArea) {
      document.body.style.cursor = "crosshair";
      return;
    }
    document.body.style.cursor = "default";
  }

  function handleMouseLeave() {
    document.body.style.cursor = "default";
    if (
      !isMoveMode &&
      !isBoxMode &&
      !isCuboidMode &&
      !isOCRMode &&
      !isDrawingSegmentationArea
    )
      hideSelectedRectangle();
  }
  const debounceSavePolyline = debounce((data) => savePolyline(data), 500);
  function handleMouseDown(event) {
    // // start calculator here!
    // if (!countingTime && currTool && currTool !== "move") {
    //   startCounting && startCounting();
    // }

    const id = event.target.getAttr("id");
    // const stage = event.target.getStage()
    if (
      event.target.getType() === "Shape" &&
      !id &&
      !isBoxMode &&
      !isCuboidMode &&
      !isOCRMode &&
      !isDrawingSegmentationArea
    ) {
      if (annotationType === "image-facial") {
        handleClickOnePoint(event);
        if (event.target.getType() === "Stage") {
          setSelectedPoints({});
          window.currPoint = {};
        }
        if (!currTool) handleStartDrawingSelectionRect(event);
      }
    }

    checkDeselect(event);
    if (isCtrlPressing) return;
    const { x: x1, y: y1 } = event.currentTarget.getPointerPosition();

    if (isBoxMode || isCuboidMode || isOCRMode || isDrawingSegmentationArea) {
      if (rectangleProps.x === 0 && rectangleProps.y === 0) {
        setRectangleProps({
          ...rectangleProps,
          stroke: cssSetting.fill,
          strokeWidth: 3,
          label: cssSetting.label,
          labelId: cssSetting.labelId,
          x: x1 / ratio,
          y: y1 / ratio,
          visible: true,
        });
        setOriginalRetanglePoint({
          ...rectangleProps,
          x: x1 / ratio,
          y: y1 / ratio,
          id: -1,
        });
      } else {
        document.body.style.cursor = "grab";
        const { x, y, width, height } = rectangleProps;

        if (isBoxMode) {
          saveBox({
            payload: rectangleProps,
          });
        }
        if (isCuboidMode) {
          saveCuboid({
            payload: {
              ...rectangleProps,
              dataPoint: [
                { x, y, width, height, uid: 0 },
                { x: x + 80, y: y - 80, width, height, uid: 1 },
              ],
            },
          });
        }
        if (isDrawingSegmentationArea) {
          if (!rectangleProps.width || !rectangleProps.height) {
            setRectangleProps(initialState.rectangleProps);
            setOriginalRetanglePoint(initialState.originalRetanglePoint);
            setCenterRuler(null);
            return;
          }
          // if (isDrawingSegmentationArea) {
          setDrawingSegmentation({
            x,
            y,
            width,
            height,
            isSaveAnnotationHistory: true,
          });
          // }
        }
        if (isOCRMode && annotationType && annotationType.includes("ocr")) {
          saveOCR({
            payload: {
              ...rectangleProps,
              editableTextValue: null,
              isVisibleTex: false,
            },
          });
        }
      }
    }
    if (isPolygonMode) {
      if (isMoveMode) return;
      if (event.evt.which === 3 && polygon.points.length >= 3) {
        savePolygon({
          payload: {
            labelId: polygon.labelId,
            dataPoint: polygon.points,
          },
        });
      } else {
        setPolygon({
          ...polygon,
          ...cssSetting,
          points: [...polygon.points, [x1 / ratio, y1 / ratio]],
          visible: true,
          color: cssSetting.fill,
          type: "polygon",
          isSaveAnnotationHistory: true,
        });
      }
    }
    if (isPolylineMode) {
      if (isMoveMode) return;
      if (event.evt.which === 3 && polygon.points.length >= 2) {
        debounceSavePolyline({
          payload: {
            labelId: polygon.labelId,
            dataPoint: polygon.points,
          },
        });
      } else {
        setPolygon({
          ...polygon,
          ...cssSetting,
          points: [...polygon.points, [x1 / ratio, y1 / ratio]],
          visible: true,
          color: cssSetting.fill,
          type: "polyline",
          isSaveAnnotationHistory: true,
        });
      }
    }
    if (copyShapeMode && copyBox) {
      if (event.evt.which === 1) {
        // left click
        saveBox({
          payload: {
            x: copyBox.dataPoint.x,
            y: copyBox.dataPoint.y,
            width: copyBox.dataPoint.width,
            height: copyBox.dataPoint.height,
            rotate: copyBox.dataPoint.rotate,
            visible: true,
            id: "rectagleProps",
            stroke: copyBox.color,
            strokeWidth: 2,
            label: copyBox.labelName,
            labelId: copyBox.labelId,
          },
        });
        dispatch(setCopyShapeMode(false));
      }
      if (event.evt.which === 3) {
        // right click
        dispatch(setCopyShapeMode(false));
        dispatch(setCopyShape(null));
      }
    }
  }

  const debounceSendMousePosition = debounce(
    (data) => dispatch(sendMousePosition(data)),
    300
  );
  function handleMouseMove(event) {
    if (!currTool) handleDrawingSelectionRect(event);
    const { x: x1, y: y1 } = originalRetanglePoint;
    const { x: x2, y: y2 } = event.currentTarget.getPointerPosition();
    if (users?.length) {
      debounceSendMousePosition({
        x: x2 / ratio,
        y: y2 / ratio,
        roomId: currImageId,
      });
    }

    setMousePos({
      x: x2,
      y: y2,
    });
    if (isBoxMode || isCuboidMode || isOCRMode || isDrawingSegmentationArea) {
      setCenterRuler({
        x: x2,
        y: y2,
      });
      if (rectangleProps.visible) {
        setRectangleProps({
          ...rectangleProps,
          x: Math.min(x1, x2 / ratio),
          y: Math.min(y1, y2 / ratio),
          width: Math.abs(x2 / ratio - x1),
          height: Math.abs(y2 / ratio - y1),
          visible: true,
        });
      }
    }

    if (
      (isPolygonMode && polygon.points.length > 0) ||
      (isPolylineMode && polygon.points.length > 0)
    ) {
      setCurMousePos([x2, y2]);
    }
    if (copyShapeMode && copyBox) {
      let newX = copyBox.dataPoint.x;
      let newY = copyBox.dataPoint.y;
      if (
        x2 / ratio >= copyBox.dataPoint.width / 2 &&
        x2 / ratio <= metadata.width - copyBox.dataPoint.width / 2
      ) {
        newX = x2 / ratio - copyBox.dataPoint.width / 2;
      }
      if (
        y2 / ratio >= copyBox.dataPoint.height / 2 &&
        y2 / ratio <= metadata.height - copyBox.dataPoint.height / 2
      ) {
        newY = y2 / ratio - copyBox.dataPoint.height / 2;
      }
      setCopyBox({
        ...copyBox,
        dataPoint: {
          ...copyBox.dataPoint,
          x: newX,
          y: newY,
        },
      });
    }
  }

  function handleMouseUpDiv() {
    if (!currTool) selectMultipleFacialPoints();
    if (isBoxMode || isCuboidMode || isOCRMode || isDrawingSegmentationArea) {
      document.body.style.cursor = "grab";
    }
    setCheckMouseUp(!checkMouseUp);
  }

  function findCircleWithId(event) {
    const group = event.target.getParent();
    return group.find((node) => {
      const groupId = node.getAttr("id");
      return groupId === 0 || groupId;
    })[0];
  }

  function handleClickOnePoint(event) {
    const circleNode = findCircleWithId(event);
    const id = circleNode && circleNode.getAttr("id");
    if (id && selectedPointIds.has(id)) return;

    const selectedPoint = pointsCanvas.find((point) => point.id === id);
    const payload = { [id]: { ...selectedPoint } };
    setSelectedPoints(payload);
  }

  function handleStartDrawingSelectionRect(event) {
    if (selectedPointIds.size > 0) return;
    const { x, y } = event.currentTarget.getPointerPosition();
    event.target.moveToBottom();
    if (event.target.getType() === "Stage") {
      setIsRectSelecting(true);
      const tempStartPosition = {
        ...selectedRect,
        x: x,
        y: y,
        width: 0,
        height: 0,
        visible: true,
      };
      setOriginalRetanglePoint(tempStartPosition);
      setSelectedRectangle(tempStartPosition);
      changeDrawCount();
    }
  }

  function hideSelectedRectangle() {
    setSelectedRectangle(initialState.selectedRect);
    setOriginalRetanglePoint(initialState.originalRetanglePoint);
    setIsRectSelecting(false);
  }

  function handleDrawingSelectionRect(event) {
    if (selectedRect.visible) {
      const { x: x1, y: y1 } = originalRetanglePoint;
      const { x: x2, y: y2 } = event.currentTarget.getPointerPosition();
      setSelectedRectangle({
        ...selectedRect,
        x: Math.min(x1, x2),
        y: Math.min(y1, y2),
        width: Math.abs(x2 - x1),
        height: Math.abs(y2 - y1),
        visible: true,
      });
    }
  }

  function selectMultipleFacialPoints() {
    if (!selectedRect.visible) {
      return;
    }
    const { x, y, width, height } = selectedRect;
    let selected = pointsCanvas.reduce((accum, point) => {
      if (
        point.x >= x &&
        point.x <= x + width &&
        point.y >= y &&
        point.y <= y + height
      ) {
        accum[point.id] = point;
      }
      return accum;
    }, {});
    !isEmpty(selected) && setSelectedPoints(selected);
    hideSelectedRectangle();
  }

  function resizeLeftIrisCircle(event) {
    const { x } = event.target.getAttrs();
    if (!x || x <= 0) return;
    setLeftIrisRadius(x / ratio);
  }

  function dispatchLeftRadius() {
    const payload = { type: "left", radius: leftIrisRadius };
    dispatchIrisRadius(payload);
  }

  function resizeRightIrisCircle(event) {
    const { x } = event.target.getAttrs();
    if (!x || x <= 0) return;
    setRightIrisRadius(x / ratio);
  }

  function dispatchRightRadius() {
    const payload = { type: "right", radius: rightIrisRadius };
    dispatchIrisRadius(payload);
  }

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      let removedCurrentShape = { ...currentShape };
      if (
        removedCurrentShape &&
        removedCurrentShape.dataPoint &&
        removedCurrentShape?.dataPoint?.isVisibleTex
      ) {
        removedCurrentShape.dataPoint.isVisibleTex = false;
      }
      setCurrentShape(removedCurrentShape);
      setCurrentShapeSuccess(null);
    }
  };

  const polyProps = isPolygonMode
    ? {
        polygon,
        isFinished: !isPolygonMode,
        ratio,
        curMousePos,
      }
    : {
        polygon,
        isFinished: !isPolylineMode,
        ratio,
        curMousePos,
        closed: false,
        strokeWidth: 3,
      };
  return (
    <div
      ref={imageWrapper}
      style={{
        height: "100%",
        position: "relative",
        backgroundColor: "#f3f3f3",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onMouseUp={handleMouseUpDiv}
      data-tour="main-image"
    >
      {<ShapeLabelForm />}
      <Draggable
        axis={isMoveMode ? "both" : "none"}
        position={{ x: imgPosition.x, y: imgPosition.y }}
        onDrag={onDragDraggable}
        onStop={onStopDragging}
        // bounds="parent"
      >
        <div className={style.canvasContainer}>
          {currentShape && currentShape.dataAttribute && (
            <ObjectAttribute
              currentShape={currentShape}
              showAttributeForm={!currentShape.closeAllAttribute}
              checkFormPosition={true}
              formPosition={formPosition}
              maxHeight={500}
            />
          )}

          <ReactReduxContext.Consumer>
            {({ store }) => (
              <Fragment>
                <Stage
                  className={style.stage}
                  onMouseOver={handleMouseOver}
                  onMouseLeave={handleMouseLeave}
                  onMouseMove={handleMouseMove}
                  onMouseDown={handleMouseDown}
                  // onMouseUp={handleMouseUp}
                  width={metadata.width * ratio}
                  height={metadata.height * ratio}
                  onFocus={() => {}}
                >
                  <Provider store={store}>
                    <Layer ref={layerRef}>
                      <IrisCirclesLayer
                        isIrisCirclesShown={isIrisCirclesShown}
                        linesData={linesData}
                        leftIrisRadius={leftIrisRadius}
                        dispatchLeftRadius={dispatchLeftRadius}
                        resizeLeftIrisCircle={resizeLeftIrisCircle}
                        ratio={ratio}
                        dispatchRightRadius={dispatchRightRadius}
                        resizeRightIrisCircle={resizeRightIrisCircle}
                        rightIrisRadius={rightIrisRadius}
                      />

                      <PointsLayer
                        points={points}
                        pointsCanvas={pointsCanvas}
                        setPoints={setPoints}
                        drawCount={drawCount}
                        selectedPoints={selectedPoints}
                        setSelectedPoints={setSelectedPoints}
                        changeDrawCount={changeDrawCount}
                        ratio={ratio}
                        opacity={opacity}
                        isReviewer={isReviewer}
                        openIssueForm={openIssueForm}
                        autoFillPointIndex={autoFillPointIndex}
                        displayingRegions={displayingRegions}
                        linesData={linesData}
                        isKeyPointShown={isKeyPointShown}
                        selectedPointIds={selectedPointIds}
                        isRectSelecting={isRectSelecting}
                        setSelectedRectangle={setSelectedRectangle}
                        selectedRectangle={selectedRect}
                      />
                      {isDrawingSegmentationArea && (
                        <SelectedZone
                          metadata={metadata}
                          ratio={ratio}
                          rectangleProps={rectangleProps}
                        />
                      )}

                      <ShapeLayer />

                      {/* box use to select multiple points */}
                      <Rect {...selectedRect} ref={rectRef} />
                      {/** copy box */}
                      {copyShapeMode && copyBox && (
                        <Rect
                          fill="rgba(0,0,0,0.3)"
                          stroke="black"
                          strokeWidth={1}
                          x={copyBox.dataPoint.x * ratio}
                          y={copyBox.dataPoint.y * ratio}
                          width={copyBox.dataPoint.width * ratio}
                          height={copyBox.dataPoint.height * ratio}
                          rotation={copyBox.dataPoint.rotate}
                        />
                      )}
                      <DrawingRectangle
                        rectangleProps={rectangleProps}
                        centerRuler={centerRuler}
                      />
                      {polygon.visible && <Polygon {...polyProps} />}
                      {/* <Mouse /> */}
                    </Layer>
                    {drawingSegmentation && (
                      <SegmentationLayer
                        cssSetting={cssSetting}
                        checkMouseUp={checkMouseUp}
                      />
                    )}
                  </Provider>
                </Stage>
              </Fragment>
            )}
          </ReactReduxContext.Consumer>
          {currentShape?.classesTypeCode === "ocr-image" &&
          currentShape?.dataPoint?.isVisibleTex ? (
            <EditableTextInput
              ratio={ratio}
              currentShape={currentShape}
              setCurrentShapeSuccess={setCurrentShapeSuccess}
            />
          ) : currentShape?.classesTypeCode === "ocr-image" &&
            !currentShape?.dataPoint?.editableTextValue &&
            !currentShape?.dataPoint?.isVisibleTex ? (
            <EditableTextInput
              ratio={ratio}
              currentShape={currentShape}
              setCurrentShapeSuccess={setCurrentShapeSuccess}
            />
          ) : null}

          <MainImage brightness={brightness} contrast={contrast} />

          {imageRotating && (
            <div
              className={style.spinner}
              style={{
                width: metadata.width * ratio,
                height: metadata.height * ratio,
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
      </Draggable>
    </div>
  );
}

ImageCanvas.propTypes = {
  points: arrayOf(object),
  pointsCanvas: arrayOf(object),
  metadata: object,
  ratio: number,
  drawCount: number,
  changeDrawCount: func,
  isReviewer: bool,
  setPoints: func,
  selectedPoints: shape({
    id: string,
    x: oneOfType([string, number]),
    y: oneOfType([string, number]),
  }),
  setSelectedPoints: func,
  isMoveMode: bool,
  imgUrl: string,
  contrast: number,
  brightness: number,
  opacity: number,
  openIssueForm: func,
  autoFillPointIndex: func,
  fitCounter: number,
  displayingRegions: any,
  linesData: objectOf(object),
  isIrisCirclesShown: bool,
  isKeyPointShown: bool,
  dispatchIrisRadius: func,
  isBoxMode: bool,
  selectedPointIds: object,
  leftRadius: number,
  rightRadius: number,
  currLabel: object,
  currImageId: string,
  saveBox: func,
  getShapes: func,
  currTool: string,
  isPolygonMode: bool,
  savePolygon: func,
  isPolylineMode: bool,
  savePolyline: func,
  setCurrentShapeSuccess: func,
  currentShape: object,
  isCuboidMode: bool,
  saveCuboid: func,
  annotationType: string,
  saveOCR: func,
  isOCRMode: bool,
  drawingSegmentation: object,
  isDrawingSegmentationArea: bool,
  setDrawingSegmentation: func,
  imageRotating: bool,
  isTourOpen: bool,
  stepIndex: number,
  countingTime: number,
  startCounting: func,
  setPolygon: func,
};

const mapState = (state) => {
  const { tourGuide } = state;
  const { isOpen: isTourOpen, stepIndex } = tourGuide;

  return {
    isMoveMode: selectIsMoveMode(state),
    isBoxMode: selectIsBoxMode(state),
    isPolygonMode: selectIsPolygonMode(state),
    isPolylineMode: selectIsPolylineMode(state),
    isCuboidMode: selectIsCuboidMode(state),
    metadata: selectImageMetadata(state),
    ratio: selectImageRatio(state),
    points: selectPoints(state),
    pointsCanvas: selectPointsCanvas(state),
    isIrisCirclesShown: selectIsIrisCirclesShown(state),
    isKeyPointShown: selectIsKeyPointShown(state),
    imgUrl: selectImageUrl(state),
    selectedPoints: makeSelectedPoint(state),
    drawCount: selectDrawCount(state),
    fitCounter: selectFitCounter(state),
    isReviewer: selectPermissionComponent("point_issue")(state),
    displayingRegions: selectDisplayingRegions(state),
    linesData: selectPointsCanvasByRegion(state),
    selectedPointIds: selectSelectedPointIds(state),
    leftRadius: selectLeftRadius(state),
    rightRadius: selectRightRadius(state),
    currLabel: selectCurrLabel(state),
    currImageId: selectImageId(state),
    currTool: makeCurrTool(state),
    segmentations: getSegmentations(state),
    currentShape: getCurrentShapes(state),
    annotationType: selectImageAnnotationType(state),
    isOCRMode: selectIsOCRMode(state),
    drawingSegmentation: getDrawingSegmentations(state),
    isDrawingSegmentationArea: checkDrawSegmentationArea(state),
    imageRotating: selectImageRotating(state),
    isTourOpen,
    stepIndex,
    countingTime: selectCountingTime(state),
  };
};

export default connect(mapState, {
  setPoints,
  openIssueForm,
  changeDrawCount,
  autoFillPointIndex,
  dispatchIrisRadius,
  saveBox,
  getShapes,
  savePolygon,
  savePolyline,
  setCurrentShapeSuccess,
  saveCuboid,
  saveOCR,
  setDrawingSegmentation,
  startCounting,
  setPolygon,
})(ImageCanvas);
