/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from "react";
import { object, number, string, element, bool, func } from "prop-types";
import Clear from "@material-ui/icons/Clear";
import Draggable from "react-draggable";
import style from "./style.module.scss";
import { injectIntl } from "react-intl";

const FloatingComponent = ({
  intl,
  maxHeight = 500,
  title,
  children,
  openProps = false,
  onClose,
}) => {
  const [open, setOpen] = useState(openProps);
  useEffect(() => {
    setOpen(openProps);
  }, [openProps]);

  const classes = style;

  const styleContainer = {
    position: "absolute",
    zIndex: 1001,
    borderRadius: "10px",
    textAlign: "center",
    // width: 250,
    boxSizing: "border-box",
    top: 0,
    left: 0,
  };

  const formStyle = () => ({
    ...styleContainer,
    maxHeight,
  });

  const onCloseFloatingContainer = () => {
    setOpen(false);
    onClose && onClose();
  };

  const getStyleOfFloatingForm = () => ({
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.73)",
    // border: "1px solid #8f8f8f",
  });

  return (
    <>
      {Boolean(open) && (
        <Draggable bounds="parent">
          <div
            className={classes.attributeLabelForm}
            style={{
              ...formStyle(),
              ...getStyleOfFloatingForm(),
            }}
          >
            <div className={classes.label}>
              <p>{title} </p>
              <button
                type="button"
                title={intl.formatMessage({ id: "common.close" })}
                onClick={onCloseFloatingContainer}
              >
                <Clear />
              </button>
            </div>
            <div className={classes.root}>{children}</div>
          </div>
        </Draggable>
      )}
    </>
  );
};

FloatingComponent.propTypes = {
  intl: object,
  formPosition: object,
  maxHeight: number,
  title: string,
  children: element,
  openProps: bool,
  onClose: func,
};

export default injectIntl(FloatingComponent);
