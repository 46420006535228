/* eslint-disable no-case-declarations */
import produce from "immer";
import { DESELECT_TOOL, SELECT_TOOL } from "redux/tools/constants";
import {
  SET_CURRENT_SEGMENTATION_TOOL,
  SET_CURRENT_SEGMENTATION_MODE,
  SET_DRAWING_SEGMENTATION,
  RESET_SEGMENTATION_MODE,
  SET_SEGMENTATION_LINEWIDTH,
  SET_IS_UNSAVE_SEGMENTATION,
  SET_ENGINE_NAME,
  SET_OPEN_SEGMENTATION_LIMITATION_MODAL,
} from "./constants";
import { engines } from "redux/segmentation/enums";

import { segTool } from "./enums";
export const initialState = {
  mode: "",
  tool: "",
  drawingSegmentation: null,
  lineWidth: 20,
  isUnsaveSegmentation: false,
  engineName: engines[0],
  isOpenLimitationModal: false,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SELECT_TOOL:
        if (action.payload !== "segmentation") {
          draft.mode = "";
          draft.tool = "";
        }
        break;
      case DESELECT_TOOL:
        draft.mode = "";
        draft.tool = "";
        break;
      case SET_DRAWING_SEGMENTATION:
        draft.drawingSegmentation = action.payload;
        break;
      case SET_SEGMENTATION_LINEWIDTH:
        draft.lineWidth = action.payload;
        break;
      case SET_CURRENT_SEGMENTATION_TOOL:
        draft.tool = action.payload;
        break;
      case SET_CURRENT_SEGMENTATION_MODE:
        draft.mode = action.payload;
        draft.tool = segTool.PEN_TOOL;
        break;
      case RESET_SEGMENTATION_MODE:
        draft.mode = "";
        draft.tool = "";
        draft.drawingSegmentation = null;
        draft.lineWidth = 20;
        draft.isUnsaveSegmentation = false;
        break;
      case SET_IS_UNSAVE_SEGMENTATION:
        draft.isUnsaveSegmentation = action.payload;
        break;
      case SET_ENGINE_NAME:
        draft.engineName = action.payload;
        break;
      case SET_OPEN_SEGMENTATION_LIMITATION_MODAL:
        draft.isOpenLimitationModal = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;
