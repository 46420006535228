export const SAVE_SEGMENTATION = "segmentation/SAVE_SEGMENTATION";
export const SAVE_SEGMENTATION_SUCCESS =
  "segmentation/SAVE_SEGMENTATION_SUCCESS";
export const SAVE_SEGMENTATION_ERROR = "segmentation/SAVE_SEGMENTATION_ERROR";

export const UPDATE_SEGMENTATION = "segmentation/UPDATE_SEGMENTATION";
export const UPDATE_SEGMENTATION_SUCCESS =
  "segmentation/UPDATE_SEGMENTATION_SUCCESS";
export const UPDATE_SEGMENTATION_ERROR =
  "segmentation/UPDATE_SEGMENTATION_ERROR";

export const SET_CURRENT_SEGMENTATION_TOOL =
  "segmentation/SET_CURRENT_SEGMENTATION_TOOL";

export const SET_CURRENT_SEGMENTATION_MODE =
  "segmentation/SET_CURRENT_SEGMENTATION_MODE";

export const SET_DRAWING_SEGMENTATION = "segmentation/SET_DRAWING_SEGMENTATION";

export const SET_IS_UNSAVE_SEGMENTATION =
  "segmentation/SET_IS_UNSAVE_SEGMENTATION";

export const DRAW_SEGMENTATION_BY_ENGINE =
  "segmentation/DRAW_SEGMENTATION_BY_ENGINE";

export const RESET_SEGMENTATION_MODE = "segmentation/RESET_SEGMENTATION_MODE";
export const DRAW_SEGMENTATION_BY_ENGINE_ERROR =
  "segmentation/DRAW_SEGMENTATION_BY_ENGINE_ERROR";

export const SET_SEGMENTATION_LINEWIDTH =
  "segmentation/SET_SEGMENTATION_LINEWIDTH";

export const SET_ENGINE_NAME = "segmentation/SET_ENGINE_NAME";
export const SET_OPEN_SEGMENTATION_LIMITATION_MODAL =
  "segmentation/SET_OPEN_SEGMENTATION_LIMITATION_MODAL";

export const CONFIRM_SMART_TOOL_REQUEST =
  "segmentation/CONFIRM_SMART_TOOL_REQUEST";
