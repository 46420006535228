import { all, takeEvery, call } from "redux-saga/effects";

import { socket } from "redux/socket/sagas";
import { sendMousePosition } from "redux/socket/eventHandlers/mouse";

import { SEND_MOUSE_POSITION } from "./constants";

function* watchSendMousePosition({ payload }) {
  yield call(sendMousePosition, socket, payload);
}

export default function* mouseSaga() {
  yield all([takeEvery(SEND_MOUSE_POSITION, watchSendMousePosition)]);
}
