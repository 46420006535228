import {
  SAVE_SEGMENTATION,
  SAVE_SEGMENTATION_SUCCESS,
  SAVE_SEGMENTATION_ERROR,
  UPDATE_SEGMENTATION,
  UPDATE_SEGMENTATION_SUCCESS,
  UPDATE_SEGMENTATION_ERROR,
  SET_CURRENT_SEGMENTATION_MODE,
  SET_CURRENT_SEGMENTATION_TOOL,
  SET_DRAWING_SEGMENTATION,
  DRAW_SEGMENTATION_BY_ENGINE,
  RESET_SEGMENTATION_MODE,
  DRAW_SEGMENTATION_BY_ENGINE_ERROR,
  SET_SEGMENTATION_LINEWIDTH,
  SET_IS_UNSAVE_SEGMENTATION,
  SET_ENGINE_NAME,
  SET_OPEN_SEGMENTATION_LIMITATION_MODAL,
  CONFIRM_SMART_TOOL_REQUEST,
} from "./constants";

export const saveSegmentation = ({ payload, callback }) => ({
  type: SAVE_SEGMENTATION,
  payload,
  callback,
});

export const setEngineName = (payload) => ({
  type: SET_ENGINE_NAME,
  payload,
});

export const setOpenSegmentationLimitationModal = (payload) => ({
  type: SET_OPEN_SEGMENTATION_LIMITATION_MODAL,
  payload,
});

export const saveSegmentationSuccess = (payload) => ({
  type: SAVE_SEGMENTATION_SUCCESS,
  payload,
});

export const saveSegmentationError = (payload) => ({
  type: SAVE_SEGMENTATION_ERROR,
  payload,
});

export const setCurrentSegmentationMode = (payload) => ({
  type: SET_CURRENT_SEGMENTATION_MODE,
  payload,
});
export const setCurrentSegmentationTool = (payload) => ({
  type: SET_CURRENT_SEGMENTATION_TOOL,
  payload,
});

export const updateSegmentation = ({ payload, id }) => ({
  type: UPDATE_SEGMENTATION,
  payload: {
    payload,
    id,
  },
});

export const updateSegmentationSuccess = (payload) => ({
  type: UPDATE_SEGMENTATION_SUCCESS,
  payload,
});

export const updateSegmentationError = (payload) => ({
  type: UPDATE_SEGMENTATION_ERROR,
  payload,
});

export const setDrawingSegmentation = (payload) => ({
  type: SET_DRAWING_SEGMENTATION,
  payload,
});

export const drawSegmentationByEngine = ({ payload, callback }) => ({
  type: DRAW_SEGMENTATION_BY_ENGINE,
  payload,
  callback,
});

export const drawSegmentationByEngineError = (payload) => ({
  type: DRAW_SEGMENTATION_BY_ENGINE_ERROR,
  payload,
});

export const resetSegmentationMode = () => {
  return {
    type: RESET_SEGMENTATION_MODE,
  };
};

export const setSegmentationLineWidth = (payload) => ({
  type: SET_SEGMENTATION_LINEWIDTH,
  payload,
});

export const setIsUnsaveSegmentation = (payload) => ({
  type: SET_IS_UNSAVE_SEGMENTATION,
  payload,
});

export const confirmSmartToolRequest = (payload) => ({
  type: CONFIRM_SMART_TOOL_REQUEST,
  payload,
});
