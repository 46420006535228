/* eslint-disable indent */
/**
 * Reporter contains all information about the things we draw, history, tags, etc.
 */
import React from "react";
// import AnnotationsTable from "components/AnnotationsTable";
// import IncidentTable from "components/IncidentTable";
import {
  string,
  number,
  bool,
  any,
  arrayOf,
  shape,
  objectOf,
  object,
  func,
} from "prop-types";
import { connect } from "react-redux";

import Issues from "containers/Issues";
import PointList from "components/PointList";
import ShapeList from "components/ShapeList";

import style from "./style.module.scss";
import Classification from "containers/Classification";
import Member from "containers/Member";
import Tag from "containers/Tag";
import {
  selectLabelerUserId,
  selectReviewerUserId,
} from "../../redux/image/selectors";

function Panels({ tabObj, labelerUserId, reviewerUserId }) {
  return (
    <div className={style.panelsContainer}>
      {tabObj.box ? (
        <div className={style.listContainer}>
          <ShapeList />
        </div>
      ) : null}
      {tabObj["facial landmarks"] ? (
        <div className={style.listContainer}>
          <PointList />
        </div>
      ) : null}
      {tabObj.issues ? (
        <div className={style.listContainer}>
          <Issues />
        </div>
      ) : null}
      <div className={style.listContainer}>
        <Classification />
      </div>
      {(labelerUserId || reviewerUserId) && (
        <div className={style.listContainer}>
          <Member
            labelerUserId={labelerUserId}
            reviewerUserId={reviewerUserId}
          />
        </div>
      )}
      <div className={style.listContainer}>
        <Tag />
      </div>
    </div>
  );
}

Panels.propTypes = {
  tabObj: object,
  labelerUserId: string,
  reviewerUserId: string,
  boxes: arrayOf(any),
  entities: shape({
    annotations: objectOf(any),
  }),
  annotations: arrayOf(string),
  // setFocusing: func,
  selectBoxTableRow: func,
  selectedBoxTableRow: string,
  shownBoxIncidents: arrayOf(object),
  boxesWithHide: arrayOf(object),
  updateBoxesWithHide: func,
  focusing: string,
  isPause: bool,
  pausedFrame: number,
  seekTo: func,
  deleteBox: func,
  setReplayPeriod: func,
  changeHideIncidentCounter: func,
  hideCounter: number,
};

const mapStateTopProps = (state) => ({
  labelerUserId: selectLabelerUserId(state),
  reviewerUserId: selectReviewerUserId(state),
});

export default connect(mapStateTopProps)(Panels);
