import React, { useEffect, useState, useRef } from "react";
import Select, { components } from "react-select";
import { arrayOf, func, object, bool, number } from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import chroma from "chroma-js";
import { injectIntl } from "react-intl";

import {
  selectLabelsForDropDown,
  selectCurrLabel,
} from "redux/points/selectors";
import { selectLabel } from "redux/points/actions";
import { selectPermissionComponent } from "redux/app/selectors";
import { selectIsSegmentationMode } from "redux/tools/selectors";
import { getIsUnsaveSegmentationSelector } from "redux/segmentation/selector";
import { selectIsFormOpened } from "redux/issues/selectors";
import { mapModeToIcon } from "components/ShapeList/icon";
import { getParameterByName } from "helpers";
import "./style.scss";
import IntlMessage from "../../helpers/IntlMessage";
import { selectIsUserTypingStatus } from "redux/keyboard/selectors";
import { setUserTypingStatus } from "redux/keyboard/action";

const CustomSelectInput = (props) => {
  const customProps = Object.assign({}, props);
  delete customProps.autoCorrect;
  delete customProps.autoCapitalize;
  return <components.Input {...customProps} />;
};

function LabelPicker({
  intl,
  labels,
  selectLabel,
  modifyPermission,
  currentLabel,
  isUnsaveSegmentation,
  width,
  isOpenedFormIssue,
}) {
  const defaultValueRef = useRef(true);
  const [selectedOption, setSelectedOption] = useState(labels[0]);
  const [displayOptions, setDisplayOptions] = useState([]);
  const isActionHistory = getParameterByName("isActionHistory");
  const isViewResult = getParameterByName("isViewResult");
  const isBenchmark = isViewResult || isActionHistory;
  const isUserTyping = useSelector(selectIsUserTypingStatus);
  const dispatch = useDispatch();
  const onFocus = () => {
    if (!isUserTyping) dispatch(setUserTypingStatus(true));
  };
  const onBlur = () => {
    dispatch(setUserTypingStatus(false));
  };
  useEffect(() => {
    if (labels?.length > 0 && defaultValueRef?.current) {
      const boxOptions = labels.filter((label) =>
        JSON.stringify(label.tools).includes("box")
      );
      const selectedOption = isBenchmark
        ? labels.find((label) => JSON.stringify(label.tools).includes("box"))
        : labels[0];
      setSelectedOption(selectedOption);
      selectLabel(selectedOption);
      if (isBenchmark) {
        setDisplayOptions(boxOptions);
      } else {
        setDisplayOptions(labels);
      }
      defaultValueRef.current = false;
    }
  }, [labels, defaultValueRef]);

  useEffect(() => {
    const checkKeyPress = (e) => {
      const maxIndexLabels = labels.length - 1;
      const checkIndexCurrentLabel = labels
        .map((label) => label.id)
        .indexOf(currentLabel.id);
      if (e.key === "m" && checkIndexCurrentLabel > 0) {
        setSelectedOption(labels[checkIndexCurrentLabel - 1]);
        selectLabel(labels[checkIndexCurrentLabel - 1]);
      }

      if (e.key === "n" && checkIndexCurrentLabel < maxIndexLabels) {
        setSelectedOption(labels[checkIndexCurrentLabel + 1]);
        selectLabel(labels[checkIndexCurrentLabel + 1]);
      }
    };

    if (!isOpenedFormIssue) {
      window.addEventListener("keypress", checkKeyPress, false);
      return () => window.removeEventListener("keypress", checkKeyPress, false);
    }
  }, [labels, currentLabel, isOpenedFormIssue]);

  const dot = (color = "#ccc") => ({
    alignItems: "center",
    display: "flex",
    maxWidth: "100%",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  useEffect(() => {
    setSelectedOption(currentLabel);
  }, [currentLabel]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      minWidth: width || "200px",
      cursor: "pointer",
      width,
    }),
    menu: (styles) => ({
      ...styles,
      minWidth: "200px",
    }),
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({
      ...styles,
      ...dot(),
      fontSize: "15px",
      fontWeight: 600,
      color: "#000",
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...dot(data.color),
      fontSize: "15px",
      fontWeight: 600,
    }),
    option: (styles, { data, isDisabled, isSelected }, provided) => {
      const color = chroma.valid(data.color) ? chroma(data.color) : data.color;
      return {
        ...styles,
        color: isDisabled ? "#ccc" : isSelected ? "white" : "black",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },

        ...provided,
        paddingBottom: "10px",
        paddingLeft: "10px",
        height: "50px",
        overflow: "hidden",
      };
    },
  };

  function handleChange(selectedOptionValue) {
    // console.log("selectedOptionValue", selectedOptionValue);
    if (!isUserTyping) {
      dispatch(setUserTypingStatus(true));
    }
    // if (e.keyCode === 27) {
    //   dispatch(setUserTypingStatus(false));
    // }
    if (isUnsaveSegmentation) {
      if (
        !window.confirm(
          intl.formatMessage({ id: "input-data-picker.leave-page-warning" })
        )
      )
        return;
    }

    setSelectedOption(selectedOptionValue);
    selectLabel(selectedOptionValue);
  }
  const formatOptionLabel = (data) => {
    const { label, tools, color } = data;
    // console.log("tools", tools);
    const Icon = tools && mapModeToIcon && mapModeToIcon[tools[0]];
    return (
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "10px", color: "#ccc" }}>
          {Icon && (
            <Icon
              // color={color}
              color={"inherit"}
              stroke={color}
              style={{ width: 20, height: 20 }}
            />
          )}
        </div>
        <div style={{ fontSize: 15, fontWeight: 600, width: "110px" }}>
          {label}
        </div>
      </div>
    );
  };
  return (
    <>
      <span className="label">
        <IntlMessage id="common.choose-class" />
      </span>
      <div data-tour="choose-class-input">
        <Select
          placeholder={intl.formatMessage({ id: "common.choose-a-class" })}
          className="container"
          components={{ Input: CustomSelectInput }}
          name="form-field-name"
          value={selectedOption}
          onChange={handleChange}
          options={displayOptions}
          styles={colourStyles}
          defaultValue={labels[0]}
          blurInputOnSelect
          isDisabled={!modifyPermission}
          formatOptionLabel={formatOptionLabel}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    </>
  );
}

LabelPicker.propTypes = {
  intl: object,
  labels: arrayOf(object),
  isSegmentationMode: bool,
  selectLabel: func,
  modifyPermission: bool,
  selectTool: func,
  drawingSegmentation: object,
  currentLabel: object,
  isUnsaveSegmentation: bool,
  width: number,
  isOpenedFormIssue: bool,
};

const mapStateToProps = (state) => {
  return {
    labels: selectLabelsForDropDown()(state),
    modifyPermission: selectPermissionComponent("labeling_modify")(state),
    isSegmentationMode: selectIsSegmentationMode(state),
    currentLabel: selectCurrLabel(state),
    isUnsaveSegmentation: getIsUnsaveSegmentationSelector(state),
    isOpenedFormIssue: selectIsFormOpened(state),
  };
};

export default connect(mapStateToProps, {
  selectLabel,
})(injectIntl(LabelPicker));
