import IntlMessage from "../../helpers/IntlMessage";
import AddIcon from "@material-ui/icons/Add";
import { debounce } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import AsyncSelect from "react-select/lib/Async";
import { searchProjectTagByText } from "redux/image/actions";
import classNames from "classnames";
import { any, func, bool } from "prop-types";
import "./styles.scss";
import { selectProjectId } from "redux/app/selectors";

const CreateTagAutoCompleteCOM = ({
  intl,
  onChange,
  allowAdd,
  value,
  ...rest
}) => {
  const projectId = useSelector(selectProjectId);
  const dispatch = useDispatch();

  const handleDebounceLoadOptions = debounce((inputValue, callback) => {
    dispatch(
      searchProjectTagByText({
        payload: {
          projectId: projectId,
          textSearch: inputValue.trim(),
        },
        callback: (res) => {
          const dataOptions = res?.tags || [];
          if (!allowAdd) {
            callback(dataOptions);
            return;
          }
          const checkCreateNewTag = res?.tags?.find(
            (item) => item.tag_name === inputValue
          );
          callback(
            checkCreateNewTag
              ? dataOptions
              : [
                  {
                    tag_id: -1,
                    tag_name: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <AddIcon fontSize="medium" />
                        <span style={{ marginLeft: "5px" }}>
                          <IntlMessage id="common.create-tag" />
                          <strong> {` ${inputValue.trim()}`}</strong>
                        </span>
                      </div>
                    ),
                    name: inputValue.trim(),
                  },
                  ...dataOptions,
                ]
          );
        },
      })
    );
  }, 300);

  const handleLoadOptions = (inputValue, callback) => {
    if (!inputValue?.trim()) return callback([]);
    handleDebounceLoadOptions(inputValue, callback);
  };

  const handleChangeTagValue = (value) => {
    onChange && onChange(value);
  };

  return (
    <AsyncSelect
      noOptionsMessage={() => intl.formatMessage({ id: "common.no-options" })}
      className={classNames("tw-flex-grow fake-create-tag-select")}
      classNamePrefix="select"
      styles={{
        control: (base, state) => ({
          ...base,
          height: "fit-content",
          border: state.isFocused ? 0 : 0,
          boxShadow: state.isFocused ? 0 : 0,
          "&:hover": {
            border: state.isFocused ? 0 : 0,
          },
          "& input": {
            marginBottom: "5px",
          },
        }),
      }}
      cacheOptions
      loadOptions={handleLoadOptions}
      placeholder=""
      getOptionLabel={(option) => option.tag_name}
      getOptionValue={(option) => option.tag_id}
      onChange={handleChangeTagValue}
      value={value}
      {...rest}
    />
  );
};

CreateTagAutoCompleteCOM.propTypes = {
  intl: any,
  onChange: func,
  allowAdd: bool,
  value: any,
  className: any,
  rest: any,
};

export const CreateTagAutoComplete = injectIntl(CreateTagAutoCompleteCOM);
