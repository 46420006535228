import produce from "immer";

import {
  SELECT_TOOL_SUCCESS,
  DESELECT_TOOL_SUCCESS,
  PICK_START_TIME,
  PICK_END_TIME,
  CHANGE_TEMPORAL_BUTTON,
  LIMITATION_SEGMENTATION_TOOL,
  LIMITATION_SEGMENTATION_TOOL_SUCCESS,
} from "./constants";
import { SELECT_LABEL } from "redux/points/constants";

import * as allTools from "./enums";

// The initial state of the App
export const initialState = {
  currTool: "",
  autoIrisCircleLoading: false,
  limitation: {
    segmentation: {
      remainRequest: 0,
    },
  },
  temporal: {
    startMs: null,
    endMs: null,
    text: "Start",
  },
};

const toolsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SELECT_TOOL_SUCCESS:
        draft.currTool = action.payload;
        if (action.payload === allTools.AUTO_IRIS_MODE) {
          draft.autoIrisCircleLoading = true;
        }
        break;
      case DESELECT_TOOL_SUCCESS:
        draft.currTool = "";
        draft.autoIrisCircleLoading = false;
        break;

      case PICK_START_TIME:
        draft.temporal.startMs = action.payload;
        draft.temporal.text = "End";
        break;

      case PICK_END_TIME:
        draft.temporal.endMs = action.payload;
        draft.temporal.text = "Start";
        break;

      case CHANGE_TEMPORAL_BUTTON:
        draft.temporal.text = action.payload;
        break;

      case SELECT_LABEL:
        if (!action.payload.tools?.includes(`${draft.currTool}-image`)) {
          draft.currTool = "";
        }
        break;
      case LIMITATION_SEGMENTATION_TOOL:
        break;
      case LIMITATION_SEGMENTATION_TOOL_SUCCESS:
        draft.limitation.segmentation.remainRequest = action.payload;
        break;
      default:
        break;
    }
  });

export default toolsReducer;
