import {
  SAVE_BOX,
  SAVE_BOX_SUCCESS,
  SAVE_BOX_ERROR,
  UPDATE_BOX,
  UPDATE_BOX_SUCCESS,
  UPDATE_BOX_ERROR,
} from "./constants";

export const saveBox = ({ payload, callback, callbackSaga }) => ({
  type: SAVE_BOX,
  payload,
  callback,
  callbackSaga,
});

export const saveBoxSuccess = (payload) => ({
  type: SAVE_BOX_SUCCESS,
  payload,
});

export const saveBoxError = (payload) => ({
  type: SAVE_BOX_ERROR,
  payload,
});

export const updateBox = (payload) => ({
  type: UPDATE_BOX,
  payload,
});

export const updateBoxSuccess = (payload) => ({
  type: UPDATE_BOX_SUCCESS,
  payload,
});

export const updateBoxError = (payload) => ({
  type: UPDATE_BOX_ERROR,
  payload,
});
