import { prepareDataDelete } from "./prepareDataDelete";
import { prepareDataSave } from "./prepareDataSave";
import {
  prepareUndoDataUpdate,
  prepareRedoDataUpdate,
} from "./prepareDataUpdate";
import { select } from "redux-saga/effects";
import {
  selectAnnotationHistory,
  selectCurrentTarget,
} from "redux/annotationHistory/selectors";
import { SET_DRAWING_POLYGON } from "redux/shape/constants";
import { initialState } from "redux/shape/reducer";
import { SET_DRAWING_SEGMENTATION } from "redux/segmentation/constants";

const mapMethodToFuntion = {
  delete: prepareDataSave,
  save: prepareDataDelete,
  update: prepareUndoDataUpdate,
};

const mapMethodRedoToFuntion = {
  save: prepareDataSave,
  delete: prepareDataDelete,
  update: prepareRedoDataUpdate,
};

export function* prepareUndoData({ type, payload, sourcePayload }) {
  const history = yield select(selectAnnotationHistory);
  const currentTarget = yield select(selectCurrentTarget);
  const currentIndex = history.findIndex((item) => item.id === currentTarget);
  const preHistoryItem = history[currentIndex - 1];
  // handle ctrl + Z points when drawing a polygon (it also can be used for polyline)
  if (type === SET_DRAWING_POLYGON) {
    return {
      type,
      payload:
        preHistoryItem?.type === SET_DRAWING_POLYGON
          ? preHistoryItem?.payload
          : initialState.drawingPolygon,
    };
  }
  if (type === SET_DRAWING_SEGMENTATION) {
    return preHistoryItem?.type === SET_DRAWING_SEGMENTATION
      ? {
          type,
          payload: preHistoryItem?.payload,
        }
      : {};
  }
  const methods = Object.keys(mapMethodToFuntion);
  for (let index = 0; index < methods.length; index++) {
    if (type?.toLowerCase().includes(methods[index])) {
      const fn = mapMethodToFuntion[methods[index]];
      if (fn) return fn(payload, sourcePayload, type);
    }
  }
}

export function* prepareRedoData({ type, payload, sourcePayload }) {
  const history = yield select(selectAnnotationHistory);
  const currentTarget = yield select(selectCurrentTarget);
  const currentIndex = history.findIndex((item) => item.id === currentTarget);
  const nextHistoryItem = history[currentIndex + 1];

  if (type === SET_DRAWING_POLYGON) {
    if (nextHistoryItem?.type === SET_DRAWING_POLYGON) {
      return {
        type,
        payload: nextHistoryItem?.payload,
      };
    }
  }
  if (type === SET_DRAWING_SEGMENTATION) {
    return nextHistoryItem?.type === SET_DRAWING_SEGMENTATION
      ? {
          type,
          payload: nextHistoryItem?.payload,
        }
      : {};
  }

  const methods = Object.keys(mapMethodToFuntion);
  for (let index = 0; index < methods.length; index++) {
    if (type?.toLowerCase().includes(methods[index])) {
      const fn = mapMethodRedoToFuntion[methods[index]];
      if (fn) return fn(payload, sourcePayload, type);
    }
  }
}
