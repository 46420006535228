import { createSelector } from "reselect";
import isEmpty from "lodash.isempty";
import { selectPointsCanvas } from "redux/canvas/selectors";
import * as allTools from "./enums";
import { getCurrentShapes } from "redux/shape/selector";
import { selectCtrlIsPressing } from "redux/keyboard/selectors";

const makeCurrTool = (state) => state.tools.currTool;

const selectIsMoveMode = createSelector(
  makeCurrTool,
  getCurrentShapes,
  selectCtrlIsPressing,
  (currTool, currentShape, keyCtrlIsPressing) => {
    if (currentShape)
      return (
        currentShape.classesTypeCode ===
          `${allTools.SEGMENTATION_MODE}-image` && keyCtrlIsPressing
      );
    return currTool === allTools.MOVE_MODE || keyCtrlIsPressing;
  }
);

// const selectIsMoveMode = (state) => state.tools.currTool === allTools.MOVE_MODE;
const selectIsBoxMode = (state) => state.tools.currTool === allTools.BOX_MODE;
const selectIsCuboidMode = (state) =>
  state.tools.currTool === allTools.CUBOID_MODE;

const selectIsPolygonMode = (state) =>
  state.tools.currTool === allTools.POLYGON_MODE;

const selectIsPolylineMode = (state) =>
  state.tools.currTool === allTools.POLYLINE_MODE;

const selectIsSegmentationMode = (state) =>
  state.tools.currTool === allTools.SEGMENTATION_MODE;

const selectIsOCRMode = (state) => state.tools.currTool === allTools.OCR;

const selectAutoIrisCircleLoading = (state) =>
  state.tools.autoIrisCircleLoading;

const selectIsDisableMultipleSelection = createSelector(
  selectPointsCanvas,
  (points) => !points.length || isEmpty(points)
);

const selectLimitationSegmentationTool = (state) =>
  state.tools.limitation.segmentation.remainRequest;

export {
  selectIsDisableMultipleSelection,
  makeCurrTool,
  selectIsMoveMode,
  selectAutoIrisCircleLoading,
  selectIsBoxMode,
  selectIsPolygonMode,
  selectIsPolylineMode,
  selectIsSegmentationMode,
  selectIsCuboidMode,
  selectIsOCRMode,
  selectLimitationSegmentationTool,
};
