import { createSelector } from "reselect";
import { getAllShapes } from "redux/shape/selector";
import { getCookie } from "helpers";
export const selectImageMetadata = (state) => state.image.metadata;
export const selectLimit = (state) => state.image.limit;
export const selectImages = (state) => state.image.allImages;
export const selectAllImgIds = (state) => {
  const sourceInFoInCookie = getCookie("sourceInfo");
  const sourceId =
    sourceInFoInCookie && JSON.parse(sourceInFoInCookie).sourceId;
  const allImageIds = [...state.image.allImgIds];
  const indexSourceId = allImageIds.indexOf(sourceId);
  if (indexSourceId !== -1) {
    const imageSourceId = allImageIds.splice(indexSourceId, 1);
    allImageIds.unshift(imageSourceId[0]);
    return allImageIds;
  }
  return allImageIds;
};
export const selectNextImageId = (state) => state.image.nextImageId;

export const selectFetchMore = (state) => state.image.fetchMore;
export const selectImageId = (state) => state.image.imageId;
export const selectCurrPage = (state) => state.image.currPage;
export const selectImageUrl = (state) => state.image.imageUrl;
export const selecOriginalImageUrl = (state) => state.image.originalUrl;

export const selectCheckLoadLargeImage = (state) =>
  Boolean(
    state.image.largeImagePreprocessor &&
      state.image.largeImagePreprocessor.preprocessorArr &&
      state.image.largeImagePreprocessor.preprocessorArr.length > 0
  );

export const selectLargeImagePreprocessor = (state) =>
  state.image.largeImagePreprocessor.preprocessorArr || [];

export const selectLargeImagePreprocessorZoomLevels = (state) => {
  let zoomLevels =
    (state.image.largeImagePreprocessor &&
      state.image.largeImagePreprocessor.zoomLevels) ||
    [];
  if (zoomLevels.length > 0)
    zoomLevels = zoomLevels.sort((a, b) => Number(a) - Number(b));
  return zoomLevels;
};
export const selectLargeImagePreprocessorByZoomLevel = (level = 1) => (state) =>
  state.image.largeImagePreprocessor &&
  state.image.largeImagePreprocessor.preprocessorArr
    ? state.image.largeImagePreprocessor.preprocessorArr.filter(
        (image) => Number(image.zoomLevel) === Number(level)
      )
    : [];

export const selectImageIndex = (state) => state.image.imageIndex;
export const selectImageAnnotationType = (state) => state.image.annotationType;

export const selectImageStatus = (state) => state.image?.status;
export const selectFitCounter = (state) => state.image.fitCounter;
export const selectLabelerAndReviwerName = (state) => ({
  labelerName: state.image.labelerName || "abcjsnfjkj",
  reviewerName: state.image.reviewerName,
});
export const selectImgWidth = (state) => state.image.metadata.width;
export const selectImgHeight = (state) => state.image.metadata.height;
export const selectOriginalUrl = (state) => state.image.originalUrl;
export const selectIsFetchingData = (state) => state.image.isFetchingData;

export const selectPaginationByStatus = (state) =>
  state.image.paginationByStatus;

export const selectFilterBtnStatus = (state) => state.image.filterBtnStatus;

export const selectThumbnailURL = (state) => state.image.thumbnailURL;

export const selectImageInfo = (state) => state.image;
export const selectImageRotating = (state) => state.image.rotating;

export const hasAnnotation = createSelector(
  getAllShapes,
  (shapes) => shapes?.length > 0
);

export const selectImgFake = (state) => state.image.imgFake;

export const selectIsBenchmarkProject = (state) =>
  state.image.isBenchmarkProject;
export const selectIsBenchmark = (state) => state.image.isBenchmark;
export const selectAnnotatedBy = (state) => state.image.annotatedBy;
export const selectLabelerUserId = (state) => state.image.labelerUserId;
export const selectReviewerUserId = (state) => state.image.reviewerUserId;
export const selectIsScrollImage = (state) => state.image.scrollImage;
export const selectAnnotatedById = (state) => state.image.annotatedById;
export const selectImageTags = (state) => state.image.tags;
export const selectFilterTags = (state) => state.image.filterTagIds;
export const selectImageRotationDirection = (state) =>
  state.image.imageRotationDirection;
