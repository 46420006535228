import produce from "immer";
import {
  OPEN_ISSUE_FORM,
  ADD_ISSUE,
  ADD_ISSUE_SUCCESS,
  ADD_ISSUE_FAILURE,
  ADD_CHILD_ISSUE,
  ADD_CHILD_ISSUE_SUCCESS,
  ADD_CHILD_ISSUE_FAILURE,
  CLOSE_ISSUE_FORM,
  FETCH_ISSUES,
  FETCH_ISSUES_SUCCESS,
  FETCH_ISSUES_FAILURE,
  FETCH_CHILD_ISSUE,
  FETCH_CHILD_ISSUE_SUCCESS,
  FETCH_CHILD_ISSUE_FAILURE,
  EDIT_ISSUE,
  EDIT_ISSUE_SUCCESS,
  EDIT_ISSUE_FAILURE,
  DELETE_ISSUE,
  DELETE_ISSUE_SUCCESS,
  DELETE_ISSUE_FAILURE,
  RESOLVE_ISSUE,
  RESOLVE_ISSUE_SUCCESS,
  RESOLVE_ISSUE_FAILURE,
  REOPEN_ISSUE,
  REOPEN_ISSUE_SUCCESS,
  REOPEN_ISSUE_FAILURE,
} from "./actions";
import { AUTO_FILL_POINT_INDEX } from "redux/points/constants";
import { SET_CURRENT_SHAPE_SUCCESS } from "redux/shape/constants";

export const initialState = {
  allIssues: [],
  isFormOpened: false,
  selectedIssue: { content: "", issueId: "" },
  loading: false,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_ISSUES:
        draft.loading = true;
        break;

      case FETCH_ISSUES_SUCCESS:
        draft.loading = false;
        draft.allIssues = action.payload;
        break;

      case FETCH_ISSUES_FAILURE:
        draft.loading = false;
        break;

      case FETCH_CHILD_ISSUE:
        draft.loading = true;
        break;

      case FETCH_CHILD_ISSUE_SUCCESS: {
        const { parentId, childIssues } = action.payload;

        draft.loading = false;
        draft.allIssues = draft.allIssues.reduce((issues, item) => {
          if (item.issueId === parentId) {
            return [...issues, { ...item, childIssues }];
          }
          return [...issues, { ...item }];
        }, []);
        break;
      }

      case FETCH_CHILD_ISSUE_FAILURE:
        draft.loading = false;
        break;

      case OPEN_ISSUE_FORM: {
        const { content = "", issueId = "" } = action.payload || {};
        draft.isFormOpened = true;
        draft.selectedIssue = { content, issueId };
        break;
      }

      case CLOSE_ISSUE_FORM:
        draft.isFormOpened = false;
        draft.selectedIssue = { ...initialState.selectedIssue };
        break;

      case ADD_ISSUE:
        draft.loading = true;
        break;

      case ADD_ISSUE_SUCCESS:
        draft.allIssues = [action.payload, ...state.allIssues];
        draft.labelName = null;
        draft.annotationId = null;
        draft.frame = null;
        draft.loading = false;
        break;

      case ADD_ISSUE_FAILURE:
        draft.loading = false;
        break;

      case ADD_CHILD_ISSUE:
        draft.loading = true;
        break;

      case ADD_CHILD_ISSUE_SUCCESS: {
        const newChild = action.payload;
        const lastChildrenCreatedDate = action.payload.createdDate;

        const newIssues = state.allIssues.map((item) => {
          if (item.issueId === newChild.parentId) {
            if (item.childIssues) {
              return {
                ...item,
                countChildren: item.childIssues.length + 1,
                lastChildrenCreatedDate,
                childIssues: [...item.childIssues, { ...newChild }],
              };
            }
            return {
              ...item,
              countChildren: 1,
              lastChildrenCreatedDate,
              childIssues: [{ ...newChild }],
            };
          }
          return item;
        });

        draft.allIssues = newIssues;
        draft.loading = false;
        break;
      }

      case ADD_CHILD_ISSUE_FAILURE:
        draft.loading = false;
        break;

      case EDIT_ISSUE:
        draft.loading = true;
        break;

      case EDIT_ISSUE_SUCCESS: {
        draft.loading = false;

        const { issueId, parentId } = action.payload;

        if (parentId) {
          draft.allIssues = draft.allIssues.map((item) => {
            if (item.issueId === parentId) {
              return {
                ...item,
                childIssues: item.childIssues.map((childItem) => {
                  if (childItem.issueId === issueId) {
                    return { ...childItem, ...action.payload };
                  }
                  return childItem;
                }),
              };
            }

            return item;
          });
        } else {
          draft.allIssues = draft.allIssues.map((item) => {
            if (item.issueId === issueId) {
              return {
                ...item,
                ...action.payload,
              };
            }

            return item;
          });
        }

        break;
      }

      case EDIT_ISSUE_FAILURE:
        draft.loading = false;
        break;

      case DELETE_ISSUE:
        draft.loading = true;
        break;

      case DELETE_ISSUE_SUCCESS: {
        draft.loading = false;

        const { issueId, parentId } = action.payload;

        if (parentId) {
          draft.allIssues = draft.allIssues.map((item) => {
            if (item.issueId === parentId) {
              const childIssues = item.childIssues.filter(
                (childItem) => childItem.issueId !== issueId
              );
              return {
                ...item,
                countChildren: item.childIssues.length - 1,
                lastChildrenCreatedDate: childIssues.length
                  ? childIssues[childIssues.length - 1].createdDate
                  : null,
                childIssues,
              };
            }
            return item;
          });
        } else {
          draft.allIssues = draft.allIssues.filter(
            (item) => item.issueId !== issueId
          );
        }
        break;
      }

      case DELETE_ISSUE_FAILURE:
        draft.loading = false;
        break;

      case RESOLVE_ISSUE:
        draft.loading = true;
        break;

      case RESOLVE_ISSUE_SUCCESS:
        draft.allIssues = draft.allIssues.map((issue) => {
          if (issue.issueId === action.payload.issueId) {
            return {
              ...issue,
              ...action.payload,
            };
          }
          return issue;
        });
        draft.loading = false;
        break;

      case RESOLVE_ISSUE_FAILURE:
        draft.loading = false;
        break;

      case REOPEN_ISSUE:
        draft.loading = true;
        break;

      case REOPEN_ISSUE_SUCCESS:
        draft.allIssues = draft.allIssues.map((issue) => {
          if (issue.issueId === action.payload.issueId) {
            return {
              ...issue,
              ...action.payload,
            };
          }
          return issue;
        });
        draft.loading = false;
        break;

      case REOPEN_ISSUE_FAILURE:
        draft.loading = false;
        break;

      case AUTO_FILL_POINT_INDEX:
        draft.isFormOpened = true;
        break;
      case SET_CURRENT_SHAPE_SUCCESS:
        draft.isFormOpened = false;
        break;
      default:
        break;
    }
  });

export default reducer;
