import React from "react";
import { connect } from "react-redux";
import { Image as KonvaImage } from "react-konva";
import { selectImageRatio } from "redux/canvas/selectors";
import { object, number, bool, func } from "prop-types";
import useImage from "use-image";

function InnerSegmentation({ segmentation, ratioProps, onSelect }) {
  const [image] = useImage(segmentation.base64Data);
  return (
    <>
      <KonvaImage
        key={segmentation.id}
        image={image}
        x={segmentation.x * ratioProps}
        y={segmentation.y * ratioProps}
        opacity={0.5}
        width={segmentation.width * ratioProps}
        height={segmentation.height * ratioProps}
        onClick={onSelect}
      />
    </>
  );
}

InnerSegmentation.propTypes = {
  drawingConfig: object,
  segmentation: object,
  ratioProps: number,
  isEraser: bool,
  onSelect: func,
};

const mapState = (state) => ({
  ratioProps: selectImageRatio(state),
});

export default connect(mapState)(InnerSegmentation);
