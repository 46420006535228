import React, { useState, useEffect } from "react";
import { object, func } from "prop-types";
import { injectIntl } from "react-intl";

import BoxIcon from "./BoxIcon";

import styles from "./style.module.scss";

function Tabs({ intl, tabObj, setTabObj }) {
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 992) {
      setCollapse(true);
    }
  }, []);

  const handleCollapse = () => {
    const containerEl = document.getElementById("container");
    if (collapse) {
      containerEl.style.gridTemplateColumns =
        "50px 200px 4fr minmax(350px, 1fr)";
    } else {
      containerEl.style.gridTemplateColumns = "50px 200px auto 25px";
    }
    setCollapse(!collapse);
  };

  const handleClick = (selectedTab) => () => {
    if (selectedTab) {
      setTabObj((prevState) => {
        const tabStatus = prevState[selectedTab];
        let { countSelectedTab } = prevState;
        countSelectedTab += tabStatus ? -1 : 1;
        if (countSelectedTab < 1) {
          // eslint-disable-next-line no-alert
          alert(
            intl.formatMessage({ id: "reporter.must-have-open-tab-alert" })
          );
          return prevState;
        }

        return {
          ...prevState,
          countSelectedTab,
          [selectedTab]: !tabStatus,
        };
      });
    }
  };

  return (
    <div className={styles.tabsContainer}>
      <button
        type="button"
        className={styles.collapseBtn}
        onClick={handleCollapse}
      >
        <span>
          {collapse
            ? intl.formatMessage({ id: "reporter.expand" })
            : intl.formatMessage({ id: "reporter.collapse" })}
        </span>
      </button>
      {Object.keys(tabObj).map((tab) => {
        if (tab === "countSelectedTab") return null;

        return (
          <button
            type="button"
            key={tab}
            onClick={handleClick(tab)}
            className={tabObj[tab] ? styles.activeTab : styles.tab}
          >
            <BoxIcon />
            <span>{intl.formatMessage({ id: tab })}</span>
          </button>
        );
      })}
    </div>
  );
}

Tabs.propTypes = {
  intl: object,
  tabObj: object,
  setTabObj: func,
};

export default injectIntl(Tabs);
