import React from "react";
import { Circle, Text } from "react-konva";

import { useSelector } from "react-redux";
import { object } from "prop-types";
import Rectangle from "components/Rectangle";
import RulerLine from "components/RulerLine";

import { checkDrawSegmentationArea } from "redux/segmentation/selector";
import { selectImageRatio } from "redux/canvas/selectors";
import {
  selectIsBoxMode,
  selectIsCuboidMode,
  selectIsOCRMode,
} from "redux/tools/selectors";
import { selectImageMetadata } from "redux/image/selectors";
export default function DrawingRectangle({ rectangleProps, centerRuler }) {
  const isDrawingSegmentationArea = useSelector(checkDrawSegmentationArea);
  const ratio = useSelector(selectImageRatio);
  const isBoxMode = useSelector(selectIsBoxMode);
  const isCuboidMode = useSelector(selectIsCuboidMode);
  const isOCRMode = useSelector(selectIsOCRMode);
  const metadata = useSelector(selectImageMetadata);

  const checkToolsAllowShowRectangle =
    isBoxMode || isCuboidMode || isDrawingSegmentationArea || isOCRMode;
  const checkShowBox = Boolean(
    checkToolsAllowShowRectangle &&
      (rectangleProps.x || rectangleProps.y) &&
      rectangleProps.visible
  );

  const checkShowPointBox = Boolean(
    checkToolsAllowShowRectangle &&
      (rectangleProps.x || rectangleProps.y) &&
      (rectangleProps.width || rectangleProps.height) &&
      rectangleProps.visible
  );

  return (
    <>
      {checkShowBox && (
        <Rectangle
          rectangleProps={{
            ...rectangleProps,
            dash: isDrawingSegmentationArea ? [10, 10] : null,
          }}
          ratio={ratio}
          rectangle={0.5}
        />
      )}
      {checkShowBox && (
        <>
          <Text
            fontSize={20}
            align={"left"}
            fontStyle={20}
            draggable
            // text={"Dit con me may  a"}
            x={100}
            y={100}
            wrap="word"
            width={200}
            // onDblClick={e => this.handleTextDblClick(e)}
          />
          <Circle
            x={rectangleProps.x * ratio}
            y={rectangleProps.y * ratio}
            width={3}
            height={3}
            radius={3}
            fill={rectangleProps.stroke}
          />
        </>
      )}

      {checkShowPointBox && (
        <Circle
          x={(rectangleProps.x + rectangleProps.width) * ratio}
          y={(rectangleProps.y + rectangleProps.height) * ratio}
          width={3}
          height={3}
          radius={3}
          fill={rectangleProps.stroke}
        />
      )}
      {checkToolsAllowShowRectangle && (
        <RulerLine
          center={centerRuler}
          canvasWidth={metadata.width * ratio}
          canvasHeight={metadata.height * ratio}
        />
      )}
    </>
  );
}

DrawingRectangle.propTypes = {
  rectangleProps: object,
  centerRuler: object,
};
