import React from "react";
import { connect } from "react-redux";
import {
  Dialog,
  CircularProgress,
  Grid,
  DialogContentText,
} from "@material-ui/core";
import { bool } from "prop-types";
import { selectDialogOpen } from "redux/app/selectors";
import IntlMessage from "../../helpers/IntlMessage";

function LoadingDialog({ open }) {
  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          padding: 20,
          width: 310,
        },
      }}
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={3}>
          {" "}
          <CircularProgress />{" "}
        </Grid>
        <Grid item xs={9}>
          <DialogContentText>
            <IntlMessage id="loading-dialog.please-wait" />
          </DialogContentText>
        </Grid>
      </Grid>
    </Dialog>
  );
}

LoadingDialog.propTypes = {
  open: bool,
};

const mapStateToProps = (state) => ({
  open: selectDialogOpen(state),
});

export default connect(mapStateToProps, {})(LoadingDialog);
