import React from "react";
import { connect } from "react-redux";
import Rectangle from "components/Rectangle";
import { bool, func, number, object } from "prop-types";
import { selectIsBoxMode } from "redux/tools/selectors";
import { updateBox } from "redux/box/actions";
import { closeAllAttribute } from "redux/shape/actions";

function Box({
  properties,
  ratio,
  isSelected,
  onSelect,
  isBoxMode,
  updateBox,
  closeAllAttribute,
}) {
  const handleChange = (newAttrs) => {
    const { x, y, width, height, rotate } = newAttrs;
    updateBox({
      ...properties,
      dataPoint: { x, y, width, height, rotate },
    });
  };

  return (
    <>
      <Rectangle
        rectangleProps={{
          ...properties.dataPoint,
          labelId: properties.labelId,
          label: properties.labelName,
          stroke: properties.color,
          strokeWidth: 4,
          visible: true,
          id: properties.id,
        }}
        opacity={0.8}
        isBoxMode={isBoxMode}
        onSelect={onSelect}
        isSelected={isSelected}
        ratio={ratio}
        onChange={handleChange}
        labeled
        closeAllAttribute={closeAllAttribute}
        rotateEnabled={true}
      />
    </>
  );
}

Box.propTypes = {
  properties: object,
  isBoxMode: bool,
  ratio: number,
  isSelected: bool,
  onSelect: func,
  updateBox: func,
  closeAllAttribute: func,
};

const mapStateToProps = () => (state) => ({
  isBoxMode: selectIsBoxMode(state),
});

export default connect(mapStateToProps, {
  updateBox,
  closeAllAttribute,
})(Box);
