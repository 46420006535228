import { func, object } from "prop-types";
import { number } from "prop-types";
import React, { useState, useRef, Fragment, memo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOCR } from "redux/ocr/actions";
import { setUserTypingStatus } from "redux/keyboard/action";
import { selectIsUserTypingStatus } from "redux/keyboard/selectors";
import { injectIntl } from "react-intl";
function getStyle(width, top, left, ratio, color) {
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  const baseStyle = {
    zIndex: 999,
    opacity: 0.8,
    width: `${width * ratio}px`,
    // height:`${height * ratio}px`,
    // height: `${height}px`,
    border: "none",
    // padding: "0px",
    margin: "0px",
    background: color,
    outline: "none",
    resize: "vertical",
    color: "white",
    fontSize: "16px",
    lineHeight: 1.2,
    align: "left",
    padding: 2,
    position: "absolute",
    top: `${top * ratio - 20}px`,
    left: `${left * ratio}px`,
    fontFamily: "sans-serif,Arial",
    overflowY: "hidden",
    height: "20px",
    overflowStyle: "none" /* IE and Edge */,
    scrollbarWidth: "none" /* Firefox */,
  };
  if (isFirefox) {
    return baseStyle;
  }
  return {
    ...baseStyle,
    marginTop: "-4px",
  };
}

// eslint-disable-next-line react/prop-types
function EditableTextInput({ ratio, currentShape, setCurrentShapeSuccess }) {
  const [propsOfTextKonva, setPropsOfTextKonva] = useState(
    currentShape?.dataPoint?.editableTextValue
  );
  const isUserTyping = useSelector(selectIsUserTypingStatus);
  const textArea = useRef();
  const dispatch = useDispatch();
  const handleTextareaKeyDown = (e) => {
    if (e.keyCode === 13) {
      let newTextObj = { ...currentShape };

      newTextObj.dataPoint.isVisibleTex = false;
      newTextObj.dataPoint.editableTextValue = propsOfTextKonva;
      dispatch(updateOCR({ ...newTextObj }));
      dispatch(setCurrentShapeSuccess(newTextObj));
      setPropsOfTextKonva("");
      dispatch(setUserTypingStatus(false));
      return;
    }
    if (e.keyCode === 27) {
      // console.log("exit");
      dispatch(setUserTypingStatus(false));
      dispatch(setCurrentShapeSuccess(null));
      setPropsOfTextKonva("");
      return;
    }
  };
  const handleTextEdit = (e) => {
    if (!isUserTyping && e.target.value) {
      dispatch(setUserTypingStatus(true));
    }
    if (e.keyCode === 27) {
      dispatch(setUserTypingStatus(false));
    }
    setPropsOfTextKonva(e.target.value);
  };
  const onFocus = () => {
    if (!isUserTyping) dispatch(setUserTypingStatus(true));
  };

  const onBlur = () => {
    dispatch(setUserTypingStatus(false));
  };
  useEffect(() => {
    // console.log(document.getElementById("Toan1998"));
    if (textArea.current) {
      textArea.current.focus();
      textArea.current.selectionStart = textArea.current.value.length;
      // console.log(`Element is not focused.`);
    }
    if (currentShape?.dataPoint?.editableTextValue) {
      return setPropsOfTextKonva(`${currentShape.dataPoint.editableTextValue}`);
    } else {
      return setPropsOfTextKonva(``);
    }
  }, [
    currentShape,
    currentShape?.dataPoint?.editableTextValue,
    currentShape?.dataPoint?.isVisibleTex,
    currentShape?.dataPoint?.x,
  ]);
  return (
    <Fragment>
      <textarea
        value={propsOfTextKonva}
        style={{
          // position: "absolute",
          // top: currentShape?.dataPoint?.y * ratio + "px",
          // left: currentShape?.dataPoint?.x * ratio + "px",
          // zIndex: 999,
          // width: currentShape?.dataPoint?.width * ratio,
          // border: "none",
          // padding: "0px",
          // margin: "0px",
          // outline: "none",
          // resize: "vertical",
          ...getStyle(
            currentShape?.dataPoint?.width,
            currentShape?.dataPoint?.y,
            currentShape?.dataPoint?.x,
            ratio,
            currentShape?.color
          ),
        }}
        onChange={(e) => handleTextEdit(e)}
        onKeyDown={(e) => handleTextareaKeyDown(e)}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={textArea}
      />
    </Fragment>
  );
}

EditableTextInput.propTypes = {
  ratio: number,
  currentShape: object,
  setCurrentShape: func,
};
export default memo(injectIntl(EditableTextInput));
