import * as React from "react";

function MagicWand(props) {
  return (
    <svg
      width={15}
      height={15}
      version="1.1"
      id="Capa_1"
      viewBox="0 0 512 512"
      style="enable-background:new 0 0 512 512;"
      space="preserve"
      fill="currentColor"
      {...props}
    >
      <g>
        <g>
          <path
            d="M344.476,105.328L1.004,448.799L64.205,512l343.472-343.471L344.476,105.328z M290.594,201.792l53.882-53.882
			l20.619,20.619l-53.882,53.882L290.594,201.792z"
          />
        </g>
      </g>
      <g>
        <g>
          <rect
            x="413.735"
            y="55.578"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 79.0342 332.0713)"
            width="53.255"
            height="30.11"
          />
        </g>
      </g>
      <g>
        <g>
          <rect
            x="420.768"
            y="255.551"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -72.2351 390.9691)"
            width="30.11"
            height="54.259"
          />
        </g>
      </g>
      <g>
        <g>
          <rect
            x="213.158"
            y="48.098"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 13.767 183.3558)"
            width="30.11"
            height="53.922"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon points="510.735,163.868 456.446,163.868 456.446,193.979 510.735,193.979 510.996,193.979 510.996,163.868 		" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="317.017,0.018 317.017,54.307 347.128,54.307 347.128,0.018 347.128,0 		" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
export default MagicWand;
