const namespace = "notification";

export const SHOW_NOTIFICATION = `${namespace}/SHOW_NOTIFICATION`;
export const REMOVE_NOTIFICATION = `${namespace}/REMOVE_NOTIFICATION`;

export const FETCH_PUSH_NOTIFICATIONS = `${namespace}/FETCH_PUSH_NOTIFICATIONS`;
export const FETCH_PUSH_NOTIFICATIONS_SUCCESS = `${namespace}/FETCH_PUSH_NOTIFICATIONS_SUCCESS`;
export const FETCH_PUSH_NOTIFICATIONS_FAILURE = `${namespace}/FETCH_PUSH_NOTIFICATIONS_FAILURE`;

export const UPDATE_CURRENT_NOTIFICATIONS_PAGE = `${namespace}/UPDATE_CURRENT_NOTIFICATIONS_PAGE`;
export const NO_MORE_NOTIFICATION = `${namespace}/NO_MORE_NOTIFICATION`;

export const READ_ONE_NOTIFICATION = `${namespace}/READ_ONE_NOTIFICATION`;
export const READ_ONE_NOTIFICATION_SUCCESS = `${namespace}/READ_ONE_NOTIFICATION_SUCCESS`;

export const CHANGE_VIEW_NOTIFICATION = `${namespace}/CHANGE_VIEW_NOTIFICATION`;

export const MARK_ALL_NOTIFICATION_AS_READ = `${namespace}/MARK_ALL_NOTIFICATION_AS_READ`;
export const MARK_ALL_NOTIFICATION_AS_READ_SUCCESS = `${namespace}/MARK_ALL_NOTIFICATION_AS_READ_SUCCESS`;
export const MARK_ALL_NOTIFICATION_AS_READ_ERROR = `${namespace}/MARK_ALL_NOTIFICATION_AS_READ_ERROR`;

// Actions
export const showNotification = (payload) => ({
  type: SHOW_NOTIFICATION,
  payload,
});

export const delayRemoveNotification = (payload) => {
  const timer = setTimeout(function () {
    removeNotification(payload);
  }, 700);

  clearTimeout(timer);
};

export const removeNotification = (payload) => ({
  type: REMOVE_NOTIFICATION,
  payload,
});

export const fetchPushNotifications = (payload) => ({
  type: FETCH_PUSH_NOTIFICATIONS,
  payload,
});

export const fetchPushNotificationsSuccess = (payload) => ({
  type: FETCH_PUSH_NOTIFICATIONS_SUCCESS,
  payload,
});

export const fetchPushNotificationsFailure = (payload) => ({
  type: FETCH_PUSH_NOTIFICATIONS_FAILURE,
  payload,
});

export const updateCurrentNotiPage = (payload) => ({
  type: UPDATE_CURRENT_NOTIFICATIONS_PAGE,
  payload,
});

export const noMoreNotifications = (payload) => ({
  type: NO_MORE_NOTIFICATION,
  payload,
});

export const readOneNotification = (payload) => ({
  type: READ_ONE_NOTIFICATION,
  payload,
});

export const readOneNotificationSuccess = (payload) => ({
  type: READ_ONE_NOTIFICATION_SUCCESS,
  payload,
});

export const changeViewNotification = (payload) => ({
  type: CHANGE_VIEW_NOTIFICATION,
  payload,
});

export const markAllNotificationAsRead = (payload) => ({
  type: MARK_ALL_NOTIFICATION_AS_READ,
  payload,
});

export const markAllNotificationAsReadSuccess = (payload) => ({
  type: MARK_ALL_NOTIFICATION_AS_READ_SUCCESS,
  payload,
});

export const markAllNotificationAsReadError = (payload) => ({
  type: MARK_ALL_NOTIFICATION_AS_READ_ERROR,
  payload,
});
