const selectDisplayTiming = (state) => state.timing.displayTime;
const selectCountingTime = (state) => state.timing.countingTime;
const selectDisplayCountingTime = (state) => state.timing.displayCountingTime;
const selectRemainTime = (state) => state.timing.remainTime;
const selectIsCountingTime = (state) => state.timing.isCountingTime;

export {
  selectDisplayTiming,
  selectCountingTime,
  selectDisplayCountingTime,
  selectRemainTime,
  selectIsCountingTime,
};
