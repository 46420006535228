import { debounce } from "lodash";
import React from "react";
import { injectIntl } from "react-intl";
import AsyncSelect from "react-select/lib/Async";
import classNames from "classnames";
import "./styles.scss";
import { searchProjectTagByTextAPI } from "api/image";
import { any, func, string } from "prop-types";
import { selectProjectId } from "redux/app/selectors";
import { useSelector } from "react-redux";

const TagAutoCompleteCOM = ({ intl, onChange, value, className, ...rest }) => {
  const projectId = useSelector(selectProjectId);

  // const dispatch = useDispatch();
  const handleDebounceLoadOptions = debounce((inputValue, callback) => {
    const check = searchProjectTagByTextAPI({
      projectId,
      textSearch: inputValue.trim(),
    });
    check
      .then((res) => {
        callback(res?.data?.tags);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, 1000);
  const handleLoadOptions = (inputValue, callback) => {
    if (!inputValue?.trim()) return callback([]);
    handleDebounceLoadOptions(inputValue, callback);
  };
  const handleChangeTagValue = (value) => {
    onChange && onChange(value);
  };

  return (
    <AsyncSelect
      noOptionsMessage={() => intl.formatMessage({ id: "common.no-options" })}
      className={classNames("tw-flex-grow fake-create-tag-select", className)}
      classNamePrefix="select"
      styles={{
        control: (base, state) => ({
          ...base,
          height: "fit-content",
          border: state.isFocused ? 0 : 0,
          boxShadow: state.isFocused ? 0 : 0,
          "&:hover": {
            border: state.isFocused ? 0 : 0,
          },
        }),
      }}
      cacheOptions
      loadOptions={handleLoadOptions}
      placeholder=""
      getOptionLabel={(option) => option.tag_name}
      getOptionValue={(option) => option.tag_id}
      onChange={handleChangeTagValue}
      value={value}
      {...rest}
    />
  );
};
TagAutoCompleteCOM.propTypes = {
  intl: any,
  onChange: func,
  value: any,
  className: string,
};
export const TagAutoComplete = injectIntl(TagAutoCompleteCOM);
