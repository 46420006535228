import React from "react";
import { connect } from "react-redux";
import { array } from "prop-types";

import { selectNotifications } from "redux/notification/selectors";
import Notification from "./Notification";

function renderNotifications(notifications) {
  return notifications.map((notification, index) => (
    <Notification
      key={notification.id || index}
      index={index}
      notification={notification}
    />
  ));
}

function Notifications({ notifications }) {
  return <>{renderNotifications(notifications)}</>;
}

Notifications.propTypes = {
  notifications: array,
};

export default connect((state) => ({
  notifications: selectNotifications(state),
}))(Notifications);
