import {
  SAVE_OCR,
  SAVE_OCR_SUCCESS,
  SAVE_OCR_ERROR,
  UPDATE_OCR,
  UPDATE_OCR_SUCCESS,
  UPDATE_OCR_ERROR,
} from "./constants";

export const saveOCR = ({ payload, callback }) => ({
  type: SAVE_OCR,
  payload,
  callback,
});

export const saveOCRSuccess = (payload) => ({
  type: SAVE_OCR_SUCCESS,
  payload,
});

export const saveOCRError = (payload) => ({
  type: SAVE_OCR_ERROR,
  payload,
});

export const updateOCR = (payload) => ({
  type: UPDATE_OCR,
  payload,
});

export const updateOCRSuccess = (payload) => ({
  type: UPDATE_OCR_SUCCESS,
  payload,
});

export const updateOCRError = (payload) => ({
  type: UPDATE_OCR_ERROR,
  payload,
});
