import {
  cancel,
  all,
  fork,
  put,
  select,
  takeEvery,
  call,
} from "redux-saga/effects";
import get from "lodash.get";
import { GET_MODULE_CODE_ERROR, LOAD_REPO_ERROR } from "redux/app/constants";
import { selectUserId, selectProjectId } from "redux/app/selectors";
import {
  fetchNotification,
  updateOneNotification,
  markAllNotificationAsReadAPI,
} from "api/notifications";
import {
  showNotification,
  delayRemoveNotification,
  FETCH_PUSH_NOTIFICATIONS,
  updateCurrentNotiPage,
  fetchPushNotificationsSuccess,
  noMoreNotifications,
  READ_ONE_NOTIFICATION,
  changeViewNotification,
  MARK_ALL_NOTIFICATION_AS_READ_ERROR,
  MARK_ALL_NOTIFICATION_AS_READ,
  markAllNotificationAsReadSuccess,
  markAllNotificationAsReadError,
} from "./actions";
import {
  selectCurrentNotiId,
  selectNotiLimit,
  selectPushNotifications,
} from "./selectors";
import {
  SUBMIT_IMAGE_ERROR,
  GET_SOURCE_CSV_FILE_ERROR,
} from "redux/image/constants";
import {
  ADD_ISSUE_FAILURE,
  ADD_CHILD_ISSUE_FAILURE,
  RESOLVE_ISSUE_FAILURE,
  DELETE_ISSUE_FAILURE,
  EDIT_ISSUE_FAILURE,
  REOPEN_ISSUE_FAILURE,
} from "redux/issues/actions";
import {
  SUBMIT_POINTS_ERROR,
  FETCH_LABELS_FAILURE,
} from "redux/points/constants";
import { SAVE_BOX_ERROR, UPDATE_BOX_ERROR } from "redux/box/constants";
import {
  GET_SHAPE_ERROR,
  DELETE_SHAPE_ERROR,
  UPDATE_SHAPE_ATTRIBUTE_ERROR,
  UPDATE_SHAPE_LABEL_ERROR,
} from "redux/shape/constants";
import {
  DRAW_SEGMENTATION_BY_ENGINE_ERROR,
  SAVE_SEGMENTATION_ERROR,
  UPDATE_SEGMENTATION_ERROR,
} from "redux/segmentation/constants";
import { UPDATE_CUBOID_ERROR, SAVE_CUBOID_ERROR } from "redux/cuboid/constants";
import { UPDATE_OCR_ERROR, SAVE_OCR_ERROR } from "redux/ocr/constants";

function* watchError() {
  yield takeEvery(GET_MODULE_CODE_ERROR, handleError);
  yield takeEvery(LOAD_REPO_ERROR, handleError);
  yield takeEvery(SUBMIT_IMAGE_ERROR, handleError);
  yield takeEvery(SUBMIT_POINTS_ERROR, handleError);
  yield takeEvery(FETCH_LABELS_FAILURE, handleError);
  yield takeEvery(GET_SOURCE_CSV_FILE_ERROR, handleError);
  yield takeEvery(ADD_ISSUE_FAILURE, handleError);
  yield takeEvery(ADD_CHILD_ISSUE_FAILURE, handleError);
  yield takeEvery(RESOLVE_ISSUE_FAILURE, handleError);
  yield takeEvery(REOPEN_ISSUE_FAILURE, handleError);
  yield takeEvery(DELETE_ISSUE_FAILURE, handleError);
  yield takeEvery(EDIT_ISSUE_FAILURE, handleError);
  yield takeEvery(SAVE_BOX_ERROR, handleError);
  yield takeEvery(UPDATE_BOX_ERROR, handleError);
  yield takeEvery(GET_SHAPE_ERROR, handleError);
  yield takeEvery(DELETE_SHAPE_ERROR, handleError);
  yield takeEvery(DRAW_SEGMENTATION_BY_ENGINE_ERROR, handleError);
  yield takeEvery(SAVE_SEGMENTATION_ERROR, handleError);
  yield takeEvery(UPDATE_SEGMENTATION_ERROR, handleError);
  yield takeEvery(UPDATE_SHAPE_ATTRIBUTE_ERROR, handleError);
  yield takeEvery(UPDATE_CUBOID_ERROR, handleError);
  yield takeEvery(SAVE_CUBOID_ERROR, handleError);
  yield takeEvery(UPDATE_OCR_ERROR, handleError);
  yield takeEvery(SAVE_OCR_ERROR, handleError);
  yield takeEvery(UPDATE_SHAPE_LABEL_ERROR, handleError);
  yield takeEvery(MARK_ALL_NOTIFICATION_AS_READ_ERROR, handleError);
}

function* handleError({ payload }) {
  yield put(showNotification({
    type: "error",
    msg: payload.msg
      ? payload.msg
      : payload,
    msgId: payload.msgId
      ? payload.msgId
      : null,
    intlValues: payload.intlValues
      ? payload.intlValues
      : {},
  }));
  const id = yield select(selectCurrentNotiId);
  delayRemoveNotification(id);
}

function* watchFetchPushNotis() {
  yield takeEvery(FETCH_PUSH_NOTIFICATIONS, function* ({ payload }) {
    const limit = yield select(selectNotiLimit);
    const userId = yield select(selectUserId);
    const allNotis = yield select(selectPushNotifications);
    const projectId = yield select(selectProjectId);

    if (payload === 0 || payload * limit >= allNotis.length) {
      let sendingPayload = {
        projectId,
        userId,
        offset: payload * limit,
        limit,
      };
      const response = yield call(fetchNotification, sendingPayload);
      const notifications = get(response, "data", []);
      const totalNewNotifications = get(response, "totalUnRead", null);
      const result = { notifications, totalNewNotifications };

      if (!response || !response.success) {
        yield put(
          showNotification({
            type: "error",
            msg: "Can not fetch notifications",
            msgId: "notification.cannot-fetch-notifications"
          })
        );
        yield cancel();
      }

      if (!notifications.length) {
        yield put(noMoreNotifications());
      }

      if (notifications && notifications.length) {
        yield put(fetchPushNotificationsSuccess(result));
        yield put(updateCurrentNotiPage(payload + 1));
        return;
      }
      yield put(noMoreNotifications());
    }
  });
}

function* watchReadOneNotification() {
  yield takeEvery(READ_ONE_NOTIFICATION, function* ({ payload }) {
    const { notificationId } = payload;
    const newPayload = { ...payload };
    delete newPayload.notificationId;
    const response = yield call(
      updateOneNotification,
      notificationId,
      newPayload
    );

    if (response.success) {
      const notificationId = response.data.notificationId;
      yield put(changeViewNotification(notificationId));
    } else {
      yield put(
        showNotification({
          type: "error",
          msg: "Fail to update notifications",
          msgId: "notification.fail-to-update-notification",
        })
      );
      yield cancel();
    }
  });
}

function* watchMarkAllNotificationAsRead() {
  yield takeEvery(MARK_ALL_NOTIFICATION_AS_READ, function* () {
    const response = yield call(markAllNotificationAsReadAPI);
    const { status, message } = response;
    if (status) {
      yield put(markAllNotificationAsReadSuccess(message));
    } else {
      yield put(markAllNotificationAsReadError(message));
    }
  });
}

export default function* saga() {
  yield all([
    fork(watchError),
    fork(watchFetchPushNotis),
    fork(watchReadOneNotification),
    fork(watchMarkAllNotificationAsRead),
  ]);
}
