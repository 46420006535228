import React from "react";
import { node, func, string, bool } from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import style from "./style.module.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import IntlMessage from "../../helpers/IntlMessage";

function ToolContainer({
  children,
  onClick,
  name,
  currTool,
  disabledClass,
  tooltipText,
  loading,
}) {
  const className = clsx({
    [style.toolContainer]: true,
    [style.selectedToolContainer]: currTool === name,
    [style.disabledClass]: disabledClass,
  });

  return (
    <>
      {tooltipText && (
        <Tooltip
          placement="right"
          id={name}
          title={<IntlMessage id={tooltipText} />}
          arrow
          classes={{
            tooltip: style.tooltip,
            arrow: style.tooltipArrow,
          }}
        >
          <div
            id={name}
            name={name}
            className={className}
            onClick={onClick}
            data-disabled={disabledClass}
            data-tour={`menu-${name}`}
          >
            {loading ? <CircularProgress size={20} /> : children}
          </div>
        </Tooltip>
      )}
    </>
  );
}

ToolContainer.propTypes = {
  children: node,
  onClick: func,
  name: string,
  currTool: string,
  disabledClass: bool,
  tooltipText: string,
  loading: bool,
};

export default ToolContainer;
