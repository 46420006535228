import produce from "immer";
import {
  FETCH_CLASSIFICATIONS,
  FETCH_CLASSIFICATIONS_SUCCESS,
  FETCH_CLASSIFICATIONS_FAILURE,
  UPDATE_CLASSIFICATIONS,
  UPDATE_CLASSIFICATIONS_SUCCESS,
  UPDATE_CLASSIFICATIONS_FAILURE,
  REMOVE_CLASSIFICATIONS,
  REMOVE_CLASSIFICATIONS_SUCCESS,
  REMOVE_CLASSIFICATIONS_FAILURE,
} from "./actions";

export const initialState = {
  allClassifications: [],
  isFormOpened: false,
  selectedClassification: { content: "", classificationId: "" },
  loading: false,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_CLASSIFICATIONS:
        draft.loading = true;
        break;

      case FETCH_CLASSIFICATIONS_SUCCESS:
        draft.loading = false;
        draft.allClassifications = action.payload;
        break;

      case FETCH_CLASSIFICATIONS_FAILURE:
        draft.loading = false;
        break;

      case UPDATE_CLASSIFICATIONS:
        draft.loading = true;
        break;

      case UPDATE_CLASSIFICATIONS_SUCCESS: {
        draft.loading = false;
        break;
      }

      case UPDATE_CLASSIFICATIONS_FAILURE:
        draft.loading = false;
        break;

      case REMOVE_CLASSIFICATIONS:
        draft.loading = true;
        break;

      case REMOVE_CLASSIFICATIONS_SUCCESS: {
        draft.loading = false;

        const { issueId, parentId } = action.payload;

        if (parentId) {
          draft.allIssues = draft.allIssues.map((item) => {
            if (item.issueId === parentId) {
              const childIssues = item.childIssues.filter(
                (childItem) => childItem.issueId !== issueId
              );
              return {
                ...item,
                countChildren: item.childIssues.length - 1,
                lastChildrenCreatedDate: childIssues.length
                  ? childIssues[childIssues.length - 1].createdDate
                  : null,
                childIssues,
              };
            }
            return item;
          });
        } else {
          draft.allIssues = draft.allIssues.filter(
            (item) => item.issueId !== issueId
          );
        }
        break;
      }

      case REMOVE_CLASSIFICATIONS_FAILURE:
        draft.loading = false;
        break;
    }
  });

export default reducer;
