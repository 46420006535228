import { call } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import {
  authenticationOn,
  authenticationOff,
} from "redux/socket/eventHandlers/auth";

import io from "socket.io-client";

import { socketServerURL, token, userInfo, socketOptions } from "api/constants";

let socket;

export let commonEmitter;
// This is how channel is created

export function handler(data, emitter, event) {
  data.event = event;
  return emitter(data);
}

export const createSocketChannel = (socket) =>
  eventChannel((emitter) => {
    commonEmitter = emitter;
    authenticationOn(socket, emitter, token);

    return () => {
      // return to close socket when functions are unsubscribed
      authenticationOff(socket, emitter);
    };
  });

// Connection monitoring sagas
export function* listenDisconnectSaga() {
  while (true) {
    yield call(disconnect);
  }
}

export function* listenConnectSaga() {
  while (true) {
    yield call(reconnect);
  }
}

// Wrapping functions for socket events (connect, disconnect, reconnect)
export function connect() {
  const { userId } = JSON.parse(userInfo);
  socketOptions.query.userId = userId;
  if (!socket) socket = io(socketServerURL, socketOptions);
  return new Promise((resolve) => {
    socket.on("connect", () => {
      resolve(socket);
    });
  });
}

function disconnect() {
  const { userId } = JSON.parse(userInfo);
  socketOptions.query.userId = userId;
  if (!socket) socket = io(socketServerURL, socketOptions);
  return new Promise((resolve) => {
    socket.on("disconnect", () => {
      resolve(socket);
    });
  });
}

function reconnect() {
  const { userId } = JSON.parse(userInfo);
  socketOptions.query.userId = userId;
  if (!socket) socket = io(socketServerURL, socketOptions);
  return new Promise((resolve) => {
    socket.on("reconnect", () => {
      resolve(socket);
    });
  });
}
