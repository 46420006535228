export const defaultStatus = {
  project_labeler: "New",
  project_reviewer: "Submitted",
  admin: "Approved",
  project_manager: "Approved",
  customer: "New",
};

export const dropdownItemsByStatus = [
  { name: "New", status: ["new", "assigned", "inprogress"] }, // this order is important
  { name: "Submitted", status: ["submitted"] },
  { name: "Approved", status: ["approved"] },
  { name: "Reopen", status: ["reopen"] },
  { name: "Skipped", status: ["skipped"] },
  { name: "doublecheck", status: ["doublecheck"] },
];

export const benchmarkDropdownStatus = [
  { name: "New", status: ["new", "assigned", "inprogress"] }, // this order is important
  { name: "Submitted", status: ["submitted"] },
];

export const ROLE_CODE_PROJECT_LABELER = "project_labeler";
