import * as allTools from "redux/tools/enums";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import GestureIcon from "@material-ui/icons/Gesture";
import { Cuboid } from "icons";
import Polyline from "./Polyline";
import Polygon from "./Polygon";

export const mapModeToIcon = {
  [`${allTools.BOX_MODE}-image`]: CheckBoxOutlineBlank,
  [`${allTools.POLYGON_MODE}-image`]: Polygon,
  [`${allTools.POLYLINE_MODE}-image`]: Polyline,
  [`${allTools.SEGMENTATION_MODE}-image`]: GestureIcon,
  [`${allTools.CUBOID_MODE}-image`]: Cuboid,
  [`${allTools.OCR}-image`]: CheckBoxOutlineBlank,
};
