import produce from "immer";
import {
  SET_USER_DATA,
  BOOTING_FINISHED,
  SET_SOURCE_INFO,
  GET_MODULE_CODE_SUCCESS,
  FETCH_FIREBASE_TOKEN_SUCCESS,
  UPDATE_ROLE_CODE,
  SET_CURRENT_DROPDOWN_STATUS,
  CHANGE_CURRENT_DROPDOWN_STATUS,
  FETCH_USER_IN_PROJECT_SUCCESS,
  SET_LABELER_ID,
  SET_DIALOG_OPEN,
  CHANGE_LANGUAGE,
  defaultLanguage,
} from "./constants";
import { getParameterByName } from "helpers";

// The initial state of the App
export const initialState = {
  locale: getParameterByName("locale") || defaultLanguage,
  firebaseToken: "",
  currentUser: {},
  sourceInfo: {
    projectId: null,
    datasetId: null,
    sourceId: null,
    checkLastActive: false,
    customerReview: false,
  },
  isBootingFinished: false,
  inputDataType: "image",
  // Reviewer: ["labeling_view", "labeling_create_issue", "labeling_verify", "labeling_approve"]
  // Labeller:  ["labeling_modify", "labeling_submit", "labeling_resolve_issue"]
  moduleCode: [],
  roleCode: "",
  currDropDownStatus: "",
  isChangingDropdown: false,
  listUser: [],
  labelerId: "",
  dialogOpen: false,
};

const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_DIALOG_OPEN:
        draft.dialogOpen = action.payload;
        break;
      case SET_CURRENT_DROPDOWN_STATUS:
        draft.currDropDownStatus = action.payload;
        break;

      case CHANGE_CURRENT_DROPDOWN_STATUS:
        draft.currDropDownStatus = action.payload;
        draft.isChangingDropdown = true;
        break;

      case SET_USER_DATA:
        draft.currentUser = action.payload;
        break;

      case BOOTING_FINISHED:
        draft.isBootingFinished = true;
        break;

      case SET_SOURCE_INFO: {
        const sourceInfo = {};
        for (let item in action.payload) {
          sourceInfo[item] = JSON.parse(action.payload[item]);
        }
        draft.sourceInfo = sourceInfo;
        break;
      }

      case GET_MODULE_CODE_SUCCESS:
        draft.moduleCode = action.payload;
        break;

      case UPDATE_ROLE_CODE:
        draft.roleCode = action.payload;
        break;

      case FETCH_FIREBASE_TOKEN_SUCCESS:
        draft.firebaseToken = action.payload;
        break;

      case FETCH_USER_IN_PROJECT_SUCCESS:
        draft.listUser = action.payload;
        break;

      case SET_LABELER_ID:
        draft.labelerId = action.payload;
        break;

      case CHANGE_LANGUAGE:
        draft.locale = action.payload;
        break;

      default:
    }
  });

export default appReducer;
