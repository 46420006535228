/* eslint-disable react/jsx-key */
import React, { Fragment, useState, useRef } from "react";
import { Rect, Transformer, Text, Label, Tag } from "react-konva";
import { checkRectangleIsOutOfStage } from "../../helpers/rectangle";
import { func, bool, object, number } from "prop-types";
import { connect } from "react-redux";
import { selectImageMetadata } from "redux/image/selectors";
import { getParameterByName } from "helpers";
import { getCurrentShapes } from "redux/shape/selector";
import { setTextVisibleCurrentShape } from "redux/shape/actions";
function Rectangle({
  rectangleProps,
  ratio,
  isSelected,
  onSelect,
  onChange,
  opacity,
  isBoxMode,
  metadata,
  labeled,
  closeAllAttribute,
  rotateEnabled,
  currentShape,
}) {
  const alterUserId = getParameterByName("alterUserId");
  const isViewResult = getParameterByName("isViewResult");
  const disableEdit = alterUserId || isViewResult;
  const rectangleRef = useRef();
  const trRef = React.useRef();
  const textRef = React.useRef();
  const [textRotation, setTextRotation] = useState(0);

  const [rectangle, setRectangle] = useState({
    ...rectangleProps,
    x: rectangleProps.x * ratio,
    y: rectangleProps.y * ratio,
    width: rectangleProps.width * ratio,
    height: rectangleProps.height * ratio,
  });

  const [text, setText] = useState({
    x: rectangleProps.x * ratio,
    y: rectangleProps.y * ratio,
  });

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([rectangleRef.current]);
      trRef &&
        trRef.current &&
        trRef.current.getLayer() &&
        trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  React.useEffect(() => {
    setRectangle({
      ...rectangleProps,
      x: rectangleProps.x * ratio,
      y: rectangleProps.y * ratio,
      width: rectangleProps.width * ratio,
      height: rectangleProps.height * ratio,
    });
    setText({
      x: rectangleProps.x * ratio,
      y: rectangleProps.y * ratio,
    });
    if (rotateEnabled) setTextRotation(rectangleProps.rotate);
  }, [rectangleProps, ratio]);

  const handleOnTransform = () => {
    if (rotateEnabled) {
      const node = rectangleRef.current;
      setTextRotation(node.rotation());
      setText({
        x: node.x(),
        y: node.y(),
      });
    }
    closeAllAttribute && closeAllAttribute();
  };

  const handleOnTransformEnd = () => {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const node = rectangleRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    onChange &&
      onChange(
        {
          ...rectangleProps,
          x: node.x() / ratio,
          y: node.y() / ratio,
          // set minimal value
          width: Math.max(5, rectangleProps.width * scaleX),
          height: Math.max(rectangleProps.height * scaleY),
          rotation: rotateEnabled ? node.rotation() : 0,
          rotate: node.rotation(),
        },
        rectangleProps
      );
  };

  // set coordinate of text while dragging rectangle
  const handleDragMove = () => {
    closeAllAttribute();
    const node = rectangleRef.current;
    setText({
      x: node.x(),
      y: node.y(),
    });
  };

  const handleDragEnd = (e) => {
    onChange(
      {
        ...rectangleProps,
        x: e.target.x() / ratio,
        y: e.target.y() / ratio,
      },
      rectangleProps
    );
  };

  const handleBoundDraggable = (pos) => {
    const node = rectangleRef.current;
    const dataPos = { ...pos };
    if (pos.x < 0) dataPos.x = 0;
    if (pos.x + node.getAttr("width") > metadata.width * ratio)
      dataPos.x = metadata.width * ratio - node.getAttr("width");
    if (pos.y < 0) dataPos.y = 0;
    if (pos.y + node.getAttr("height") > metadata.height * ratio)
      dataPos.y = metadata.height * ratio - node.getAttr("height");
    return dataPos;
  };
  const rectLayer = [
    <Fragment>
      <Rect
        {...rectangle}
        ref={rectangleRef}
        opacity={opacity || 0.4}
        onClick={onSelect}
        onTap={onSelect}
        onMouseDown={onSelect}
        draggable={!isBoxMode}
        onDragEnd={handleDragEnd}
        onTransform={handleOnTransform}
        onTransformEnd={handleOnTransformEnd}
        onDragMove={handleDragMove}
        dragBoundFunc={handleBoundDraggable}
        strokeScaleEnabled={false}
        // rotation={rectangle.rotate}
        strokeWidth={2}
        listening={!disableEdit}
        maxWidth={rectangleProps.width * ratio}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            const isOutsideOfStage = checkRectangleIsOutOfStage(newBox, {
              ...metadata,
              ratio,
            });
            // limit resize
            if (isOutsideOfStage || newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          rotateEnabled={rotateEnabled || false}
          anchorSize={6}
          ignoreStroke
        />
      )}
    </Fragment>,
  ];
  const handleSelectShape = () => () => {
    let newCurrentObject = { ...currentShape };
    newCurrentObject.dataPoint.isVisibleTex = true;
    onSelect(newCurrentObject);
  };
  const label =
    isSelected &&
    !currentShape?.dataPoint.isVisibleTex &&
    currentShape?.dataPoint?.editableTextValue ? (
      <Label
        rotation={rotateEnabled ? textRotation : 0}
        x={text.x}
        y={text.y}
        offsetY={24}
        offsetX={2}
        opacity={opacity || 0.4}
        ref={textRef}
        maxWidth={rectangleProps.width * ratio}
      >
        <Tag fill={rectangle.stroke}></Tag>
        {currentShape?.classesTypeCode === "ocr-image" ? (
          <Text
            text={
              currentShape?.dataPoint?.editableTextValue
                ? currentShape.dataPoint.editableTextValue.trim()
                : "Double click to edit"
            }
            // text={"Toan dep trai"}
            fontFamily="Arial, sans-serif"
            fontSize={16}
            lineHeight={1.2}
            align="left"
            fill="#fff"
            padding={2}
            onDblClick={handleSelectShape()}
            maxWidth={rectangleProps.width * ratio}
          />
        ) : (
          <Text
            text={rectangle.label}
            fontFamily="Arial, sans-serif"
            fontSize={16}
            lineHeight={1.2}
            align="left"
            fill="#fff"
            padding={2}
            maxWidth={rectangleProps.width * ratio}
          />
        )}
      </Label>
    ) : null;
  labeled && rectLayer.push(label);
  return rectLayer;
}

Rectangle.propTypes = {
  rectangleProps: object,
  ratio: number,
  isSelected: bool,
  onSelect: func,
  onChange: func,
  opacity: number,
  isBoxMode: bool,
  metadata: object,
  labeled: bool,
  closeAllAttribute: func,
  rotateEnabled: bool,
  currentShape: object,
  setTextVisibleCurrentShape: func,
};

const mapState = () => (state) => ({
  metadata: selectImageMetadata(state),
  currentShape: getCurrentShapes(state),
});
export default connect(mapState, { setTextVisibleCurrentShape })(Rectangle);
