import { RECEIVE_USERS, RECEIVE_USER, REMOVE_USER } from "./constants";

export const receiveUsers = (payload) => ({
  type: RECEIVE_USERS,
  payload,
});

export const receiveUser = (payload) => ({
  type: RECEIVE_USER,
  payload,
});

export const removeUser = (payload) => ({
  type: REMOVE_USER,
  payload,
});
