import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { string, func, bool, object } from "prop-types";
import { injectIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import UserDefaultAvatar from "@material-ui/icons/AccountCircle";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { selectUserFullname, selectUserImage } from "redux/app/selectors";
import { selectContentForIssue } from "redux/issues/selectors";
import { closeIssueForm, addIssue, editIssue } from "redux/issues/actions";
import {
  selectClickedFlagIndex,
  makeSelectedRowId,
} from "redux/points/selectors";
import { setUserTypingStatus } from "redux/keyboard/action";
import { selectIsUserTypingStatus } from "redux/keyboard/selectors";

import styles from "./style.module.scss";

function IssueForm({
  intl,
  username,
  userImage,
  closeIssueForm,
  addIssue,
  content,
  editIssue,
  isUserTyping,
  setUserTypingStatus,
  flagIndex,
}) {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(flagIndex?.trim() ? `${flagIndex} ` : flagIndex);
  }, [flagIndex]);

  useEffect(() => {
    if (content) setInputValue(content);
  }, [content]);

  const onChange = (event) => {
    if (!isUserTyping) setUserTypingStatus(true);
    setInputValue(event.target.value);
  };

  const handleAddIssue = () => {
    if (!inputValue) return;
    addIssue({ content: inputValue });
    closeIssueForm();
  };

  const handleUpdateIssue = () => {
    if (!inputValue) return;
    editIssue({ content: inputValue });
    closeIssueForm();
  };

  const handleCloseForm = () => {
    closeIssueForm();
  };

  const onFocus = () => {
    if (!isUserTyping) setUserTypingStatus(true);
  };

  const onBlur = () => {
    setUserTypingStatus(false);
  };

  return (
    <form className={styles.issueFormContainer}>
      <div className={styles.formHeader}>
        <span>
          {userImage ? (
            <img src={userImage} alt="user-avatar" className={styles.image} />
          ) : (
            <UserDefaultAvatar className={styles.image} />
          )}
        </span>
        <span>{username}</span>
      </div>
      <div>
        <TextareaAutosize
          type="text"
          aria-label="issue-textarea"
          variant="outlined"
          rowsMin={3}
          className={styles.message}
          value={inputValue}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus
        />
      </div>
      <div className={styles.wizardButtons}>
        <Button
          size="small"
          className={styles.customBtnLight}
          onClick={handleCloseForm}
        >
          {intl.formatMessage({ id: "common.cancel" })}
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={content ? handleUpdateIssue : handleAddIssue}
          // disabled={!inputValue.trim()}
        >
          {content
            ? intl.formatMessage({ id: "common.update" })
            : intl.formatMessage({ id: "common.add" })}
        </Button>
      </div>
    </form>
  );
}

IssueForm.propTypes = {
  intl: object,
  username: string,
  userImage: string,
  closeIssueForm: func,
  addIssue: func,
  content: string,
  editIssue: func,
  isUserTyping: bool,
  setUserTypingStatus: func,
  flagIndex: string,
};

const mapStateToProps = (state) => ({
  username: selectUserFullname(state),
  userImage: selectUserImage(state),
  content: selectContentForIssue(state),
  isUserTyping: selectIsUserTypingStatus(state),
  flagIndex: selectClickedFlagIndex(state),
  selectedPointId: makeSelectedRowId(state),
});

export default connect(mapStateToProps, {
  closeIssueForm,
  addIssue,
  editIssue,
  setUserTypingStatus,
})(injectIntl(IssueForm));
