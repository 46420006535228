import React from "react";
import { node } from "prop-types";
import style from "./style.module.scss";

function TextContainer({ children }) {
  return <div className={style.textContainer}>{children}</div>;
}

TextContainer.propTypes = {
  children: node.isRequired,
};

export default TextContainer;
