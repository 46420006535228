/**
 * Produce an array of {x, y} items for iris points
 * @param {Object} center The center of the iris, hold two value {x, y}
 * @param {Number} radius The radius of the iris circle
 * @param {Number} slope The slope of the line between left and right corner points of the eye
 * @return {Array} an array of 8 item coordinates for points
 */
export function drawIrisCircle(center, radius, point, slope) {
  function drawPoint(r, currentPoint, totalPoints, pivotAngle) {
    const pivot = {};
    var theta = (Math.PI * 2) / totalPoints;
    var angle = theta * currentPoint;
    pivot.x = r * Math.cos(angle + pivotAngle);
    pivot.y = r * Math.sin(angle + pivotAngle);
    return pivot;
  }

  const totalPoints = 8;
  const output = {};
  const arcsin = Math.abs(point.y - center.y) / radius;

  // NOTE: This feature will break if the id of point change
  const leftIndice = [100, 101, 102, 95, 96, 97, 98, 99];
  const rightIndice = [111, 110, 109, 108, 107, 106, 105, 104];
  const ids = point.id === 95 ? leftIndice : rightIndice;

  // This makes the line between 95-99 and 71-77 always to be parallel.
  // Also apply for the line between 108-104 and 89-83
  const pointAngle = slope < 0 ? -Math.asin(arcsin) : Math.asin(arcsin);

  for (var i = 1; i <= totalPoints; i++) {
    let result = drawPoint(radius, i, totalPoints, pointAngle);
    output[ids[i - 1]] = { x: result.x + center.x, y: result.y + center.y };
  }
  return output;
}

/**
 * Finding roots of quadratic equation in form of ax^2 + bx + c = 0;
 * @param {Number} first is the value of a
 * @param {Number} second is the value of b
 * @param {Number} third is the value of c
 * @return {Array|null} the array of roots or null if no roots found
 */
export function quadraticEquationSolver(first, second, third) {
  let root1;
  let root2;
  // calculate discriminant
  let discriminant = Math.pow(second, 2) - 4 * first * third;

  if (discriminant > 0) {
    root1 = (-second + Math.sqrt(discriminant)) / (2 * first);
    root2 = (-second - Math.sqrt(discriminant)) / (2 * first);
    return [root1, root2];
  } else if (discriminant === 0) {
    root1 = root2 = -second / (2 * first);
    return [root1, root2];
  } else {
    return null;
  }
}

/**
 * Produce a point on the Iris center landmark
 * @param {Object} irisCenter { x, y} value of the iris center point
 * @param {Object} eyeStart { x, y} the point that start the eye from left to right
 * @param {Object} eyeEnd {x, y} the point at the end of the eye from left to right
 * @return {Object} { x, y } one point on the iris circle
 */

export function onePointOnIrisCircle(irisCenter, eyeStart, eyeEnd, radius) {
  // find the equation of the line between eyeStart and eyeEnd
  const slope = (eyeEnd.y - eyeStart.y) / (eyeEnd.x - eyeStart.x);

  // find the equation of the line that is parallel between
  // eyeStart-eyeEnd line and the line that has irisCenter
  const irisCenterYIntercept = irisCenter.y - slope * irisCenter.x;

  // find the point on the iris circle that is also run over irisCenter
  // and is in parallel with the eyeStart-eyeEnd line
  const firstNum = Math.pow(slope, 2) + 1;
  const secondNum =
    2 * slope * (irisCenterYIntercept - irisCenter.y) - 2 * irisCenter.x;
  const thirdNum =
    Math.pow(irisCenterYIntercept - irisCenter.y, 2) +
    Math.pow(irisCenter.x, 2) -
    Math.pow(radius, 2);
  const result = quadraticEquationSolver(firstNum, secondNum, thirdNum);
  const payload = [
    { x: result[0], y: slope * result[0] + irisCenterYIntercept },
    { x: result[1], y: slope * result[1] + irisCenterYIntercept },
  ];
  if (result[0] < result[1]) payload[0].start = true;
  else payload[1].start = true;
  return payload;
}
