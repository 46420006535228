// reducer
const requireReducers = require.context(".", true, /reducer.js$/);
let reducerModules = {};

requireReducers.keys().forEach((fileName) => {
  const reducerName = fileName.replace(/(\.\/|\/reducer.js)/g, "");
  reducerModules[reducerName] = requireReducers(fileName).default;
});

// saga
const requireSagas = require.context(".", true, /sagas.js$/);
let sagaModules = [];
requireSagas.keys().forEach((fileName) => {
  sagaModules.push(requireSagas(fileName).default);
});

export { reducerModules, sagaModules };
