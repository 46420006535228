import { all, fork, takeEvery, call, put, select } from "redux-saga/effects";
import { saveBox, updateBox } from "api/box";
import { saveBoxError, updateBoxError } from "redux/box/actions";
import { addShapesSuccess, updateShapesSuccess } from "redux/shape/actions";
import { SAVE_BOX, UPDATE_BOX } from "./constants";
import { selectImageId } from "redux/image/selectors";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import { selectLabels } from "redux/points/selectors";
import { toCamelCase, getParameterByName } from "helpers";
import { addAnnotationHistory } from "redux/annotationHistory/actions";
import { selectShapeById } from "redux/shape/selector";
import { v4 as uuidv4 } from "uuid";
// import { endCounting } from "redux/timing/actions";

function* watchSaveBox() {
  yield takeEvery(SAVE_BOX, function* ({
    payload,
    callback,
    callbackSaga,
    noSaveAnnotationHistory,
  }) {
    const isViewResult = getParameterByName("isViewResult");
    const isActionHistory = getParameterByName("isActionHistory");
    const isBenchmark = isViewResult || isActionHistory;
    let settingQuality;
    if (isBenchmark) {
      settingQuality = "benchmark";
    }

    const {
      x,
      y,
      width,
      height,
      labelId,
      isHideNotification,
      stroke,
      rotate,
    } = payload;
    const imageId = yield select(selectImageId);
    const response = yield call(saveBox, {
      sourceId: imageId,
      boxItem: {
        x,
        y,
        width,
        height,
        rotate: rotate ? rotate : 0,
        labelId: Number(labelId),
      },
      color: stroke,
      settingQuality,
      isBenchmark,
    });
    if (response.success) {
      const labelList = yield select(selectLabels);
      const camelcaseData = toCamelCase(response.data);
      const labelInfo = labelList.find(
        (label) => Number(label.id) === camelcaseData.labelId
      );

      const successPayload = {
        dataPoint: camelcaseData.dataPoint,
        id: camelcaseData.labelPointId,
        visible: true,
        color: labelInfo && labelInfo.color,
        labelName: labelInfo && labelInfo.name,
        classesTypeCode: "box-image",
        labelId: camelcaseData.labelId,
        dataAttribute: camelcaseData.dataAttribute,
      };

      yield put(addShapesSuccess(successPayload));
      if (callbackSaga) yield fork(callbackSaga, successPayload);
      if (noSaveAnnotationHistory) return;
      const historyID = uuidv4();
      // save annotation history
      yield put(
        addAnnotationHistory({
          id: historyID,
          type: SAVE_BOX,
          payload: successPayload,
        })
      );
      if (!isHideNotification)
        yield put(
          showNotification({ type: "success", msg: "Box saved successfully.", msgId: "notification.box-saved-success", })
        );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(saveBoxError({msg: "Save box error!", msgId: "notification.save-box-error"}));
    }
    // yield put(endCounting());
    if (callback) callback();
  });
}

function* watchUpdateBox() {
  yield takeEvery(UPDATE_BOX, function* ({ payload, noSaveAnnotationHistory }) {
    const {
      dataPoint: { x, y, width, height, rotate },
      labelId,
      id,
      isHideNotification,
    } = payload;
    const imageId = yield select(selectImageId);
    const isViewResult = getParameterByName("isViewResult");
    const isActionHistory = getParameterByName("isActionHistory");
    const isBenchmark = isViewResult || isActionHistory;
    let settingQuality;
    if (isBenchmark) {
      settingQuality = "benchmark";
    }

    const annotation = yield select(selectShapeById(id));
    const response = yield call(updateBox, {
      id: id,
      sourceId: imageId,
      boxItem: { x, y, width, height, rotate, labelId },
      settingQuality,
    });
    if (response.success) {
      yield put(updateShapesSuccess(payload));
      if (noSaveAnnotationHistory) return;
      // save annotation history
      const historyID = uuidv4();
      yield put(
        addAnnotationHistory({
          id: historyID,
          type: UPDATE_BOX,
          sourcePayload: annotation,
          payload,
        })
      );
      if (!isHideNotification)
        yield put(
          showNotification({
            type: "success",
            msg: "Box updated successfully.",
            msgId: "notification.box-updated-success"
          })
        );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(updateBoxError({msg: "Update box error!", msgId: "notification.box-updated-error"}));
    }
  });
}

export default function* boxSaga() {
  yield all([fork(watchSaveBox), fork(watchUpdateBox)]);
}
