import {
  KEYBOARD_EVENT,
  SET_USER_TYPING_STATUS,
  SET_OFFSET_COPY_PASTE,
  SET_CTRL_IS_PRESSING,
} from "./constants";

export const actionKeyboardEvent = (payload) => ({
  type: KEYBOARD_EVENT,
  payload,
});

export const setOffsetCopyPaste = (payload) => ({
  type: SET_OFFSET_COPY_PASTE,
  payload,
});

export const setUserTypingStatus = (payload) => ({
  type: SET_USER_TYPING_STATUS,
  payload,
});

export const setCtrlIsPressing = (payload) => ({
  type: SET_CTRL_IS_PRESSING,
  payload,
});
