import React, { useRef, memo, useState, useEffect } from "react";
import { connect } from "react-redux";
import ConnectLines from "components/Landmarks/ConnectLines";
import isEmpty from "lodash.isempty";
import cloneDeep from "lodash.clonedeep";
import IssueFlag from "components/Landmarks/IssueFlag";
import { setPointsCanvas } from "redux/canvas/actions";
import { selectFirstSelectedPointId } from "redux/points/selectors";
import {
  selectIsIndiceShown,
  selectIsLinesShown,
} from "redux/canvas/selectors";
import {
  objectOf,
  any,
  arrayOf,
  object,
  string,
  func,
  number,
  shape,
  bool,
  oneOfType,
} from "prop-types";
import Point from "./Point";

const PointsLayer = memo(function Temp({
  isReviewer,
  setPoints,
  changeDrawCount,
  selectedPoints,
  setSelectedPoints,
  pointsCanvas,
  ratio,
  points,
  displayingRegions,
  setPointsCanvas,
  isIndiceShown,
  opacity,
  autoFillPointIndex,
  linesData,
  isLinesShown,
  isKeyPointShown,
  selectedPointIds,
  isRectSelecting,
  firstSelectedPointId,
}) {
  const pointRef = useRef();
  const colours = {
    selectedPoint: "blue",
    editedPoint: "#00cc00",
    hasIssues: "#ff0000",
    default: "yellow",
    keyPoint: "#50E3C2",
  };
  const [isDragging, setIsDragging] = useState(false);

  const KeyPointsObj = {
    FaceContour: [0, 16, 32],
    OuterLip: [33, 45, 39, 51],
    InnerLip: [60, 67, 57, 70, 63, 64],
    LeftEye: [71, 77, 74, 80],
    RightEye: [83, 89, 86, 92],
    LeftIrisCenter: [103],
    RightIrisCenter: [112],
    Nostril: [163],
    LeftEyeBrow: [113, 121, 122],
    RightEyeBrow: [130, 138, 139],
  };

  useEffect(() => {
    // save selectedPoint to window object to use in sagas
    if (pointRef.current && !isEmpty(pointRef.current)) {
      window.currPoint = pointRef.current;
    }
  }, [Object.keys(selectedPoints)[0]]);

  function handleMouseOver(event) {
    document.body.style.cursor = "pointer";
    event.target.moveToBottom();
    const pointId = event.target.getAttr("id");
    if (pointId == null || isNaN(pointId)) return;
    changeScaleAndColor(event.target, colours.selectedPoint, 1.8, 1.8);
  }

  function changeScaleAndColor(node, colour, x, y) {
    if (!node) return;
    node.scale({ x, y });
    node.fill(colour);
    changeDrawCount();
  }

  function scrollIntoViewPointList(id) {
    // Scroll to the selected point in the facial landmarks reporter
    const targetEl = document.getElementById(id);
    if (targetEl) {
      targetEl.scrollIntoView();
    }
  }

  function handleDragMove(event) {
    setIsDragging(true);
    if (selectedPointIds.size === 1) return; // do not rerender if there is only one selected point

    changeMultiplePointsPosition(event);
  }

  function changeMultiplePointsPosition(event) {
    const { id, x, y, issued } = event.target.getAttrs();
    const prevPoint = selectedPoints[id];
    if (!x || !y || !prevPoint) return;
    const changed = { x: x - prevPoint.x, y: y - prevPoint.y };
    const canvasSelectedPoints = cloneDeep(selectedPoints);
    for (let point in canvasSelectedPoints) {
      canvasSelectedPoints[point].x += changed.x;
      canvasSelectedPoints[point].y += changed.y;
      canvasSelectedPoints[point].isChanged = true;
      canvasSelectedPoints[point].issued = issued;
    }

    const newSelectedPoints = cloneDeep(canvasSelectedPoints);
    for (let point in newSelectedPoints) {
      newSelectedPoints[point].x /= ratio;
      newSelectedPoints[point].y /= ratio;
    }

    setSelectedPoints(canvasSelectedPoints);

    const newPointsCanvas = [];
    const newPoints = points.reduce((acc, point) => {
      const { id, x, y } = point;
      const newPointCanvas = { ...point, x: x * ratio, y: y * ratio };
      if (newSelectedPoints[id]) {
        acc[id] = newSelectedPoints[id];
        newPointsCanvas.push(canvasSelectedPoints[id]);
      } else {
        acc.push(point);
        if (displayingRegions.has(newPointCanvas.region))
          newPointsCanvas.push(newPointCanvas);
      }
      return acc;
    }, []);

    setPoints(newPoints);
    setPointsCanvas(newPointsCanvas);
  }

  function handleDragEnd(event) {
    setIsDragging(false);
    changeMultiplePointsPosition(event);
  }

  function handleClickFlag(event) {
    const id = event.target.getAttr("id");
    if (id === undefined) return;
    autoFillPointIndex(id);
  }

  function generateColour(issued, isChanged, colour, keyPointShow) {
    if (issued) return colours.hasIssues;

    if (keyPointShow) {
      return colours.keyPoint;
    }

    if (isChanged) {
      return colours.editedPoint;
    }

    return colour;
  }

  function findCircleWithId(event) {
    const group = event.target.getParent();
    return group.find((node) => {
      const groupId = node.getAttr("id");
      return groupId === 0 || groupId;
    })[0];
  }

  function handleMouseOverOuterCircle(event) {
    document.body.style.cursor = "pointer";
    const innerCircle = findCircleWithId(event);
    changeScaleAndColor(innerCircle, colours.selectedPoint, 1.8, 1.8);
  }

  function handleMouseLeaveOuterCircle(event) {
    document.body.style.cursor = "default";
    const innerCircle = findCircleWithId(event);
    if (!innerCircle) return;
    const { id, colour } = innerCircle.getAttrs();
    innerCircle.fill(colours.editedPoint);
    changeDrawCount();

    if (selectedPointIds.has(id)) return;
    changeScaleAndColor(innerCircle, colour, 1, 1);
  }

  function handleClickOuterCircle(event) {
    const innerCircle = findCircleWithId(event);
    if (!innerCircle) return;
    const pointId = innerCircle.getAttr("id");
    if (selectedPointIds.size > 1) {
      return;
    }
    window.currPoint = event.target.getParent(); // save selectedPoint to window object to use in sagas
    innerCircle.fill(colours.editedPoint);
    const selectedPoint = pointsCanvas.find((point) => point.id === pointId);
    const payload = { [selectedPoint.id]: { ...selectedPoint } };
    setSelectedPoints(payload);
    scrollIntoViewPointList(selectedPoint.id);
  }

  function handleMouseLeave(event) {
    const { id, colour } = event.currentTarget.getAttrs();
    if (selectedPointIds.has(id)) return;
    const circlesArr = event.currentTarget.getChildren().toArray();
    const circle = circlesArr.find((cir) => cir.getAttr("id") === id);
    changeScaleAndColor(circle, colour, 1, 1);
  }

  function isKeyPoint(id, region) {
    return (
      isKeyPointShown &&
      KeyPointsObj[region] &&
      KeyPointsObj[region].findIndex((element) => element === id) > -1
    );
  }

  const layer = [];
  {
    isLinesShown &&
      layer.push(
        <ConnectLines
          linesData={linesData}
          displayingRegions={displayingRegions}
        />
      );
  }

  const pointsLayer =
    pointsCanvas &&
    pointsCanvas.map((point, index) => {
      const { x, y, id, issued, isChanged, colour, region } = point;

      const generatedColour = generateColour(
        issued,
        isChanged,
        colour,
        isKeyPoint(id, region)
      );

      return (
        <Point
          key={id}
          id={id}
          x={x}
          y={y}
          isRectSelecting={isRectSelecting}
          handleMouseLeave={handleMouseLeave}
          handleDragMove={handleDragMove}
          index={index}
          region={region}
          isChanged={isChanged}
          issued={issued}
          generatedColour={generatedColour}
          handleDragEnd={handleDragEnd}
          selectedPointIds={selectedPointIds}
          pointRef={pointRef}
          isIndiceShown={isIndiceShown}
          isKeyPoint={isKeyPoint}
          handleMouseOverOuterCircle={handleMouseOverOuterCircle}
          handleClickOuterCircle={handleClickOuterCircle}
          handleMouseLeaveOuterCircle={handleMouseLeaveOuterCircle}
          handleMouseOver={handleMouseOver}
          opacity={opacity}
        />
      );
    });

  layer.push(pointsLayer);

  if (
    isReviewer &&
    selectedPoints[firstSelectedPointId] &&
    displayingRegions.has(selectedPoints[firstSelectedPointId].region) &&
    !isDragging
  ) {
    layer.push(
      <IssueFlag
        x={selectedPoints[firstSelectedPointId].x + 6}
        y={selectedPoints[firstSelectedPointId].y + 6}
        id={firstSelectedPointId}
        data-id="issue-flag"
        labelText="labelText"
        onClick={handleClickFlag}
      />
    );
  }

  return <>{layer}</>;
});

PointsLayer.propTypes = {
  points: arrayOf(object),
  pointsCanvas: arrayOf(object),
  ratio: number,
  isIndiceShown: bool,
  setPointsCanvas: func,
  setPoints: func,
  changeDrawCount: func,
  selectedPoints: shape({
    id: string,
    x: oneOfType([number, string]),
    y: oneOfType([number, string]),
  }),
  setSelectedPoints: func,
  opacity: number,
  isReviewer: bool,
  openIssueForm: func,
  autoFillPointIndex: func,
  displayingRegions: any,
  linesData: objectOf(object),
  isLinesShown: bool,
  isKeyPointShown: bool,
  selectedPointIds: object,
  isRectSelecting: bool,
  firstSelectedPointId: number,
};

const mapState = (state) => ({
  isIndiceShown: selectIsIndiceShown(state),
  isLinesShown: selectIsLinesShown(state),
  firstSelectedPointId: selectFirstSelectedPointId(state),
});

export default connect(mapState, { setPointsCanvas })(PointsLayer);
