export const SET_POINTS = "points/SET_POINTS";
export const SET_SELECTED_POINTS = "points/SET_SELECTED_POINTS";

export const SUBMIT_POINTS = "points/SUBMIT_POINTS";
export const SUBMIT_POINTS_SUCCESS = "points/SUBMIT_POINTS_SUCCESS";
export const SUBMIT_POINTS_ERROR = "points/SUBMIT_POINTS_ERROR";

export const FETCH_LABELS_SUCCESS = "points/FETCH_LABELS_SUCCESS";
export const FETCH_LABELS_FAILURE = "points/FETCH_LABELS_FAILURE";

export const AUTO_FILL_POINT_INDEX = "points/AUTO_FILL_POINT_INDEX";
export const AUTO_FILL_OBJECT_INDEX = "points/AUTO_FILL_OBJECT_INDEX";

export const CHANGE_DISPLAY_REGIONS = "points/CHANGE_DISPLAY_REGIONS";
export const ADD_DISPLAY_REGION = "points/ADD_DISPLAY_REGION";
export const REMOVE_DISPLAY_REGION = "points/REMOVE_DISPLAY_REGION";

export const DISPATCH_IRIS_RADIUS = "canvas/DISPATCH_IRIS_RADIUS";
export const DISPATCH_IRIS_RADIUS_SUCCESS =
  "canvas/DISPATCH_IRIS_RADIUS_SUCCESS";
export const SELECT_LABEL = "points/SELECT_LABEL";
