/*
 * Result Table reducer keeps all states of result tables on the right hand side
 *
 */

import produce from "immer";
import {
  SET_POINTS,
  SET_SELECTED_POINTS,
  AUTO_FILL_POINT_INDEX,
  FETCH_LABELS_SUCCESS,
  CHANGE_DISPLAY_REGIONS,
  ADD_DISPLAY_REGION,
  REMOVE_DISPLAY_REGION,
  DISPATCH_IRIS_RADIUS_SUCCESS,
  // DISPATCH_IRIS_RADIUS,
  AUTO_FILL_OBJECT_INDEX,
  SELECT_LABEL,
} from "./constants";
import { CHANGE_IMAGE_RATIO } from "redux/canvas/constants";
import {
  SELECT_ONE_SOURCE,
  SELECT_ONE_SOURCE_SUCCESS,
} from "redux/image/constants";
import { CLOSE_ISSUE_FORM } from "redux/issues/actions";

// The initial state of the App
export const initialState = {
  points: [],
  selectedPoints: {},
  selectedPointIds: new Set(),
  irisCenter: {
    leftRadius: 30,
    rightRadius: 30,
  },
  labels: [],
  currLabel: null,
  displayingRegions: new Set(),
  clickedFlagIndex: "",
};

const pointsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SELECT_ONE_SOURCE:
        draft.points = [];
        break;
      case SELECT_ONE_SOURCE_SUCCESS: {
        const { leftRadius, rightRadius } = action.payload;
        draft.irisCenter.leftRadius = leftRadius
          ? Number(leftRadius)
          : leftRadius;
        draft.irisCenter.rightRadius = rightRadius
          ? Number(rightRadius)
          : rightRadius;
        break;
      }

      case SET_POINTS:
        draft.points = action.payload;
        break;

      case SET_SELECTED_POINTS: {
        const ids = Object.keys(action.payload);
        const idsSet = new Set();
        for (let id in action.payload) {
          idsSet.add(Number(id));
        }
        draft.selectedPointIds = idsSet;
        draft.selectedPoints = action.payload || {};
        draft.clickedFlagIndex = ids[0] ? `#${ids[0]}` : "";
        break;
      }

      case CHANGE_IMAGE_RATIO: {
        const currPoints = state.selectedPoints;
        for (let item in currPoints) {
          const { x, y } = currPoints[item];
          const newX = Number(x) * Number(action.payload);
          const newY = Number(y) * Number(action.payload);
          const point = { ...currPoints[item], x: newX, y: newY };
          draft.selectedPoints[item] = point;
        }
        break;
      }

      case AUTO_FILL_POINT_INDEX: {
        draft.clickedFlagIndex = `#${action.payload}`;
        break;
      }
      case AUTO_FILL_OBJECT_INDEX: {
        draft.clickedFlagIndex = action.payload;
        break;
      }

      case CLOSE_ISSUE_FORM: {
        draft.clickedFlagIndex = "";
        break;
      }

      case FETCH_LABELS_SUCCESS:
        draft.labels = action.payload;
        break;

      case CHANGE_DISPLAY_REGIONS:
        draft.displayingRegions = action.payload;
        break;

      case ADD_DISPLAY_REGION: {
        const currRegions = new Set(state.displayingRegions);
        draft.displayingRegions = currRegions.add(action.payload);
        break;
      }

      case REMOVE_DISPLAY_REGION: {
        const currRegions = new Set(state.displayingRegions);
        currRegions.delete(action.payload);
        draft.displayingRegions = currRegions;
        break;
      }

      case DISPATCH_IRIS_RADIUS_SUCCESS: {
        const { leftRadius, rightRadius } = action.payload;
        if (leftRadius) draft.irisCenter.leftRadius = Number(leftRadius);
        if (rightRadius) draft.irisCenter.rightRadius = Number(rightRadius);
        break;
      }
      case SELECT_LABEL:
        draft.currLabel = action.payload;
        break;

      default:
    }
  });

export default pointsReducer;
