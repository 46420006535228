/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { object, number, bool, array, func, arrayOf } from "prop-types";
import Clear from "@material-ui/icons/Clear";
import style from "./style.module.scss";
import { getDisplayingShapes } from "redux/shape/selector";
import { updateShapeAttribute, closeAllAttribute } from "redux/shape/actions";
import { getAllLabelsAttribute } from "redux/points/selectors";
import { setUserTypingStatus } from "redux/keyboard/action";
import { selectIsUserTypingStatus } from "redux/keyboard/selectors";

const ObjectAttribute = ({
  intl,
  currentShape,
  showAttributeForm,
  checkFormPosition,
  formPosition,
  maxHeight,
  displayingShapes,
  updateShapeAttribute,
  closeAllAttribute,
  labels,
  isUserTyping,
  setUserTypingStatus,
}) => {
  const getListAllAttributeOfLabel = () => {
    const labelUsed = labels.filter((item) => item.id === currentShape.labelId);
    let attributesLabel =
      labelUsed.length > 0 ? [...labelUsed[0].dataAttribute] : [];
    attributesLabel = attributesLabel.map((item) => {
      const selected = currentShape.dataAttribute.filter(
        (attr) => attr.labelAttributeId === item.labelAttributeId
      );
      item.attributeValue =
        selected.length > 0
          ? selected[0].attributeValue
          : item.dataType === "array"
          ? ""
          : item.defaultAttributeValue;
      if (item.dataType === "array" && item.attributeValue === "") {
        const options = JSON.parse(item.defaultAttributeValue);
        item.attribute = options[0];
      }
      return item;
    });
    return attributesLabel;
  };

  const valueRef = useRef();

  const [attributes, setAttributes] = useState(
    currentShape ? [...currentShape.dataAttribute] : []
  );

  const getValueFromAttribute = (attrs) =>
    (attrs || []).map((attr) => {
      if (attr.dataType === "array") {
        const defaultVal = JSON.parse(attr.defaultAttributeValue);
        return attr.attributeValue || defaultVal[0];
      }
      return attr.attributeValue;
    });
  const allAttribute = getListAllAttributeOfLabel();
  const initialValue = getValueFromAttribute(allAttribute);
  const [value, setValue] = useState(initialValue);
  const [isChangedAttribute, setIsChangedAttribute] = useState(false);
  const classes = style;

  const styleContainer = {
    position: "absolute",
    zIndex: 1000,
    borderRadius: "10px",
    textAlign: "center",
    width: 230,
    boxSizing: "border-box",
  };
  useEffect(() => {
    return () => {
      if (!(valueRef && valueRef.current)) return;
      const updateAttr = valueRef.current?.findIndex(
        (attr, index) => attr !== attributes[index]?.attributeValue
      );
      if (updateAttr !== -1) {
        setIsChangedAttribute(false);
      }
    };
  }, []);

  useEffect(() => {
    const labelAttributes = getListAllAttributeOfLabel();
    setAttributes(labelAttributes);
    if (!isChangedAttribute) {
      const values = getValueFromAttribute(labelAttributes);
      setValue(values);
    }
  }, [currentShape]);

  useEffect(() => {
    valueRef.current = value;
    if (isChangedAttribute) {
      const timer = setTimeout(
        () => handlUpdateShapeAttributes(attributes, value),
        800
      );
      setIsChangedAttribute(false);
      return () => clearTimeout(timer);
    }
  }, [value]);

  const handlUpdateShapeAttributes = (iAttributes, iValue) => {
    let newAttributes = [...iAttributes];
    newAttributes = newAttributes.map((item, index) => {
      item.attributeValue = iValue[index];
      return item;
    });
    updateShapeAttribute({
      labelId: currentShape.id,
      dataAttribute: newAttributes,
    });
  };

  const handleChange = (index) => (event) => {
    if (!isUserTyping) setUserTypingStatus(true);
    let newValue = [...value];
    newValue[index] = event.target.value;
    setValue(newValue);
    setIsChangedAttribute(true);
  };

  const checkDisplayShape = () => {
    if (!checkFormPosition) return true;
    return (
      displayingShapes.findIndex(
        (shape) => Number(shape.id) === Number(currentShape.id)
      ) !== -1
    );
  };
  const onFocus = () => {
    if (!isUserTyping) setUserTypingStatus(true);
  };

  const onBlur = () => {
    setUserTypingStatus(false);
  };

  const handleKeyDown = (e) => {
    // Fix SF-1315, redirect to http://localhost:3000/? when hit enter
    if (e.which === 13) {
      e.preventDefault();
      setUserTypingStatus(false);
      setIsChangedAttribute(false);
      handlUpdateShapeAttributes(attributes, value);
      closeAllAttribute();
    }
  };

  const renderTextField = (attr, index, props) => {
    return (
      <div key={index}>
        <FormControl variant="outlined" className={classes.formField}>
          <InputLabel
            htmlFor="component-outlined"
            classes={{
              outlined: classes.muiInputLabelOutlined,
              shrink: classes.MuiInputLabelShrink,
            }}
          >
            {attr.attributeName}
          </InputLabel>
          <OutlinedInput
            onKeyDown={handleKeyDown}
            onBlur={onBlur}
            onFocus={onFocus}
            key={index}
            id={"attribute-" + attr.labelAttributeId}
            value={value[index]}
            onChange={handleChange(index)}
            label={attr.attributeName}
            classes={{ input: classes.outlinedInput }}
            size="small"
            {...props}
          />
        </FormControl>
      </div>
    );
  };

  const renderSelectField = (attr, index) => {
    let options = [];
    if (!attr.hasOwnProperty("defaultAttributeValue")) {
      const allAttribute = getListAllAttributeOfLabel();
      const attributeUsed = allAttribute.filter(
        (item) => item.labelAttributeId === attr.labelAttributeId
      );
      options =
        attributeUsed.length > 0
          ? JSON.parse(attributeUsed[0].defaultAttributeValue)
          : [];
    } else {
      options = JSON.parse(attr.defaultAttributeValue);
    }
    return (
      <div key={index}>
        <FormControl variant="outlined" className={classes.formField}>
          <InputLabel
            id="demo-simple-select-outlined-label"
            classes={{
              outlined: classes.muiInputLabelOutlined,
              shrink: classes.MuiInputLabelShrink,
            }}
          >
            {attr.attributeName}
          </InputLabel>
          <Select
            key={index}
            labelId="demo-simple-select-outlined-label"
            id={"attribute-" + attr.labelAttributeId}
            value={value[index]}
            onChange={handleChange(index)}
            label={attr.attributeName}
            size="small"
            classes={{ select: classes.outlinedInput }}
            onBlur={onBlur}
            onFocus={onFocus}
          >
            {options.map((value) => (
              <MenuItem
                key={value}
                value={value}
                classes={{
                  root: classes.muiMenuItemRoot,
                }}
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderDateField = (attr, index) => {
    return (
      <div key={index}>
        <FormControl variant="outlined" className={classes.formField}>
          <InputLabel
            id="demo-simple-select-outlined-label"
            classes={{
              outlined: classes.muiInputLabelOutlined,
              shrink: classes.MuiInputLabelShrink,
            }}
            shrink
          >
            {attr.attributeName}
          </InputLabel>
          <OutlinedInput
            onKeyDown={handleKeyDown}
            onBlur={onBlur}
            onFocus={onFocus}
            key={index}
            id={"attribute-" + attr.labelAttributeId}
            type="date"
            value={value[index]}
            onChange={handleChange(index)}
            size="small"
            classes={{ input: classes.outlinedInput }}
          />
        </FormControl>
      </div>
    );
  };

  const formStyle = () => {
    if (checkFormPosition) {
      return {
        ...styleContainer,
        maxHeight,
        transform: `translateX(${formPosition.left}px)`,
        top: formPosition.top,
      };
    } else {
      return {
        backgroundColor: "#f0f0f0",
        maxHeight,
        marginLeft: 40,
      };
    }
  };

  const onCloseAllAttribute = () => {
    closeAllAttribute();
  };

  const getStyleOfFloatingForm = () =>
    checkFormPosition
      ? {
          boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.73)",
          border: "1px solid #8f8f8f",
        }
      : {};
  return (
    checkDisplayShape() &&
    attributes.length > 0 &&
    showAttributeForm && (
      <div
        className={classes.attributeLabelForm}
        style={{
          ...formStyle(),
          ...getStyleOfFloatingForm(),
        }}
      >
        {checkFormPosition && (
          <div className={classes.label}>
            <p>{currentShape.labelName}</p>
            <button
              type="button"
              title={intl.formatMessage({ id: "common.close" })}
              onClick={onCloseAllAttribute}
            >
              <Clear />
            </button>
          </div>
        )}
        <form className={classes.root} noValidate autoComplete="off">
          {attributes.map((attr, index) => {
            switch (attr.dataType) {
              case "text":
                return renderTextField(attr, index);
              case "number":
                return renderTextField(attr, index, { type: "number" });
              case "array":
                return value[index] && renderSelectField(attr, index);
              case "date":
                return renderDateField(attr, index);
              default:
                return renderTextField(attr, index);
            }
          })}
        </form>
      </div>
    )
  );
};

ObjectAttribute.propTypes = {
  intl: object,
  currentShape: object,
  showAttributeForm: bool,
  formPosition: object,
  maxHeight: number,
  checkFormPosition: bool,
  displayingShapes: array,
  updateShapeAttribute: func,
  closeAllAttribute: func,
  labels: arrayOf(object),
  isUserTyping: bool,
  setUserTypingStatus: func,
};

const mapStateToProps = (state) => ({
  displayingShapes: getDisplayingShapes(state),
  labels: getAllLabelsAttribute()(state),
  isUserTyping: selectIsUserTypingStatus(state),
});

export default connect(mapStateToProps, {
  updateShapeAttribute,
  closeAllAttribute,
  setUserTypingStatus,
})(injectIntl(ObjectAttribute));
