import React from "react";
import { Image } from "react-konva";
import { number, string, func } from "prop-types";
import useImage from "use-image";
import Flag from "./Flag.svg";

const handleMouseOver = () => {
  document.body.style.cursor = "pointer";
};

const handleMouseLeave = () => {
  document.body.style.cursor = "default";
};

const FlagIcon = ({ x, y, name, onClick, labelText, id }) => {
  const [image] = useImage(Flag);
  return (
    <Image
      id={id}
      x={x}
      y={y}
      name={name}
      image={image}
      fill="white"
      labelText={labelText}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={() => {}}
    />
  );
};

FlagIcon.propTypes = {
  x: number,
  y: number,
  name: string,
  onClick: func,
  labelText: string,
  id: number,
};

export default FlagIcon;
