export const defaultHotkeys = {
  a: {
    name: "a",
    key: "a",
  },
  d: {
    name: "d",
    key: "d",
  },
  s: {
    name: "s",
    key: "s",
  },
  f: {
    name: "f",
    key: "f",
  },
  v: {
    name: "v",
    key: "v",
  },
  y: {
    name: "y",
    key: "y",
  },
  decline: {
    name: "n",
    key: "n",
  },
  n: {
    name: "n",
    key: "n",
  },
  m: {
    name: "m",
    key: "m",
  },
  move: {
    name: "1",
    key: "1",
  },
  autoIrisCircle: {
    name: "2",
    key: "2",
  },
  box: {
    name: "3",
    key: "3",
  },
  polygon: {
    name: "4",
    key: "4",
  },
  segmentation: {
    name: "5",
    key: "5",
  },
  cuboid: {
    name: "6",
    key: "6",
  },
  ocr: {
    name: "7",
    key: "7",
  },
  polyline: {
    name: "8",
    key: "8",
  },
  showIndice: {
    name: "i",
    key: "i",
  },
  showIrisCircles: {
    name: "c",
    key: "c",
  },
  showLines: {
    name: "l",
    key: "l",
  },
  ArrowLeft: {
    name: "Arrow Left",
    key: "ArrowLeft",
  },
  ArrowRight: {
    name: "Arrow Right",
    key: "ArrowRight",
  },
  ArrowUp: {
    name: "Arrow Up",
    key: "ArrowUp",
  },
  ArrowDown: {
    name: "Arrow Down",
    key: "ArrowDown",
  },
  Control: {
    name: "Ctrl",
    key: "Control",
  },
  Space: {
    name: "Space",
    key: " ",
  },
  Shift: {
    name: "Shift",
    key: "Shift",
  },
  Delete: {
    name: "Delete",
    key: "Delete",
  },
  Backspace: {
    name: "Backspace",
    key: "Backspace",
  },
  Escape: {
    name: "Escape",
    key: "Escape",
  },
  z: {
    name: "z",
    key: "z",
  },
  w: {
    name: "w",
    key: "w",
  },
};
