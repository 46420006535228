import {
  all,
  fork,
  takeEvery,
  call,
  put,
  select,
  cancel,
} from "redux-saga/effects";

import {
  getShapeAPI,
  deleteShapeAPI,
  deleteAllShapeAPI,
  updateShapeAttribute,
  updateShapeLabelAPI,
} from "api/shape";
import {
  getShapes,
  getShapesSuccess,
  getShapesError,
  setDisplayingShape,
  deleteShapeSucess,
  deleteShapeError,
  updateShapeAttributeError,
  updateShapeLabelError,
  updateShapeLabelSuccess,
  setCurrentShapeSuccess,
  deleteAllShapeError,
  deleteAllShapeSuccess,
} from "redux/shape/actions";
import {
  getDisplayingShapes,
  getAllShapes,
  selectShapeById,
} from "redux/shape/selector";
import { selectProjectId, selectDatasetId } from "redux/app/selectors";
import {
  GET_SHAPE,
  SET_DISPLAYING_SHAPE_BY_ID,
  SET_DISPLAYING_SHAPE_BY_LABEL_ID,
  DELETE_SHAPE,
  DELETE_ALL_SHAPE,
  UPDATE_SHAPE_ATTRIBUTE,
  UPDATE_SHAPE_LABEL,
  SET_CURRENT_SHAPE,
  SET_COPY_SHAPE,
  SET_DRAWING_POLYGON,
} from "./constants";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { selectToolSuccess } from "redux/tools/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import { selectImageId, selectAnnotatedById } from "../image/selectors";
import { addAnnotationHistory } from "redux/annotationHistory/actions";
import { v4 as uuidv4 } from "uuid";
import { selectLabelsForDropDown } from "redux/points/selectors";
import * as allTools from "redux/tools/enums";
import { selectLabel } from "redux/points/actions";
import { getParameterByName } from "helpers";

import {
  // setCurrentSegmentationMode,
  setDrawingSegmentation,
  resetSegmentationMode,
} from "redux/segmentation/actions";
// import { segMode } from "redux/segmentation/enums";
import get from "lodash.get";
import { getIsUnsaveSegmentationSelector } from "redux/segmentation/selector";
import { SET_DRAWING_SEGMENTATION } from "redux/segmentation/constants";

const convertSegmentationItem = async (data) => {
  const response = data.map(async (item) => {
    if (item.classesTypeCode !== "segmentation-image") return item;
    const base64Data = await fetch(item.dataPoint.url)
      .then((response) => response.json())
      .catch((error) => console.log(`Failed because: ${error}`));
    return {
      ...item,
      dataPoint: {
        ...item.dataPoint,
        base64Data,
      },
    };
  });
  return Promise.all(response);
};

function* watchGetShapes() {
  yield takeEvery(GET_SHAPE, function* ({ sourceId }) {
    const datasetId = yield select(selectDatasetId);
    const projectId = yield select(selectProjectId);
    // TODO: should check this in consensus
    const isViewResult = getParameterByName("isViewResult");
    const isActionHistory = getParameterByName("isActionHistory");
    const settingQuality = isViewResult || isActionHistory ? "benchmark" : null;
    let alterUserId = getParameterByName("alterUserId");
    const annotatedById = yield select(selectAnnotatedById);
    let sentSourceId = sourceId;
    if (isViewResult) {
      alterUserId = annotatedById;
      sentSourceId = sourceId.replace(`-${annotatedById}`, "");
    }

    const response = yield call(getShapeAPI, {
      sourceId: sentSourceId,
      datasetId,
      projectId,
      settingQuality,
      alterUserId,
    });
    if (response.success) {
      const dataConverted = yield call(convertSegmentationItem, response.data);
      yield put(getShapesSuccess(dataConverted));
    } else {
      yield put(
        getShapesError({
          msg: "Get shape error!",
          msgId: "notification.get-shape-error",
        })
      );
    }
  });
}

function* watchSetDisplayingShapeById() {
  yield takeEvery(SET_DISPLAYING_SHAPE_BY_ID, function* ({ id, labelId }) {
    const displayingShapes = yield select(getDisplayingShapes);
    const displayingArr = displayingShapes.find(
      (shape) => Number(shape.id) === Number(id)
    )
      ? displayingShapes.filter((shape) => Number(shape.id) !== Number(id))
      : [...displayingShapes, { id, labelId }];
    yield put(setDisplayingShape(displayingArr));
  });
}

function* watchSetDisplayingShapeByLabelId() {
  yield takeEvery(SET_DISPLAYING_SHAPE_BY_LABEL_ID, function* ({ labelId }) {
    const displayingShapes = yield select(getDisplayingShapes);
    const shapes = yield select(getAllShapes);
    let displayingArr = [];
    const findLabelDisplaying = displayingShapes.find(
      (shape) => Number(shape.labelId) === Number(labelId)
    );
    if (findLabelDisplaying) {
      displayingArr = displayingShapes.filter(
        (shape) => Number(shape.labelId) !== Number(labelId)
      );
    } else {
      const shapeByLabelId = shapes
        .filter((shape) => Number(shape.labelId) === Number(labelId))
        .map(({ id, labelId }) => ({ id, labelId }));
      displayingArr = [...displayingShapes, ...shapeByLabelId];
    }
    yield put(setDisplayingShape(displayingArr));
  });
}

function* watchDeleteShape() {
  yield takeEvery(DELETE_SHAPE, function* ({
    payload,
    noSaveAnnotationHistory,
  }) {
    const { id } = payload;
    const annotation = yield select(selectShapeById(id));
    const response = yield call(deleteShapeAPI, { id });
    if (response.success) {
      if (payload.classesTypeCode === "segmentation-image") {
        yield put(resetSegmentationMode());
      }
      yield put(deleteShapeSucess({ id }));
      if (noSaveAnnotationHistory) return;
      const historyID = uuidv4();
      // save annotation history
      yield put(
        addAnnotationHistory({
          id: historyID,
          type: DELETE_SHAPE,
          payload: annotation,
        })
      );
      yield put(
        showNotification({
          type: "success",
          msg: "Deleted successfully.",
          msgId: "notification.deleted-success",
        })
      );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(
        deleteShapeError({
          msg: "Delete shape error!",
          msgId: "notification.delete-shape-error",
        })
      );
    }
  });
}

function* watchUpdateShapeAttribute() {
  yield takeEvery(UPDATE_SHAPE_ATTRIBUTE, function* ({ payload }) {
    const imageId = yield select(selectImageId);

    const { labelId, dataAttribute } = payload;
    const response = yield call(updateShapeAttribute, {
      labelId,
      dataAttribute,
    });
    if (response.success) {
      yield put(getShapes(imageId));
      yield put(
        showNotification({
          type: "success",
          msg: "Shape attribute updated successfully.",
          msgId: "notification.shape-attribute-updated-success",
        })
      );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(
        updateShapeAttributeError({
          msg: "Update shape attribute error!",
          msgId: "notification.update-shape-attribute-error",
        })
      );
    }
  });
}

function* watchUpdateShapeLabel() {
  yield takeEvery(UPDATE_SHAPE_LABEL, function* ({ payload }) {
    const { labelId, color, id } = payload;
    const isActionHistory = getParameterByName("isActionHistory");
    const alterUserId = getParameterByName("alterUserId");
    const settingQuality = isActionHistory && !alterUserId ? "benchmark" : null;
    const sourceId = yield select(selectImageId);
    const labels = yield select(selectLabelsForDropDown());
    const response = yield call(updateShapeLabelAPI, {
      id,
      labelId,
      color,
      settingQuality,
      sourceId,
    });
    const shapeLabel = labels.find(
      (label) => Number(label.id) === Number(labelId)
    );
    const shapes = yield select(getAllShapes);
    let updateShape = shapes.find((shape) => Number(shape.id) === Number(id));
    updateShape = {
      ...updateShape,
      labelId,
      color,
      labelName: shapeLabel?.label,
    };

    if (response.success) {
      yield put(updateShapeLabelSuccess(updateShape));
      yield put(
        showNotification({
          type: "success",
          msg: "Update shape label success.",
          msgId: "notification.update-shape-label-success",
        })
      );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(
        updateShapeLabelError({
          msg: "Update shape label error.",
          msgId: "notification.update-shape-label-error",
        })
      );
    }
  });
}

function* watchCurrentShapeSegmentation() {
  yield takeEvery(SET_CURRENT_SHAPE, function* ({ payload }) {
    const isUnsaveSegmentation = yield select(getIsUnsaveSegmentationSelector);
    if (isUnsaveSegmentation) {
      if (
        !window.confirm(
          "Your drawing is not saved. Do you want to leave the page?"
        )
      )
        return;
    }
    yield put(resetSegmentationMode());

    if (payload?.classesTypeCode === `${allTools.SEGMENTATION_MODE}-image`) {
      const labels = yield select(selectLabelsForDropDown());
      yield put(selectToolSuccess(""));
      // yield put(setCurrentSegmentationMode(segMode.SMART_MODE));
      if (payload && payload.labelId) {
        const tmpCurrentLabel = labels.find(
          (x) => Number(x.id) === Number(payload.labelId)
        );
        yield put(selectLabel(tmpCurrentLabel));

        const x = get(payload, "dataPoint.x", 0);
        const y = get(payload, "dataPoint.y", 0);
        const width = get(payload, "dataPoint.width", 0);
        const height = get(payload, "dataPoint.height", 0);
        const base64Data = get(payload, "dataPoint.base64Data", "");
        const pointArr = get(payload, "dataPoint.pointArr", []);

        const dataDrawingSegmentation = {
          id: payload.id,
          labelId: payload.labelId,
          labelName: payload.labelName,
          color: payload.color,
          classesTypeCode: payload.classesTypeCode,
          dataAttribute: payload.dataAttribute,
          x,
          y,
          width,
          height,
          url: base64Data,
          pointArr,
          isSaveAnnotationHistory: true,
        };
        yield put(setDrawingSegmentation(dataDrawingSegmentation));
      } else {
        yield put(selectLabel(null));
      }
    }

    yield put(setCurrentShapeSuccess(payload));
  });
}

function* watchSetCopyShape() {
  yield takeEvery(SET_COPY_SHAPE, function* ({ payload }) {
    if (payload?.id) {
      yield put(
        showNotification({
          type: "success",
          msg: "Shape copied.",
          msgId: "notification.shape-copied",
        })
      );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    }
  });
}

function* watchSetDrawingPolygonAndSegmentation() {
  yield takeEvery([SET_DRAWING_POLYGON, SET_DRAWING_SEGMENTATION], function* ({
    payload,
    type,
  }) {
    if (!payload) yield cancel();

    const { isSaveAnnotationHistory, ...rest } = payload;

    if (isSaveAnnotationHistory) {
      const historyID = uuidv4();
      // save annotation history
      yield put(
        addAnnotationHistory({
          id: historyID,
          type,
          payload: { ...rest },
        })
      );
    }
  });
}

function* watchDeleteAllShape() {
  yield takeEvery(DELETE_ALL_SHAPE, function* ({ payload }) {
    const { imageId } = payload;
    const response = yield call(deleteAllShapeAPI, imageId);
    if (response.message === "success") {
      yield put(deleteAllShapeSuccess());
      yield put(
        showNotification({
          type: "success",
          msg: "Deleted all shape successfully.",
          msgId: "notification.deleted-all-shape-success",
        })
      );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(
        deleteAllShapeError({
          msg: "Delete all shape error!",
          msgId: "notification.delete-all-shape-error",
        })
      );
    }
  });
}

export default function* boxSaga() {
  yield all([
    fork(watchGetShapes),
    fork(watchSetDisplayingShapeById),
    fork(watchSetDisplayingShapeByLabelId),
    fork(watchDeleteShape),
    fork(watchUpdateShapeAttribute),
    fork(watchUpdateShapeLabel),
    fork(watchCurrentShapeSegmentation),
    fork(watchSetCopyShape),
    fork(watchSetDrawingPolygonAndSegmentation),
    fork(watchDeleteAllShape),
  ]);
}
