import {
  all,
  fork,
  put,
  takeEvery,
  cancel,
  select,
  call,
  take,
  takeLatest,
} from "redux-saga/effects";
import isEmpty from "lodash.isempty";
import isNil from "lodash.isnil";
import { getCookie, getParameterByName, setCookie, toCamelCase } from "helpers";
import { defaultStatus, dropdownItemsByStatus } from "helpers/constants";
import {
  changeCurrentDropdownStatus,
  fetchUserInProjectSuccess,
} from "redux/app/actions";
import {
  bootingFinished,
  cleanAnnotationsData,
  setSourceInfo,
  setUserData,
  getModuleCode,
  loadRepoError,
  getModuleCodeError,
  getModuleCodeSuccess,
  updateRoleCode,
  // fetchFirebaseToken,
  fetchFirebaseTokenSuccess,
  fetchInitialDataSuccess,
  fetchUserInProject,
  setLabelerId,
} from "./actions";
import {
  fetchImages,
  getViewResultBenchmarking,
  getActionHistoryBenchmarking,
} from "api/image";
import { fetchLabels } from "api/issues";
import {
  FETCH_INPUT_DATA,
  SELECT_ONE_SOURCE_SUCCESS,
  FETCH_INPUT_DATA_SUCCESS,
} from "redux/image/constants";
import {
  fetchLabelsSuccess /*, fetchLabelsFailure*/,
} from "redux/points/actions";
import { FETCH_INITIAL_DATA, UPDATE_ROLE_CODE } from "redux/app/constants";
import { setCurrentDropdownStatus, fetchInitialData } from "redux/app/actions";
import {
  selectLimit,
  selectImages,
  selectAllImgIds,
  selectIsScrollImage,
  selectNextImageId,
  selectFilterTags,
} from "redux/image/selectors";
import get from "lodash.get";
import {
  fetchInputDataSuccess,
  noMoreToFetch,
  selectOneSource,
} from "redux/image/actions";
import {
  showNotification,
  fetchPushNotifications,
  delayRemoveNotification,
} from "redux/notification/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import {
  selectDatasetId,
  selectProjectId,
  selectCustomerReview,
  selectSourceId,
  selectLabelerId,
  selectPermissionComponent,
  selectCurrentDropdownStatus,
} from "redux/app/selectors";
import { startChannel } from "redux/socket/actions";
import {
  LOAD_REPO,
  SET_SOURCE_INFO,
  GET_MODULE_CODE,
  FETCH_FIREBASE_TOKEN,
  FETCH_INITIAL_DATA_SUCCESS,
  FETCH_USER_IN_PROJECT,
  SEND_EMAIL_TO_SYSTEM_ADMIN,
} from "./constants";
import { getRole, getUserInProject } from "api";
import { messaging } from "firebase/init-fcm";
import { goFetchFirebaseToken } from "api/firebase";
import { sendEmailToSystemAdminAPI } from "api/configApp";
import cloneDeep from "lodash.clonedeep";

function* watchLoadAppInfo() {
  yield takeEvery(LOAD_REPO, function* () {
    try {
      const tokenInCookie = getCookie("Bearer");
      const userInfoInCookie = getCookie("userInfo");
      const sourceInFoInCookie = getCookie("sourceInfo");
      const userId = userInfoInCookie
        ? JSON.parse(userInfoInCookie).userId
        : null;
      const projectId = getParameterByName("projectId");
      const datasetId = getParameterByName("datasetId");
      const sourceId = getParameterByName("sourceId");
      const checkLastActive = getParameterByName("checkLastActive");
      const customerReview = getParameterByName("customerReview");
      let sourceInfo = {
        projectId,
        datasetId,
        sourceId,
        checkLastActive,
        customerReview: customerReview ? JSON.parse(customerReview) : false,
      };

      if (sourceInFoInCookie && !window.location.href.includes("?")) {
        sourceInfo = JSON.parse(sourceInFoInCookie);
      }

      if (
        !userInfoInCookie ||
        !userId ||
        !tokenInCookie ||
        !sourceInfo.projectId ||
        !sourceInfo.datasetId
      ) {
        yield put(
          loadRepoError({
            msg: "Error when fetching the repository.",
            msgId: "notification.error-fetching-repo",
          })
        );
        console.log("No token or userInfoInCookie");
        yield cancel();
      }

      yield put(
        getModuleCode({
          projectId: sourceInfo.projectId,
          userId,
          callback: function* getModuleCodeCallback(res) {
            if (res.status === 200 && res.success === true) {
              yield put(
                fetchUserInProject({ projectId: sourceInfo.projectId })
              );
              setCookie(
                "sourceInfo",
                JSON.stringify({
                  datasetId,
                  sourceId,
                  checkLastActive,
                  projectId,
                  customerReview,
                }),
                7
              );

              yield put(
                setSourceInfo({
                  ...sourceInfo,
                  checkLastActive: Boolean(checkLastActive),
                })
              );
              const camelCaseUserInfo = toCamelCase(
                JSON.parse(userInfoInCookie)
              );
              yield put(setUserData(camelCaseUserInfo));
              yield put(startChannel());
            }
          },
        })
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("load repo error", error);
      yield cancel();
    }
  });
}

function* watchGetModuleCode() {
  yield takeEvery(GET_MODULE_CODE, function* ({ payload }) {
    const { projectId, userId, callback } = payload;

    const response = yield call(getRole, { projectId, userId });
    if (callback) yield fork(callback, response);
    if (response.success) {
      const data = get(response, "data", []);
      const roleCode = get(response, "roleCode", "");
      const allowedModules = ["labeling", "customer", "point_issue"];
      const labelingModule = data.filter((module) =>
        allowedModules.some((allow) => module.includes(allow))
      );
      yield put(updateRoleCode(roleCode));
      yield put(getModuleCodeSuccess(labelingModule));
    } else if (response.status === 403) {
      yield put(
        getModuleCodeError({
          msg: "Does not have permission to access this link.",
          msgId: "notification.does-not-have-permission-to-access-this-link",
        })
      );
    } else {
      yield put(
        getModuleCodeError({
          msg: "Error when fetching the repository.",
          msgId: "notification.error-fetching-repo",
        })
      );
    }
  });
}

function* watchUserData() {
  yield takeEvery(SET_SOURCE_INFO, function* () {
    const projectId = yield select(selectProjectId);
    const sourceId = yield select(selectSourceId);

    if (sourceId) {
      yield put(selectOneSource({ id: sourceId }));
    }
    const result = yield call(fetchLabels, projectId);
    const labels = get(result, "data", []);
    if (result.success && labels.length) {
      yield put(fetchLabelsSuccess(labels));
    } else {
      console.error(
        "No classes to fetch, please contact your project manager!"
      );
    }
  });
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function* watchFetchInputAndSelectOne() {
  const twoActions = yield all([
    take(FETCH_INITIAL_DATA),
    take(SELECT_ONE_SOURCE_SUCCESS),
  ]);
  const result = twoActions.find(
    (action) => action.type === SELECT_ONE_SOURCE_SUCCESS
  );
  const status = get(result, "payload.status", null);
  const sourceId = get(result, "payload.sourceId", null);
  if (status && sourceId) {
    const sending = {
      status,
      page: 0,
      isSelectOneSourceSuccess: true,
      imageInitial: sourceId,
    };
    yield put(fetchInitialData(sending));

    if (["assigned", "inprogress", "new"].includes(status)) {
      yield put(setCurrentDropdownStatus("New"));
    } else {
      const payload = capitalizeFirstLetter(status);

      yield put(setCurrentDropdownStatus(payload));
    }
  }
}

function* watchFetchInitialImages() {
  yield takeEvery(FETCH_INITIAL_DATA, function* ({ payload }) {
    let { status, page, imageInitial } = payload;
    const limit = yield select(selectLimit);
    const allImageIds = yield select(selectAllImgIds);
    const datasetId = yield select(selectDatasetId);
    const projectId = yield select(selectProjectId);
    const customerReview = yield select(selectCustomerReview);
    const labelId = yield select(selectLabelerId);
    const isViewResult = getParameterByName("isViewResult");
    const isActionHistory = getParameterByName("isActionHistory");
    const sourceId = getParameterByName("sourceId");
    const alterUserId = getParameterByName("alterUserId");
    const defaultStatus = dropdownItemsByStatus.find((item) =>
      item.status.includes(status)
    );
    const statusArr = defaultStatus.status;
    const sendingStatus = statusArr.join(",");

    const currentStatus = yield select(selectCurrentDropdownStatus);

    const enableApprove = yield select(
      selectPermissionComponent("labeling_approve")
    );

    const userInfoInCookie = getCookie("userInfo");
    const roleCode = userInfoInCookie && JSON.parse(userInfoInCookie).roleCode;

    if (
      (!sourceId || sourceId === "null") &&
      enableApprove &&
      currentStatus != "Submitted" &&
      !payload.isSelectOneSourceSuccess
    ) {
      yield put(changeCurrentDropdownStatus("Submitted"));
      return;
    }

    if (page === 0 || page * limit >= allImageIds.length) {
      let sendingPayload = {
        projectId,
        offset: page * limit,
        limit,
        status: sendingStatus,
        imageInitial,
      };
      if (datasetId && JSON.parse(datasetId)) {
        sendingPayload.datasetId = JSON.parse(datasetId);
      }
      if (customerReview) {
        sendingPayload.customerReview = customerReview;
      }

      if (!isEmpty(labelId)) {
        sendingPayload.labelId = labelId;
      }

      let images;
      if (isViewResult) {
        images = yield call(getViewResultBenchmarking, {
          sourceId,
          pageSize: limit,
          currentPage: page + 1,
          status,
        });
      } else if (isActionHistory) {
        images = yield call(getActionHistoryBenchmarking, {
          projectId,
          pageSize: limit,
          currentPage: page + 1,
          status,
          alterUserId,
          imageInitial,
        });
      } else {
        images = yield call(fetchImages, sendingPayload);
      }

      if (images && images.length) {
        const initialStatus = status.includes("new") ? "new" : status;
        const newPayload = {
          images,
          status: initialStatus,
          page: page + 1,
        };
        if (typeof payload.isSelectOneSourceSuccess !== "undefined") {
          newPayload.isSelectOneSourceSuccess =
            payload.isSelectOneSourceSuccess;
        }
        yield put(fetchInitialDataSuccess(newPayload));

        return;
      }

      if (
        (!sourceId || sourceId === "null") &&
        roleCode === "admin" &&
        status === "approved"
      ) {
        yield put(changeCurrentDropdownStatus("New"));
        return;
      }
    }
  });
}

export function* watchFetchInputData() {
  yield takeEvery(FETCH_INPUT_DATA, function* ({ payload }) {
    const { status, page, isNotNextImage } = payload;
    let limit = yield select(selectLimit);
    const datasetId = yield select(selectDatasetId);
    const projectId = yield select(selectProjectId);
    const customerReview = yield select(selectCustomerReview);
    const labelId = yield select(selectLabelerId);
    const isViewResult = getParameterByName("isViewResult");
    const isActionHistory = getParameterByName("isActionHistory");
    const sourceId = getParameterByName("sourceId");
    const alterUserId = getParameterByName("alterUserId");
    const isScrollImage = yield select(selectIsScrollImage);
    const defaultStatus = dropdownItemsByStatus.find((item) =>
      item.status.includes(status)
    );
    const filterTags = yield select(selectFilterTags);
    const payloadFilterTags = filterTags.map((item) => item.tag_id);
    const statusArr = defaultStatus.status;
    const sendingStatus = statusArr.join(",");
    // const currDropdownStt = yield select(selectCurrentDropdownStatus);
    // if (currDropdownStt === "New") limit = 3;

    let sendingPayload = {
      projectId,
      offset: page * limit,
      limit,
      status: sendingStatus,
      tagIds: payloadFilterTags,
    };
    if (datasetId && JSON.parse(datasetId)) {
      sendingPayload.datasetId = JSON.parse(datasetId);
    }
    if (customerReview) {
      sendingPayload.customerReview = customerReview;
    }

    if (!isEmpty(labelId)) {
      sendingPayload.labelId = labelId;
    }

    let images;
    if (isViewResult) {
      images = yield call(getViewResultBenchmarking, {
        sourceId,
        pageSize: limit,
        currentPage: page + 1,
        status,
      });
    } else if (isActionHistory) {
      images = yield call(getActionHistoryBenchmarking, {
        projectId,
        pageSize: limit,
        currentPage: page + 1,
        status,
        alterUserId,
      });
    } else {
      images = yield call(fetchImages, sendingPayload);
    }
    const allImages = yield select(selectImages);
    const allImageIds = Object.keys(allImages);

    if (images && images.length) {
      const newPayload = {
        images: cloneDeep(images),
        status,
        page: page + 1,
        isNotNextImage,
      };
      yield put(fetchInputDataSuccess(cloneDeep(newPayload)));
      return;
    }
    if (allImageIds.length < 1) {
      yield put(
        selectOneSource({
          id: null,
        })
      );
    }

    yield put(noMoreToFetch({ status, isScrollImage }));
  });
}

function* watchFetchInputDataSuccessSaga() {
  yield takeEvery(FETCH_INPUT_DATA_SUCCESS, function* (action) {
    const isNotNextImage = get(action, ["payload", "isNotNextImage"], false);
    if (isNotNextImage) return;
    const allImages = yield select(selectImages);
    const orderedImages = get(action, ["payload", "images"], []);
    const status = get(action, ["payload", "status"], "new");

    const isScrollImage = yield select(selectIsScrollImage);
    const nextImageId = yield select(selectNextImageId);
    for (const key in allImages) {
      orderedImages.push(allImages[key]);
    }

    const sourceInFoInCookie = getCookie("sourceInfo");
    const sourceId =
      sourceInFoInCookie && JSON.parse(sourceInFoInCookie).sourceId;

    const isNew = ["new", "assigned", "inprogress"].includes(status);
    const firstStatusValue = get(allImages, [orderedImages[0], "status"], "");
    const isReselectData = !isNew && status !== firstStatusValue;

    if (orderedImages.length < 1 || isReselectData || !firstStatusValue) {
      const sourceInFoInCookie = getCookie("sourceInfo");
      const sourceId =
        sourceInFoInCookie && JSON.parse(sourceInFoInCookie).sourceId;

      if (!isScrollImage) {
        yield put(
          selectOneSource({
            id: nextImageId
              ? nextImageId
              : orderedImages.map((item) => item.sourceId).includes(sourceId)
              ? sourceId
              : orderedImages[0].sourceId,
          })
        );
      }
    }
    if (!sourceId || sourceId === "null") {
      yield put(
        selectOneSource({
          id: orderedImages[0].sourceId,
        })
      );
    }
  });
}

function* watchFetchModuleCodeSuccess() {
  yield takeEvery(UPDATE_ROLE_CODE, function* ({ payload }) {
    const dropdownValue = defaultStatus[payload];

    const defaultItem = dropdownItemsByStatus.find(
      (item) => item.name === dropdownValue
    );
    const statusArr = defaultItem.status;
    yield put(setCurrentDropdownStatus(""));

    if (statusArr.length > 1) {
      // Call three times for all three status new, assigned and inprogress
      for (let i = 0; i < statusArr.length; i++) {
        const sending = {
          status: statusArr[i],
          page: 0,
        };
        yield put(fetchInitialData(sending));
      }
    } else {
      const sending = {
        status: statusArr[0],
        page: 0,
      };
      yield put(fetchInitialData(sending));
    }
    yield put(fetchPushNotifications(0));
  });
}

function* watchInitialFetchingData() {
  yield takeLatest(FETCH_INITIAL_DATA_SUCCESS, function* (action) {
    const allImages = yield select(selectImages);
    const orderedImages = get(action, ["payload", "images"], []);

    for (const key in allImages) {
      orderedImages.push(allImages[key]);
    }

    const sourceInFoInCookie = getCookie("sourceInfo");
    const sourceId =
      sourceInFoInCookie && JSON.parse(sourceInFoInCookie).sourceId;
    if (!sourceId || sourceId === "null") {
      yield put(
        selectOneSource({
          id: orderedImages[0].sourceId,
        })
      );
    }

    if (!["new", "assigned"].includes(action.payload.status)) {
      yield put(cleanAnnotationsData());
      // yield put(fetchFirebaseToken());
      yield put(bootingFinished());
    }
  });
}

function* watchFetchFirebaseToken() {
  yield takeEvery(FETCH_FIREBASE_TOKEN, function* () {
    const token = yield call(goFetchFirebaseToken, messaging);
    if (!token) {
      yield put(
        showNotification({
          type: "error",
          msg: "Cannot fetch FIREBASE token.",
          msgId: "notification.cannot-fetch-firebase-token",
        })
      );
      const id = yield select(selectCurrentNotiId);
      delayRemoveNotification(id);
      yield cancel();
    }
    yield put(fetchFirebaseTokenSuccess(token));
  });
}

function* watchGetUserProject() {
  yield takeEvery(FETCH_USER_IN_PROJECT, function* ({ payload }) {
    const { projectId } = payload;
    const labelerId = getParameterByName("labeler");
    if (!isNil(JSON.parse(labelerId))) {
      yield put(setLabelerId(labelerId));
    }

    if (projectId) {
      const response = yield call(getUserInProject, { projectId });
      if (response.status) {
        const listUser = get(response, "data.users", []);
        yield put(fetchUserInProjectSuccess(listUser));
      }
    }
  });
}

function* watchSendEmailToSystemAdmin() {
  yield takeEvery(SEND_EMAIL_TO_SYSTEM_ADMIN, function* ({
    payload,
    callback,
  }) {
    const response = yield call(sendEmailToSystemAdminAPI, payload);
    const errorMessage = "Send Contact Email Error!";
    const errorMessageId = "notification.send-contact-email-error";
    if (response.status === 200 && response?.data?.status === true) {
      callback && callback(response);
    } else {
      yield put(
        showNotification({
          type: "error",
          msg: errorMessage,
          msgId: errorMessageId,
        })
      );
    }
  });
}

export default function* app() {
  yield all([
    fork(watchFetchModuleCodeSuccess),
    fork(watchFetchInputAndSelectOne),
    fork(watchFetchFirebaseToken),
    fork(watchInitialFetchingData),
    fork(watchLoadAppInfo),
    fork(watchFetchInitialImages),
    fork(watchUserData),
    fork(watchGetModuleCode),
    fork(watchFetchInputData),
    fork(watchGetUserProject),
    fork(watchSendEmailToSystemAdmin),
    fork(watchFetchInputDataSuccessSaga),
  ]);
}
