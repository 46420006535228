import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { arrayOf, object, bool, func } from "prop-types";
import clsx from "clsx";

import IssueCard from "./IssueCard";
import IssueForm from "./IssueForm";
import PlusIcon from "@material-ui/icons/Add";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  selectAllIssues,
  selectIsFormOpened,
  selectIssueLoading,
} from "redux/issues/selectors";
import { selectPermissionComponent } from "redux/app/selectors";
import { selectImageId } from "redux/image/selectors";
import { openIssueForm } from "redux/issues/actions";
import { getCurrentShapes } from "redux/shape/selector";
import { autoFillObjectIndex } from "redux/points/actions";

import styles from "./style.module.scss";
import IntlMessage from "../../helpers/IntlMessage";

function Issues({
  allIssues,
  isFormOpened,
  openIssueForm,
  addIssuePermission,
  hasSelectedSource,
  isLoading,
  currentShape,
  autoFillObjectIndex,
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState("open");
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(allIssues.filter((item) => item.status === activeTab));
  }, [allIssues, activeTab]);

  const handleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const tabClass = clsx({
    [styles.tabButton]: true,
    [styles.activeTab]: true,
  });

  const changeTab = (tab) => () => {
    setActiveTab(tab);
  };

  const handleCreateIssue = () => {
    openIssueForm();
    if (currentShape && currentShape.id) {
      autoFillObjectIndex(`#${currentShape.labelName}#${currentShape.id}`);
    }
  };

  return (
    <div className={styles.issueContainer}>
      <div
        className={styles.issueHeader}
        role="button"
        tabIndex="0"
        onKeyPress={() => {}}
        onClick={handleCollapse}
      >
        <button type="button">
          {isCollapsed ? <ChevronRight /> : <ExpandMore />}
        </button>
        <span>
          <IntlMessage id="issues.issues" />
        </span>
        {isLoading && <CircularProgress size={17} style={{ marginLeft: 10 }} />}
      </div>

      {!isCollapsed ? (
        <div className={styles.issueBody}>
          <div className={styles.issueTabs}>
            <button
              type="button"
              onClick={changeTab("open")}
              className={activeTab === "open" ? tabClass : styles.tabButton}
            >
              <IntlMessage id="common.open" />
            </button>
            <button
              type="button"
              onClick={changeTab("resolved")}
              className={activeTab === "resolved" ? tabClass : styles.tabButton}
            >
              <IntlMessage id="common.resolved" />
            </button>
            {addIssuePermission && hasSelectedSource && (
              <button
                type="button"
                className={
                  isFormOpened
                    ? `${styles.newButton} ${styles.disabled}`
                    : `${styles.newButton}`
                }
                onClick={handleCreateIssue}
                disabled={isFormOpened}
              >
                <PlusIcon />
                <span>
                  <IntlMessage id="common.new" />
                </span>
              </button>
            )}
          </div>

          <div className={styles.cardContainer}>
            {data && data.length ? (
              data.map((item) => <IssueCard item={item} key={item.issueId} />)
            ) : (
              <span className={styles.noData}>
                <IntlMessage id="common.no-data-to-show" />
              </span>
            )}
          </div>
        </div>
      ) : null}

      {isFormOpened && <IssueForm />}
    </div>
  );
}

Issues.propTypes = {
  allIssues: arrayOf(object),
  isFormOpened: bool,
  openIssueForm: func,
  addIssuePermission: bool,
  isLoading: bool,
  hasSelectedSource: bool,
  currentShape: object,
  autoFillObjectIndex: func,
};

const mapStateToProps = (state) => ({
  allIssues: selectAllIssues(state),
  isFormOpened: selectIsFormOpened(state),
  addIssuePermission: selectPermissionComponent("labeling_create_issue")(state),
  isLoading: selectIssueLoading(state),
  hasSelectedSource: !!selectImageId(state),
  currentShape: getCurrentShapes(state),
});

export default connect(mapStateToProps, { openIssueForm, autoFillObjectIndex })(
  Issues
);
