/* eslint-disable indent */
import React, { useState } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { object, func, string, bool } from "prop-types";
import { injectIntl } from "react-intl";

import {
  fetchChildIssue,
  addChildIssue,
  resolveIssue,
  reopenIssue,
} from "redux/issues/actions";
import ReplyIcon from "@material-ui/icons/Reply";
import { selectUserId } from "redux/app/selectors";
import MessageWrapper from "./MessageWrapper";
import { setUserTypingStatus } from "redux/keyboard/action";
import { selectIsUserTypingStatus } from "redux/keyboard/selectors";
import { preventDefault } from "helpers";

import styles from "./style.module.scss";

function IssueCard(props) {
  const { item } = props;
  const [isShownReply, setIsShownReply] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  const handleClick = () => {
    if (firstLoad) {
      setFirstLoad(false);
      props.fetchChildIssue({ issueId: item.issueId });
    }
    if (!isShownReply) {
      setInputValue("");
    }
    setIsShownReply((prev) => !prev);
  };

  const handleRefresh = (event) => {
    preventDefault(event);
    fetchChildIssue({ issueId: item.issueId });
  };

  const handeResolve = (event) => {
    preventDefault(event);
    props.resolveIssue(item);
  };

  const handleReopen = (event) => {
    preventDefault(event);
    props.reopenIssue(item);
  };

  const onKeyDownInput = (event) => {
    if (event && event.altKey && event.keyCode === 13) {
      setInputValue((prev) => `${prev}\n`);
    }

    if (event && event.keyCode === 13 && !event.altKey) {
      event.preventDefault();
      const value = get(event, "target.value");

      if (value.trim()) {
        const { sourceId, annotationId, issueId: parentId, labelName } = item;
        props.addChildIssue({
          sourceId,
          annotationId,
          parentId,
          type: "comment",
          content: value.trim(),
          labelName,
          createdBy: props.userId,
        });
      }

      setInputValue("");
    }
  };

  const onChange = (event) => {
    setInputValue(event.target.value);
  };

  const onFocus = () => {
    if (!props.isUserTyping) props.setUserTypingStatus(true);
  };

  const onBlur = () => {
    props.setUserTypingStatus(false);
  };

  return (
    <div
      className={styles.issueCardContainer}
      role="button"
      tabIndex={0}
      onClick={handleClick}
    >
      <MessageWrapper
        item={item}
        handeResolve={handeResolve}
        handleRefresh={handleRefresh}
        handleReopen={handleReopen}
        isParent
      />
      {isShownReply && item && item.childIssues && item.childIssues.length ? (
        item.childIssues.map((child) => (
          <MessageWrapper item={child} key={item.issueId} />
        ))
      ) : (
        <div
          className={styles.messageFooter}
          style={{ display: Number(item.countChildren) ? "" : "none" }}
        >
          <ReplyIcon style={{ width: 12, height: 12 }} />
          <span>
            {item.countChildren === 1
              ? `1 ${props.intl
                  .formatMessage({ id: "issues.reply" })
                  .toLowerCase()}`
              : `${item.countChildren} ${props.intl
                  .formatMessage({ id: "issues.replies" })
                  .toLowerCase()}`}
          </span>
          <span>
            {props.intl.formatMessage({ id: "issues.last-reply" })}{" "}
            {item.lastChildrenCreatedDate}
          </span>
        </div>
      )}
      {isShownReply && item && item.status === "open" ? (
        <textarea
          className={styles.reply}
          placeholder={`${props.intl.formatMessage({
            id: "issues.reply",
          })} ...`}
          onClick={preventDefault}
          onKeyDown={onKeyDownInput}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          value={inputValue}
          autoFocus
          rows="1"
        />
      ) : null}
    </div>
  );
}

IssueCard.propTypes = {
  intl: object,
  item: object,
  userId: string,
  fetchChildIssue: func,
  addChildIssue: func,
  resolveIssue: func,
  reopenIssue: func,
  isUserTyping: bool,
  setUserTypingStatus: func,
};

const mapStateToProps = (state) => ({
  userId: selectUserId(state),
  isUserTyping: selectIsUserTypingStatus(state),
});

export default connect(mapStateToProps, {
  fetchChildIssue,
  addChildIssue,
  resolveIssue,
  reopenIssue,
  setUserTypingStatus,
})(injectIntl(IssueCard));
