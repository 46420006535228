import React, { useEffect } from "react";
import { func, object, string, bool } from "prop-types";
import { useSelector, connect } from "react-redux";
import { injectIntl } from "react-intl";
import { MagicWand } from "icons";
import ToolContainer from "components/ToolContainer";
import { makeCurrTool } from "redux/tools/selectors";
import * as allTools from "redux/tools/enums";
import { deselectTool, limitationSegmentationTool } from "redux/tools/actions";
import AdsPopup from "components/AdsPopup";

function SegmentationToolButton({
  intl,
  checkDisableTool,
  handlePickTool,
  limitationSegmentationTool,
  disabledClass = false,
}) {
  const currTool = useSelector(makeCurrTool);

  const [anchorElAds, setAnchorElAds] = React.useState(null);

  useEffect(() => {
    limitationSegmentationTool();
  }, []);

  const handleStartToDrawSegementation = (event) => {
    handlePickTool(event);
    setAnchorElAds(event.currentTarget);
  };

  const handleClosePopper = () => {
    setAnchorElAds(null);
  };

  return (
    <>
      <ToolContainer
        currTool={currTool}
        disabledClass={
          checkDisableTool(allTools.SEGMENTATION_MODE) || disabledClass
        }
        name={allTools.SEGMENTATION_MODE}
        tooltipText={`${intl.formatMessage({
          id: "segmentation-tool-button.binary-mask-segmentation",
        })} (5)`}
        onClick={handleStartToDrawSegementation}
      >
        <MagicWand style={{ pointerEvents: "none" }} />
      </ToolContainer>
      <AdsPopup openAds={anchorElAds} onClose={handleClosePopper} />
    </>
  );
}

SegmentationToolButton.propTypes = {
  intl: object,
  checkDisableTool: bool,
  handlePickTool: func,
  name: string,
  limitationSegmentationTool: func,
  disabledClass: bool,
};
export default connect(null, {
  deselectTool,
  limitationSegmentationTool,
})(injectIntl(SegmentationToolButton));
