import produce from "immer";
import {
  FETCH_INPUT_DATA_SUCCESS,
  FETCH_INPUT_DATA,
  REFETCH_INPUT_DATA,
  START_FETCHING_MORE,
  NO_MORE_TO_FETCH,
  SELECT_ONE_SOURCE,
  SELECT_ONE_SOURCE_SUCCESS,
  GET_SOURCE_CSV_FILE_SUCCESS,
  SUBMIT_IMAGE_SUCCESS,
  FIT_IMAGE_SIZE,
  CLEAN_SELECTED_IMAGE,
  CHANGE_FILTER_BTN_STATUS,
  ROTATE_IMAGE,
  ROTATE_IMAGE_FINISH,
  MARK_BENCHMARK,
  MARK_BENCHMARK_FAILURE,
  MARK_BENCHMARK_SUCCESS,
  SCROLL_IMAGE,
  GET_NEXT_IMAGE_ID,
} from "./constants";
import {
  ADD_ISSUE_SUCCESS,
  DELETE_ISSUE_SUCCESS,
  RESOLVE_ISSUE_SUCCESS,
  REOPEN_ISSUE_SUCCESS,
} from "redux/issues/actions";
import {
  FETCH_INITIAL_DATA_SUCCESS,
  FETCH_INITIAL_DATA,
  CHANGE_CURRENT_DROPDOWN_STATUS,
} from "redux/app/constants";
import { getCookie, getParameterByName } from "helpers";
import cloneDeep from "lodash.clonedeep";

function getImgFake(url) {
  let img = new Image();
  img.setAttribute("crossorigin", "anonymous");
  img.src = url;
  return img;
}

export const initialState = {
  imageId: "",
  imageUrl: "",
  originalUrl: "",
  imageIndex: null,
  isFetchingData: false,
  isBenchmarkProject: false,
  isBenchmark: false,
  status: "",
  metadata: {
    name: "",
    width: 0,
    height: 0,
    opacity: 1,
    brightness: 1,
    contrast: 1,
    size: 0,
  },
  limit: 10,
  fetchMore: false,
  currPage: 0,
  allImgIds: new Set(),
  allImages: {},
  paginationByStatus: {
    new: { page: 0, more: true },
    inprogress: { page: 0, more: true },
    assigned: { page: 0, more: true },
    other: { page: 0, more: true },
  },
  labelerName: "",
  labelerUserId: "",
  reviewerName: "",
  reviewerUserId: "",
  fitCounter: 0,
  filterBtnStatus: true,
  annotationType: "",
  largeImagePreprocessor: {
    preprocessorArr: [],
    zoomLevels: [0],
  },
  thumbnailURL: "",
  rotating: false,
  imageRotationDirection: 1,
  imgFake: null,
  loadingBenchmark: false,
  annotatedBy: "",
  qualityData: 0,
  scrollImage: false,
  nextImageId: "",
  filterTagIds: [],
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_CURRENT_DROPDOWN_STATUS:
        draft.allImgIds = new Set();
        draft.allImages = {};
        draft.paginationByStatus = initialState.paginationByStatus;
        draft.nextImageId = "";
        break;

      case GET_SOURCE_CSV_FILE_SUCCESS: {
        const { labelerName, reviewerName } = action.payload;
        draft.labelerName = labelerName || "";
        draft.reviewerName = reviewerName || "";
        break;
      }

      case REFETCH_INPUT_DATA:
        draft.isFetchingData = true;
        break;

      case FETCH_INPUT_DATA:
        draft.isFetchingData = true;
        break;

      case START_FETCHING_MORE:
        break;

      case FETCH_INITIAL_DATA:
        draft.isFetchingData = true;
        break;

      case FETCH_INPUT_DATA_SUCCESS: {
        const { images, status, page } = action.payload;
        const isViewResult = getParameterByName("isViewResult");
        draft.isFetchingData = false;
        const statusProp = !["new", "assigned", "inprogress"].includes(status)
          ? "other"
          : status;
        draft.paginationByStatus[statusProp].page = page;
        draft.allImgIds = new Set();

        let orderedImages = [];
        const allImgIdsArr = [...state.allImgIds];
        for (const key in allImgIdsArr) {
          orderedImages.push(state.allImages[allImgIdsArr[key]]);
        }
        orderedImages = orderedImages.concat(cloneDeep(images));

        let newImageList = {};
        for (const image of orderedImages) {
          if (image.sourceId) {
            const id = isViewResult
              ? `${image.sourceId}-${image.annotatedById}`
              : image.sourceId;
            draft.allImgIds.add(id);
            newImageList[id] = image;
          }
        }
        draft.allImages = newImageList;
        break;
      }

      case FETCH_INITIAL_DATA_SUCCESS: {
        const { images, status, page } = action.payload;
        draft.isFetchingData = false;
        const statusProp = !["new", "assigned", "inprogress"].includes(status)
          ? "other"
          : status;
        draft.paginationByStatus[statusProp].page = page;
        if (typeof action.payload.isSelectOneSourceSuccess !== "undefined") {
          draft.isSelectOneSourceSuccess = true;
        } else {
          draft.isSelectOneSourceSuccess = false;
        }

        draft.allImgIds = new Set();
        draft.allImages = {};

        let orderedImages = [];
        const allImgIdsArr = [...state.allImgIds];
        for (const key in allImgIdsArr) {
          orderedImages.push(state.allImages[allImgIdsArr[key]]);
        }
        orderedImages = orderedImages.concat(cloneDeep(images));

        const filteredImages = orderedImages.filter((x) => {
          if (["new", "assigned", "inprogress"].includes(status)) {
            return ["new", "assigned", "inprogress"].includes(x.status);
          }
          return status == x.status;
        });

        const isViewResult = getParameterByName("isViewResult");
        let newImageList = {};
        for (const image of filteredImages) {
          const id = isViewResult
            ? `${image.sourceId}-${image.annotatedById}`
            : image.sourceId;
          draft.allImgIds.add(id);
          newImageList[id] = image;
        }
        draft.allImages = newImageList;
        break;
      }

      case NO_MORE_TO_FETCH: {
        const { status, isScrollImage } = action.payload;
        draft.isFetchingData = false;
        if (!isScrollImage) {
          draft.imageId = "";
        }
        if (!["new", "assigned", "inprogress"].includes(status)) {
          draft.paginationByStatus.other.more = false;
        } else {
          draft.paginationByStatus[status].more = false;
        }
        break;
      }

      case SELECT_ONE_SOURCE:
        draft.imageUrl = "";
        draft.thumbnailURL = "";
        draft.largeImagePreprocessor = initialState.largeImagePreprocessor;
        draft.imageIndex = [...draft.allImgIds].findIndex(
          (item) => Number(item) === Number(action.payload.id)
        );
        break;
      case SELECT_ONE_SOURCE_SUCCESS: {
        const isViewResult = getParameterByName("isViewResult");
        const userInfoInCookie = JSON.parse(getCookie("userInfo"));
        const storage_config = JSON.parse(userInfoInCookie.storage_config);
        const imageId = isViewResult
          ? `${action.payload.sourceId}-${action.payload.annotatedById}`
          : action.payload.sourceId;
        if (storage_config.storage_type === "s3") {
          draft.thumbnailURL = action.payload.thumbnailVideo;
          draft.imageUrl = action.payload.url;
          draft.imgFake = getImgFake(action.payload.url);
        } else {
          draft.thumbnailURL = action.payload.thumbnailVideo;
          draft.imageUrl = action.payload.url;
          draft.imgFake = getImgFake(action.payload.url);
        }
        draft.annotationType = action.payload.dataType;
        draft.metadata.width = action.payload.metadata.width;
        draft.metadata.height = action.payload.metadata.height;
        draft.metadata.name = action.payload.name;
        draft.metadata.size = action.payload.metadata.size;
        draft.largeImagePreprocessor = action.payload.largeImagePreprocessor;
        draft.imageId = imageId;
        draft.status = action.payload.status;
        draft.metadata.opacity = action.payload.opacity;
        draft.metadata.brightness = action.payload.brightness;
        draft.metadata.contrast = action.payload.contrast;
        draft.originalUrl = action.payload.oldUrl;
        draft.isBenchmarkProject =
          action.payload.settingQuality === "benchmark";
        draft.isBenchmark = action.payload.isBenchmark;
        draft.annotatedBy = action.payload.annotatedBy;
        draft.qualityData = action.payload.qualityData;
        draft.labelerUserId = action.payload.labelerUserId;
        draft.reviewerUserId = action.payload.reviewerUserId;
        draft.scrollImage = false;
        draft.annotatedById = action.payload.annotatedById;
        draft.tags = action.payload.tags;
        draft.imageRotationDirection =
          action.payload.metadata.imageRotationDirection || 1;
        break;
      }

      case CLEAN_SELECTED_IMAGE:
        draft.metadata.width = initialState.metadata.width;
        draft.metadata.height = initialState.metadata.height;
        draft.metadata.name = initialState.name;
        draft.metadata.size = initialState.metadata.size;
        draft.imageUrl = initialState.url;
        draft.thumbnailURL = "";
        draft.largeImagePreprocessor = initialState.largeImagePreprocessor;
        draft.imageId = initialState.sourceId;
        draft.status = initialState.status;
        draft.metadata.opacity = initialState.opacity;
        draft.metadata.brightness = initialState.brightness;
        draft.metadata.contrast = initialState.contrast;
        draft.originalUrl = initialState.oldUrl;
        draft.labelerName = "";
        draft.reviewerName = "";
        draft.annotationType = "";
        break;

      case ADD_ISSUE_SUCCESS: {
        const { sourceId } = action.payload;
        const currSource = state.allImages[sourceId];
        if (currSource) {
          const open =
            Number(
              (currSource && currSource.issue && currSource.issue.open) || 0
            ) + 1;
          const total =
            Number(
              (currSource && currSource.issue && currSource.issue.total) || 0
            ) + 1;

          draft.allImages[sourceId].issue = {
            status: open === 0 ? false : true,
            open,
            total,
          };
        }

        break;
      }

      case DELETE_ISSUE_SUCCESS:
        if (!action.payload.parentId) {
          const { sourceId } = action.payload;

          const currSource = state.allImages[sourceId];
          if (currSource) {
            const open = Math.max(Number(currSource.issue.open || 0) - 1, 0);
            const total = Math.max(Number(currSource.issue.total) - 1, 0);

            draft.allImages[sourceId].issue = {
              status: open === 0 ? false : true,
              open,
              total,
            };
          }
        }
        break;

      case RESOLVE_ISSUE_SUCCESS: {
        const { sourceId } = action.payload;
        const currSource = state.allImages[sourceId];
        if (currSource) {
          const open = Math.max(Number(currSource.issue.open || 0) - 1, 0);
          const total = currSource.issue.total;

          draft.allImages[sourceId].issue = {
            status: open === 0 ? false : true,
            open,
            total,
          };
        }
        break;
      }

      case REOPEN_ISSUE_SUCCESS: {
        const { sourceId } = action.payload;
        const currSource = state.allImages[sourceId];
        if (currSource) {
          const open = Math.max(Number(currSource.issue.open || 0) + 1, 0);
          const total = currSource.issue.total;

          draft.allImages[sourceId].issue = {
            status: open > 0 ? true : false,
            open,
            total,
          };
        }
        break;
      }

      case SUBMIT_IMAGE_SUCCESS: {
        const { sourceId, status } = action.payload;
        draft.status = status;
        if (draft.allImages) {
          draft.allImages[sourceId] = {};
        }
        draft.allImgIds.delete(sourceId);
        break;
      }

      case FIT_IMAGE_SIZE:
        draft.fitCounter += 1;
        break;

      case CHANGE_FILTER_BTN_STATUS: {
        const { filterBtnStatus, filterTagIds } = action.payload;
        draft.filterBtnStatus = filterBtnStatus;
        if (filterTagIds) draft.filterTagIds = filterTagIds;
        break;
      }
      case ROTATE_IMAGE_FINISH: {
        const {
          id,
          status,
          width,
          height,
          src,
          thumbSrc,
          imageRotationDirection,
        } = action.payload;
        draft.rotating = false;
        if (status) {
          draft.imageUrl = src;
          draft.metadata.width = width;
          draft.metadata.height = height;
          draft.thumbnailURL = thumbSrc;
          draft.imgFake = getImgFake(src);
          if (draft.allImages[id]) draft.allImages[id].thumbnailUrl = thumbSrc;
          draft.imageRotationDirection = imageRotationDirection;
        }
        break;
      }

      case ROTATE_IMAGE:
        draft.rotating = true;
        break;

      case MARK_BENCHMARK: {
        draft.loadingBenchmark = true;
        break;
      }

      case MARK_BENCHMARK_FAILURE: {
        draft.loadingBenchmark = false;
        break;
      }

      case MARK_BENCHMARK_SUCCESS: {
        const { sourceId, email, isBenchmark } = action.payload;
        draft.isBenchmark = isBenchmark;
        draft.loadingBenchmark = false;
        draft.annotatedBy = email;
        draft.allImages[sourceId].isBenchmark = true;
        draft.allImages[sourceId].annotatedBy = email;
        break;
      }
      case SCROLL_IMAGE:
        draft.scrollImage = action.payload;
        break;

      case GET_NEXT_IMAGE_ID:
        draft.nextImageId = action.payload;
        break;

      default:
    }
  });

export default reducer;
