import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Popper from "@material-ui/core/Popper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TuneIcon from "@material-ui/icons/Tune";
import { updateImage } from "api";
import {
  selectImageId,
  selectImageUrl,
  selectImgWidth,
  selectImgHeight,
  selectOriginalUrl,
} from "redux/image/selectors";
import { saveEditedImage } from "redux/image/actions";
import { selectProjectId } from "redux/app/selectors";

import EditedImg from "./EditedImg";
import IntlMessage from "../../helpers/IntlMessage";

const useStyles = makeStyles({
  popper: {
    width: 250,
    height: 170,
    border: "1px solid #8f8f8f",
    borderRadius: 3,
    padding: 10,
    top: "calc(100% - 260px) !important",
    left: "calc(100% - 770px) !important",
    zIndex: 100,
    background: "white",
  },
  settingsWrapper: {
    position: "absolute",
    bottom: 20,
    right: 20,
    zIndex: 100,
  },
  button: {
    borderRadius: "50%",
    backgroundColor: "#1871e8",
    height: 50,
    minWidth: 50,
    width: 50,
    padding: 0,
    color: "white",
  },
});

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      leaveDelay={200}
      placement="top"
      title={value}
      arrow
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  value: PropTypes.string,
};

function Settings(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [saveUrl, setSaveUrl] = useState("");
  const [saveCount, setSaveCount] = useState(0); // eslint-disable-line
  useEffect(() => {
    setSaveUrl(props.imgUrl);
    if (!open && props.imageId && props.projectId) {
      updateImage({
        imageId: props.imageId,
        payload: {
          projectId: props.projectId,
          opacity: props.opacity || 1,
          brightness: props.brightness || 1,
          contrast: props.contrast || 1,
        },
      });
    }
    // eslint-disable-next-line
  }, [open]);

  // function handleSaveImg() {
  //   setSaveUrl(props.imgUrl);
  //   setSaveCount(saveCount + 1);
  //   handleClickAway();
  // }

  // function handleResetImg() {
  //   setSaveUrl(props.originalUrl);
  //   setSaveCount(saveCount + 1);
  //   handleClickAway();
  // }

  function handleClickAway() {
    setOpen(false);
  }

  function toggle() {
    setOpen((prev) => !prev);
  }

  function handleChangeContrast(e, value) {
    props.setContrast(value);
  }

  function handleChangeBrightness(e, value) {
    props.setBrightness(value);
  }

  function handleChangeOpacity(e, value) {
    props.setOpacity(value);
  }

  function handleReset(event) {
    handleChangeContrast(event, 1);
    handleChangeBrightness(event, 1);
    handleChangeOpacity(event, 1);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.settingsWrapper} id="settings">
        <Button
          onClick={toggle}
          className={classes.button}
          variant="contained"
          color="primary"
        >
          <TuneIcon />
        </Button>
        <Popper open={open} placement="right-start" className={classes.popper}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Box fontSize={12}>
                <IntlMessage id="img-settings.opacity" />
              </Box>
            </Grid>
            <Grid item xs>
              <Slider
                value={props.opacity}
                onChange={handleChangeOpacity}
                ValueLabelComponent={ValueLabelComponent}
                step={0.05}
                max={1}
                min={0}
                marks={true}
                valueLabelDisplay="auto"
                aria-labelledby="contrast"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Box fontSize={12}>
                <IntlMessage id="img-settings.contrast" />
              </Box>
            </Grid>
            <Grid item xs>
              <Slider
                value={props.contrast}
                onChange={handleChangeContrast}
                ValueLabelComponent={ValueLabelComponent}
                step={0.05}
                max={2}
                min={0}
                valueLabelDisplay="auto"
                aria-labelledby="contrast"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Box fontSize={12}>
                <IntlMessage id="img-settings.brightness" />
              </Box>
            </Grid>
            <Grid item xs>
              <Slider
                value={props.brightness}
                onChange={handleChangeBrightness}
                ValueLabelComponent={ValueLabelComponent}
                step={0.05}
                max={2}
                min={0}
                valueLabelDisplay="auto"
                aria-labelledby="brightness"
              />
            </Grid>
          </Grid>
          <div style={{ margin: "10px auto" }}>
            {/* <Button onClick={handleSaveImg} variant="contained" color="primary">
              Save
            </Button>
            &nbsp; &nbsp; */}
            <Button variant="contained" color="primary" onClick={handleReset}>
              <IntlMessage id="common.reset" />
            </Button>
          </div>
        </Popper>
        <EditedImg
          width={props.imgWidth}
          height={props.imgHeight}
          brightness={props.brightness}
          contrast={props.contrast}
          saveUrl={saveUrl}
          saveCount={saveCount}
          saveEditedImage={props.saveEditedImage}
        />
      </div>
    </ClickAwayListener>
  );
}

Settings.propTypes = {
  brightness: PropTypes.number,
  contrast: PropTypes.number,
  opacity: PropTypes.number,
  setContrast: PropTypes.func,
  setBrightness: PropTypes.func,
  setOpacity: PropTypes.func,
  imageId: PropTypes.string,
  projectId: PropTypes.number,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  imgUrl: PropTypes.string,
  saveEditedImage: PropTypes.func,
  originalUrl: PropTypes.string,
};

export default connect(
  (state) => ({
    imageId: selectImageId(state),
    projectId: selectProjectId(state),
    imgUrl: selectImageUrl(state),
    imgWidth: selectImgWidth(state),
    imgHeight: selectImgHeight(state),
    originalUrl: selectOriginalUrl(state),
  }),
  { saveEditedImage }
)(Settings);
