import { JUMP_TO_STEP } from "./actions";

const INIT_STATE = {
  isOpen: false,
  stepIndex: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case JUMP_TO_STEP: {
      const { isOpen, stepIndex } = action.payload;
      return {
        ...state,
        isOpen,
        stepIndex,
      };
    }
    default:
      return state;
  }
};
