import {
  all,
  fork,
  takeEvery,
  call,
  put,
  cancel,
  cancelled,
  delay,
  select,
  takeLatest,
} from "redux-saga/effects";

import { START_COUNTING, END_COUNTING } from "./constants";
import { saveCounting, finishCounting, saveRemainTime } from "./actions";
import {
  selectCountingTime,
  selectDisplayCountingTime,
  selectRemainTime,
} from "./selectors";
import { selectDatasetId, selectProjectId } from "redux/app/selectors";
import { saveCountingApi, getRemainTimeAPI } from "api/timing";

let timerTask;

const delayOffSet = 1;

function* bgSync() {
  try {
    const remainTimeRes = yield call(getRemainTimeAPI);
    if (remainTimeRes.success === true) {
      yield put(saveRemainTime(remainTimeRes.remainTime));
    }
    while (true) {
      const remainTime = yield select(selectRemainTime);
      if (remainTime - delayOffSet < 0) yield cancel(timerTask);
      let countingTime = yield select(selectCountingTime);
      let displayCountingTime = yield select(selectDisplayCountingTime);
      countingTime += 1;
      displayCountingTime += 1;
      const minutesPart = Math.floor(displayCountingTime / 60);
      const minutes = minutesPart < 10 ? `0${minutesPart}` : minutesPart;

      const secondsPart = displayCountingTime % 60;
      const seconds = secondsPart < 10 ? `0${secondsPart}` : secondsPart;

      const displayTime = `${minutes}: ${seconds}`;

      if (countingTime >= 5) {
        const projectId = yield select(selectProjectId);
        const datasetId = yield select(selectDatasetId);

        yield put(
          saveCounting({
            displayTime,
            countingTime: 0,
            displayCountingTime,
            remainTime: remainTime - delayOffSet,
          })
        );
        yield fork(saveCountingApi, { countingTime: 5, projectId, datasetId });
      } else {
        yield put(
          saveCounting({
            displayTime,
            countingTime,
            displayCountingTime,
            remainTime: remainTime - delayOffSet,
          })
        );
      }

      yield delay(1000 * delayOffSet);
    }
  } finally {
    if (yield cancelled()) {
      const countingTime = yield select(selectCountingTime);
      const projectId = yield select(selectProjectId);
      const datasetId = yield select(selectDatasetId);
      const saveCountingRes = yield call(saveCountingApi, {
        countingTime,
        projectId,
        datasetId,
      });
      yield put(finishCounting(saveCountingRes));
    }
  }
}

function* startCounting() {
  // starts the task in the background
  timerTask = yield fork(bgSync);
}

function* endCounting() {
  if (timerTask) {
    yield cancel(timerTask);
  }
}

export default function* timingSaga() {
  yield all([
    takeLatest(START_COUNTING, startCounting),
    takeEvery(END_COUNTING, endCounting),
  ]);
}
