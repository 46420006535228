import {
  SAVE_POLYLINE,
  SAVE_POLYLINE_SUCCESS,
  SAVE_POLYLINE_ERROR,
  UPDATE_POLYLINE,
  UPDATE_POLYLINE_SUCCESS,
  UPDATE_POLYLINE_ERROR,
} from "./constants";

export const savePolyline = ({ payload, callback }) => ({
  type: SAVE_POLYLINE,
  payload,
  callback,
});

export const savePolylineSuccess = (payload) => ({
  type: SAVE_POLYLINE_SUCCESS,
  payload,
});

export const savePolylineError = (payload) => ({
  type: SAVE_POLYLINE_ERROR,
  payload,
});

export const updatePolyline = (payload) => ({
  type: UPDATE_POLYLINE,
  payload,
});

export const updatePolylineSuccess = (payload) => ({
  type: UPDATE_POLYLINE_SUCCESS,
  payload,
});

export const updatePolylineError = (payload) => ({
  type: UPDATE_POLYLINE_ERROR,
  payload,
});
