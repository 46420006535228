import appLocaleData from "react-intl/locale-data/ja";
import jpMessage from "../locales/ja_JP";

const JpLang = {
  messages: {
    ...jpMessage,
  },
  locale: "ja-JP",
  data: appLocaleData,
};
export default JpLang;
