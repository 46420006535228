export const SELECT_TOOL = "tools/SELECT_TOOL";
export const SELECT_TOOL_SUCCESS = "tools/SELECT_TOOL_SUCCESS";
export const DESELECT_TOOL = "tools/DESELECT_TOOL";
export const DESELECT_TOOL_SUCCESS = "tools/DESELECT_TOOL_SUCCESS";
export const PICK_START_TIME = "tools/PICK_START_TIME";
export const PICK_END_TIME = "tools/PICK_END_TIME";
export const CHANGE_TEMPORAL_BUTTON = "tools/CHANGE_TEMPORAL_BUTTON";
export const LIMITATION_SEGMENTATION_TOOL =
  "tools/LIMITATION_SEGMENTATION_TOOL";
export const LIMITATION_SEGMENTATION_TOOL_SUCCESS =
  "tools/LIMITATION_SEGMENTATION_TOOL_SUCCESS";
export const LIMITATION_SEGMENTATION_TOOL_FAIL =
  "tools/LIMITATION_SEGMENTATION_TOOL_FAIL";
