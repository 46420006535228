import React from "react";
import { Line } from "react-konva";
import { object, number } from "prop-types";

export default function RulerLine({ center, canvasWidth, canvasHeight }) {
  return (
    <>
      {center !== null && (
        <Line
          points={[0, center.y, center.x, center.y, canvasWidth, center.y]}
          stroke="#b18d29"
          strokeWidth={2}
          dash={[8, 8]}
        />
      )}
      {center !== null && (
        <Line
          points={[center.x, 0, center.x, center.y, center.x, canvasHeight]}
          stroke="#b18d29"
          strokeWidth={2}
          dash={[8, 8]}
        />
      )}
    </>
  );
}

RulerLine.propTypes = {
  center: object,
  canvasWidth: number,
  canvasHeight: number,
};
