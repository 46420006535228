import {
  SAVE_POLYGON,
  SAVE_POLYGON_SUCCESS,
  SAVE_POLYGON_ERROR,
  UPDATE_POLYGON,
  UPDATE_POLYGON_SUCCESS,
  UPDATE_POLYGON_ERROR,
  DELETE_POLYGON_POINT,
} from "./constants";

export const savePolygon = ({ payload, callback, callbackSaga }) => ({
  type: SAVE_POLYGON,
  payload,
  callback,
  callbackSaga,
});

export const savePolygonSuccess = (payload) => ({
  type: SAVE_POLYGON_SUCCESS,
  payload,
});

export const savePolygonError = (payload) => ({
  type: SAVE_POLYGON_ERROR,
  payload,
});

export const updatePolygon = (payload) => ({
  type: UPDATE_POLYGON,
  payload,
});

export const updatePolygonSuccess = (payload) => ({
  type: UPDATE_POLYGON_SUCCESS,
  payload,
});

export const updatePolygonError = (payload) => ({
  type: UPDATE_POLYGON_ERROR,
  payload,
});

export const deletePolygonPoint = (payload) => ({
  type: DELETE_POLYGON_POINT,
  payload,
});
