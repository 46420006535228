import {
  all,
  fork,
  takeEvery,
  put,
  select,
  call,
  cancel,
} from "redux-saga/effects";
import isEmpty from "lodash.isempty";
import cloneDeep from "lodash.clonedeep";
import { eventChannel } from "redux-saga";
import {
  changeImageRatio,
  setPointsCanvas,
  showPointsIndice,
  showIrisCircles,
  showConnectLines,
} from "redux/canvas/actions";
import {
  selectIsIrisCirclesShown,
  selectImageRatio,
  selectPointsCanvas,
} from "redux/canvas/selectors";
import {
  selectImageIndex,
  selectImages,
  selectAllImgIds,
  selectImageStatus,
} from "redux/image/selectors";
// import {
//   showNotification,
//   delayRemoveNotification,
// } from "redux/notification/actions";
// import { selectCurrentNotiId } from "redux/notification/selectors";
import { setModalItem } from "redux/canvas/actions";

import { selectOneSource } from "redux/image/actions";
import { selectTool, deselectTool } from "redux/tools/actions";
import { actionKeyboardEvent } from "./action";
import { KEYBOARD_EVENT } from "./constants";
import {
  selectCurrentKey,
  selectIsUserTypingStatus,
  selectKeyCount,
  selectCtrlIsPressing,
} from "./selectors";
import {
  makeSelectedRowId,
  selectPoints,
  selectDisplayingRegions,
  makeSelectedPoint,
  selectCurrLabel,
} from "redux/points/selectors";
import {
  setSelectedPoints,
  setPoints,
  submitPoints,
} from "redux/points/actions";
import {
  undoAnnotation,
  redoAnnotation,
} from "redux/annotationHistory/actions";

import {
  getCurrentShapes,
  getCopyShape,
  selectCurrentPolyonPointIndex,
} from "redux/shape/selector";
import {
  deleteShape,
  setCopyShape,
  setCopyShapeMode,
} from "redux/shape/actions";
import * as allTools from "redux/tools/enums";
import { makeCurrTool } from "redux/tools/selectors";
import { setCtrlIsPressing } from "redux/keyboard/action";
import { getParameterByName } from "helpers";

import { defaultHotkeys } from "./defaultHotkeys";
import { deletePolygonPoint } from "redux/polygon/actions";

function* initKeyboardEventListener() {
  const chan = eventChannel((emitter) => {
    const handleKeyEvt = (evt) => {
      emitter(evt);
    };

    document.addEventListener("keydown", handleKeyEvt);
    document.addEventListener("keyup", handleKeyEvt);
    return () => {
      document.removeEventListener("keydown", handleKeyEvt);
      document.removeEventListener("keyup", handleKeyEvt);
    };
  });

  yield takeEvery(chan, function* (evt) {
    yield put(
      actionKeyboardEvent({
        key: evt.key,
        altKey: evt.altKey,
        ctrlKey: evt.ctrlKey,
        metaKey: evt.metaKey,
        shiftKey: evt.shiftKey,
        type: evt.type === "keyup" ? "keyup" : "keydown",
      })
    );
  });
}

function* initMouseScrollEventListener() {
  const chan = eventChannel((emitter) => {
    const handleKeyEvt = (evt) => {
      emitter(evt);
    };

    document.addEventListener("wheel", handleKeyEvt);
    return () => {
      document.removeEventListener("wheel", handleKeyEvt);
    };
  });

  yield takeEvery(chan, function* (evt) {
    const imageRatio = yield select(selectImageRatio);
    if (!evt || !evt.target) return;
    if (
      (evt &&
        evt.target &&
        evt.target.className &&
        typeof evt.target.className === "string" &&
        evt.target.className.includes("elementUseWheel")) ||
      evt.target.tagName === "CANVAS"
    ) {
      if (evt.deltaY < 0) {
        yield put(changeImageRatio(1.1 * imageRatio));
      } else {
        yield put(changeImageRatio(imageRatio / 1.1));
      }
    }
  });
}
export function* toogleToolSaga(key, currKey, tool) {
  const currClass = yield select(selectCurrLabel);
  if (currClass?.id && currClass.tools?.includes(`${tool}-image`)) {
    yield call(toggleTool, key, currKey, tool);
  }
}
function handleKeyDown(e, keyCtrlIsPressing, isUserTyping) {
  // const keyCtrlIsPressing12 = useSelector(selectCtrlIsPressing);
  // console.log("how many tham it takes", e, keyCtrlIsPressing, isUserTyping);
  const code = e.which || e.keyCode;
  const overwrittenCodes = [68, 65, 83];
  if (overwrittenCodes.includes(code) && keyCtrlIsPressing && !isUserTyping) {
    e.preventDefault();
  }
}
export function* sagaKeyboardEvent() {
  const { localStorage } = window;
  localStorage.setItem("SALF-hotKeys", JSON.stringify(defaultHotkeys));

  let keyPrev = null;

  yield takeEvery(KEYBOARD_EVENT, function* ({ payload }) {
    const { currPoint } = window;
    const hotKeys = JSON.parse(localStorage.getItem("SALF-hotKeys"));
    const currKey = yield select(selectCurrentKey);
    const isUserTyping = yield select(selectIsUserTypingStatus);
    const selectedPointId = yield select(makeSelectedRowId);
    const pointsCanvas = yield select(selectPointsCanvas);
    const currTool = yield select(makeCurrTool);
    // const isCustomerApproved = yield select(selectIsCustomerApproved);
    // const isCustomerDeclined = yield select(selectIsCustomerDeclined);
    let keyCtrlIsPressing = yield select(selectCtrlIsPressing);
    // Overwrite browser's hotkeys

    const currentShape = yield select(getCurrentShapes);
    const copyShape = yield select(getCopyShape);
    // document.addEventListener("keydown", handleKeyDown);
    document.onkeydown = (e) => {
      handleKeyDown(e, keyCtrlIsPressing, isUserTyping);
    };
    // const currClass = yield select(selectCurrLabel);

    const alterUserId = getParameterByName("alterUserId");
    const isViewResult = getParameterByName("isViewResult");
    const disableEdit = isViewResult || alterUserId;
    if (isUserTyping || disableEdit) yield cancel();
    if (payload.type === "keyup") {
      // document.removeEventListener("keydown", handleKeyDown);
      switch (payload.key) {
        case hotKeys.v.key:
          if (
            (keyCtrlIsPressing || keyPrev === hotKeys.Control.key) &&
            copyShape
          ) {
            if (copyShape.classesTypeCode === "box-image") {
              yield put(setCopyShapeMode(true));
            }
          }
          break;
        case hotKeys.z.key:
          if (keyCtrlIsPressing || keyPrev === hotKeys.Control.key) {
            yield put(undoAnnotation());
          }
          break;
        case hotKeys.y.key: {
          if (keyCtrlIsPressing || keyPrev === hotKeys.Control.key) {
            yield put(redoAnnotation());
          }
          break;
        }
        case hotKeys.d.key:
          if (keyCtrlIsPressing || keyPrev === hotKeys.Control.key) {
            yield call(changeImage, 1);
          }
          break;

        case hotKeys.a.key:
          if (keyCtrlIsPressing || keyPrev === hotKeys.Control.key) {
            yield call(changeImage, -1);
          }
          break;

        case hotKeys.s.key:
          // Choose previous point
          if (selectedPointId && !keyCtrlIsPressing) {
            const currentPointIndex = pointsCanvas.findIndex(
              (item) => item.id === selectedPointId
            );
            const selectedPoint =
              pointsCanvas[Math.max(currentPointIndex - 1, 0)];
            const payload = { [selectedPoint.id]: { ...selectedPoint } };
            const previousPoint = setSelectedPoints(payload);
            yield put(previousPoint);

            // Scroll to the selected point in the facial landmarks reporter
            const targetEl = document.getElementById(previousPoint.id);
            if (targetEl) {
              targetEl.scrollIntoView();
            }
          }
          break;

        case hotKeys.f.key: {
          // Choose next point
          if (Number(selectedPointId) != null) {
            const currentPointIndex = pointsCanvas.findIndex(
              (item) => item.id === selectedPointId
            );
            const nextPoint =
              pointsCanvas[
                Math.min(currentPointIndex + 1, pointsCanvas.length)
              ];
            if (!nextPoint) return;
            const payload = { [nextPoint.id]: { ...nextPoint } };
            yield put(setSelectedPoints(payload));

            // Scroll to the selected point in the facial landmarks reporter
            const targetEl = document.getElementById(nextPoint.id);
            if (targetEl) {
              targetEl.scrollIntoView();
            }
          }
          break;
        }

        case hotKeys.move.key:
          yield call(toggleTool, hotKeys.move.key, currKey, allTools.MOVE_MODE);
          break;
        case hotKeys.box.key:
          yield call(
            toogleToolSaga,
            hotKeys.box.key,
            currKey,
            allTools.BOX_MODE
          );

          break;
        case hotKeys.polygon.key:
          yield call(
            toogleToolSaga,
            hotKeys.polygon.key,
            currKey,
            allTools.POLYGON_MODE
          );
          break;
        case hotKeys.polyline.key:
          yield call(
            toogleToolSaga,
            hotKeys.polyline.key,
            currKey,
            allTools.POLYLINE_MODE
          );
          break;
        case hotKeys.segmentation.key:
          yield call(
            toogleToolSaga,
            hotKeys.segmentation.key,
            currKey,
            allTools.SEGMENTATION_MODE
          );
          break;
        case hotKeys.cuboid.key:
          yield call(
            toogleToolSaga,
            hotKeys.cuboid.key,
            currKey,
            allTools.CUBOID_MODE
          );
          break;
        case hotKeys.ocr.key:
          yield call(toogleToolSaga, hotKeys.ocr.key, currKey, allTools.OCR);
          break;
        case hotKeys.autoIrisCircle.key: {
          const isIrisCirclesShown = yield select(selectIsIrisCirclesShown);
          if (!isIrisCirclesShown) return;
          yield put(selectTool("autoIris"));
          break;
        }

        case hotKeys.showIndice.key: {
          const keyCount = yield select(selectKeyCount);
          if (keyCount % 2 === 1) {
            yield put(showPointsIndice(false));
            return;
          }
          yield put(showPointsIndice(true));
          break;
        }

        case hotKeys.showIrisCircles.key: {
          if (
            (keyCtrlIsPressing || keyPrev === hotKeys.Control.key) &&
            currentShape &&
            ["box-image"].includes(currentShape.classesTypeCode)
          ) {
            yield put(setCopyShape(currentShape));
            return;
          }
          const keyCount = yield select(selectKeyCount);
          if (keyCount % 2 === 1) {
            yield put(showIrisCircles(false));
            return;
          }
          yield put(showIrisCircles(true));
          break;
        }

        case hotKeys.showLines.key: {
          const keyCount = yield select(selectKeyCount);
          if (keyCount % 2 === 1) {
            yield put(showConnectLines(false));
            return;
          }
          yield put(showConnectLines(true));
          break;
        }

        case hotKeys.ArrowRight.key:
          yield call(updatePointPosition, currPoint, 1);
          break;

        case hotKeys.ArrowLeft.key:
          yield call(updatePointPosition, currPoint, -1);
          break;

        case hotKeys.ArrowUp.key:
          yield call(updatePointPosition, currPoint, null, -1);
          break;

        case hotKeys.ArrowDown.key:
          yield call(updatePointPosition, currPoint, null, 1);
          break;

        case hotKeys.Control.key:
          yield put(setCtrlIsPressing(false));
          break;
        case hotKeys.Delete.key:
        case hotKeys.Backspace.key:
          if (currentShape && currentShape.classesTypeCode) {
            const currentPolygonPoint = yield select(
              selectCurrentPolyonPointIndex
            );
            if (
              [
                `${allTools.POLYGON_MODE}-image`,
                `${allTools.POLYLINE_MODE}-image`,
              ].includes(currentShape.classesTypeCode) &&
              currentPolygonPoint !== -1 &&
              currentShape.classesTypeCode
            ) {
              yield put(
                deletePolygonPoint({
                  shapeId: currentShape.id,
                  index: currentPolygonPoint,
                })
              );
              break;
            }
            // yield put(deleteShape(currentShape));
            yield put(
              setModalItem({
                payload: currentShape,
                fn: null,
                title: "Delete object",
                titleId: "shape-list.delete-object",
                content: "Do you want to delete this object?",
                contentId: "shape-list.confirm-deletion",
                id: currentShape.id,
                fnAction: deleteShape,
              })
            );
          }

          break;
        case hotKeys.Escape.key:
          if (currTool) yield put(selectTool(""));

          break;
        default:
      }
    }

    if (payload.type === "keydown") {
      if (
        ![
          hotKeys.Control.key,
          hotKeys.v.key,
          hotKeys.autoIrisCircle.key,
        ].includes(payload.key)
      )
        keyPrev = null;
      switch (payload.key) {
        case hotKeys.Control.key:
          keyPrev = payload.key;
          yield put(setCtrlIsPressing(true));
          break;

        case hotKeys.s.key:
          if (keyCtrlIsPressing) {
            yield put(submitPoints("save"));
          }
          break;

        default:
      }
    }
  });
}

function* toggleTool(inputKey, currKey, type) {
  const currTool = yield select(makeCurrTool);
  if (inputKey === currKey) {
    if (currTool === type) {
      document.body.style.cursor = "default";
      yield put(deselectTool());
      return;
    }
    yield put(selectTool(type));
  }
}

function* changeImage(number) {
  const imageIndex = yield select(selectImageIndex);
  const allImages = yield select(selectImages);
  const allImgIds = yield select(selectAllImgIds);
  const imageStatus = yield select(selectImageStatus);
  const filteredImages = allImgIds.filter(
    (imgId) => allImages[imgId].status === imageStatus
  );

  if (!(imageIndex < filteredImages.length) || (imageIndex === 0 && number < 0))
    return;
  const newIndex = imageIndex != null ? Number(imageIndex) + number : 0;
  if (!filteredImages[newIndex]) return;
  const payload = {
    id: filteredImages[newIndex],
    index: newIndex,
  };
  yield put(selectOneSource(payload));

  // Scroll selected source to top
  const targetEl = document.querySelector(
    `div[data-id="${filteredImages[newIndex]}"]`
  );
  if (targetEl) {
    targetEl.parentNode.scrollTop =
      targetEl.offsetTop - targetEl.parentNode.offsetTop;
  }
}

function* updatePointPosition(node, x, y) {
  if (!node || isEmpty(node)) return;
  if (!x) {
    node.move({ y });
  }
  if (!y) {
    node.move({ x });
  }
  const { id, x: x1, y: y1, issued = false } = node.attrs;
  const points = yield select(selectPoints);
  const ratio = yield select(selectImageRatio);
  const displayingRegions = yield select(selectDisplayingRegions);
  const selectedPoints = yield select(makeSelectedPoint);

  const prevPoint = selectedPoints[id];
  const changed = { x: x1 - prevPoint.x, y: y1 - prevPoint.y };
  const canvasSelectedPoints = cloneDeep(selectedPoints);
  for (let point in canvasSelectedPoints) {
    canvasSelectedPoints[point].x += changed.x;
    canvasSelectedPoints[point].y += changed.y;
    canvasSelectedPoints[point].isChanged = true;
    canvasSelectedPoints[point].issued = issued;
  }

  const newSelectedPoints = cloneDeep(canvasSelectedPoints);
  for (let point in newSelectedPoints) {
    newSelectedPoints[point].x /= ratio;
    newSelectedPoints[point].y /= ratio;
  }

  setSelectedPoints(canvasSelectedPoints);

  const newPointsCanvas = [];
  const newPoints = points.reduce((acc, point) => {
    const { id, x, y } = point;
    const newPointCanvas = { ...point, x: x * ratio, y: y * ratio };
    if (newSelectedPoints[id]) {
      acc[id] = newSelectedPoints[id];
      newPointsCanvas.push(canvasSelectedPoints[id]);
    } else {
      acc.push(point);
      if (displayingRegions.has(newPointCanvas.region))
        newPointsCanvas.push(newPointCanvas);
    }
    return acc;
  }, []);

  yield put(setPoints(newPoints));
  yield put(setPointsCanvas(newPointsCanvas));
}

export default function* keyboardSagas() {
  yield all([
    fork(initKeyboardEventListener),
    fork(sagaKeyboardEvent),
    fork(initMouseScrollEventListener),
  ]);
}
