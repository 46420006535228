import {
  GET_SHAPE,
  GET_SHAPE_SUCCESS,
  GET_SHAPE_ERROR,
  SET_CURRENT_SHAPE,
  SET_DISPLAYING_SHAPE,
  SET_DISPLAYING_SHAPE_BY_ID,
  SET_DISPLAYING_SHAPE_BY_LABEL_ID,
  DELETE_SHAPE,
  ADD_SHAPE_SUCCESS,
  UPDATE_SHAPE_SUCCESS,
  DELETE_SHAPE_SUCCESS,
  DELETE_SHAPE_ERROR,
  UPDATE_SHAPE_ATTRIBUTE,
  UPDATE_SHAPE_ATTRIBUTE_SUCCESS,
  UPDATE_SHAPE_ATTRIBUTE_ERROR,
  CLOSE_ALL_ATTRIBUTE,
  SET_COPY_SHAPE,
  SET_COPY_SHAPE_MODE,
  SHAPE_SOCKET_RECEIVE,
  UPDATE_SHAPE_LABEL,
  UPDATE_SHAPE_LABEL_SUCCESS,
  UPDATE_SHAPE_LABEL_ERROR,
  SET_CURRENT_SHAPE_SUCCESS,
  SET_DRAWING_POLYGON,
  SET_CURRENT_POLYGON_POINT_INDEX,
  DELETE_ALL_SHAPE,
  DELETE_ALL_SHAPE_SUCCESS,
  DELETE_ALL_SHAPE_ERROR,
} from "./constants";

export const setCurrentPolygonPointIndex = (payload) => ({
  type: SET_CURRENT_POLYGON_POINT_INDEX,
  payload,
});

export const setDrawingPolygon = (payload) => ({
  type: SET_DRAWING_POLYGON,
  payload,
});

export const shapeSocketReceive = (payload) => ({
  type: SHAPE_SOCKET_RECEIVE,
  payload,
});

export const setCopyShape = (payload) => ({
  type: SET_COPY_SHAPE,
  payload,
});

export const setCopyShapeMode = (payload) => ({
  type: SET_COPY_SHAPE_MODE,
  payload,
});

export const getShapes = (sourceId) => ({
  type: GET_SHAPE,
  sourceId,
});

export const getShapesSuccess = (payload) => ({
  type: GET_SHAPE_SUCCESS,
  payload,
});

export const addShapesSuccess = (payload) => ({
  type: ADD_SHAPE_SUCCESS,
  payload,
});

export const updateShapesSuccess = (payload) => ({
  type: UPDATE_SHAPE_SUCCESS,
  payload,
});

export const getShapesError = (payload) => ({
  type: GET_SHAPE_ERROR,
  payload,
});

export const setCurrentShape = (payload) => ({
  type: SET_CURRENT_SHAPE,
  payload,
});
export const setTextVisibleCurrentShape = (payload) => ({
  type: SET_CURRENT_SHAPE,
  payload,
});
export const setCurrentShapeSuccess = (payload) => ({
  type: SET_CURRENT_SHAPE_SUCCESS,
  payload,
});
export const setDisplayingShape = (payload) => ({
  type: SET_DISPLAYING_SHAPE,
  payload,
});

export const setDisplayingShapeById = ({ id, labelId }) => ({
  type: SET_DISPLAYING_SHAPE_BY_ID,
  id,
  labelId,
});

export const setDisplayingShapeByLabelId = (labelId) => ({
  type: SET_DISPLAYING_SHAPE_BY_LABEL_ID,
  labelId,
});

export const deleteShape = (payload) => ({
  type: DELETE_SHAPE,
  payload,
});
export const deleteShapeSucess = (payload) => ({
  type: DELETE_SHAPE_SUCCESS,
  payload,
});
export const deleteShapeError = (payload) => ({
  type: DELETE_SHAPE_ERROR,
  payload,
});

export const updateShapeAttribute = (payload) => ({
  type: UPDATE_SHAPE_ATTRIBUTE,
  payload,
});
export const updateShapeAttributeSuccess = (payload) => ({
  type: UPDATE_SHAPE_ATTRIBUTE_SUCCESS,
  payload,
});
export const updateShapeAttributeError = (payload) => ({
  type: UPDATE_SHAPE_ATTRIBUTE_ERROR,
  payload,
});
export const closeAllAttribute = (payload) => ({
  type: CLOSE_ALL_ATTRIBUTE,
  payload,
});

export const updateShapeLabel = ({ payload, callback }) => ({
  type: UPDATE_SHAPE_LABEL,
  payload,
  callback,
});
export const updateShapeLabelSuccess = (payload) => ({
  type: UPDATE_SHAPE_LABEL_SUCCESS,
  payload,
});
export const updateShapeLabelError = (payload) => ({
  type: UPDATE_SHAPE_LABEL_ERROR,
  payload,
});

export const deleteAllShape = (payload) => ({
  type: DELETE_ALL_SHAPE,
  payload,
});
export const deleteAllShapeSuccess = (payload) => ({
  type: DELETE_ALL_SHAPE_SUCCESS,
  payload,
});
export const deleteAllShapeError = (payload) => ({
  type: DELETE_ALL_SHAPE_ERROR,
  payload,
});
