import { createSelector } from "reselect";
import groupBy from "lodash.groupby";

export const getAllShapes = (state) => state.shape.shapes;
export const getDisplayingShapes = (state) => state.shape.displayingShapes;

export const getCurrentShapes = (state) => state.shape.currentShape;
export const getCopyShape = (state) => state.shape.copyShape;

export const getLabelsOfShapes = () =>
  createSelector(getAllShapes, (shapes) =>
    shapes.reduce((labels, shape) => {
      return labels.includes(Number(shape.labelId))
        ? labels
        : [...labels, Number(shape.labelId)];
    }, [])
  );

export const makeShapeByLabel = createSelector(getAllShapes, (shapes) =>
  groupBy(shapes, "labelId")
);

export const selectCopyShapeMode = (state) => state.shape.copyShapeMode;

export const selectShapeById = (id) => (state) =>
  state.shape.shapes?.find((shape) => Number(shape.id) === Number(id)) || null;
export const selectCheckAddShape = (state) => state.shape.checkAddShape;
export const selectDrawingPolygon = (state) => state.shape.drawingPolygon;
export const selectCurrentPolyonPointIndex = (state) =>
  state.shape.currentPolygonPointIndex;
