import React, { useState } from "react";
import { injectIntl } from "react-intl";

import TitleWrapper from "components/PointList/TittleWrapper";
import ListContainer from "./ListContainer";

import { selectImageId } from "redux/image/selectors";
import { deleteAllShape } from "redux/shape/actions";
import { setModalItem } from "redux/canvas/actions";
import styles from "./style.module.scss";
import { object, string, func } from "prop-types";
import { connect } from "react-redux";

function ShapeList({ intl, imageId, deleteAllShape, setModalItem }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
  };
  const handleDeleteAllLabel = () => {
    setModalItem({
      payload: { imageId },
      fn: deleteAllShape,
      title: intl.formatMessage({ id: "shape-list.delete-all" }),
      content: intl.formatMessage({ id: "shape-list.confirm-delete-all" }),
      id: imageId,
      fnAction: null,
    });
  };

  return (
    <div className={styles.container} data-tour="box-list">
      <TitleWrapper
        title={intl.formatMessage({ id: "shape-list.item-list" })}
        deleteAllLabel={intl.formatMessage({
          id: "shape-list.delete-all-labels",
        })}
        isCollapsed={isCollapsed}
        handleCollapse={handleCollapse}
        handleDeleleAllLabel={handleDeleteAllLabel}
      />
      {isCollapsed ? null : <ListContainer />}
    </div>
  );
}

ShapeList.propTypes = {
  intl: object,
  imageId: string,
  deleteAllShape: func,
  setModalItem: func,
};

function mapState(state) {
  return {
    imageId: selectImageId(state),
  };
}

export default connect(mapState, { deleteAllShape, setModalItem })(
  injectIntl(ShapeList)
);
