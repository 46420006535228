import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { number, object } from "prop-types";
import styles from "./style.module.scss";
import IntlMessage from "../../helpers/IntlMessage";

function renderMessage(msgId, msg, intlValues) {
  const values = intlValues ? intlValues : {};
  if (msgId) {
    return <IntlMessage id={msgId} values={values} />;
  } else if (msg) {
    return <IntlMessage id={msg} />;
  } else return null;
}

function Notification({ notification, index }) {
  const [visible, setVisible] = useState(true);
  let backgroundColor = null;
  let color = null;

  switch (notification.type) {
    case "success":
      color = "#4caf50";
      backgroundColor = "#efffee";
      break;
    case "error":
      color = "#db0101";
      backgroundColor = "#fae8e8";
      break;
    default:
      color = "#2196f3";
      backgroundColor = "#e2ecf4";
  }

  const useStyles = makeStyles(() => ({
    snackbar: {
      "& > div": {
        backgroundColor,
        color,
      },
      "&> .MuiSnackbarContent-root": {
        width: "max-content",
      },
    },
  }));
  const classes = useStyles();

  const handleClose = () => setVisible(false);

  return (
    <div className={styles.wrapper} style={{ top: 10 + 50 * index }}>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={visible}
        onClose={handleClose}
        autoHideDuration={5000 + 1000 * index}
        message={renderMessage(
          notification.msgId,
          notification.msg,
          notification.intlValues
        )}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
}

Notification.propTypes = {
  notification: object,
  index: number,
};

export default Notification;
