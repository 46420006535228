import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "10px",
    marginTop: "30px",
  },
  popover: {
    width: "600px",
    border: "1px solid #9ca8b4",
    padding: "16px",
    boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 73%)",
    marginLeft: "2px",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    marginTop: "20px",
  },
  button: {
    display: "inline-block",
    padding: "10px 15px",
    backgroundColor: "#1871E8",
    color: "#fff",
    borderRadius: "4px",
    fontWeight: 600,
    boxShadow: "0px 1px 3px 0px rgb(0 0 0 / 60%)",
    margin: "25px 0px 10px 0px",
    cursor: "pointer",
  },
  tooltip: {
    marginLeft: "5px",
  },
  header: {
    fontWeight: 600,
    marginBottom: "15px",
  },
  model_type_group: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  model_type_item: {
    width: "105px",
    padding: "10px",
    backgroundColor: "#efefef",
    borderRadius: "5px",
    fontWeight: 600,
    cursor: "pointer",
    textAlign: "center",
  },
  active: {
    backgroundColor: "white",
    border: "2px solid #1890FE",
    color: "#1890FE",
  },
  row_flex: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  flex_center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: "20px",
    },
    "& button": {
      padding: "0px",
    },
  },
  flex_end: {
    display: "flex",
    justifyContent: "end",
  },
  flex_start: {
    display: "flex",
    justifyContent: "start",
  },
  select_model: {
    flexGrow: 1,
  },
  color_blue: {
    color: "#3172d2",
  },
  annotation_label: {
    padding: "5px 10px",
    backgroundColor: "#b46563",
    color: "white",
    width: "fit-content",
    borderRadius: "5px",
  },

  mappedLabelScroll: {
    maxHeight: "400px",
    overflowY: "scroll",
    overflowX: "hidden",

    "&::-webkit-scrollbar-track": {
      webkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "10px",
      backgroundColor: "#ffffff",
    },

    "&::-webkit-scrollbar": {
      width: "5px",
      backgroundColor: "#ffffff",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      webkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      backgroundColor: "rgb(192, 192, 192)",
    },

    "& .mappedColumn": {
      paddingLeft: "11px",
    },
  },
}));

export default useStyles;
