/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { Popper, Button } from "@material-ui/core";
import InputBase from "components/InputBase";
import { func, bool } from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { injectIntl } from "react-intl";
import { sendEmailToSystemAdmin } from "redux/app/actions";
import SendContactEmailSuccessModal from "components/SendContactEmailSuccessModal";
import { Robot } from "icons";
import useStyles from "./style";
import IntlMessage from "../../helpers/IntlMessage";

function AdsPopup({ openAds, onClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = Boolean(openAds);
  const [message, setMessage] = useState("");
  const [openContactModal, setOpenContactModal] = useState(false);

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const sendEmail = () => {
    dispatch(
      sendEmailToSystemAdmin({ message }, () => {
        onClose();
        setOpenContactModal(true);
      })
    );
  };

  const handleCloseSendEmailModal = () => {
    setOpenContactModal(false);
  };

  const id = open ? "simple-popper" : undefined;

  const body = (
    <>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <img src={Robot} alt="" className={classes.robot}></img>
          <span className={classes.introText}>
            <IntlMessage id="ads-popup.hello" />
          </span>
        </div>
        <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
      </div>
      <div className={classes.content}>
        <img src={Robot} className={classes.robotBody} alt=""></img>
        <p className={classes.questionText}>
          <IntlMessage id="ads-popup.question-text" />
        </p>
      </div>
      <div className={classes.footer}>
        <InputBase value={message} onChange={handleChangeMessage} />
        <div className={classes.footerButton}>
          <Button
            className={classes.buttonReject}
            onClick={onClose}
            variant="contained"
          >
            <IntlMessage id="ads-popup.not-now" />
          </Button>
          <Button
            className={classes.buttonAccept}
            onClick={sendEmail}
            variant="contained"
          >
            <IntlMessage id="ads-popup.lets-talk" />
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <Popper id={id} open={open}>
        <div className={classes.paper}>{body}</div>
      </Popper>
      <SendContactEmailSuccessModal
        open={openContactModal}
        onClose={handleCloseSendEmailModal}
      />
    </div>
  );
}
AdsPopup.propTypes = {
  openAds: bool,
  onClose: func,
};

export default injectIntl(AdsPopup);
