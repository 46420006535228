import React from "react";
import { useSelector } from "react-redux";
import { selectCheckLoadLargeImage } from "redux/image/selectors";
import { number } from "prop-types";
import LargeImage from "./LargeImage";
import SmallImage from "./SmallImage";

const MainImage = React.memo(function MainImage({ brightness, contrast }) {
  const isLoadLargeImage = useSelector(selectCheckLoadLargeImage);

  return (
    <>
      {isLoadLargeImage ? (
        <LargeImage contrast={contrast} brightness={brightness} />
      ) : (
        <SmallImage contrast={contrast} brightness={brightness} />
      )}
    </>
  );
});

MainImage.propTypes = {
  contrast: number,
  brightness: number,
};

export default MainImage;
