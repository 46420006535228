import React from "react";
import { object, func, bool, string } from "prop-types";
import { connect } from "react-redux";
import ReactHashtag from "react-hashtag";
import clsx from "clsx";
import { injectIntl } from "react-intl";

import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import UserDefaultAvatar from "@material-ui/icons/AccountCircle";
import {
  seekByIssue,
  deleteIssue,
  openIssueForm,
  clickHashTag,
} from "redux/issues/actions";
import { selectUserId, selectPermissionComponent } from "redux/app/selectors";
import { selectImageAnnotationType } from "redux/image/selectors";
import { getAllShapes } from "redux/shape/selector";
import { setCurrentShape } from "redux/shape/actions";
import { setModalItem } from "redux/canvas/actions";
import { getParameterByName } from "helpers";

import styles from "./style.module.scss";
import IntlMessage from "../../../helpers/IntlMessage";

function MessageWrapper({
  intl,
  item,
  seekByIssue,
  handeResolve,
  handleRefresh,
  handleReopen,
  isParent = false,
  deleteIssue,
  userId,
  openIssueForm,
  resolvePermission,
  clickHashTag,
  annotationType,
  shapes,
  setCurrentShape,
  setModalItem,
}) {
  const messageHeaderClass = clsx({
    [styles.messageHeader]: true,
    [styles.headerParent]: isParent,
    [styles.headerChild]: !isParent,
  });
  const messageBodyClass = clsx({
    [styles.messageBody]: true,
    [styles.bodyParent]: isParent,
    [styles.bodyChild]: !isParent,
  });
  const image = clsx({
    [styles.image]: true,
    [styles.imgChild]: !isParent,
    [styles.imgParent]: isParent,
  });
  const alterUserId = getParameterByName("alterUserId");
  const isViewResult = getParameterByName("isViewResult");

  const content = item.content.split(/\n/g);

  const highlightAnnotation = (event) => {
    preventDefault(event);
    seekByIssue(item);
  };

  const handleDeleteIssueConfirm = (event) => {
    preventDefault(event);
    setModalItem({
      payload: item,
      fn: deleteIssue,
      title: intl.formatMessage({ id: "issues.delete-issue" }),
      content: intl.formatMessage({ id: "issues.confirm-delete-issue" }),
      id: item.issueId,
      fnAction: null,
    });
  };

  const handleEditIssue = (event) => {
    preventDefault(event);
    openIssueForm({ ...item });
  };

  function preventDefault(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  function handleHashtagClick(event) {
    if (alterUserId || isViewResult) {
      return;
    }
    const textHasgtag = event.target.textContent;
    if (annotationType === "image-facial") {
      const pointIndex = event.target.textContent.slice(1);
      if (!isNaN(Number(pointIndex))) {
        clickHashTag(Number(pointIndex));
      }
    } else {
      let tags = textHasgtag.split("#");
      const shapeId = tags.find((x) => !isNaN(x) && Number(x) > 0);
      const currentShape = shapes.find((x) => Number(x.id) === Number(shapeId));
      setCurrentShape(currentShape);
    }
  }

  return (
    <div style={{ marginLeft: isParent ? null : 25 }}>
      <div className={messageHeaderClass}>
        <span>
          {item.imagePreviewPath ? (
            <img
              className={image}
              src={item.imagePreviewPath}
              alt="user-avatar"
            />
          ) : (
            <UserDefaultAvatar className={image} />
          )}
        </span>
        <span className={styles.name}>{item.fullName || item.userName}</span>
        <span className={styles.date}>
          {item.updatedDate || item.createdDate}
        </span>
        {isParent ? (
          <>
            {item && item.status === "open" && resolvePermission ? (
              <button
                type="button"
                className={styles.resolve}
                onClick={handeResolve}
              >
                <IntlMessage id="issues.resolve" />
              </button>
            ) : null}
            {item && item.status === "resolved" ? (
              <button
                type="button"
                className={styles.resolve}
                onClick={handleReopen}
              >
                Reopen
              </button>
            ) : null}
            <button
              type="button"
              className={styles.refresh}
              onClick={handleRefresh}
            >
              <RefreshIcon />
            </button>
          </>
        ) : null}
      </div>
      <div className={messageBodyClass}>
        {isParent && item.labelName ? (
          <>
            <p className="font-weight-bold">
              <IntlMessage id="issues.selected-object" />
            </p>
            <button type="button" onClick={highlightAnnotation}>
              #{item.labelName} {item.frame ? `#${item.frame}` : null}
            </button>
          </>
        ) : null}

        {content.map((text) => (
          <>
            <ReactHashtag
              renderHashtag={(hashTag) => (
                <span onClick={handleHashtagClick} className={styles.hashTag}>
                  {hashTag}
                </span>
              )}
            >
              {text}
            </ReactHashtag>
            <br />
          </>
        ))}

        {userId === item.createdBy ? (
          <div className={styles.messageActions}>
            <button type="button" onClick={handleEditIssue}>
              <EditIcon />
            </button>
            <button type="button" onClick={handleDeleteIssueConfirm}>
              <DeleteIcon />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

MessageWrapper.propTypes = {
  intl: object,
  item: object,
  seekByIssue: func,
  handeResolve: func,
  handleRefresh: func,
  handleReopen: func,
  isParent: bool,
  deleteIssue: func,
  userId: string,
  openIssueForm: func,
  resolvePermission: bool,
  clickHashTag: func,
  annotationType: string,
  shapes: object,
  setCurrentShape: func,
  setModalItem: func,
};

const mapStateToProps = (state) => ({
  userId: selectUserId(state),
  resolvePermission: selectPermissionComponent("labeling_resolve_issue")(state),
  annotationType: selectImageAnnotationType(state),
  shapes: getAllShapes(state),
});

export default connect(mapStateToProps, {
  seekByIssue,
  deleteIssue,
  openIssueForm,
  clickHashTag,
  setCurrentShape,
  setModalItem,
})(injectIntl(MessageWrapper));
