import React, { useEffect, useState } from "react";
import { func, string, object, arrayOf, number, bool } from "prop-types";
import { useSelector, useDispatch, connect } from "react-redux";
import { Popover, Divider, Grid } from "@material-ui/core";
import AccountTreeIcon from "@material-ui/icons/AccountTree";

import Select from "react-select";

import { AnnotationShape } from "icons";
import ToolContainer from "components/ToolContainer";
import { makeCurrTool } from "redux/tools/selectors";
import * as allTools from "redux/tools/enums";
import { deselectTool } from "redux/tools/actions";

import AdsPopup from "components/AdsPopup";
import useStyles from "./style";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import IconButton from "@material-ui/core/IconButton";
import { fetchDefaultModels } from "api";
import { automaticAnnotationApi } from "api/segmentation";
import get from "lodash.get";
import { selectLabelsForDropDown } from "redux/points/selectors";

import { setDialogOpen } from "redux/app/actions";
import { selectProjectId, selectDatasetId } from "redux/app/selectors";
import { selectImageId } from "redux/image/selectors";
import { showNotification } from "redux/notification/actions";
import IntlMessage from "../../helpers/IntlMessage";

function AutomaticAnnotationToolButton({
  name,
  labels,
  setDialogOpen,
  projectId,
  datasetId,
  sourceId,
  showNotification,
  disabledClass = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currTool = useSelector(makeCurrTool);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAds, setAnchorElAds] = React.useState(null);

  const [mappedLabels, setMappedLabels] = useState([]);
  const [selectedDefaultModel, setSelectedDefaultModel] = useState(null);
  const [selectedProjectLabel, setSelectedProjectLabel] = useState(null);
  const [selectedDefaultLabel, setSelectedDefaultLabel] = useState(null);
  const [clearOldAnnotation, setClearOldAnnotation] = useState(false);
  const [defaultModels, setDefaultModels] = useState(null);
  const [defaultLabels, setDefaultLabels] = useState(null);
  const [projectLabels, setProjectLabels] = useState(null);

  useEffect(() => {
    async function getListDefaultModels(projectId) {
      let response = await fetchDefaultModels({ projectId });
      let data = get(response, "data", []);
      if (data && data.length > 0) {
        data = data.map((x) => {
          return {
            ...x,
            label: x.model_name,
            value: x.model_id,
          };
        });

        setDefaultModels(data);
        setSelectedDefaultModel(data[0]);
      }
    }

    if (projectId) {
      getListDefaultModels(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    refreshSelectOptions();
  }, [labels, mappedLabels]);

  useEffect(() => {
    setMappedLabels([]);
    setSelectedDefaultLabel(null);
    if (selectedDefaultModel?.model_id) {
      refreshSelectOptions();
    }
  }, [selectedDefaultModel]);

  useEffect(() => {
    if (selectedDefaultLabel && selectedProjectLabel) {
      let mappedExtend = [...mappedLabels];

      mappedExtend.push({
        projectLabel_id: selectedProjectLabel.id,
        projectLabel_name: selectedProjectLabel.name,
        projectLabel_type: selectedProjectLabel.tools.includes("box-image")
          ? "box-image"
          : "polygon-image",
        defaultLabel_name: selectedDefaultLabel.value,
      });

      setMappedLabels(mappedExtend);

      setSelectedProjectLabel(null);
      setSelectedDefaultLabel(null);
    }
  }, [selectedDefaultLabel, selectedProjectLabel]);

  const refreshSelectOptions = () => {
    const filterProjectLabelOptions = labels?.filter(
      (x) =>
        (((selectedDefaultModel?.data_type || ["box"]).includes("box") &&
          x.tools.includes("box-image")) ||
          ((selectedDefaultModel?.data_type || []).includes("polygon") &&
            x.tools.includes("polygon-image"))) &&
        mappedLabels.filter((y) => y.projectLabel_id == x.id).length <= 0
    );
    const projectLabelOptions = filterProjectLabelOptions?.map((x) => {
      return {
        ...x,
        label:
          x.label +
          ` (${x.tools.includes("box-image") ? "Bounding Box" : "Polygon"})`,
        name: x.label,
      };
    });
    setProjectLabels(projectLabelOptions);

    const filterDefaultLabelOptions = selectedDefaultModel?.default_classes?.filter(
      (x) => mappedLabels.filter((y) => y.defaultLabel_name == x).length <= 0
    );
    const defaultLabelOptions = filterDefaultLabelOptions?.map((x) => {
      return {
        label: x,
        value: x,
      };
    });
    setDefaultLabels(defaultLabelOptions);
  };

  const autoMappedClass = () => {
    if (projectLabels && projectLabels.length > 0) {
      let mapData = [];
      projectLabels.forEach((option) => {
        const mapClass = defaultLabels.find(
          (x) => x.value == option.name.replace("  ", " ")
        );
        if (mapClass) {
          mapData.push({
            projectLabel_id: option.id,
            projectLabel_name: option.name,
            projectLabel_type: option.tools.includes("box-image")
              ? "box-image"
              : "polygon-image",
            defaultLabel_name: mapClass.value,
          });
        }
      });
      setMappedLabels(mappedLabels.concat(mapData));
    }
  };

  const handleTogglePopover = (event) => {
    const disabledClass = event.target.getAttribute("data-disabled");
    setAnchorElAds(event.currentTarget);
    if (disabledClass === `true`) {
      return;
    }
    const tempAnchorEl = event.currentTarget;
    if (!currTool) {
      setAnchorEl(tempAnchorEl);
    } else {
      dispatch(deselectTool());
    }
  };

  const handleClose = () => {
    resetStateAndClosePopup();
  };

  const resetStateAndClosePopup = () => {
    setSelectedDefaultLabel(null);
    setSelectedProjectLabel(null);
    setClearOldAnnotation(false);
    setAnchorEl(null);
  };

  const handleClosePopper = () => {
    setAnchorElAds(null);
  };

  const onChangeDefaultModel = (model) => {
    setSelectedDefaultModel(model);
  };

  const onChangeDefaultLabels = (label) => {
    setSelectedDefaultLabel(label);
  };

  const onChangeProjectLabels = (label) => {
    setSelectedProjectLabel(label);
  };

  const onRemoveMappedClass = (projectLabel_id, defaultLabel_name) => {
    let mappedFilter = mappedLabels.filter(
      (x) =>
        x.projectLabel_id != projectLabel_id ||
        x.defaultLabel_name != defaultLabel_name
    );
    setMappedLabels(mappedFilter);
  };

  const onAnnotate = async () => {
    if (!selectedDefaultModel || !selectedDefaultModel.model_id) {
      showNotification({
        type: "error",
        msg: "auto-anno-tool.select-a-model",
      });
      return;
    }

    if (!mappedLabels || mappedLabels.length <= 0) {
      showNotification({
        type: "error",
        msg: "auto-anno-tool.map-the-classes",
      });
      return;
    }

    const res = await automaticAnnotationApi({
      projectId,
      datasetId,
      mappedLabels,
      modelId: selectedDefaultModel.model_id,
      clear: clearOldAnnotation,
      sourceIds: [sourceId],
      isCustomModel: !selectedDefaultModel.is_default,
    });
    console.log(res);

    setDialogOpen(true);
    resetStateAndClosePopup();
  };

  const onChangeClearOldAnnotation = () => {
    setClearOldAnnotation(!clearOldAnnotation);
  };

  return (
    <>
      <ToolContainer
        currTool={currTool}
        disabledClass={disabledClass}
        name={allTools.AUTOMATIC_ANNOTATION_MODE}
        tooltipText="common.ai-tool"
        onClick={handleTogglePopover}
      >
        <AnnotationShape style={{ pointerEvents: "none" }} />
      </ToolContainer>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="right-start"
        className={classes.root}
        onClose={handleClose}
      >
        <div className={classes.popover}>
          <div className={classes.header}>
            <IntlMessage id="common.ai-tools" /> -{" "}
            <IntlMessage id="common.automatic-annotation" />
          </div>
          <Divider style={{ margin: "15px 0px" }} />
          <div className={classes.row_flex}>
            <span style={{ width: "100px", textAlign: "left" }}>
              <IntlMessage id="common.model" />
            </span>
            <Select
              placeholder={<IntlMessage id="auto-anno-tool.select-a-model" />}
              className={classes.select_model}
              options={defaultModels}
              onChange={onChangeDefaultModel}
              menuPosition="fixed"
              value={selectedDefaultModel}
            />
          </div>
          <Grid
            container
            style={{ marginBottom: "10px" }}
            className={
              (mappedLabels || []).length > 8 ? classes.mappedLabelScroll : ""
            }
          >
            {mappedLabels &&
              mappedLabels.length > 0 &&
              mappedLabels.map((x, i) => {
                return (
                  <Grid
                    container
                    spacing={3}
                    key={`mapped_label_${i}`}
                    style={{ marginBottom: "2px" }}
                  >
                    <Grid
                      item
                      md={5}
                      className={`${classes.flex_end} mappedColumn`}
                    >
                      <div className={classes.annotation_label}>
                        {x.projectLabel_name}
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      className={`${classes.flex_center} mappedColumn`}
                    >
                      <IconButton
                        className="annotation-icon-button-delete"
                        onClick={() =>
                          onRemoveMappedClass(
                            x.projectLabel_id,
                            x.defaultLabel_name
                          )
                        }
                        title={
                          <IntlMessage id="auto-anno-tool.delete-mapping" />
                        }
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      md={5}
                      className={`${classes.flex_start} mappedColumn`}
                    >
                      <div className={classes.annotation_label}>
                        {x.defaultLabel_name}
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={5}>
              <Select
                options={projectLabels}
                placeholder={<IntlMessage id="common.class-name" />}
                className={classes.select_model}
                menuPosition="fixed"
                onChange={onChangeProjectLabels}
                value={selectedProjectLabel}
              />
            </Grid>
            <Grid item md={2} className={classes.flex_center}>
              <SyncAltIcon className={classes.color_blue} />
            </Grid>
            <Grid item md={5}>
              <Select
                options={defaultLabels}
                placeholder={
                  <IntlMessage id="auto-anno-tool.model-default-label" />
                }
                className={classes.select_model}
                menuPosition="fixed"
                onChange={onChangeDefaultLabels}
                value={selectedDefaultLabel}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "15px" }}>
            <Grid item md={6}>
              <input
                type="checkbox"
                id="rdCleanOldAnnotate"
                checked={clearOldAnnotation}
                onChange={onChangeClearOldAnnotation}
                style={{ width: "15px", height: "15px", marginRight: "5px" }}
              />
              <label htmlFor="rdCleanOldAnnotate">
                <IntlMessage id="auto-anno-tool.clean-old-annotations" />
              </label>
            </Grid>
            <Grid item md={6} style={{ textAlign: "right" }}>
              <div
                onClick={autoMappedClass}
                style={{
                  color: "#007bff",
                  display: "flex",
                  backgroundColor: "white",
                  borderWidth: 0,
                  float: "right",
                }}
              >
                <AccountTreeIcon />
                <div style={{ marginTop: "3px", marginLeft: "5px" }}>
                  Auto mapping
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid style={{ textAlign: "right" }}>
            <div className={classes.button} name={name} onClick={onAnnotate}>
              <IntlMessage id="common.annotate" />
            </div>
          </Grid>
        </div>
      </Popover>
      <AdsPopup openAds={anchorElAds} onClose={handleClosePopper} />
    </>
  );
}

AutomaticAnnotationToolButton.propTypes = {
  checkDisableTool: bool,
  handlePickTool: func,
  name: string,
  limitationSegmentationTool: func,
  labels: arrayOf(object),
  setDialogOpen: func,
  projectId: number,
  datasetId: number,
  sourceId: string || number,
  showNotification: func,
  disabledClass: bool,
};

const mapStateToProps = (state) => {
  return {
    labels: selectLabelsForDropDown()(state),
    projectId: selectProjectId(state),
    datasetId: selectDatasetId(state),
    sourceId: selectImageId(state),
  };
};

export default connect(mapStateToProps, {
  deselectTool,
  setDialogOpen,
  showNotification,
})(AutomaticAnnotationToolButton);
