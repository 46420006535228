import * as React from "react";

function Polyline(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 196.28 188.94"
      fill="currentColor"
      {...props}
    >
      <title>Polyline</title>
      <path
        fill="#fff"
        stroke="currentColor"
        strokeWidth={10}
        strokeMiterlimit={10}
        d="M65.06 137.47l22.39-42.24 22.9-5.08 20.87-38.68"
      />
    </svg>
  );
}

export default Polyline;
