import produce from "immer";
import {
  CHANGE_IMAGE_RATIO,
  SET_POINTS_CANVAS,
  SHOW_POINTS_INDICE,
  SHOW_CONNECT_LINES,
  CHANGE_DRAW_COUNT,
  SHOW_CHECKED_REGIONS,
  HIDE_UNCHECKED_REGIONS,
  TOGGLE_DISPLAY_ALL_REGIONS,
  SHOW_IRIS_CIRCLES,
  SHOW_KEY_POINT,
  SET_MODAL_ITEM,
} from "./constants";

export const initialState = {
  pointsCanvas: [],
  ratio: 1,
  isIndiceShown: false,
  isLinesShown: true,
  isIrisCirclesShown: true,
  isKeyPointShown: true,
  drawCount: 1,
  modalItem: {
    payload: null,
    fn: null,
    title: null,
    titleId: null,
    content: null,
    contentId: null,
    id: null,
    fnAction: null,
  },
};

function removeUncheckedPoints(total, points) {
  const length = points.length;
  const startIndex = total.reduce((acc, point, index) => {
    if (points[0].id === point.id) acc = index;
    return acc;
  }, 0);
  total.splice(startIndex, length);
  return total;
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_POINTS_CANVAS:
        draft.pointsCanvas = action.payload;
        break;

      case CHANGE_IMAGE_RATIO:
        draft.ratio = action.payload;
        break;

      case SHOW_CONNECT_LINES:
        draft.isLinesShown = action.payload;
        break;

      case SHOW_IRIS_CIRCLES:
        draft.isIrisCirclesShown = action.payload;
        break;

      case SHOW_KEY_POINT:
        draft.isKeyPointShown = action.payload;
        break;

      case SHOW_POINTS_INDICE:
        draft.isIndiceShown = action.payload;
        break;

      case CHANGE_DRAW_COUNT:
        draft.drawCount = state.drawCount + 1;
        break;

      case SHOW_CHECKED_REGIONS:
        draft.pointsCanvas = [...state.pointsCanvas, ...action.payload];
        break;

      case HIDE_UNCHECKED_REGIONS:
        draft.pointsCanvas = removeUncheckedPoints(
          state.pointsCanvas,
          action.payload
        );
        break;

      case TOGGLE_DISPLAY_ALL_REGIONS:
        draft.pointsCanvas = action.payload;
        break;
      case SET_MODAL_ITEM:
        draft.modalItem = action.payload;
        break;

      default:
    }
  });

export default reducer;
