import * as React from "react";

function Box(props) {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 409.042 409.043"
      fill="currentColor"
      {...props}
    >
      <g>
        <path
          d="M389.433,72.762H19.611C8.797,72.762,0,81.558,0,92.374V316.67c0,10.818,8.797,19.611,19.611,19.611h369.822
     c10.812,0,19.609-8.793,19.609-19.611V92.374C409.042,81.558,400.246,72.762,389.433,72.762z M392.234,316.67
     c0,1.555-1.26,2.802-2.801,2.802H19.611c-1.546,0-2.802-1.247-2.802-2.802V92.374c0-1.546,1.256-2.802,2.802-2.802h369.822
     c1.541,0,2.801,1.255,2.801,2.802V316.67z"
        />
      </g>
    </svg>
  );
}

export default Box;
