import React from "react";
import { Circle } from "react-konva";

export default function ListSmartPoints({ points, ratio = 0 }) {
  return (
    points?.map(({ isPositive, x, y }, index) => (
      <Circle
        key={index}
        x={x * ratio}
        y={y * ratio}
        fill={isPositive ? "green" : "#CD5C5C"}
        stroke="#DCDCDC"
        strokeWidth={2}
        radius={3}
        shadowColor="black"
        shadowOpacity={0.5}
        shadowOffset={{ x: 2, y: 2 }}
        shadowBlur={5}
      />
    )) || null
  );
}
