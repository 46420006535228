import { number, object, string } from "prop-types";
import React from "react";

export default function OriginalImage({
  src,
  width,
  height,
  style,
  brightness,
  contrast,
  ...rest
}) {
  return (
    <img
      src={src}
      width={width}
      height={height}
      // id="mainImage"
      style={{
        filter: `brightness(${brightness}) contrast(${contrast})`,
        ...style,
      }}
      {...rest}
    />
  );
}

OriginalImage.propTypes = {
  src: string,
  width: number,
  height: number,
  style: object,
  brightness: number,
  contrast: number,
};
