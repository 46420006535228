import React, { useState } from "react";
import { shape, number, func, string, bool, object } from "prop-types";
import { connect, useSelector } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Popover from "@material-ui/core/Popover";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

import {
  selectImageMetadata,
  selectImageId,
  selectLabelerAndReviwerName,
  selectImageRotating,
  selectImageAnnotationType,
  selectIsBenchmark,
  selectIsBenchmarkProject,
  selectAnnotatedBy,
} from "redux/image/selectors";
import {
  selectIsIndiceShown,
  selectIsLinesShown,
  selectIsIrisCirclesShown,
  selectIsKeyPointShown,
} from "redux/canvas/selectors";
import {
  showPointsIndice,
  showConnectLines,
  showIrisCircles,
  showKeyPoint,
} from "redux/canvas/actions";
import Dialog from "./Dialog";
import Resizer from "./Resizer";
// import Rotate from "./Rotate";
import ButtonGroup from "./ButtonGroup";
import style from "./style.module.scss";
import IntlMessage from "../../helpers/IntlMessage";
import { BenchmarkStart } from "icons";
import { getParameterByName } from "helpers";
import { markBenchmark } from "redux/image/actions";
import { userInfo } from "api/constants";
import { selectRoleCode } from "redux/app/selectors";
import { injectIntl } from "react-intl";

const ImgAnnotationHeader = ({
  imageId,
  imageMetadata,
  isIndiceShown,
  showPointsIndice,
  reviewerName,
  isLinesShown,
  showConnectLines,
  isIrisCirclesShown,
  showIrisCircles,
  isKeyPointShown,
  showKeyPoint,
  annotationType,
  // imageRotating,
  markBenchmark,
  intl,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [typeOfDialog, setTypeOfDialog] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const isViewResult = getParameterByName("isViewResult");
  const isActionHistory = getParameterByName("isActionHistory");
  const alterUserId = getParameterByName("alterUserId");
  const _userInfo = JSON.parse(userInfo || "{}");
  const userEmail = _userInfo.email;

  const roleCode = useSelector(selectRoleCode);
  const isBenchmark = useSelector(selectIsBenchmark);
  const isBenchmarkProject = useSelector(selectIsBenchmarkProject);
  const annotatedBy = useSelector(selectAnnotatedBy);
  let displayAnnotatedBy = annotatedBy;
  if (isActionHistory && !alterUserId) {
    displayAnnotatedBy = userEmail;
  }
  const enableBenchmark =
    roleCode === "admin" || roleCode === "project_manager";
  const labelerDisplay = () => {
    return isViewResult || isActionHistory ? (
      <span className={style.name}>
        Annotated By: <b>{displayAnnotatedBy}</b> |
      </span>
    ) : (
      ""
    );
  };

  function handleOpenDialog(e) {
    let typeOfDialog = e.currentTarget.getAttribute("data-content");
    setTypeOfDialog(typeOfDialog);
    setDialogOpen(true);
  }

  function handleShowIndice(event) {
    showPointsIndice(event.target.checked);
  }

  function handleShowLines(event) {
    showConnectLines(event.target.checked);
  }

  function handleShowKeyPoint(event) {
    showKeyPoint(event.target.checked);
  }

  function handleClickShow(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleShowIrisCircles(event) {
    showIrisCircles(event.target.checked);
  }

  const handleMarkBenchmark = () => {
    markBenchmark && markBenchmark({ isBenchmark: !isBenchmark });
  };

  // const { name, size } = imageMetadata;
  const { name } = imageMetadata;
  const open = Boolean(anchorEl);

  return (
    <>
      <Dialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        typeOfDialog={typeOfDialog}
      />
      <div className={style.mediaTopBar}>
        <Tooltip
          placement="bottom-start"
          id={name}
          title={name}
          arrow
          classes={{
            tooltip: style.tooltip,
            arrow: style.tooltipArrow,
          }}
        >
          <div className={style.imageSizeContainer}>
            <p>{name}</p>
          </div>
        </Tooltip>
        {imageId && !alterUserId && !isViewResult && (
          <ButtonGroup handleOpenDialog={handleOpenDialog} />
        )}
        {labelerDisplay()}
        {reviewerName && (
          <span className={style.name}>
            <IntlMessage id="common.reviewer" />: <b>{reviewerName}</b>
          </span>
        )}
        <div className={style.imageSizeContainer}>
          {annotationType === "image-facial" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 15px",
                  cursor: "pointer",
                }}
                onClick={handleClickShow}
              >
                <div>{open ? <ChevronRight /> : <ExpandMore />}</div>
                <div>Show</div>
              </div>
              <Popover
                id="show-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div>
                  &nbsp; &nbsp;
                  <FormControlLabel
                    value="Indice"
                    control={
                      <Checkbox
                        onChange={handleShowIndice}
                        checked={isIndiceShown}
                        color="primary"
                        size="small"
                      />
                    }
                    label={<span className={style.labelValue}>Indice</span>}
                    labelPlacement="end"
                    disabled={!imageId}
                  />
                </div>
                <div>
                  &nbsp; &nbsp;
                  <FormControlLabel
                    value="ConnectLines"
                    control={
                      <Checkbox
                        onChange={handleShowLines}
                        checked={isLinesShown}
                        color="primary"
                        size="small"
                      />
                    }
                    label={
                      <span className={style.labelValue}>
                        <IntlMessage id="img-anno-header.connect-lines" />
                      </span>
                    }
                    labelPlacement="end"
                    disabled={!imageId}
                  />
                </div>
                <div>
                  &nbsp; &nbsp;
                  <FormControlLabel
                    value="IrisCirleDisplay"
                    control={
                      <Checkbox
                        onChange={handleShowIrisCircles}
                        checked={isIrisCirclesShown}
                        color="primary"
                        size="small"
                      />
                    }
                    label={
                      <span className={style.labelValue}>
                        <IntlMessage id="img-anno-header.iris-circles" />
                      </span>
                    }
                    labelPlacement="end"
                    disabled={!imageId}
                  />
                </div>
                <div>
                  &nbsp; &nbsp;
                  <FormControlLabel
                    value="Key Point"
                    control={
                      <Checkbox
                        onChange={handleShowKeyPoint}
                        checked={isKeyPointShown}
                        color="primary"
                        size="small"
                      />
                    }
                    label={
                      <span className={style.labelValue}>
                        <IntlMessage id="img-anno-header.key-point" />
                      </span>
                    }
                    labelPlacement="end"
                    disabled={!imageId}
                  />
                </div>
              </Popover>
            </>
          )}
          {isBenchmarkProject &&
            !isViewResult &&
            !isActionHistory &&
            enableBenchmark && (
              <div>
                <Tooltip
                  placement="bottom-start"
                  id={"benchmark"}
                  title={intl.formatMessage({
                    id: isBenchmark ? "Remove benchmark" : "Add benchmark",
                  })}
                  arrow
                  classes={{
                    tooltip: style.tooltip,
                    arrow: style.tooltipArrow,
                  }}
                >
                  <span
                    className={style.benchmarkIcon}
                    onClick={handleMarkBenchmark}
                  >
                    <BenchmarkStart isBenchmark={isBenchmark} />
                  </span>
                </Tooltip>
              </div>
            )}
          {/* Button rotate */}
          {/* {!imageRotating &&
            size &&
            size < 15 * 1024 * 1024 &&
            !name.includes(".tif") && <Rotate />} */}
          <Resizer />
        </div>
      </div>
    </>
  );
};

ImgAnnotationHeader.propTypes = {
  imageMetadata: shape({
    name: string,
    width: number,
    height: number,
  }),
  isIndiceShown: bool,
  showPointsIndice: func,
  imageId: string,
  labelerName: string,
  reviewerName: string,
  isLinesShown: bool,
  showConnectLines: func,
  isIrisCirclesShown: bool,
  showIrisCircles: func,
  isKeyPointShown: bool,
  showKeyPoint: func,
  annotationType: string,
  imageRotating: bool,
  markBenchmark: func,
  intl: object,
};

const mapStateToProps = (state) => ({
  imageMetadata: selectImageMetadata(state),
  imageId: selectImageId(state),
  isIndiceShown: selectIsIndiceShown(state),
  isLinesShown: selectIsLinesShown(state),
  isIrisCirclesShown: selectIsIrisCirclesShown(state),
  isKeyPointShown: selectIsKeyPointShown(state),
  reviewerName: selectLabelerAndReviwerName(state).reviewerName,
  labelerName: selectLabelerAndReviwerName(state).labelerName,
  annotationType: selectImageAnnotationType(state),
  imageRotating: selectImageRotating(state),
});

export default injectIntl(
  connect(mapStateToProps, {
    showPointsIndice,
    showConnectLines,
    showIrisCircles,
    showKeyPoint,
    markBenchmark,
  })(ImgAnnotationHeader)
);
