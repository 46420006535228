import React from "react";
import { string, bool, func } from "prop-types";
import EventNote from "@material-ui/icons/EventNote";
import Brightness from "@material-ui/icons/Brightness1";
import Announcement from "@material-ui/icons/Announcement";
import CloudDownload from "@material-ui/icons/CloudDownload";
import LocalPlay from "@material-ui/icons/LocalPlay";
import CenterFocusStrong from "@material-ui/icons/CenterFocusStrong";
import { adminApp, videoLabelingApp } from "api/constants";
import clsx from "clsx";
import moment from "moment";

import styles from "./style.module.scss";

function TypeIcon({ type }) {
  if (type === "issue") return <Announcement style={{ color: "#FF4D5C" }} />;
  if (type === "task") return <EventNote style={{ color: "#00cc00" }} />;
  if (type === "annotation")
    return <CenterFocusStrong style={{ color: "#4C65FF" }} />;
  if (type === "export") return <CloudDownload style={{ color: "black" }} />;
  if (type === "project") return <LocalPlay style={{ color: "#7f5200" }} />;
  return null;
}

TypeIcon.propTypes = {
  type: string,
};

function NotificationCard({
  readOneNotification,
  notificationId,
  type,
  content,
  isRead,
  projectId,
  datasetId,
  sourceId,
  createdDate,
  appName,
  customerReview,
}) {
  function redirect(event) {
    if (!isRead) handleReadNoti(event);

    const currUrl = window.location.host;
    let redirectUrl = `http://${currUrl}/?projectId=${projectId}&sourceId=${sourceId}&datasetId=${datasetId}&checkLastActive=false&customerReview=${customerReview}`;

    if (appName === "labeling-video") {
      redirectUrl = `${videoLabelingApp}/?projectId=${projectId}&sourceId=${sourceId}&datasetId=${datasetId}&checkLastActive=false&customerReview=${customerReview}`;
    }

    if (appName === "admin") {
      switch (type) {
        case "task":
          redirectUrl = `${adminApp}/app/projects/${projectId}/dataset/${datasetId}`;
          break;
        case "export":
          redirectUrl = `${adminApp}/app/projects/${projectId}?tab=history`;
          break;
        case "project":
          break;
        case "exam":
          break;
        case "invite":
          break;
      }
    }

    window.open(redirectUrl, "_blank");
    window.focus();
  }

  function handleReadNoti(event) {
    event.stopPropagation();
    const payload = {
      notificationId,
      isRead: true,
      content,
      type,
    };
    readOneNotification(payload);
  }

  function processString(input) {
    const textArr = input.split("%");
    const indice = [];
    for (let i = 0; i < input.length; i++) {
      if (input[i] === "%") indice.push(i);
    }
    const temp = textArr.filter((item) => item);
    if (indice.includes(0)) temp[0] = <strong key="0">{temp[0]}</strong>;
    if (indice.includes(input.length - 1))
      temp[temp.length - 1] = <strong key="1">{temp[temp.length - 1]}</strong>;
    if (!indice.includes(input.length - 1))
      temp[temp.length - 2] = <strong key="2">{temp[temp.length - 2]}</strong>;
    if (indice.length === 6)
      temp[temp.length - 4] = <strong key="6"> {temp[temp.length - 4]}</strong>;
    return temp;
  }

  const notiClassName = clsx({
    [styles.notificationCard]: true,
    [styles.isRead]: isRead,
  });
  return (
    <div className={notiClassName} onClick={redirect}>
      <div className={styles.mainArea}>
        <TypeIcon type={type.toLowerCase(0)} />
        <span>{processString(content)}</span>
        <Brightness
          onClick={handleReadNoti}
          style={isRead ? { display: "none" } : { width: 13, color: "#1876F2" }}
        />
      </div>
      <div className={styles.time}>{moment(createdDate).from()}</div>
    </div>
  );
}

NotificationCard.propTypes = {
  content: string,
  type: string,
  isRead: bool,
  createdDate: string,
  readOneNotification: func,
  notificationId: string,
  projectId: string,
  datasetId: string,
  customerReview: bool,
  appName: string,
  sourceId: string,
};

export default NotificationCard;
