import S3FileUpload from "react-s3";
import { getCookie } from "helpers";
import {
  baseURL,
  commonHeader,
  amazonS3Config,
  engineURL,
  commonHeaderAdmin,
  userLogCommonHeader,
} from "api/constants";
import { SEGMENTATION_MODE } from "redux/tools/enums";
import { socket } from "redux/socket/sagas";

export function saveFileToS3(image, path) {
  const userInfoInCookie = getCookie("userInfo");
  const orgId = userInfoInCookie
    ? JSON.parse(userInfoInCookie).organizationId
    : null;
  const date = new Date();
  amazonS3Config.dirName = path
    ? path // eslint-disable-line
    : `org_${orgId}/image/segmentation/${date.getTime()}`;

  amazonS3Config.accessKeyId = process.env.REACT_APP_S3_ACCESSKEY_ID;
  amazonS3Config.secretAccessKey = process.env.REACT_APP_S3_SECRET_ACCESS_KEY;

  return S3FileUpload.uploadFile(image, amazonS3Config)
    .then((data) => data)
    .catch((err) => console.error(err)); // eslint-disable-line
}

export const fetchDataBase64 = (url) =>
  fetch(url)
    .then((response) => response.json())
    .catch((error) => console.log(`Failed because: ${error}`));

export function saveSegmentationAPI({
  sourceId,
  labelId,
  base64Data,
  pointArr = [],
  x,
  y,
  width,
  height,
}) {
  return fetch(`${baseURL}/segmentation`, {
    method: "POST",
    ...userLogCommonHeader,
    headers: {
      ...userLogCommonHeader.headers,
      type: SEGMENTATION_MODE,
    },
    body: JSON.stringify({
      sourceId,
      labelId,
      base64Data,
      pointArr,
      x,
      y,
      width,
      height,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function getSessionIDSegmentationByEngineAPI({
  mediaType,
  fileUrl,
  clicks,
  boundingBox,
  modelName,
}) {
  const [x, y, width, height] = boundingBox;
  const validatedBoundingBox = [
    x > 0 ? x : 0,
    y > 0 ? y : 0,
    width + x > 1 ? 1 - x : width,
    height + y > 1 ? 1 - y : height,
  ];
  return fetch(`${engineURL}/api/v1/interactive-segment`, {
    method: "POST",
    ...commonHeaderAdmin,
    body: JSON.stringify({
      mediaType,
      fileUrl,
      clicks,
      boundingBox: validatedBoundingBox,
      modelName,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function drawSegmentationByEngineAPI(sessionId) {
  return fetch(`${engineURL}/api/v1/interactive-segment/${sessionId}`, {
    method: "GET",
    ...commonHeaderAdmin,
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function updateSegmentationAPI({
  sourceId,
  id,
  base64Data,
  pointArr = [],
  x,
  y,
  width,
  height,
}) {
  return fetch(`${baseURL}/segmentation/${id}`, {
    method: "PUT",
    ...commonHeader,
    body: JSON.stringify({
      sourceId,
      base64Data,
      pointArr,
      x,
      y,
      width,
      height,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function getLimitationSegmentationTool() {
  return fetch(`${engineURL}/api/v1/usage/?apiName=image`, {
    method: "GET",
    ...commonHeaderAdmin,
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function confirmSmartToolSuccesfulRequestAPI(sessionId) {
  return fetch(`${engineURL}/api/v1/interactive-segment-finished`, {
    method: "POST",
    ...commonHeaderAdmin,
    body: JSON.stringify({
      sessionId,
      success: true,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export async function automaticAnnotationApi(data) {
  return fetch(`${baseURL}/automaticAnnotation`, {
    method: "POST",
    ...commonHeader,
    body: JSON.stringify({
      ...data,
      socketId: socket.id,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}
