/* eslint-disable no-case-declarations */
import produce from "immer";
import { SELECT_ONE_SOURCE } from "redux/image/constants";
import {
  ADD_SHAPE_SUCCESS,
  CLOSE_ALL_ATTRIBUTE,
  DELETE_ALL_SHAPE_SUCCESS,
  DELETE_SHAPE_SUCCESS,
  GET_SHAPE_SUCCESS,
  SET_COPY_SHAPE,
  SET_COPY_SHAPE_MODE,
  SET_CURRENT_POLYGON_POINT_INDEX,
  SET_CURRENT_SHAPE_SUCCESS,
  SET_DISPLAYING_SHAPE,
  SET_DRAWING_POLYGON,
  SHAPE_SOCKET_RECEIVE,
  UPDATE_SHAPE_ATTRIBUTE_SUCCESS,
  // SHOW_FORM_ATTRIBUTE,
  // HIDE_FORM_ATTRIBUTE,
  UPDATE_SHAPE_LABEL_SUCCESS,
  UPDATE_SHAPE_SUCCESS,
} from "./constants";
export const initialState = {
  shapes: [],
  currentShape: null,
  copyShape: null,
  displayingShapes: [],
  copyShapeMode: false,
  checkAddShape: false,
  drawingPolygon: {
    id: -1,
    visible: false,
    points: [],
  },
  currentPolygonPointIndex: -1,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SELECT_ONE_SOURCE: {
        draft.currentShape = null;
        draft.shapes = [];
        if (!action.payload.id) {
          draft.copyShape = null;
          draft.displayingShapes = [];
          draft.copyShapeMode = false;
        }
        break;
      }
      case SHAPE_SOCKET_RECEIVE:
        const { type, data } = action.payload;
        if (type === "delete") {
          draft.shapes = state.shapes.filter((item) => item.id !== data.id);
          draft.displayingShapes = draft.displayingShapes.filter(
            (item) => item.id !== data.id
          );
          break;
        }
        const existedIndex = state.shapes.findIndex(
          (item) => item.id === data.id
        );
        const existedDisplayIndex = state.displayingShapes.findIndex(
          (item) => item.id === data.id
        );
        if (existedDisplayIndex === -1)
          draft.displayingShapes.push({ id: data.id, labelId: data.labelId });
        if (existedIndex < 0) {
          draft.shapes.push(data);
          break;
        }
        draft.shapes[existedIndex] = data;
        break;
      case SET_COPY_SHAPE_MODE:
        draft.copyShapeMode = action.payload;
        break;
      case SET_COPY_SHAPE:
        draft.copyShape = action.payload;
        break;
      case GET_SHAPE_SUCCESS:
        draft.shapes = action.payload;
        draft.displayingShapes = action.payload.map(({ id, labelId }) => ({
          id,
          labelId,
        }));

        break;

      case ADD_SHAPE_SUCCESS:
        draft.shapes = [...state.shapes, action.payload];
        draft.displayingShapes = [
          ...state.displayingShapes,
          action.payload,
        ].map(({ id, labelId }) => ({
          id,
          labelId,
        }));
        // draft.currentShape = {
        //   ...draft.currentShape,
        //   ...action.payload,
        // };
        draft.checkAddShape = !state.checkAddShape;

        break;

      case UPDATE_SHAPE_SUCCESS:
        draft.shapes = state.shapes.map((item) => {
          if (item.id === action.payload.id) return action.payload;
          return item;
        });
        break;
      case DELETE_SHAPE_SUCCESS:
        draft.currentShape = null;
        draft.shapes = state.shapes.filter(
          (shape) => shape.id !== action.payload.id
        );
        break;
      case DELETE_ALL_SHAPE_SUCCESS:
        draft.shapes = [];
        draft.displayingShapes = [];
        draft.currentShape = null;
        break;
      case SET_CURRENT_SHAPE_SUCCESS:
        if (action.payload && action.payload.id)
          draft.shapes = [
            ...state.shapes.filter((x) => x.id !== action.payload.id),
            action.payload,
          ];
        draft.currentShape = action.payload;
        if (draft.currentShape) {
          draft.currentShape.closeAllAttribute = false;
        }
        break;

      case SET_DISPLAYING_SHAPE:
        draft.displayingShapes = action.payload;
        draft.currentShape = null;
        break;

      case UPDATE_SHAPE_ATTRIBUTE_SUCCESS:
        draft.shapes = [
          ...state.shapes.map((x) => {
            x.dataAttribute =
              x.id !== action.payload.labelId
                ? x.dataAttribute
                : action.payload.dataAttribute;
            return x;
          }),
        ];
        draft.currentShape.dataAttribute = [...action.payload.dataAttribute];
        break;
      case CLOSE_ALL_ATTRIBUTE:
        if (state.currentShape) draft.currentShape.closeAllAttribute = true;
        break;

      case UPDATE_SHAPE_LABEL_SUCCESS:
        draft.shapes = state.shapes.map((item) => {
          if (item.id === action.payload.id) return action.payload;
          return item;
        });

        draft.currentShape = action.payload;

        let displayShapes = state.displayingShapes;
        displayShapes = displayShapes.map((shape) =>
          Number(shape.id) === Number(action.payload.id)
            ? {
                ...shape,
                labelId: action.payload.labelId,
              }
            : shape
        );
        draft.displayingShapes = displayShapes;

        break;
      case SET_DRAWING_POLYGON:
        draft.drawingPolygon = action.payload;
        break;
      case SET_CURRENT_POLYGON_POINT_INDEX:
        draft.currentPolygonPointIndex = action.payload;
        break;
      default:
        break;
    }
  });

export default reducer;
