import { baseURL, commonHeader } from "api/constants";

export async function getShapeAPI({
  sourceId,
  datasetId,
  projectId,
  settingQuality,
  alterUserId,
}) {
  let query = `labelShape?datasetId=${datasetId}&projectId=${projectId}&sourceId=${sourceId}`;
  if (settingQuality) {
    query += `&settingQuality=${settingQuality}`;
  }
  if (alterUserId) {
    query += `&alterUserId=${alterUserId}`;
  }
  try {
    const res = await fetch(`${baseURL}/${query}`, {
      method: "GET",
      ...commonHeader,
    });
    return await res.json();
  } catch (error) {
    console.log(error, "error");
    return { error: true, ...error };
  }
}

export async function deleteShapeAPI({ id }) {
  try {
    const res = await fetch(`${baseURL}/labelShape/${id}`, {
      method: "DELETE",
      ...commonHeader,
    });
    return await res.json();
  } catch (error) {
    console.log(error, "error");
    return { error: true, ...error };
  }
}

export async function updateShapeAttribute({ labelId, dataAttribute }) {
  try {
    const res = await fetch(`${baseURL}/labelShape/${labelId}`, {
      method: "PUT",
      ...commonHeader,
      body: JSON.stringify({
        dataAttribute,
      }),
    });
    return await res.json();
  } catch (error) {
    console.log(error, "error");
    return { error: true, ...error };
  }
}

export async function updateShapeLabelAPI({
  id,
  labelId,
  color,
  settingQuality,
  sourceId,
}) {
  try {
    const res = await fetch(`${baseURL}/labelShape/${id}`, {
      method: "PUT",
      ...commonHeader,
      body: JSON.stringify({
        color,
        labelId,
        settingQuality,
        sourceId,
      }),
    });
    return await res.json();
  } catch (error) {
    console.log(error, "error");
    return { error: true, ...error };
  }
}

export async function deleteAllShapeAPI(imageId) {
  try {
    const res = await fetch(`${baseURL}/label/${imageId}`, {
      method: "DELETE",
      ...commonHeader,
    });
    return await res.json();
  } catch (error) {
    console.log(error, "error");
    return { error: true, ...error };
  }
}
