module.exports = {
  // Special cases:
  All: "All",

  // Common phrases
  "common.ai-tool": "AI tool",
  "common.ai-tools": "AI Tools",
  "common.automatic-annotation": "Automatic Annotation",
  "common.model": "Model",
  "common.class-name": "Class name",
  "common.annotate": "Annotate",
  "common.close": "Close",
  "common.approve": "Approve",
  "common.decline": "Decline",
  "common.skip": "Skip",
  "common.doublecheck": "Double check",
  "common.submit": "Submit",
  "common.reopen": "Reopen",
  "common.yes": "Yes",
  "common.no": "No",
  "common.labeler": "Labeler",
  "common.tags": "Tags",
  "common.reviewer": "Reviewer",
  "common.member": "Member",
  "common.tag": "Tags",
  "common.placeholder-tag": "Select tags",
  "common.no-options": " No Options",
  "common.smart-tag": "Smart Tag",
  "common.selected-tags-title": "Current Selected Tags",
  "common.selected-tags-subtitle": "Current tags of selected datasets",
  "common.create-smart-tag-title": "Create new smart tag",
  "common.create-smart-tag-subtitle":
    "Choose color and type name to create a new smart tag for selected datasets",
  "common.color-pattern-title": "Color pattern",
  "common.search-or-create-tag": "Search or create tag",
  "common.tag-name-title": "Tag name",
  "common.search-project-tag-by-text-error": "Failed to fetch tag.",
  "common.create-tag": "Create tag",
  "common.add-tag-to-project-successfully": "Add tag to project successfully",
  "common.add-tag-to-project-error": "Add tag to project: error",
  "common.add-tag-to-source-successfully": "Add tag to source: Success",
  "common.add-tag-to-source-error": "Add tag to source: Failed",
  "common.fit": "Fit",
  "common.save": "Save",
  "common.reset": "Reset",
  "common.message-us": "Message us",
  "common.new": "New",
  "common.submitted": "Submitted",
  "common.approved": "Approved",
  "common.skipped": "Skipped",
  "common.reopened": "Reopened",
  "common.tools": "Tools",
  "common.cancel": "Cancel",
  "common.ok": "OK",
  "common.no-data-to-show": "No data to show",
  "common.start": "Start",
  "common.done": "Done",
  "common.choose-class": "Choose Class",
  "common.choose-a-class": "Choose a class",
  "common.open": "Open",
  "common.resolved": "Resolved",
  "common.update": "Update",
  "common.add": "Add",

  // AdsPopup
  "ads-popup.hello": "Hello there!",
  "ads-popup.question-text":
    "Do you want to use your own AI models for automated labeling?",
  "ads-popup.not-now": "Not now",
  "ads-popup.lets-talk": "Yes. Let's talk",

  // AutomaticAnnoTool
  "auto-anno-tool.please-select-model":
    "Please select a model before annotate!",
  "auto-anno-tool.map-the-classes": "Please map the classes before annotate!",
  "auto-anno-tool.interactors": "Interactors",
  "auto-anno-tool.detectors": "Detectors",
  "auto-anno-tool.trackers": "Trackers",
  "auto-anno-tool.select-a-model": "Select a model",
  "auto-anno-tool.delete-mapping": "Delete mapping",
  "auto-anno-tool.model-default-label": "Model's default labels",
  "auto-anno-tool.clean-old-annotations": "Clean old annotations",

  "img-anno-header.please-choose-a-class": "Please choose a class",
  "img-anno-header.no-option-to-choose": "No option to choose",
  "img-anno-header.skip-confirm-question":
    "Are you sure you want to <b>skip</b> the image ?",
  "img-anno-header.approve-confirm-question":
    "Are you sure you want to <b>approve</b> the image ?",
  "img-anno-header.submit-confirm-question":
    "Are you sure you want to <b>submit</b> the image ?",
  "img-anno-header.doublecheck-confirm-question":
    "Are you sure you want to <b>double check</b> the image ?",
  "img-anno-header.confirmation": "Confirmation",
  "img-anno-header.reason": "Reason",
  "img-anno-header.classification": "Classification",
  "img-anno-header.connect-lines": "Connect lines",
  "img-anno-header.iris-circles": "Iris circles",
  "img-anno-header.key-point": "Key point",
  "img-anno-header.reopen-confirm-question":
    "Are you sure you want to reopen the image?",

  "img-settings.opacity": "Opacity",
  "img-settings.contrast": "Contrast",
  "img-settings.brightness": "Brightness",

  "input-data-picker.apply-filters": "APPLY FILTERS",
  "input-data-picker.leave-page-warning":
    "Your drawing is not saved. Do you want to leave the page?",
  "input-data-picker.no-image": "No image.",
  "input-data-picker.images": "IMAGES",
  "input-data-picker.filter": "FILTER",

  "keyboard-modal.hotkeys": "Hotkeys",
  "keyboard-modal.general": "General",
  "keyboard-modal.go-to-previous-photo": "Go to the previous photo",
  "keyboard-modal.go-to-previous-class": "Go to the previous class",
  "keyboard-modal.go-to-next-photo": "Go to the next photo",
  "keyboard-modal.go-to-next-class": "Go to the next class",
  "keyboard-modal.copy-paste-box": "Copy/Paste the box",
  "keyboard-modal.move-tool": "Move tool",
  "keyboard-modal.auto-put-points-into-iris-circle":
    "Automatically put points into iris circles",
  "keyboard-modal.box-tool": "Box tool",
  "keyboard-modal.polygonal-segmentation-tool": "Polygonal segmentation tool",
  "keyboard-modal.binary-mask-segmentation-tool":
    "Binary mask segmentation tool",
  "keyboard-modal.cuboid-tool": "Cuboid tool",
  "keyboard-modal.text-box-tool": "Text box tool",
  "keyboard-modal.deselect-tool": "Deselect tool",
  "keyboard-modal.point-edit": "Point edit",
  "keyboard-modal.save-points": "Save points",
  "keyboard-modal.go-to-previous-point": "Go to the previous point",
  "keyboard-modal.go-to-next-point": "Go to the next point",
  "keyboard-modal.point-moves-left": "Point moves left",
  "keyboard-modal.point-moves-right": "Point moves right",
  "keyboard-modal.point-moves-up": "Point moves up",
  "keyboard-modal.point-moves-down": "Point moves down",
  "keyboard-modal.mouse-wheel": "Mouse Wheel",
  "keyboard-modal.zoom-in-out": "Zoom in/out image",
  "keyboard-modal.show-hide-point-index": "Show/Hide every point index",
  "keyboard-modal.show-hide-iris-circles": "Show/Hide iris circles",
  "keyboard-modal.show-hide-line-connect-points":
    "Show/Hide lines that connect points",

  "limit-time-modal.limit-reached": "Uh-oh! Limit reached",
  "limit-time-modal.you-have-reached-the-limit":
    "You have reached the limit of annotation plan",
  "limit-time-modal.please-upgrade-plan":
    "Please upgrade your plan to create more values with our powerful tool.",
  "limit-time-modal.upgrade-plan": "Upgrade plan",

  "main-image.no-image-selected": "No image is selected",

  "point-list.add-an-issue": "Add an issue",
  "point-list.facial-landmarks": "Facial Landmarks",
  "point-list.all-points": "all points",

  "push-notification.notification": "Notifications",
  "push-notification.mark-all-as-read": "Mark all as read",
  "push-notification.no-notifications": "There is no notifications yet.",

  "segmentation-limit-modal.you-have-reached-the-limit":
    "You have reached the limit of usage of asssited tool usage",
  "segmentation-limit-modal.please-contact-your-admin":
    "Please contact your administrations to upgrade your plan.",
  "segmentation-limit-modal.understood": "Okay, I understand",
  "segmentation-limit-modal.please-upgrade-your-plan":
    "Please upgrade your plan to create more values with our powerfull tool.",
  "segmentation-limit-modal.no-thanks": "No, Thanks",

  "segmentation-tool-button.binary-mask-segmentation":
    "Binary mask segmentation",
  "segmentation-tool-button.choose-ai-model": "Choose AI model",
  "segmentation-tool-button.engine": "Engine",

  "segmentation-top-bar.draw": "Draw",
  "segmentation-top-bar.draw-by-smart-tool": "Draw by smart tool",
  "segmentation-top-bar.draw-manually": "Draw manually",
  "segmentation-top-bar.eraser": "Eraser",

  "send-contact-email-success-modal.upgrade-request-sent":
    "Upgrade request has been sent",
  "send-contact-email-success-modal.thank-you":
    "Thank you for choosing our service !",
  "send-contact-email-success-modal.we-will-be-in-touch":
    "Your request has been received and we will be in touch shortly.",

  "shape-list.item-list": "Items List",
  "shape-list.delete-all-labels": "Delete all labels",
  "shape-list.index": "Index",
  "shape-list.issue": "Issue",
  "shape-list.delete-object": "Delete object",
  "shape-list.confirm-deletion": "Do you want to delete this object?",

  "polygon.delete-polygon-points": "Delete polygon points",
  "polygon.cannot-delete-polygon-points": "Cannot delete polygon point.",

  "spinner.no-data": "No data",

  "tour-guide.choose-class-step.content":
    'Choose a class that you\'ve created on tab "Class". This class will define your annotation type.',
  "tour-guide.choose-class-step.title": "Select a class for labeling",
  "tour-guide.choose-tool-step.content":
    "Choose the tool that you expect annotation. You can use hotkey beside the tool icon to hit this tool.",
  "tour-guide.choose-tool-step.title": "Choose annotation tool",
  "tour-guide.start-labeling-step.content":
    "Label the object that matches with the selected class and annotation type.",
  "tour-guide.start-labeling-step.title": "Start labeling",
  "tour-guide.review-step.content":
    "You can review, edit or delete all created annotations from this table.",
  "tour-guide.review-step.title": "Review your labeling result",

  "undo-redo-top-bar.undo": "Undo",
  "undo-redo-top-bar.redo": "Redo",

  "bottom-hotkeys.cancel-draw-annotation": "Cancel draw annotation",

  "issues.reply": "Reply",
  "issues.replies": "Replies",
  "issues.last-reply": "Last reply",
  "issues.delete-issue": "Delete issue",
  "issues.confirm-delete-issue": "Do you want to delete this issue?",
  "issues.resolve": "Resolve",
  "issues.selected-object": "Selected object",
  "issues.issues": "Issues",

  "loading-dialog.please-wait":
    "Please wait while our SmartTools work their magic…",

  "reporter.must-have-open-tab-alert": "Must have one tab open.",
  "reporter.expand": "Expand",
  "reporter.collapse": "Collapse",

  "tool-bar.move-the-image": "Move the image",
  "tool-bar.auto-position-iris-circles": "Auto position iris circles",
  "tool-bar.bounding-box": "Bounding box",
  "tool-bar.landmark-point": "Landmark point",
  "tool-bar.polygonal-segmentation": "Polygonal segmentation",
  "tool-bar.polyline": "Polyline",
  "tool-bar.cuboid": "Cuboid",
  "tool-bar.smart-tool-object-tracking": "Smart tool object tracking",
  "tool-bar.text-box": "Text box",

  "top-bar.total-annotation-session-time": "Total annotation session time",
  "top-bar.clock-starts-counting":
    "The clock starts counting time when you open the labeling tool and this total time will be subtracted to monthly annotation time.",
  "top-bar.user-profile": "User profile",
  "top-bar.my-plan": "My plan",
  "top-bar.upgrade": "Upgrade",
  "top-bar.payment-history": "Payment history",
  "top-bar.log-out": "Log out",
  "top-bar.confirm-log-out":
    "Do you really want to log out from AI Cycle Data Platform?",

  "notification.auto-anno-error": "Automatic annotation unsuccessfully.",
  "notification.auto-anno-success": "Automatic annotation successfully.",
  "notification.redo-anno": "Redo annotation.",
  "notification.does-not-have-permission-to-access-this-link":
    "Does not have permission to access this link.",
  "notification.error-fetching-repo": "Error when fetching the repository.",
  "notification.cannot-fetch-firebase-token": "Cannot fetch FIREBASE token.",
  "notification.send-contact-email-error": "Send Contact Email Error!",
  "notification.box-saved-success": "Box saved successfully.",
  "notification.save-box-error": "Save box error!",
  "notification.box-updated-success": "Box updated successfully.",
  "notification.box-updated-error": "Update box error!",
  "notification.update-class-success": "Update classification successfully.",
  "notification.update-class-error":
    "Error when update classification. Try again.",
  "notification.cuboid-saved-success": "Cuboid saved successfully.",
  "notification.cuboid-saved-error": "Save cuboid error!",
  "notification.update-cuboid-success": "Update cuboid success.",
  "notification.update-cuboid-error": "Update cuboid error.",
  "notification.error-when-get-csv-file": "Error when get csv file.",
  "notification.submit-image-success": "Image {status} successfully.",
  "notification.submit-image-error": "{status} image error! Try again.",
  "notification.save-new-image-success": "Save new image successfully",
  "notification.fail-to-save-edited-image": "Fail to save edited image!",
  "notification.fail-to-mark-image": "Fail to mark image!",
  "notification.issue-add-success": "Issue added successfully.",
  "notification.replied-success": "Replied successfully.",
  "notification.error-when-reply": "Error when reply to the issue. Try again.",
  "notification.error-when-delete-issue": "Error when delete issue. Try again.",
  "notification.edit-issue-success": "Edit issue success.",
  "notification.cannot-fetch-notifications": "Can not fetch notifications",
  "notification.fail-to-update-notification": "Fail to update notifications",
  "notification.ocr-saved-success": "OCR saved successfully.",
  "notification.save-ocr-error": "Save ocr error!",
  "notification.ocr-updated-success": "OCR updated successfully.",
  "notification.update-ocr-error": "Update ocr error!",
  "notification.points-saved": "Points saved!",
  "notification.submit-points-success": "Submit points success!",
  "notification.submit-points-error": "Submit points Error. Please try again!",
  "notification.please-adjust-iris-circles": "Please adjust iris circles!",
  "notification.iris-circle-radius-changed": "Iris circle radius changed",
  "notification.fail-to-save-circle-radius": "Fail to save circle radius",
  "notification.new-polygon-updated-successfully":
    "New polygon updated successfully",
  "notification.new-polygon-added-successfully":
    "New polygon added successfully.",
  "notification.new-polyline-added": "New polyline added successfully.",
  "notification.new-polyline-updated-success":
    "New polyline updated successfully",
  "notification.save-segmentation-success": "Save segmentation success.",
  "notification.save-segmentation-error": "Save segmentation error!",
  "notification.gpu-not-available":
    "GPU is not available. Please contact your admin for support!",
  "notification.segmentation-updated-success":
    "Segmentation updated successfully.",
  "notification.update-segmentation-error": "Update segmentation error!",
  "notification.get-shape-error": "Get shape error!",
  "notification.deleted-success": "Deleted successfully.",
  "notification.delete-shape-error": "Delete shape error!",
  "notification.shape-attribute-updated-success":
    "Shape attribute updated successfully.",
  "notification.update-shape-attribute-error": "Update shape attribute error!",
  "notification.update-shape-label-success": "Update shape label success.",
  "notification.update-shape-label-error": "Update shape label error.",
  "notification.shape-copied": "Shape copied.",
  "notification.image-reopen-successfully": "Image reopen successfully",
  "notification.image-skipped-successfully": "Image skipped successfully",
  "notification.image-approved-successfully": "Image approved successfully",
  "notification.image-submitted-successfully": "Image submitted successfully",
  "notification.deleted-all-shape-success": "Deleted all shape successfully",
  "notification.delete-all-shape-error": "Delete all shape error",
  "shape-list.delete-all": "Delete all",
  "shape-list.confirm-delete-all": "Do you want to delete all objects?",

  box: "box",
  "facial landmarks": "facial landmarks",
  issues: "issues",
  "Copy/Paste (Bounding Box only)": "Copy/Paste (Bounding Box only)",
  "Remove benchmark": "Remove benchmark",
  "Add benchmark": "Add benchmark",
  "Data have been updated": "Data have been updated",
  "An error occurred. Please try again.":
    "An error occurred. Please try again.",
  "Mark image successfully": "Mark image successfully",
  "Remove mark image successfully": "Remove mark image successfully",
  "Undo annotation": "Undo annotation",
};
