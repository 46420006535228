import { createSelector } from "reselect";

export const selectPoints = (state) => state.points.points;
export const makeSelectedPoint = (state) => state.points.selectedPoints;
export const makeSelectedRowId = (state) => {
  const value = state.points.selectedPointIds.values();
  const first = value.next();
  return Number(first.value);
};
export const selectSelectedPointIds = (state) => state.points.selectedPointIds;
export const selectFirstSelectedPointId = (state) =>
  [...state.points.selectedPointIds][0];
export const selectClickedFlagIndex = (state) => state.points.clickedFlagIndex;
export const selectLabels = (state) => state.points.labels;
export const selectDisplayingRegions = (state) =>
  state.points.displayingRegions;
export const selectLabelColor = createSelector(selectLabels, (labels) => {
  if (!labels.length) return [];
  return labels.reduce((acc, label) => {
    acc[label.labelTypeCode] = label.color;
    return acc;
  }, {});
});

export const makePointData = createSelector(selectPoints, (points) => {
  if (!points.length) return {};
  return points.reduce((acc, point) => {
    const newPoint = {
      ...point,
      position: `${point.x.toFixed(2)} - ${point.y.toFixed(2)}`,
    };
    if (Array.isArray(acc[point.region])) {
      acc[point.region].push(newPoint);
    } else {
      acc[point.region] = [];
      acc[point.region].push(newPoint);
    }
    return acc;
  }, {});
});

export const selectColourRegionByPoints = createSelector(
  selectPoints,
  (points) => {
    if (!points.length) return {};
    return points.reduce((acc, point) => {
      acc[point.region] = point.colour;

      return acc;
    }, {});
  }
);

export const selectAllRegions = createSelector(makePointData, (points) =>
  Object.keys(points)
);

export const selectLeftRadius = (state) => state.points.irisCenter.leftRadius;
export const selectRightRadius = (state) => state.points.irisCenter.rightRadius;

export const selectLabelsForDropDown = () =>
  createSelector(selectLabels, (allLabels) => {
    return allLabels.map((label) => ({
      label: label.name,
      value: label.id,
      id: label.id,
      color: label.color,
      key: label.id,
      tools: label.tools,
    }));
  });

export const getAllLabelsAttribute = () =>
  createSelector(selectLabels, (allLabels) => {
    return allLabels.map((label) => ({
      id: parseInt(label.id),
      dataAttribute: label.dataAttribute
    }));
  });

export const selectCurrLabel = (state) => state.points.currLabel;

export const makeColorByLabels = createSelector(selectLabels, (allLabels) =>
  allLabels.reduce((colorsByLabels, labels) => {
    colorsByLabels[labels.name] = labels.color;
    return colorsByLabels;
  }, {})
);
