import camelCase from "lodash.camelcase";
import isEmpty from "lodash.isempty";
import serviceConfig from "../../serviceConfig_dev.json";

const defaultDomain =
  window.location.hostname.indexOf(serviceConfig.defaultDomain) > 0
    ? `.${serviceConfig.defaultDomain}`
    : window.location.hostname;
export function getCookie(cname) {
  const name = `${cname}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteAllCookies() {
  document.cookie.split(";").forEach((c) => {
    const name = c.split("=")[0];
    setCookie(name, "", -1);
  });
  console.log(document.cookie);
}
export function setCookie(
  cname,
  cvalue,
  exdays,
  domain = defaultDomain,
  path = "/"
) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  document.cookie = `${cname}=${cvalue}; expires=${d.toUTCString()}; domain=${domain} ;path=${path}`;
}
export function clearLocalStorage() {
  localStorage.clear();
}
/* eslint-disable */
export function toCamelCase(data) {
  const items = { ...data };
  const payload = {};
  for (let i in items) {
    const key = camelCase(i);
    payload[key] = items[i];
  }
  return payload;
}
export function getParameterByName(
  name,
  url = window.location.href,
  isDecode = true
) {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return isDecode ? decodeURIComponent(results[2]) : results[2];
}
export function preventDefault(event) {
  event.preventDefault();
  event.stopPropagation();
}
/**
 * propertiesArray sample as below: annotationKey will replace by annotationId
 * [
    ['annotationKey', 'annotationId'],
   ]
 */
export function replacePropertyName(obj, propertiesArray) {
  if (isEmpty(obj) || propertiesArray == null || !propertiesArray.length)
    return;
  const targetObj = { ...obj };
  propertiesArray.forEach((item) => {
    targetObj[item[1]] = targetObj[item[0]];
    delete targetObj[item[0]];
  });
  return targetObj;
}
/**
 * Add an item to a localStorage() object
 * @param {String} name  The localStorage() key
 * @param {String} key   The localStorage() value object key
 * @param {String} value The localStorage() value object value
 */
export function addToLocalStorageObject(name, key, value) {
  // Get the existing data
  let existing = localStorage.getItem(name);
  // If no existing data, create an array
  // Otherwise, convert the localStorage string to an array
  existing = existing ? JSON.parse(existing) : {};
  // Add new data to localStorage Array
  existing[key] = value;
  // Save back to localStorage
  localStorage.setItem(name, JSON.stringify(existing));
}
/**
 * Add an item to a localStorage() object
 * @param {String} name  The localStorage() key
 * @param {String} key   The localStorage() value object key if exists
 * @return {Object, String, Number, Array}
 */
export function getDataInLocalStorage(name, key) {
  const existing = localStorage.getItem(name);
  if (!existing) return;
  if (!key) return JSON.parse(existing);
  return JSON.parse(existing)[key];
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function measureLength(x1, y1, x2, y2) {
  const xDistance = Math.abs(x1 - x2);
  const yDistance = Math.abs(y1 - y2);
  return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
}

const hexToRGBObj = (hex) => {
  let rgbSring = hex;
  rgbSring = rgbSring.slice(4, rgbSring.length - 1).split(", ");
  if (rgbSring.length !== 3) return [0, 0, 0];
  return rgbSring;
};

export const changeOpacityOfColor = (color, opacity) => {
  return `rgb(${hexToRGBObj(color).join(", ")},${opacity} )`;
};
