import {
  SELECT_TOOL,
  DESELECT_TOOL,
  PICK_START_TIME,
  PICK_END_TIME,
  CHANGE_TEMPORAL_BUTTON,
  SELECT_TOOL_SUCCESS,
  DESELECT_TOOL_SUCCESS,
  LIMITATION_SEGMENTATION_TOOL,
  LIMITATION_SEGMENTATION_TOOL_SUCCESS,
  LIMITATION_SEGMENTATION_TOOL_FAIL,
} from "./constants";

export const selectTool = (payload) => ({
  type: SELECT_TOOL,
  payload,
});

export const selectToolSuccess = (payload) => ({
  type: SELECT_TOOL_SUCCESS,
  payload,
});
export const deselectTool = () => ({
  type: DESELECT_TOOL,
});
export const deselectToolSuccess = () => ({
  type: DESELECT_TOOL_SUCCESS,
});

export const pickStartTime = (payload) => ({
  type: PICK_START_TIME,
  payload,
});

export const pickEndTime = (payload) => ({
  type: PICK_END_TIME,
  payload,
});

export const changeTemporalButton = (payload) => ({
  type: CHANGE_TEMPORAL_BUTTON,
  payload,
});

export const limitationSegmentationTool = (callback) => ({
  type: LIMITATION_SEGMENTATION_TOOL,
  payload: {
    callback,
  },
});

export const limitationSegmentationToolSuccess = (payload) => ({
  type: LIMITATION_SEGMENTATION_TOOL_SUCCESS,
  payload,
});

export const limitationSegmentationToolFail = (payload) => ({
  type: LIMITATION_SEGMENTATION_TOOL_FAIL,
  payload,
});
