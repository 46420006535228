import React, { useState } from "react";
import { func, object } from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { setSelectedPoints } from "redux/points/actions";
import ListContainer from "./ListContainer";
import TitleWrapper from "./TittleWrapper";

import styles from "./style.module.scss";

function PointList(props) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
    props.setSelectedPoints({});
  };

  return (
    <div className={styles.container}>
      <TitleWrapper
        title={props.intl.formatMessage({ id: "point-list.facial-landmarks" })}
        isCollapsed={isCollapsed}
        handleCollapse={handleCollapse}
      />
      {isCollapsed ? null : <ListContainer />}
    </div>
  );
}

PointList.propTypes = {
  intl: object,
  setSelectedPoints: func,
};

export default connect(null, { setSelectedPoints })(injectIntl(PointList));
