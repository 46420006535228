import * as React from "react";

function AnnotationShape(props) {
  return (
    <svg
      height={30}
      viewBox="0 0 24 24"
      width={30}
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.83993"
        y="4.84042"
        width="14.3182"
        height="14.3182"
        rx="1.15909"
        stroke="#2C323B"
        strokeWidth="0.681818"
      />
      <path
        d="M12.1301 15L11.5272 13.21H8.9028L8.28995 15H7L9.53048 8H10.9638L13.4992 15H12.1301ZM10.1779 9.33403L9.19934 12.2204H11.2306L10.2619 9.33403H10.1779Z"
        fill="#ffffff"
      />
      <path d="M16 15H14.7249V8H16V15Z" fill="#ffffff" />
      <rect
        x="6.60059"
        y="2"
        width="0.75"
        height="2.5"
        rx="0.075"
        fill="#2C323B"
      />
      <rect
        x="9.00195"
        y="2"
        width="0.75"
        height="2.5"
        rx="0.075"
        fill="#2C323B"
      />
      <rect
        x="11.7021"
        y="2"
        width="0.75"
        height="2.5"
        rx="0.075"
        fill="#2C323B"
      />
      <rect
        x="14.4014"
        y="2"
        width="0.75"
        height="2.5"
        rx="0.075"
        fill="#2C323B"
      />
      <rect
        x="17.1016"
        y="2"
        width="0.75"
        height="2.5"
        rx="0.075"
        fill="#2C323B"
      />
      <rect
        x="6.60059"
        y="19.4985"
        width="0.75"
        height="2.50146"
        rx="0.075"
        fill="#2C323B"
      />
      <rect
        x="9.00195"
        y="19.4985"
        width="0.75"
        height="2.50146"
        rx="0.075"
        fill="#2C323B"
      />
      <rect
        x="11.7021"
        y="19.4985"
        width="0.75"
        height="2.50146"
        rx="0.075"
        fill="#2C323B"
      />
      <rect
        x="14.4014"
        y="19.4985"
        width="0.75"
        height="2.50146"
        rx="0.075"
        fill="#2C323B"
      />
      <rect
        x="17.1016"
        y="19.4985"
        width="0.75"
        height="2.50146"
        rx="0.075"
        fill="#2C323B"
      />
      <rect
        x="4.50098"
        y="6.60059"
        width="0.75"
        height="2.50098"
        rx="0.075"
        transform="rotate(90 4.50098 6.60059)"
        fill="#2C323B"
      />
      <rect
        x="4.50098"
        y="9.00195"
        width="0.75"
        height="2.50098"
        rx="0.075"
        transform="rotate(90 4.50098 9.00195)"
        fill="#2C323B"
      />
      <rect
        x="4.50098"
        y="11.7017"
        width="0.75"
        height="2.50098"
        rx="0.075"
        transform="rotate(90 4.50098 11.7017)"
        fill="#2C323B"
      />
      <rect
        x="4.50098"
        y="14.4019"
        width="0.75"
        height="2.50098"
        rx="0.075"
        transform="rotate(90 4.50098 14.4019)"
        fill="#2C323B"
      />
      <rect
        x="4.50098"
        y="17.1016"
        width="0.75"
        height="2.50098"
        rx="0.075"
        transform="rotate(90 4.50098 17.1016)"
        fill="#2C323B"
      />
      <rect
        x="22"
        y="6.60059"
        width="0.75"
        height="2.5"
        rx="0.075"
        transform="rotate(90 22 6.60059)"
        fill="#2C323B"
      />
      <rect
        x="22"
        y="9.00195"
        width="0.75"
        height="2.5"
        rx="0.075"
        transform="rotate(90 22 9.00195)"
        fill="#2C323B"
      />
      <rect
        x="22"
        y="11.7017"
        width="0.75"
        height="2.5"
        rx="0.075"
        transform="rotate(90 22 11.7017)"
        fill="#2C323B"
      />
      <rect
        x="22"
        y="14.4019"
        width="0.75"
        height="2.5"
        rx="0.075"
        transform="rotate(90 22 14.4019)"
        fill="#2C323B"
      />
      <rect
        x="22"
        y="17.1016"
        width="0.75"
        height="2.5"
        rx="0.075"
        transform="rotate(90 22 17.1016)"
        fill="#2C323B"
      />
    </svg>
  );
}

export default AnnotationShape;
