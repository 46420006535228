import { baseURL, commonHeader, userLogCommonHeader } from "api/constants";
import { POLYGON_MODE } from "redux/tools/enums";

export function savePolygonAPI({ sourceId, labelId, dataPoint }) {
  return fetch(`${baseURL}/labelPolygon`, {
    method: "POST",
    ...userLogCommonHeader,
    headers: {
      ...userLogCommonHeader.headers,
      type: POLYGON_MODE,
    },
    body: JSON.stringify({
      sourceId,
      labelId,
      dataPoint,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function getPolygonAPI({ sourceId, datasetId, projectId }) {
  return fetch(
    `${baseURL}/labelBox?datasetId=${datasetId}&projectId=${projectId}&sourceId=${sourceId}`,
    {
      method: "GET",
      ...commonHeader,
    }
  )
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function updatePolygonAPI({ id, sourceId, dataPoint, labelId }) {
  return fetch(`${baseURL}/labelPolygon/${id}`, {
    method: "PUT",
    ...commonHeader,
    body: JSON.stringify({
      sourceId,
      dataPoint,
      labelId,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}
