import React from "react";
import style from "./style.module.scss";
import { defaultHotkeys } from "redux/keyboard/defaultHotkeys";
import IntlMessage from "../../helpers/IntlMessage";

export default function BottomHotkeys() {
  let hotKeys = JSON.parse(localStorage.getItem("SALF-hotKeys"));
  if (!hotKeys) {
    hotKeys = defaultHotkeys;
  }

  return (
    <div className={style.bottomHotkeysContainer}>
      <div className={style.cellContainer}>
        <div className={style.cellLeft}>
          <div className={style.textContainer}>{hotKeys.Control.name}</div>
          <span className={style.iconPlus}>+</span>
          <div className={style.textContainer}>{hotKeys.a.name}</div>
        </div>
        <div className={style.cellRight}>
          <IntlMessage id="keyboard-modal.go-to-previous-photo" />
        </div>
      </div>
      <div className={style.cellContainer}>
        <div className={style.cellLeft}>
          <div className={style.textContainer}>{hotKeys.Control.name}</div>
          <span className={style.iconPlus}>+</span>
          <div className={style.textContainer}>{hotKeys.d.name}</div>
        </div>
        <div className={style.cellRight}>
          <IntlMessage id="keyboard-modal.go-to-next-photo" />
        </div>
      </div>
      <div className={style.cellContainer}>
        <div className={style.cellLeft}>
          <div className={style.textContainer}>{hotKeys.Control.name}</div>
          <span className={style.iconPlus}>+</span>
          <div className={style.textContainer}>
            {hotKeys.showIrisCircles.name}
          </div>
          <span className={style.iconPlus}>/</span>
          <div className={style.textContainer}>{hotKeys.v.name}</div>
        </div>
        <div className={style.cellRight}>
          <IntlMessage id="Copy/Paste (Bounding Box only)" />
        </div>
      </div>
      <div className={style.cellContainer}>
        <div className={style.cellLeft}>
          <div className={style.textContainer}>
            <IntlMessage id="keyboard-modal.mouse-wheel" />
          </div>
        </div>
        <div className={style.cellRight}>
          <IntlMessage id="keyboard-modal.zoom-in-out" />
        </div>
      </div>
      <div className={style.cellContainer}>
        <div className={style.cellLeft}>
          <div className={style.textContainer}>{hotKeys.Control.name}</div>
          <span className={style.iconPlus}>+</span>
          <div className={style.textContainer}>{hotKeys.z.name}</div>
          <span className={style.iconPlus}>/</span>
          <div className={style.textContainer}>{hotKeys.y.name}</div>
        </div>
        <div className={style.cellRight}>
          <IntlMessage id="undo-redo-top-bar.undo" />/
          <IntlMessage id="undo-redo-top-bar.redo" />
        </div>
      </div>
      <div className={style.cellContainer}>
        <div className={style.cellLeft}>
          <div className={style.textContainer}>{hotKeys.Escape.name}</div>
        </div>
        <div className={style.cellRight}>
          <IntlMessage id="bottom-hotkeys.cancel-draw-annotation" />
        </div>
      </div>
    </div>
  );
}
