import React from "react";
import { Text, Group, Circle } from "react-konva";
import { bool, number, string, func, object } from "prop-types";

function Point({
  id,
  x,
  y,
  isRectSelecting,
  handleMouseLeave,
  handleDragMove,
  index,
  region,
  isChanged,
  issued,
  generatedColour,
  handleDragEnd,
  selectedPointIds,
  pointRef,
  isIndiceShown,
  isKeyPoint,
  handleMouseOverOuterCircle,
  handleClickOuterCircle,
  handleMouseLeaveOuterCircle,
  handleMouseOver,
  opacity,
}) {
  return (
    <Group
      id={id}
      x={x}
      y={y}
      key={id}
      draggable
      listening={!isRectSelecting}
      onMouseLeave={handleMouseLeave}
      onDragMove={handleDragMove}
      index={index}
      region={region}
      isChanged={isChanged}
      issued={issued}
      colour={generatedColour}
      onDragEnd={handleDragEnd}
      onFocus={() => {}}
      ref={selectedPointIds.has(id) ? pointRef : null}
    >
      {isIndiceShown && (
        <Text
          x={0}
          y={0}
          scaleX={1}
          scaleY={1}
          fontFamily="Arial"
          text={id}
          fontSize={10}
          lineHeight={1.2}
          fill={isKeyPoint(id, region) ? "orange" : "#fff"}
        />
      )}
      <Circle
        x={0}
        y={0}
        scaleX={1}
        scaleY={1}
        radius={7}
        fill="transparent"
        onMouseOver={handleMouseOverOuterCircle}
        onMouseDown={handleClickOuterCircle}
        onMouseLeave={handleMouseLeaveOuterCircle}
      />

      <Circle
        id={id}
        x={0}
        y={0}
        scaleX={selectedPointIds.has(id) ? 1.8 : 1}
        scaleY={selectedPointIds.has(id) ? 1.8 : 1}
        radius={isKeyPoint(id, region) ? 4 : 2.5}
        isChanged={isChanged}
        issued={issued}
        fill={generatedColour}
        colour={generatedColour}
        onMouseOver={handleMouseOver}
        opacity={opacity}
      />
    </Group>
  );
}

Point.propTypes = {
  id: number,
  x: number,
  y: number,
  isRectSelecting: bool,
  handleMouseLeave: func,
  handleDragMove: func,
  index: number,
  region: string,
  isChanged: bool,
  issued: bool,
  generatedColour: string,
  handleDragEnd: func,
  selectedPointIds: object,
  pointRef: object,
  isIndiceShown: bool,
  isKeyPoint: func,
  handleMouseOverOuterCircle: func,
  handleClickOuterCircle: func,
  handleMouseLeaveOuterCircle: func,
  handleMouseOver: func,
  opacity: number,
};

export default Point;
