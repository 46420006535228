import {
  CHANGE_IMAGE_RATIO,
  SET_POINTS_CANVAS,
  SHOW_POINTS_INDICE,
  CHANGE_DRAW_COUNT,
  SHOW_CHECKED_REGIONS,
  HIDE_UNCHECKED_REGIONS,
  TOGGLE_DISPLAY_ALL_REGIONS,
  SHOW_CONNECT_LINES,
  SHOW_IRIS_CIRCLES,
  SHOW_KEY_POINT,
  SET_MODAL_ITEM,
} from "./constants";

export function changeImageRatio(payload) {
  return {
    type: CHANGE_IMAGE_RATIO,
    payload,
  };
}

export function setPointsCanvas(payload) {
  return {
    type: SET_POINTS_CANVAS,
    payload,
  };
}

export function showPointsIndice(payload) {
  return {
    type: SHOW_POINTS_INDICE,
    payload,
  };
}

export function changeDrawCount() {
  return {
    type: CHANGE_DRAW_COUNT,
  };
}

export function showCheckedRegions(payload) {
  return {
    type: SHOW_CHECKED_REGIONS,
    payload,
  };
}

export function hideUncheckedRegions(payload) {
  return {
    type: HIDE_UNCHECKED_REGIONS,
    payload,
  };
}

export function toggleDisplayAllRegions(payload) {
  return {
    type: TOGGLE_DISPLAY_ALL_REGIONS,
    payload,
  };
}

export function showConnectLines(payload) {
  return {
    type: SHOW_CONNECT_LINES,
    payload,
  };
}

export function showIrisCircles(payload) {
  return {
    type: SHOW_IRIS_CIRCLES,
    payload,
  };
}

export function showKeyPoint(payload) {
  return {
    type: SHOW_KEY_POINT,
    payload,
  };
}
export const setModalItem = (payload) => ({
  type: SET_MODAL_ITEM,
  payload,
});
