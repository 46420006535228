import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { Divider, MenuItem, Select } from "@material-ui/core";
import {
  MagicWand,
  PaintBrush,
  // Eraser,
  // Drawing,
  Tick,
  Cancel,
  MagicWandMinus,
  MagicWandPlus,
  PaintBrushMinus,
  PaintBrushPlus,
} from "icons";
import { object, string, func, array, number, bool } from "prop-types";
// import { SEGMENTATION_MODE } from "redux/tools/enums";
// import { makeCurrTool } from "redux/tools/selectors";
import ToolContainer from "components/ToolContainer";
import { segMode, segTool } from "redux/segmentation/enums";
import Slider from "components/Slider";
import {
  getCurrentSegConfig,
  getSegmentations,
  getDrawingSegmentations,
  getSegmentationLineWidth,
  isReadyToDrawSegmentation,
  getIsUnsaveSegmentationSelector,
  getSegmentationEnginesName,
} from "redux/segmentation/selector";
import { engines } from "redux/segmentation/enums";

import {
  setCurrentSegmentationMode,
  setCurrentSegmentationTool,
  saveSegmentation,
  updateSegmentation,
  resetSegmentationMode,
  setSegmentationLineWidth,
  setEngineName,
} from "redux/segmentation/actions";
import style from "./style.module.scss";

function SegmentationTopBar({
  intl,
  // currTool,
  drawConfig,
  setCurrentSegmentationTool,
  setCurrentSegmentationMode,
  drawingSegmentation,
  resetSegmentationMode,
  saveSegmentation,
  updateSegmentation,
  segmentationLineWidth,
  setSegmentationLineWidth,
  isReadyToDrawSegmentation,
  isUnsaveSegmentation,
}) {
  const dispatch = useDispatch();

  const engineName = useSelector(getSegmentationEnginesName);

  const handlePickMode = (event) => {
    const disabledClass = event.target.getAttribute("data-disabled");
    if (disabledClass === `true`) {
      return;
    }
    const pickMode = event.target.getAttribute("name");
    setCurrentSegmentationMode(pickMode);
  };

  const handlePickTool = (event) => {
    const tempTool = event.target.getAttribute("name");
    setCurrentSegmentationTool(tempTool);
  };

  const { tool, mode } = drawConfig;

  const handleDoneSegmentation = () => {
    if (!isUnsaveSegmentation) {
      resetSegmentationMode();
      return;
    }
    if (drawingSegmentation && drawingSegmentation.id) {
      updateSegmentation({
        payload: drawingSegmentation,
        id: drawingSegmentation.id,
        x: drawingSegmentation.x,
        y: drawingSegmentation.y,
        width: drawingSegmentation.width,
        height: drawingSegmentation.height,
      });
    } else {
      saveSegmentation({ payload: drawingSegmentation });
    }
  };

  const handleCancelSegmentation = () => {
    resetSegmentationMode();
  };

  const handleChangeSlider = (event, newValue) => {
    setSegmentationLineWidth(newValue);
  };

  const handleOnChange = (event) => {
    dispatch(setEngineName(event.target.value));
  };

  return isReadyToDrawSegmentation ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className={style.segmentationMode}>
        <ToolContainer
          name={segMode.SMART_MODE}
          currTool={mode}
          tooltipText={intl.formatMessage({
            id: "segmentation-top-bar.draw-by-smart-tool",
          })}
          onClick={handlePickMode}
          // disabledClass
        >
          <MagicWand style={{ pointerEvents: "none" }} width={24} height={24} />
        </ToolContainer>
      </div>
      <div className={style.segmentationMode}>
        <ToolContainer
          name={segMode.MANNUAL_MODE}
          currTool={mode}
          tooltipText={intl.formatMessage({
            id: "segmentation-top-bar.draw-by-smart-tool",
          })}
          onClick={handlePickMode}
        >
          <PaintBrush
            style={{ pointerEvents: "none" }}
            width={24}
            height={24}
          />
        </ToolContainer>
      </div>
      <Divider
        orientation="vertical"
        flexItem
        classes={{
          root: style.dividerRoot,
        }}
      />

      <div className={style.segmentationMode}>
        <ToolContainer
          name={segTool.PEN_TOOL}
          currTool={tool}
          tooltipText={intl.formatMessage({ id: "segmentation-top-bar.draw" })}
          disabledClass={!mode}
          onClick={handlePickTool}
        >
          {mode === segMode.MANNUAL_MODE ? (
            <PaintBrushPlus
              style={{ pointerEvents: "none" }}
              width={24}
              height={24}
            />
          ) : (
            <MagicWandPlus
              style={{ pointerEvents: "none" }}
              width={24}
              height={24}
            />
          )}
        </ToolContainer>
      </div>
      <div className={style.segmentationMode}>
        <ToolContainer
          name={segTool.ERASER_TOOL}
          disabledClass={!mode}
          currTool={tool}
          tooltipText={intl.formatMessage({
            id: "segmentation-top-bar.eraser",
          })}
          onClick={handlePickTool}
        >
          {mode === segMode.MANNUAL_MODE ? (
            <PaintBrushMinus
              style={{ pointerEvents: "none" }}
              width={24}
              height={24}
            />
          ) : (
            <MagicWandMinus
              style={{ pointerEvents: "none" }}
              width={24}
              height={24}
            />
          )}
        </ToolContainer>
      </div>
      <Divider
        orientation="vertical"
        flexItem
        classes={{
          root: style.dividerRoot,
        }}
      />
      {mode === segMode.MANNUAL_MODE && (
        <Slider
          value={segmentationLineWidth}
          onChange={handleChangeSlider}
          min={5}
        />
      )}
      {mode === segMode.SMART_MODE && (
        <Select
          value={engineName}
          onChange={handleOnChange}
          autoWidth
          classes={{ root: style.engineNameSelectRoot }}
        >
          {engines.map((engine) => (
            <MenuItem value={engine} key={engine}>
              {engine}
            </MenuItem>
          ))}
        </Select>
      )}

      <Divider
        orientation="vertical"
        flexItem
        classes={{
          root: style.dividerRoot,
        }}
      />
      <div className={style.segmentationMode}>
        <ToolContainer
          name="done"
          tooltipText={intl.formatMessage({ id: "common.done" })}
          onClick={handleDoneSegmentation}
        >
          <img src={Tick} className={style.iconSvg} alt="done-tool" />
        </ToolContainer>
      </div>
      <div className={style.segmentationMode}>
        <ToolContainer
          name="cancel"
          tooltipText={intl.formatMessage({ id: "common.cancel" })}
          onClick={handleCancelSegmentation}
        >
          <img src={Cancel} className={style.iconSvg} alt="cancel-tool" />
        </ToolContainer>
      </div>
    </div>
  ) : null;
}

SegmentationTopBar.propTypes = {
  intl: object,
  currTool: string,
  drawConfig: object,
  setCurrentSegmentationMode: func,
  setCurrentSegmentationTool: func,
  segmentations: array,
  saveSegmentation: func,
  drawingSegmentation: string,
  updateSegmentation: func,
  resetSegmentationMode: func,
  segmentationLineWidth: number,
  setSegmentationLineWidth: func,
  isReadyToDrawSegmentation: number,
  isUnsaveSegmentation: bool,
};

const mapStateToProps = (state) => ({
  // currTool: makeCurrTool(state),
  drawConfig: getCurrentSegConfig(state),
  segmentations: getSegmentations(state),
  drawingSegmentation: getDrawingSegmentations(state),
  segmentationLineWidth: getSegmentationLineWidth(state),
  isReadyToDrawSegmentation: isReadyToDrawSegmentation(state),
  isUnsaveSegmentation: getIsUnsaveSegmentationSelector(state),
});

export default connect(mapStateToProps, {
  setCurrentSegmentationMode,
  setCurrentSegmentationTool,
  saveSegmentation,
  updateSegmentation,
  resetSegmentationMode,
  setSegmentationLineWidth,
})(injectIntl(SegmentationTopBar));
