import React from "react";
import { func, string } from "prop-types";
import { MenuItem, Select } from "@material-ui/core";
import { getParameterByName } from "helpers";

import useStyles from "./style";

const supportedLanguages = {
  en: {
    key: "en",
    value: "en",
    label: "English",
  },
  ja: {
    key: "ja",
    value: "ja",
    label: "日本語",
  },
};

function LanguageSelector({ locale, onSelectLocale }) {
  const classes = useStyles();
  const defaultLocale = getParameterByName("locale");
  return (
    <Select
      value={locale}
      onChange={onSelectLocale}
      label={supportedLanguages[locale].label}
      disableUnderline={true}
      classes={{
        root: classes.root,
      }}
      inputProps={{
        classes: {
          icon: classes.icon,
        },
      }}
      disabled={!!defaultLocale}
    >
      {Object.values(supportedLanguages).map((language) => (
        <MenuItem key={language.key} value={language.value}>
          {language.label}
        </MenuItem>
      ))}
    </Select>
  );
}

LanguageSelector.propTypes = {
  locale: string,
  onSelectLocale: func,
};

export default LanguageSelector;
