import { all, fork, takeEvery, call, put, select } from "redux-saga/effects";
import { saveCuboid, updateCuboid } from "api/cuboid";
import { saveCuboidError, updateCuboidError } from "redux/cuboid/actions";
import { addShapesSuccess, updateShapesSuccess } from "redux/shape/actions";
import { SAVE_CUBOID, UPDATE_CUBOID } from "./constants";
import { selectImageId } from "redux/image/selectors";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import { selectLabels } from "redux/points/selectors";
// import { endCounting } from "redux/timing/actions";

function* watchSaveCuboid() {
  yield takeEvery(SAVE_CUBOID, function* ({ payload, callback }) {
    const { dataPoint, labelId, isHideNotification } = payload;

    const imageId = yield select(selectImageId);
    const response = yield call(saveCuboid, {
      sourceId: imageId,
      dataPoint,
      labelId,
    });

    if (response.success) {
      const labelList = yield select(selectLabels);
      const { data } = response;
      const labelInfo = labelList.find(
        (label) => Number(label.id) === Number(payload.labelId)
      );
      yield put(
        addShapesSuccess({
          dataPoint: data.dataPoint,
          visible: true,
          color: labelInfo && labelInfo.color,
          labelName: labelInfo && labelInfo.name,
          classesTypeCode: "cuboid-image",
          labelId: data.labelId,
          id: data.labelPointId,
          dataAttribute: data.dataAttribute,
        })
      );
      if (!isHideNotification)
        yield put(
          showNotification({
            type: "success",
            msg: "Cuboid saved successfully.",
            msgId: "notification.cuboid-saved-success",
          })
        );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(saveCuboidError({msg: "Save cuboid error!", msgId: "notification.cuboid-saved-error"}));
    }
    // yield put(endCounting());
    if (callback) callback();
  });
}

function* watchUpdateCuboid() {
  yield takeEvery(UPDATE_CUBOID, function* ({ payload }) {
    const { dataPoint, labelId, id, isHideNotification } = payload;
    const imageId = yield select(selectImageId);

    const response = yield call(updateCuboid, {
      id,
      sourceId: imageId,
      labelId,
      dataPoint: dataPoint.map(({ x, y, width, height, uid }) => ({
        x,
        y,
        width,
        height,
        uid,
      })),
    });
    if (response.success) {
      // const { data } = response
      yield put(updateShapesSuccess(payload));
      if (!isHideNotification)
        yield put(
          showNotification({ 
            type: "success",
            msg: "Update cuboid success.",
            msgId: "notification.update-cuboid-success",
          })
        );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(updateCuboidError({msg: "Update cuboid error!", msgId: "notification.update-cuboid-error"}));
    }
  });
}

export default function* boxSaga() {
  yield all([fork(watchSaveCuboid), fork(watchUpdateCuboid)]);
}
