import {
  SAVE_CUBOID,
  SAVE_CUBOID_SUCCESS,
  SAVE_CUBOID_ERROR,
  UPDATE_CUBOID,
  UPDATE_CUBOID_SUCCESS,
  UPDATE_CUBOID_ERROR,
} from "./constants";

export const saveCuboid = ({ payload, callback }) => ({
  type: SAVE_CUBOID,
  payload,
  callback,
});

export const saveCuboidSuccess = (payload) => ({
  type: SAVE_CUBOID_SUCCESS,
  payload,
});

export const saveCuboidError = (payload) => ({
  type: SAVE_CUBOID_ERROR,
  payload,
});

export const updateCuboid = (payload) => ({
  type: UPDATE_CUBOID,
  payload,
});

export const updateCuboidSuccess = (payload) => ({
  type: UPDATE_CUBOID_SUCCESS,
  payload,
});

export const updateCuboidError = (payload) => ({
  type: UPDATE_CUBOID_ERROR,
  payload,
});
