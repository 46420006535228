import React from "react";
import { string, func, arrayOf, object, bool } from "prop-types";
import style from "./style.module.scss";
import DropDown from "./DropDown";

function ImageScrollContent({
  allStatus,
  changeCurrentDropdownStatus,
  selectedStatus,
  handleScroll,
  renderAllImages,
  // imageId,
  // isHandleScroll,
}) {
  // comment code scroll to image when selected image in admin
  // const imageRef = useRef(null);
  // const scrollToImage = () => imageRef.current.scrollIntoView();

  // useEffect(() => {
  //   if (!isHandleScroll) {
  //     const imageElement = document.getElementById(`imageContainer_${imageId}`);
  //     if (imageElement) {
  //       imageRef.current = imageElement;
  //       scrollToImage();
  //     }
  //   }
  // }, [renderAllImages]);

  return (
    <>
      <DropDown
        status={allStatus}
        setSelectedStatus={changeCurrentDropdownStatus}
        selectedStatus={selectedStatus}
      />
      <div className={style.imagesArea} onScroll={handleScroll}>
        {renderAllImages()}
      </div>
    </>
  );
}

ImageScrollContent.propTypes = {
  allStatus: arrayOf(object),
  changeCurrentDropdownStatus: func,
  selectedStatus: string,
  handleScroll: func,
  renderAllImages: func,
  imageId: string,
  isHandleScroll: bool,
};

export default ImageScrollContent;
