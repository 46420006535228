import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { any, arrayOf, bool } from "prop-types";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  Typography,
  // Button,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import styles from "./style.module.scss";
import IntlMessage from "../../helpers/IntlMessage";
import {
  selectImageTags,
  selectIsFetchingData,
  selectImageId,
} from "redux/image/selectors";
import DrawerModal from "components/DrawerModal";
import { ColorPattern } from "./ColorPattern";
import { CreateTagAutoComplete } from "../../components/CreateTagAutoComplete";
import { selectProjectId, selectRoleCode } from "redux/app/selectors";
import {
  createTagAndAddTagToSource,
  deleteTagOfSource,
  selectOneSource,
  addTagToSource,
} from "redux/image/actions";

function Tag({ imageTags, isFetchingData }) {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openEditTagPanel, setOpenEditTagPanel] = useState(false);
  const [color, setColor] = useState("");
  const projectId = useSelector(selectProjectId);
  const imageId = useSelector(selectImageId);
  const roleCode = useSelector(selectRoleCode);

  const isAllowedToEdit =
    roleCode === "admin" || roleCode === "project_manager";

  const handleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const changeAlphaOfColor = (color, alpha = 1) => {
    if (!color?.trim()) return "";
    if (color.includes("#")) {
      color = color.replace("#", "");
      const r = parseInt(
        color.length === 3 ? color.slice(0, 1).repeat(2) : color.slice(0, 2),
        16
      );
      const g = parseInt(
        color.length === 3 ? color.slice(1, 2).repeat(2) : color.slice(2, 4),
        16
      );
      const b = parseInt(
        color.length === 3 ? color.slice(2, 3).repeat(2) : color.slice(4, 6),
        16
      );
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    }
    if (color.includes("rgba(")) {
      return color?.replace(/[^,]+(?=\))/, alpha.toString());
    }
    if (color.includes("rgb(")) {
      return color
        ?.replace(/rgb/i, "rgba")
        ?.replace(/\)/i, `,${alpha.toString()}`);
    }
    return "";
  };

  const handleOpenEditTagPanel = (event) => {
    event.stopPropagation();
    setOpenEditTagPanel(true);
  };

  const handleCloseEditTagPanel = () => {
    setOpenEditTagPanel(false);
  };

  const handleRemoveTagFromSource = (tags) => {
    dispatch(
      deleteTagOfSource({
        payload: {
          projectId,
          sourceIds: [imageId],
          tags: tags.map((item) => item.tagId),
        },
        callback: generalCallback,
      })
    );
  };

  const handleAddTagToSource = (tag) => () => {
    dispatch(
      addTagToSource({
        payload: {
          projectId,
          sourceIds: [imageId],
          tags: [tag.tag_id],
        },
        callback: generalCallback,
      })
    );
  };

  const generalCallback = () => {
    dispatch(selectOneSource({ id: imageId }));
  };

  const handleChangeColor = (changeColor) => {
    setColor(changeColor);
  };

  const handleCreateSourceTag = (tag) => {
    dispatch(
      createTagAndAddTagToSource({
        payload: {
          projectId,
          newtag: [{ value: tag.name, color }],
          sourceIds: [imageId],
        },
        callback: generalCallback,
      })
    );
  };

  const handleChangeTagAutoComplete = (value) => {
    if (value.tag_id === -1) {
      handleCreateSourceTag(value);
    } else {
      handleAddTagToSource(value)();
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleDeleteAllTag = () => {
    handleRemoveTagFromSource(imageTags);
  };

  return (
    <div className={styles.tagContainer}>
      <div
        className={styles.tagHeader}
        role="button"
        tabIndex="0"
        onKeyPress={() => {}}
        onClick={handleCollapse}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <button type="button">
            {isCollapsed ? <ChevronRight /> : <ExpandMore />}
          </button>
          <span>
            <IntlMessage id="common.tag" />
          </span>
        </div>
        {isAllowedToEdit && (
          <IconButton
            size="small"
            style={{ marginRight: "15px" }}
            onClick={handleOpenEditTagPanel}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      {imageTags && imageTags.length === 0 ? (
        <span className={styles.noData}>
          <IntlMessage id="common.no-data-to-show" />
        </span>
      ) : !isCollapsed ? (
        <div className={styles.tagBody}>
          {(imageTags || []).map((item) => {
            return (
              <span
                key={item.tagId}
                className={styles["tag-wrapper"]}
                style={{
                  backgroundColor: changeAlphaOfColor(item.color, 0.2),
                  color: item.color,
                }}
              >
                {item.tagName}
              </span>
            );
          })}
        </div>
      ) : null}
      {isAllowedToEdit && (
        <DrawerModal
          title={<IntlMessage id="common.smart-tag" />}
          open={openEditTagPanel}
          toggle={handleCloseEditTagPanel}
        >
          {isFetchingData ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress fontSize={30} />
            </div>
          ) : (
            <React.Fragment>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="h2"
                    style={{ fontWeight: 600, textTransform: "uppercase" }}
                  >
                    <IntlMessage id="common.selected-tags-title" />
                  </Typography>
                  <br />
                  <Typography variant="h2" style={{ marginLeft: "5px" }}>
                    ({imageTags ? imageTags.length : 0})
                  </Typography>
                </div>
                {/* <Button
                  onClick={handleDeleteAllTag}
                  variant="text"
                  style={{ color: "#F63535" }}
                >
                  <IntlMessage id="shape-list.delete-all" />
                </Button> */}
              </div>
              <Typography
                variant="h4"
                style={{ fontWeight: 400, color: "#555f7c" }}
              >
                <IntlMessage id="common.selected-tags-subtitle" />
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginTop: "15px",
                  marginBottom: "30px",
                }}
              >
                {imageTags
                  ? (imageTags || []).map((item) => {
                      return (
                        <div
                          key={item.tagId}
                          className={styles["tag-wrapper"]}
                          style={{
                            display: "flex",
                            backgroundColor: changeAlphaOfColor(
                              item.color,
                              0.2
                            ),
                            flexWrap: "wrap",
                          }}
                        >
                          <span style={{ color: item.color }}>
                            {item.tagName}
                          </span>
                          <CancelRoundedIcon
                            className={styles["delete-one-tag-btn"]}
                            fontSize="small"
                            style={{ fontSize: "15px" }}
                            onClick={() => {
                              handleRemoveTagFromSource([item]);
                            }}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
              <Divider style={{ marginTop: "25px", marginBottom: "25px" }} />
              <div>
                <Typography
                  variant="h2"
                  style={{ fontWeight: 600, textTransform: "uppercase" }}
                >
                  <IntlMessage id="common.create-smart-tag-title" />
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 400,
                    color: "#555f7c",
                    marginTop: "10px",
                  }}
                >
                  <IntlMessage id="common.create-smart-tag-subtitle" />
                </Typography>
              </div>
              <div style={{ marginTop: "25px", marginBottom: "15px" }}>
                <Typography variant="h2" style={{ fontWeight: 600 }}>
                  <IntlMessage id="common.color-pattern-title" />
                </Typography>
              </div>
              <ColorPattern onChange={handleChangeColor} />
              <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                <Typography variant="h2" style={{ fontWeight: 600 }}>
                  <IntlMessage id="common.tag-name-title" />
                </Typography>
              </div>
              <CreateTagAutoComplete
                onChange={handleChangeTagAutoComplete}
                allowAdd={true}
                value={null}
                placeholder={<IntlMessage id="common.search-or-create-tag" />}
              />
            </React.Fragment>
          )}
        </DrawerModal>
      )}
    </div>
  );
}

Tag.propTypes = {
  imageTags: arrayOf(any),
  isFetchingData: bool,
};

const mapStateToProps = (state) => ({
  imageTags: selectImageTags(state),
  isFetchingData: selectIsFetchingData(state),
});

export default connect(mapStateToProps)(Tag);
