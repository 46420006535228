/* eslint-disable no-case-declarations */
import produce from "immer";
import {
  ADD_ANNOTATION_HISTORY,
  SET_CURRENT_TARGET,
  SET_ANNOTATION_HISTORY,
  SET_TOOGLE_UNDO_REDO_FLAG,
} from "./constants";
import cloneDeep from "lodash.clonedeep";
import { SET_DRAWING_POLYGON } from "redux/shape/constants";
import { SET_DRAWING_SEGMENTATION } from "redux/segmentation/constants";

export const initialState = {
  history: [],
  currentTarget: null,
  toggleUndoRedo: 0,
};

const annotationHistoryReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ANNOTATION_HISTORY:
        draft.history = action.payload;
        break;
      case ADD_ANNOTATION_HISTORY:
        const stateHistory = cloneDeep(state.history);
        const currentIndex = stateHistory.findIndex(
          (item) => item.id === state.currentTarget
        );

        // Check if the history array exceeds the limit (30 items)
        if (stateHistory.length >= 30) {
          stateHistory.shift(); // Remove the oldest history
        }

        if (currentIndex !== stateHistory.length - 1) {
          stateHistory.splice(currentIndex + 1);
        }

        draft.history = [...stateHistory, action.payload];
        draft.currentTarget = action.payload.id;
        break;
      case SET_CURRENT_TARGET:
        draft.currentTarget = action.payload;

        break;
      case SET_DRAWING_POLYGON:
        if (!action.payload?.points?.length > 0) {
          const tempHistory = cloneDeep(state.history).filter(
            (item) => item.type !== SET_DRAWING_POLYGON
          );
          draft.history = tempHistory;
          draft.currentTarget = tempHistory[tempHistory.length - 1]?.id;
        }
        break;
      case SET_DRAWING_SEGMENTATION:
        if (
          !action.payload &&
          state.history.find((item) => item.type === SET_DRAWING_SEGMENTATION)
        ) {
          const tempHistory = cloneDeep(state.history).filter(
            (item) => item.type !== SET_DRAWING_SEGMENTATION
          );
          draft.history = tempHistory;
          draft.currentTarget = tempHistory[tempHistory.length - 1]?.id;
          draft.toggleUndoRedo = 0;
        }
        break;

      case SET_TOOGLE_UNDO_REDO_FLAG:
        draft.toggleUndoRedo = state.toggleUndoRedo + 1;
        break;

      default:
        break;
    }
  });

export default annotationHistoryReducer;
