import produce from "immer";
import {
  SHOW_NOTIFICATION,
  REMOVE_NOTIFICATION,
  FETCH_PUSH_NOTIFICATIONS,
  FETCH_PUSH_NOTIFICATIONS_SUCCESS,
  NO_MORE_NOTIFICATION,
  UPDATE_CURRENT_NOTIFICATIONS_PAGE,
  CHANGE_VIEW_NOTIFICATION,
  MARK_ALL_NOTIFICATION_AS_READ_SUCCESS,
} from "./actions";

export const initialState = {
  currNotiId: "",
  notifications: [],
  pushNotifications: [],
  totalNewNotifications: 0,
  currPage: 0,
  limit: 10,
  fetchMore: false,
};

// Reducer
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SHOW_NOTIFICATION: {
        const id = Date.now();

        // Check if there are existing notifications and remove the previous one
        if (state.notifications.length > 0) {
          draft.notifications = state.notifications.slice(1);
        }

        draft.notifications = [
          { ...action.payload, id },
          ...draft.notifications, // Add the new notification at the beginning
        ];
        draft.currNotiId = id;
        break;
      }

      case REMOVE_NOTIFICATION:
        draft.notifications = state.notifications.filter(
          (item) => item.id !== action.payload
        );
        break;

      case FETCH_PUSH_NOTIFICATIONS_SUCCESS:
        draft.pushNotifications = [
          ...state.pushNotifications,
          ...action.payload.notifications,
        ];
        draft.totalNewNotifications = action.payload.totalNewNotifications;
        break;

      case FETCH_PUSH_NOTIFICATIONS:
        draft.fetchMore = true;
        break;

      case NO_MORE_NOTIFICATION:
        draft.fetchMore = false;
        break;

      case UPDATE_CURRENT_NOTIFICATIONS_PAGE:
        draft.currPage = action.payload;
        break;

      case CHANGE_VIEW_NOTIFICATION: {
        const newNotifications = state.pushNotifications.map((noti) => {
          if (noti.notificationId === action.payload) {
            return {
              ...noti,
              isRead: true,
            };
          } else {
            return noti;
          }
        });
        draft.pushNotifications = newNotifications;
        draft.totalNewNotifications -= 1;
        break;
      }
      case MARK_ALL_NOTIFICATION_AS_READ_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        const newNotifications = state.pushNotifications.map((noti) => ({
          ...noti,
          isRead: true,
        }));
        draft.pushNotifications = newNotifications;
        draft.totalNewNotifications = 0;
        break;

      default:
        break;
    }
  });

export default reducer;
