/* eslint-disable react/display-name */
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import React from "react";
import { object } from "prop-types";

import "./styles.scss";
import IntlMessage from "../../helpers/IntlMessage";

const TooltipTour = ({
  step,
  tooltipProps,
  primaryProps,
  // backProps,
  skipProps,
}) => {
  return (
    <Card {...tooltipProps} className="tourGuideTooltip">
      <CardContent>
        <Typography gutterBottom variant="h5" component="h5">
          <IntlMessage id={step.titleId} />
        </Typography>
        <Typography variant="body2">
          <IntlMessage id={step.contentId} />
        </Typography>
      </CardContent>
      {/* {index > 0 && (
          <Button color="secondary" variant="text" {...backProps}>
            Back
          </Button>
        )} */}
      <div className="tourGuideFooter">
        <Button variant="contained" {...skipProps}>
          Skip
        </Button>
        <Button color="primary" variant="contained" {...primaryProps}>
          {step.target === '[data-tour="box-list"]' ? "Finish" : "Next"}
        </Button>
      </div>
    </Card>
  );
};

TooltipTour.propTypes = {
  step: object,
  tooltipProps: object,
  primaryProps: object,
  backProps: object,
  skipProps: object,
};

export default TooltipTour;
