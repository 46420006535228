/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Modal } from "@material-ui/core";
import { func, bool } from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import IntlMessage from "../../helpers/IntlMessage";
import useStyles from "./style";

function LimitationModal({ open, onClose }) {
  const classes = useStyles();
  const body = (
    <div className={classes.paper}>
      <p className={classes.header}>
        <IntlMessage id="send-contact-email-success-modal.upgrade-request-sent" />
        <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
      </p>
      <div className={classes.content}>
        <p className={classes.thanks}>
          <IntlMessage id="send-contact-email-success-modal.thank-you" />
        </p>
        <img
          src="https://blueeye-seta.s3.amazonaws.com/blueeye-admin-assets/pricing-thanks-form.svg"
          alt="thanks"
        />
        <p>
          <IntlMessage id="send-contact-email-success-modal.we-will-be-in-touch" />
        </p>
      </div>
    </div>
  );
  return (
    <div>
      <Modal open={open} onClose={onClose} className={classes.modal}>
        {body}
      </Modal>
    </div>
  );
}

LimitationModal.propTypes = {
  open: bool,
  onClose: func,
};

export default LimitationModal;
