import * as React from "react";

function PaintBrushMinus(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.8913 1C21.9698 1.8552 20.7694 2.34708 19.5128 2.38452H19.2689H19.2167C18.1544 2.38452 17.5884 4.30457 17.4186 5.22759L18.6376 6.44667H18.9032C19.9362 6.46599 20.931 6.05607 21.6505 5.31466C22.5213 4.38729 22.9305 2.93746 22.8913 1Z"
        fill="currentColor"
      />
      <path
        d="M14.6653 7.8007C12.963 9.27231 2.31345 18.607 1.10744 23C5.46129 21.7766 14.8177 11.1314 16.2893 9.42469L14.6653 7.8007Z"
        fill="currentColor"
      />
      <path
        d="M16.7889 5.51888L14.7339 6.94694L17.1416 9.35461L18.5696 7.2996L16.7889 5.51888Z"
        fill="currentColor"
      />
      <rect
        x="24"
        y="18.286"
        width="1.42857"
        height="10"
        rx="0.714286"
        transform="rotate(90 24 18.286)"
        fill="currentColor"
      />
    </svg>
  );
}

export default PaintBrushMinus;
