import { bool, func, number, object } from "prop-types";
import React, { useState, useEffect } from "react";
import { Line } from "react-konva";
import Rectangle from "components/Rectangle/RectangleOfCuboid";
import { changeOpacityOfColor } from "helpers";
import { getLinessOfCuboid } from "helpers/cuboid";

// Cuboid contains two of rectangle with uid (0, 1).
// The rectangle that have uid 0 is the front-side of cuboid object.
// We always put in in the top of layer.
// But when we choose and edit back-side of cuboid,
// the rectangle that have uid 1 must be in the top of layer
function Cuboid({ properties, ratio, onSelect, isSelected, onChange }) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [rectangles, setRectangles] = useState([]);
  const [cuboidPoints, setCuboidPoints] = useState([]);
  useEffect(() => {
    if (isSelected) {
      setSelectedIndex(0);
    }
  }, [isSelected]);

  useEffect(() => {
    if (rectangles && rectangles.length > 1)
      setCuboidPoints(getLinessOfCuboid(rectangles));
  }, [rectangles]);

  useEffect(() => {
    if (properties.dataPoint) {
      const tempDataPoint = [...properties.dataPoint];
      tempDataPoint.reverse();
      setRectangles(tempDataPoint);
    }
  }, [properties]);

  useEffect(() => {
    if (rectangles && rectangles.length > 1) {
      // change order of rectangle to move
      // the selected rectangle or the front-side rectangle to top
      if (typeof selectedIndex !== "number") {
        setRectangles([...rectangles.sort((a, b) => b.uid - a.uid)]);
      } else {
        setRectangles([
          rectangles.find((item) => item.uid !== selectedIndex),
          rectangles.find((item) => item.uid === selectedIndex),
        ]);
      }
    }
  }, [selectedIndex]);

  const handleSelect = (index) => () => {
    setSelectedIndex(index);
    onSelect(properties);
  };

  const handleChangeCuboid = (uid) => (newAttrs) => {
    const tempdataPoint = [...rectangles];
    const rectanglelIndex = tempdataPoint.findIndex((x) => x.uid === uid);
    tempdataPoint.splice(rectanglelIndex, 1, newAttrs);
    handleUpdateCuboid(tempdataPoint);
  };

  const handleUpdateCuboid = (data) => {
    const dataPoint = [...data];
    const payload = {
      ...properties,
      dataPoint,
    };
    if (onChange) {
      setRectangles(dataPoint);
      onChange(payload);
    }
    setSelectedIndex(null);
  };

  const handleChangeLines = (uid) => (node) => {
    const tempRectangle = [...rectangles];
    const rectanglelIndex = tempRectangle.findIndex((x) => x.uid === uid);
    tempRectangle.splice(rectanglelIndex, 1, node);
    setCuboidPoints(getLinessOfCuboid(tempRectangle));
  };

  const cuboidLayer =
    rectangles && rectangles.length > 1
      ? cuboidPoints.map((item, index) => (
          <Line
            key={`${properties.id}_line_${index}`}
            strokeWidth={3}
            stroke={properties.color}
            points={item.map((point) => point * ratio)}
          />
        ))
      : [];

  rectangles.map((item, index) => {
    const retangleIndex = (
      <Rectangle
        key={`${properties.id}_${index}`}
        rectangleProps={{
          ...item,
          labelId: properties.labelId,
          label: properties.labelName,
          stroke: properties.color,
          fill: item?.uid === 0 && changeOpacityOfColor(properties.color, 0.5),
          strokeWidth: 3,
          visible: true,
          id: properties.id,
        }}
        ratio={ratio}
        opacity={1}
        onSelect={handleSelect(item.uid)}
        isSelected={isSelected && selectedIndex === item.uid}
        onChange={handleChangeCuboid(item.uid)}
        onTransform={handleChangeLines(item.uid)}
        onDragMove={handleChangeLines(item.uid)}
      />
    );
    cuboidLayer.push(retangleIndex);
  });
  return cuboidLayer;
}

Cuboid.propTypes = {
  properties: object,
  ratio: number,
  onSelect: func,
  isSelected: bool,
  onChange: func,
};

export default Cuboid;
