import * as React from "react";

function MagicWandPlus(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.4638 4.11415L0.046875 17.5311L2.51568 19.9999L15.9326 6.58295L13.4638 4.11415ZM11.3591 7.8823L13.4638 5.77752L14.2693 6.58295L12.1645 8.68773L11.3591 7.8823Z"
        fill="currentColor"
      />
      <path
        d="M17.5289 1.6078L16.058 3.07877L16.8896 3.91045L18.3606 2.43948L17.5289 1.6078Z"
        fill="currentColor"
      />
      <path
        d="M16.6985 9.87666L15.8668 10.7083L17.3655 12.207L18.1972 11.3754L16.6985 9.87666Z"
        fill="currentColor"
      />
      <path
        d="M8.59455 1.77194L7.76288 2.60361L9.25227 4.09301L10.0839 3.26133L8.59455 1.77194Z"
        fill="currentColor"
      />
      <path
        d="M19.9598 6.40121H17.8392V7.57743H19.9598H19.97V6.40121H19.9598Z"
        fill="currentColor"
      />
      <path
        d="M12.3906 0.000703129V2.12138H13.5668V0.000703129V0L12.3906 0.000703129Z"
        fill="currentColor"
      />
      <rect
        x="24"
        y="18.2859"
        width="1.42857"
        height="10"
        rx="0.714286"
        transform="rotate(90 24 18.2859)"
        fill="currentColor"
      />
      <rect
        x="19.7129"
        y="24"
        width="1.42857"
        height="10"
        rx="0.714286"
        transform="rotate(-180 19.7129 24)"
        fill="currentColor"
      />
    </svg>
  );
}

export default MagicWandPlus;
