import {
  BOOTING_FINISHED,
  CLEAN_ANNOTATIONS_DATA,
  SET_USER_DATA,
  SET_SOURCE_INFO,
  LOAD_REPO,
  LOAD_REPO_ERROR,
  GET_MODULE_CODE,
  GET_MODULE_CODE_ERROR,
  UPDATE_ROLE_CODE,
  GET_MODULE_CODE_SUCCESS,
  FETCH_FIREBASE_TOKEN,
  FETCH_FIREBASE_TOKEN_SUCCESS,
  SET_CURRENT_DROPDOWN_STATUS,
  FETCH_INITIAL_DATA,
  FETCH_INITIAL_DATA_SUCCESS,
  CHANGE_CURRENT_DROPDOWN_STATUS,
  FETCH_USER_IN_PROJECT,
  SET_LABELER_ID,
  SET_DIALOG_OPEN,
  SEND_EMAIL_TO_SYSTEM_ADMIN,
  CHANGE_LANGUAGE,
  FETCH_USER_IN_PROJECT_SUCCESS,
} from "./constants";

export function bootingFinished(payload) {
  return {
    type: BOOTING_FINISHED,
    payload,
  };
}

export function cleanAnnotationsData() {
  return {
    type: CLEAN_ANNOTATIONS_DATA,
  };
}

export function setUserData(payload) {
  return {
    type: SET_USER_DATA,
    payload,
  };
}

export function loadRepo(payload) {
  return {
    type: LOAD_REPO,
    payload,
  };
}

export function loadRepoError(payload) {
  return {
    type: LOAD_REPO_ERROR,
    payload,
  };
}

export function setSourceInfo(payload) {
  return {
    type: SET_SOURCE_INFO,
    payload,
  };
}

export function getModuleCode(payload) {
  return {
    type: GET_MODULE_CODE,
    payload,
  };
}

export function getModuleCodeSuccess(payload) {
  return {
    type: GET_MODULE_CODE_SUCCESS,
    payload,
  };
}

export function getModuleCodeError(payload) {
  return {
    type: GET_MODULE_CODE_ERROR,
    payload,
  };
}

export function updateRoleCode(payload) {
  return {
    type: UPDATE_ROLE_CODE,
    payload,
  };
}

export function fetchFirebaseToken() {
  return {
    type: FETCH_FIREBASE_TOKEN,
  };
}

export function fetchFirebaseTokenSuccess(payload) {
  return {
    type: FETCH_FIREBASE_TOKEN_SUCCESS,
    payload,
  };
}

export function setCurrentDropdownStatus(payload) {
  return {
    type: SET_CURRENT_DROPDOWN_STATUS,
    payload,
  };
}

export function changeCurrentDropdownStatus(payload) {
  return {
    type: CHANGE_CURRENT_DROPDOWN_STATUS,
    payload,
  };
}

export function fetchInitialData(payload) {
  return {
    type: FETCH_INITIAL_DATA,
    payload,
  };
}

export function fetchInitialDataSuccess(payload) {
  return {
    type: FETCH_INITIAL_DATA_SUCCESS,
    payload,
  };
}

export function fetchUserInProject(payload) {
  return {
    type: FETCH_USER_IN_PROJECT,
    payload,
  };
}

export function fetchUserInProjectSuccess(payload) {
  return {
    type: FETCH_USER_IN_PROJECT_SUCCESS,
    payload,
  };
}

export function setLabelerId(payload) {
  return {
    type: SET_LABELER_ID,
    payload,
  };
}

export const setDialogOpen = (payload) => ({
  type: SET_DIALOG_OPEN,
  payload,
});

export const sendEmailToSystemAdmin = (payload, callback) => ({
  type: SEND_EMAIL_TO_SYSTEM_ADMIN,
  payload,
  callback,
});

export const changeLanguage = (payload) => {
  localStorage.setItem("currentLanguage", payload);
  return {
    type: CHANGE_LANGUAGE,
    payload,
  };
};
