import { DELETE_SHAPE } from "redux/shape/constants";

export function prepareDataDelete(shape) {
  const { id } = shape;
  return {
    type: DELETE_SHAPE,
    payload: { id },
    noSaveAnnotationHistory: true,
  };
}
