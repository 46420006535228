import React from "react";
import { bool, string, func } from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import IntlMessage from "../../helpers/IntlMessage";

import styles from "./style.module.scss";

function BaseModal(props) {
  const { title, content, isOpen, setIsOpen, handleSubmit } = props;

  const toggle = () => setIsOpen(false);

  const handleClick = () => {
    handleSubmit();
    toggle();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={toggle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle classes={{ root: styles.title }} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: styles.content }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle}>
          <IntlMessage id="common.cancel" />
        </Button>
        <Button onClick={handleClick} color="primary">
          <IntlMessage id="common.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BaseModal.propTypes = {
  title: string,
  content: string,
  isOpen: bool,
  setIsOpen: func,
  handleSubmit: func,
};

export default BaseModal;
