const namespace = "classifications";

export const FETCH_CLASSIFICATIONS = `${namespace}/FETCH_CLASSIFICATIONS`;
export const FETCH_CLASSIFICATIONS_SUCCESS = `${namespace}/FETCH_CLASSIFICATIONS_SUCCESS`;
export const FETCH_CLASSIFICATIONS_FAILURE = `${namespace}/FETCH_CLASSIFICATIONS_FAILURE`;
export const UPDATE_CLASSIFICATIONS = `${namespace}/UPDATE_CLASSIFICATIONS`;
export const UPDATE_CLASSIFICATIONS_SUCCESS = `${namespace}/UPDATE_CLASSIFICATIONS_SUCCESS`;
export const UPDATE_CLASSIFICATIONS_FAILURE = `${namespace}/UPDATE_CLASSIFICATIONS_FAILURE`;
export const REMOVE_CLASSIFICATIONS = `${namespace}/REMOVE_CLASSIFICATIONS`;
export const REMOVE_CLASSIFICATIONS_SUCCESS = `${namespace}/REMOVE_CLASSIFICATIONS_SUCCESS`;
export const REMOVE_CLASSIFICATIONS_FAILURE = `${namespace}/REMOVE_CLASSIFICATIONS_FAILURE`;

export const fetchClassifications = () => ({
  type: FETCH_CLASSIFICATIONS,
});

export const fetchClassificationsSuccess = (payload) => ({
  type: FETCH_CLASSIFICATIONS_SUCCESS,
  payload,
});

export const fetchClassificationsFailure = (payload) => ({
  type: FETCH_CLASSIFICATIONS_FAILURE,
  payload,
});

export const updateClassificationsData = (payload) => ({
  type: UPDATE_CLASSIFICATIONS,
  payload,
});

export const updateClassificationsDataSuccess = (payload) => ({
  type: UPDATE_CLASSIFICATIONS_SUCCESS,
  payload,
});

export const updateClassificationsDataFailure = (payload) => ({
  type: UPDATE_CLASSIFICATIONS_FAILURE,
  payload,
});

export const removeClassificationsData = (payload) => ({
  type: REMOVE_CLASSIFICATIONS,
  payload,
});

export const removeClassificationsDataSuccess = (payload) => ({
  type: REMOVE_CLASSIFICATIONS_SUCCESS,
  payload,
});

export const removeClassificationsDataFailure = (payload) => ({
  type: REMOVE_CLASSIFICATIONS_FAILURE,
  payload,
});
