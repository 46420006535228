import * as React from "react";
import { bool } from "prop-types";
function benchmarkStart({ isBenchmark }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill={isBenchmark ? "#FFBB0A" : "#555e7c"} />
      <g clipPath="url(#clip0_1051_12614)">
        <path
          d="M5.05442 13.1175C4.93755 13.1175 4.8215 13.081 4.72246 13.0094C4.53797 12.8757 4.45178 12.6459 4.502 12.4241L5.18821 9.40133L2.861 7.36043C2.68994 7.21109 2.62459 6.97459 2.69482 6.7582C2.76505 6.54221 2.95614 6.38929 3.18238 6.36838L6.26156 6.08883L7.47896 3.23988C7.56873 3.03056 7.77316 2.89539 8.0003 2.89539C8.22743 2.89539 8.43187 3.03056 8.52163 3.23939L9.73903 6.08883L12.8177 6.36838C13.0445 6.3888 13.2355 6.54221 13.3058 6.7582C13.376 6.97418 13.3111 7.21109 13.14 7.36043L10.8128 9.40092L11.499 12.4236C11.5493 12.6459 11.463 12.8757 11.2786 13.009C11.0946 13.1423 10.8492 13.1526 10.6555 13.0361L8.0003 11.4494L5.34512 13.037C5.25535 13.0903 5.15533 13.1175 5.05442 13.1175Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1051_12614">
          <rect
            width="10.6667"
            height="10.6667"
            fill="white"
            transform="translate(2.66699 2.66669)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

benchmarkStart.propTypes = {
  isBenchmark: bool,
};

export default benchmarkStart;
