import React, { useEffect, useState } from "react";
import { InputBase } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { selectIsUserTypingStatus } from "redux/keyboard/selectors";
import { setUserTypingStatus } from "redux/keyboard/action";
import useStyles from "./style";
import { func, object, string } from "prop-types";

function OverrideInputBase({ intl, value, onChange, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [valueState, setValueState] = useState("");
  const isUserTyping = useSelector(selectIsUserTypingStatus);

  useEffect(() => {
    if (value !== valueState) setValueState(value);
  }, [value]);

  const handleChange = (e) => {
    if (!isUserTyping) dispatch(setUserTypingStatus(true));
    setValueState(e.target.value);
    onChange(e);
  };

  const onFocus = () => {
    if (!isUserTyping) dispatch(setUserTypingStatus(true));
  };

  const onBlur = () => {
    dispatch(setUserTypingStatus(false));
  };

  return (
    <InputBase
      classes={{
        root: classes.InputBaseRoot,
      }}
      placeholder={intl.formatMessage({ id: "common.message-us" })}
      multiline
      rows={3}
      rowsMax={3}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      {...rest}
    />
  );
}

OverrideInputBase.propTypes = {
  intl: object,
  value: string,
  onChange: func,
};

export default injectIntl(OverrideInputBase);
