import React from "react";
import { connect } from "react-redux";
import InnerCuboid from "components/Cuboid";
import { bool, func, number, object } from "prop-types";
import { updateCuboid } from "redux/cuboid/actions";

function Cuboid({ properties, ratio, isSelected, onSelect, updateCuboid }) {
  const handleChange = (newAttrs) => {
    updateCuboid(newAttrs);
  };

  return (
    <InnerCuboid
      properties={properties}
      onSelect={onSelect}
      isSelected={isSelected}
      ratio={ratio}
      onChange={handleChange}
    />
  );
}

Cuboid.propTypes = {
  properties: object,
  ratio: number,
  isSelected: bool,
  onSelect: func,
  updateCuboid: func,
};

const mapStateToProps = () => () => ({});

export default connect(mapStateToProps, { updateCuboid })(Cuboid);
