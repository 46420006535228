import React from "react";
import { bool, string, func } from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IntlMessage from "../../helpers/IntlMessage";
import styles from "./style.module.scss";

function renderDialogTitle(title, titleId) {
  if (titleId) {
    return <IntlMessage id={titleId} />;
  } else if (title) {
    return <IntlMessage id={title} />;
  } else return null;
}

function renderDialogContent(content, contentId) {
  if (contentId) {
    return <IntlMessage id={contentId} />;
  } else if (content) {
    return <IntlMessage id={content} />;
  } else return null;
}

function BaseModal(props) {
  const {
    title,
    titleId,
    content,
    contentId,
    isOpen,
    toggle,
    handleSubmit,
  } = props;

  const handleKeyClick = (e) => {
    const ENTER = 13;
    if (e.keyCode === ENTER) {
      handleSubmit();
      toggle();
    }
  };

  const handleClick = () => {
    handleSubmit();
    toggle();
  };

  return (
    <Dialog
      open={isOpen ? isOpen : false}
      onClose={toggle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onKeyUp={handleKeyClick}
    >
      <DialogTitle classes={{ root: styles.title }} id="alert-dialog-title">
        {renderDialogTitle(title, titleId)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText classes={{ root: styles.content }}>
          {renderDialogContent(content, contentId)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle}>
          <IntlMessage id="common.cancel" />
        </Button>
        <Button onClick={handleClick} color="primary">
          <IntlMessage id="common.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BaseModal.propTypes = {
  title: string,
  titleId: string,
  content: string,
  contentId: string,
  isOpen: bool,
  toggle: func,
  handleSubmit: func,
};

export default BaseModal;
