import {
  START_CHANNEL,
  STOP_CHANNEL,
  AUTHENTICATED_SOCKET,
  UNAUTHORIZED_SOCKET,
  JOIN_ROOM,
  LEAVE_ROOM,
  RECEIVE_USER_LEAVE_ROOM,
} from "./constants";

export const startChannel = () => ({ type: START_CHANNEL });
export const stopChannel = () => ({ type: STOP_CHANNEL });

export const joinRoom = (payload) => ({ type: JOIN_ROOM, payload });
export const leaveRoom = (payload) => ({ type: LEAVE_ROOM, payload });
export const receiveUserLeaveRoom = (payload) => ({
  type: RECEIVE_USER_LEAVE_ROOM,
  payload,
});

export const authenticatedSocket = () => ({ type: AUTHENTICATED_SOCKET });
export const unauthorizedSocket = () => ({ type: UNAUTHORIZED_SOCKET });
