export const ADD_ANNOTATION_HISTORY =
  "annotationHistory/ADD_ANNOTATION_HISTORY";

export const SET_ANNOTATION_HISTORY =
  "annotationHistory/SET_ANNOTATION_HISTORY";

export const REDO_ANNOTATION = "annotationHistory/REDO_ANNOTATION";
export const UNDO_ANNOTATION = "annotationHistory/UNDO_ANNOTATION";
export const SET_CURRENT_TARGET = "annotationHistory/SET_CURRENT_TARGET";
export const SET_TOOGLE_UNDO_REDO_FLAG =
  "annotationHistory/SET_TOOGLE_UNDO_REDO_FLAG";
