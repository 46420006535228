import { number, object, string } from "prop-types";
import React from "react";
import { Image as KonvaImage } from "react-konva";
import useImage from "use-image";

export default function Image({ url, ratio, rectangleProps }) {
  const [image] = useImage(url);

  return (
    <KonvaImage
      image={image}
      x={rectangleProps.x * ratio}
      y={rectangleProps.y * ratio}
      width={rectangleProps.width * ratio}
      height={rectangleProps.height * ratio}
      crop={{
        x: rectangleProps.x,
        y: rectangleProps.y,
        width: rectangleProps.width,
        height: rectangleProps.height,
      }}
    />
  );
}

Image.propTypes = {
  url: string,
  ratio: number,
  metadata: object,
  rectangleProps: object,
};
