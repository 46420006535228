export function prepareUndoDataUpdate(payload, sourcePayload, type) {
  return {
    type,
    payload: {
      ...sourcePayload,
    },
    noSaveAnnotationHistory: true,
  };
}

export function prepareRedoDataUpdate(payload, sourcePayload, type) {
  return {
    type,
    payload: {
      ...payload,
    },
    noSaveAnnotationHistory: true,
  };
}
