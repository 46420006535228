import {
  ADD_ANNOTATION_HISTORY,
  REDO_ANNOTATION,
  UNDO_ANNOTATION,
  SET_CURRENT_TARGET,
  SET_ANNOTATION_HISTORY,
} from "./constants";

export const addAnnotationHistory = (payload) => ({
  type: ADD_ANNOTATION_HISTORY,
  payload,
});

export const setAnnotationHistory = (payload) => ({
  type: SET_ANNOTATION_HISTORY,
  payload,
});

export const redoAnnotation = (payload) => ({
  type: REDO_ANNOTATION,
  payload,
});

export const undoAnnotation = (payload) => ({
  type: UNDO_ANNOTATION,
  payload,
});

export const setCurrentTarget = (payload) => ({
  type: SET_CURRENT_TARGET,
  payload,
});
