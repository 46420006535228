import { baseURL, commonHeader, toolName } from "api/constants";

export function saveCountingApi({ countingTime, projectId, datasetId }) {
  return fetch(`${baseURL}/logCountTime`, {
    method: "POST",
    ...commonHeader,
    body: JSON.stringify({
      countingTime,
      toolName,
      projectId,
      datasetId,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function getRemainTimeAPI() {
  return fetch(`${baseURL}/logCountTime/get-remain-time`, {
    method: "GET",
    ...commonHeader,
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}
