import React from "react";
import { connect } from "react-redux";
import { bool, func, string, number, array, object } from "prop-types";
import clsx from "clsx";
import { injectIntl } from "react-intl";
import { deleteShape } from "redux/shape/actions";
import { setCurrentShape } from "redux/shape/actions";
import { setSelectedPoints } from "redux/points/actions";
import { selectImageRatio } from "redux/canvas/selectors";
import DeleteIcon from "@material-ui/icons/Delete";
import FlagOutlined from "@material-ui/icons/FlagOutlined";
import { getDisplayingShapes, getCurrentShapes } from "redux/shape/selector";
import { setDisplayingShapeById } from "redux/shape/actions";
import styles from "./style.module.scss";
import { mapModeToIcon } from "./icon";
import ObjectAttribute from "components/ObjectAttribute";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { setModalItem } from "redux/canvas/actions";
import { getParameterByName } from "helpers";

function ListItem({
  intl,
  item,
  gridTemplateColumns,
  currentShape,
  index,
  displayingShapes,
  setDisplayingShapeById,
  setCurrentShape,
  deleteShape,
  setModalItem,
}) {
  const listRow = clsx({
    [styles.listRow]: true,
    [styles.selectedRow]:
      currentShape && Number(currentShape.id) === Number(item.id),
  });

  const attributeRow = clsx({
    [styles.listBody]: true,
    [styles.attributeBody]: true,
  });
  const [togleAttributeForm, setTogleAttributeForm] = React.useState(false);
  const alterUserId = getParameterByName("alterUserId");
  const isViewResult = getParameterByName("isViewResult");
  const enableEditting = !(alterUserId || isViewResult);

  const handleClickRow = () => {
    if (!enableEditting) {
      return;
    }

    setCurrentShape(item);
    if (item.dataAttribute && item.dataAttribute.length)
      setTogleAttributeForm(!togleAttributeForm);
  };

  const handleDeleteItemBoxConfirm = () => {
    setModalItem({
      payload: item,
      fn: deleteShape,
      title: intl.formatMessage({ id: "shape-list.delete-object" }),
      content: intl.formatMessage({ id: "shape-list.confirm-deletion" }),
      id: item.id,
      fnAction: null,
    });
  };

  const handleChangeChecked = () => {
    setDisplayingShapeById({ id: item.id, labelId: item.labelId });
  };

  const getLabelName = (object) => {
    if (object.classesTypeCode && object.classesTypeCode.includes("ocr")) {
      const { dataAttribute } = object;
      if (
        dataAttribute &&
        dataAttribute.length > 0 &&
        dataAttribute[0].attributeValue
      ) {
        return dataAttribute[0].attributeValue;
      }
    }
    return `${object.labelName} ${index}`;
  };

  const Icon = mapModeToIcon[item.classesTypeCode];
  return (
    <div>
      <div
        key={item.id}
        id={item.id}
        className={listRow}
        style={{
          gridTemplateColumns,
          paddingLeft: 20,
        }}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={handleClickRow}
      >
        <span>
          <input
            type="checkbox"
            onChange={handleChangeChecked}
            checked={
              displayingShapes.findIndex(
                (shape) => Number(shape.id) === Number(item.id)
              ) !== -1
            }
          />
        </span>

        {currentShape && currentShape.id === item.id && togleAttributeForm ? (
          <ChevronRight />
        ) : (
          <ExpandMore />
        )}

        <span className={styles.labelWrapper}>
          {Icon && (
            <Icon
              color={"inherit"}
              // color={item.color}
              stroke={item.color}
            />
          )}
          <span className={`${styles.label} ${styles.textEllipsis}`}>
            {getLabelName(item)}
          </span>
        </span>

        <span className={styles.issuedColumns}>
          <FlagOutlined fontSize="small" />
          <span>{item.issued || 0}</span>
        </span>
        {enableEditting && (
          <span>
            <DeleteIcon
              onClick={handleDeleteItemBoxConfirm}
              className={styles.doneIcon}
            />
          </span>
        )}
      </div>
      {currentShape && currentShape.id === item.id && togleAttributeForm && (
        <div className={attributeRow}>
          <ObjectAttribute
            currentShape={currentShape}
            showAttributeForm={true}
            checkFormPosition={false}
            formPosition={{}}
            maxHeight={400}
          />
        </div>
      )}
    </div>
  );
}

ListItem.propTypes = {
  intl: object,
  color: string,
  isChanged: bool,
  item: object,
  setSelectedPoints: func,
  gridTemplateColumns: string,
  index: number,
  displayingShapes: array,
  setDisplayingShapeById: func,
  setCurrentShape: func,
  deleteShape: func,
  currentShape: object,
  setDrawingSegmentation: func,
  setModalItem: func,
};

const mapStateToProps = (state) => ({
  ratio: selectImageRatio(state),
  displayingShapes: getDisplayingShapes(state),
  currentShape: getCurrentShapes(state),
});

export default connect(mapStateToProps, {
  setSelectedPoints,
  setDisplayingShapeById,
  setCurrentShape,
  deleteShape,
  setModalItem,
})(injectIntl(ListItem));
