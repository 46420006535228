import React from "react";
import { connect } from "react-redux";
import { bool, func, string, number, shape } from "prop-types";
import clsx from "clsx";
import { makeSelectedRowId } from "redux/points/selectors";
import { setSelectedPoints } from "redux/points/actions";
import { selectImageRatio } from "redux/canvas/selectors";
import DoneOutlined from "@material-ui/icons/DoneOutlined";
import FlagOutlined from "@material-ui/icons/FlagOutlined";
import styles from "./style.module.scss";

function ListItem({
  item,
  gridTemplateColumns,
  setSelectedPoints,
  selectedRowId,
  ratio,
}) {
  const listRow = clsx({
    [styles.listRow]: true,
    [styles.selectedRow]: selectedRowId === item.id,
  });

  function handleClickRow() {
    const { position, id } = item;
    const pointCoordinate = position.split(" - ");
    const x = Number(pointCoordinate[0]) * ratio;
    const y = Number(pointCoordinate[1]) * ratio;
    const payload = { [id]: { ...item, x, y, type: "point" } };
    setSelectedPoints(payload);
  }

  return (
    <div
      key={item.id}
      id={item.id}
      className={listRow}
      style={{
        gridTemplateColumns,
      }}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={handleClickRow}
    >
      <span></span>
      <span className={styles.labelWrapper}>
        <span
          className={styles.labelDot}
          style={{ backgroundColor: item.colour || "#e5e5e5" }}
        />
        <span className={`${styles.label} ${styles.textEllipsis}`}>
          {item.id}
        </span>
      </span>
      <span className={styles.time}>{item.position}</span>
      <span>{item.issued ? <FlagOutlined /> : ""}</span>
      <span>
        {item.isChanged ? <DoneOutlined className={styles.doneIcon} /> : ""}
      </span>
    </div>
  );
}

ListItem.propTypes = {
  color: string,
  index: string,
  isChanged: bool,
  item: shape({
    id: string,
    index: number,
    x: number,
    y: number,
    position: string,
    issued: bool,
  }),
  selectedRowId: string,
  setSelectedPoints: func,
  ratio: number,
  gridTemplateColumns: string,
};

const mapStateToProps = (state) => ({
  selectedRowId: makeSelectedRowId(state),
  ratio: selectImageRatio(state),
});

export default connect(mapStateToProps, { setSelectedPoints })(ListItem);
