import {
  all,
  fork,
  take,
  put,
  race,
  call,
  cancelled,
  delay,
  select,
  takeEvery,
} from "redux-saga/effects";
import { SELECT_ONE_SOURCE_SUCCESS } from "redux/image/constants";
import { shapeSocketReceive } from "redux/shape/actions";
import { selectLabels } from "redux/points/selectors";
import { selectUserId, selectUserFullname } from "redux/app/selectors";
import { receiveMousePosition } from "redux/mouse/actions";
import { randomColor } from "redux/mouse/reducer";
import { receiveUsers, removeUser } from "redux/users/actions";
import { selectImageId } from "redux/image/selectors";
import {
  stopChannel,
  joinRoom,
  leaveRoom,
  receiveUserLeaveRoom,
} from "./actions";
import {
  joinRoom as joinRoomSocket,
  leaveRoom as leaveRoomSocket,
} from "./eventHandlers/room";
import { START_CHANNEL, STOP_CHANNEL } from "./constants";
import socketSelect from "./selectors";
import {
  commonEmitter,
  connect,
  listenConnectSaga,
  listenDisconnectSaga,
  createSocketChannel,
  handler,
} from "./socketChannel";
import { setDialogOpen } from "redux/app/actions";
import { showNotification } from "redux/notification/actions";
import { selectOneSource } from "redux/image/actions";
import { resetSegmentationMode } from "redux/segmentation/actions";
import { getParameterByName } from "helpers";

export let socket;
function* startStopChannel() {
  try {
    while (true) {
      yield take(START_CHANNEL);
      yield race({
        task: call(listenServerSaga),
        cancel: take(STOP_CHANNEL),
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("error", error);
  }
}

// Saga to switch on channel
export function* listenServerSaga() {
  try {
    const { connected, timeout } = yield race({
      connected: call(connect),
      timeout: delay(5000),
    });

    if (timeout) {
      console.log("stop");
    }

    if (connected) socket = connected;
    const socketChannel = yield call(createSocketChannel, connected);
    yield fork(listenDisconnectSaga);
    yield fork(listenConnectSaga);
    yield call(initRealTime);

    socket.on("user-leave-room", (data) =>
      handler(data, commonEmitter, "user-leave-room")
    );
    socket.on("user-join", (data) => handler(data, commonEmitter, "user-join"));
    socket.on("userList", (data) => handler(data, commonEmitter, "userList"));
    socket.on("action", (data) => handler(data, commonEmitter, "action"));
    socket.on("mouse-tracking", (data) =>
      handler(data, commonEmitter, "mouse-tracking")
    );
    socket.on("mouse-position", (data) =>
      handler(data, commonEmitter, "mouse-position")
    );
    socket.on("user-disconnected", (data) =>
      handler(data, commonEmitter, "user-disconnected")
    );
    socket.on("automatic_annotation_end", (data) =>
      handler(data, commonEmitter, "automatic_annotation_end")
    );

    while (true) {
      // take the response from socketChannel and deal with them in mapEventToAction function
      const payload = yield take(socketChannel);
      yield call(mapEventToAction, payload);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("listenServerSaga error", error);
  } finally {
    if (yield cancelled()) {
      // eslint-disable-next-line no-console
      console.log("cancel socket");
      if (socket) socket.disconnect(true);
      yield put(stopChannel());
    }
  }
}

const mapClassType = {
  labelBox: "box-image",
};

function* mapEventToAction(payload) {
  const { event } = payload;
  /* eslint-disable default-case, no-param-reassign, no-console */
  switch (event) {
    case "authenticated":
      break;
    case "unauthorized":
      break;
    case "user-join": {
      let values = Object.values(payload.users);
      const keys = Object.keys(payload.users);
      values = values.map((item, i) => ({
        ...item,
        userId: keys[i],
      }));
      const currUserId = yield select(selectUserId);
      values = values.filter((user) => user.userId !== currUserId);
      yield put(receiveUsers(values));
      break;
    }
    case "userList": {
      let values = Object.values(payload);
      values.pop();
      const keys = Object.keys(payload);
      values = values.map((item, i) => ({
        ...item,
        userId: keys[i],
      }));
      const currUserId = yield select(selectUserId);
      values = values.filter((user) => user.userId !== currUserId);
      yield put(receiveUsers(values));
      break;
    }
    case "action": {
      const userId = yield select(selectUserId);
      const isViewResult = getParameterByName("isViewResult");
      const isActionHistory = getParameterByName("isActionHistory");
      const isBenchmark = isViewResult || isActionHistory;
      let { data } = payload;
      if (userId === payload.userId || isBenchmark) break;
      const labels = yield select(selectLabels);
      const label = labels.find((item) => parseInt(item.id) === data.labelId);
      data.id = parseInt(data.labelPointId);
      data.color = label.color;
      data.labelName = label.name;
      data.classesTypeCode = mapClassType[data.type];
      yield put(shapeSocketReceive({ data, type: payload.action }));
      break;
    }
    case "mouse-tracking":
      break;
    case "mouse-position":
      yield put(receiveMousePosition(payload));
      break;
    case "user-disconnected":
    case "user-leave-room":
      yield put(receiveUserLeaveRoom(payload));
      yield put(removeUser(payload));
      break;
    case "automatic_annotation_end": {
      yield put(setDialogOpen(false));
      if (payload.status == 200) {
        yield put(
          showNotification({
            type: "success",
            msg: "Automatic annotation successfully.",
            msgId: "notification.auto-anno-success",
          })
        );
        const currentImageId = yield select(selectImageId);
        yield put(resetSegmentationMode());
        yield put(selectOneSource({ id: currentImageId }));
      } else {
        yield put(
          showNotification({
            type: "error",
            msg: "Automatic annotation unsuccessfully.",
            msgId: "notification.auto-anno-error",
          })
        );
      }
      break;
    }
    default:
      break;
  }
}

function* initRealTime() {
  const sourceId = yield select(selectImageId);
  if (!socket || !sourceId) return;
  const { currentRoomId } = yield select(socketSelect);
  const fullName = yield select(selectUserFullname);
  if (currentRoomId) {
    yield call(leaveRoomSocket, socket, currentRoomId);
    yield put(leaveRoom(currentRoomId));
  }
  yield call(joinRoomSocket, socket, {
    roomId: sourceId,
    fullName,
    color: randomColor(),
  });
  yield put(joinRoom(sourceId));
}

function* watchSelectOneSource() {
  yield call(initRealTime);
}

export default function* socketSaga() {
  yield all([
    fork(startStopChannel),
    takeEvery(SELECT_ONE_SOURCE_SUCCESS, watchSelectOneSource),
  ]);
}
