import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import ColorPickerIcon from "../../icons/ColorPicker";
import classnames from "classnames";
import Menu from "@material-ui/core/Menu";
import { any } from "prop-types";

import styles from "./style.module.scss";

const defaultColor = [
  "#D0021B",
  "#F5A623",
  "#F8E71C",
  "#8B572A",
  "#7ED321",
  "#417505",
  "#BD10E0",
  "#9013FE",
  "#4A90E2",
  "#50E3C2",
  "#B8E986",
  "#000000",
  "#4A4A4A",
  "#9B9B9B",
];

export const ColorPattern = ({ onChange }) => {
  const [color, setColor] = useState(
    defaultColor[Math.floor(Math.random() * (defaultColor.length - 1))]
  );
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    onChange && onChange(color);
  }, [color]);

  const handleChangeColorPicker = (event) => {
    setColor(event.hex);

    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenColorPicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickChangeColor = (changeColor) => () => {
    setColor(changeColor);
  };
  return (
    <div
      style={{ display: "flex", alignItems: "center", justifyContent: "start" }}
    >
      <div
        className={classnames(
          styles["color-picker"],
          !defaultColor.includes(color) && styles["selected-color"]
        )}
        style={{ backgroundColor: !defaultColor.includes(color) && color }}
        onClick={handleOpenColorPicker}
      >
        {defaultColor.includes(color) && (
          <ColorPickerIcon width={20} height={20} />
        )}
      </div>
      {defaultColor.map((item) => (
        <div
          key={item}
          className={classnames(
            styles["color-picker"],
            color === item && styles["selected-color"]
          )}
          style={{ backgroundColor: item }}
          onClick={handleClickChangeColor(item)}
        />
      ))}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <SketchPicker onChange={handleChangeColorPicker} />
      </Menu>
    </div>
  );
};

ColorPattern.propTypes = {
  onChange: any,
};
