import React from "react";
import { connect, useDispatch } from "react-redux";
import Rectangle from "components/Rectangle";
import { bool, func, number, object } from "prop-types";
import { selectIsBoxMode } from "redux/tools/selectors";
import { updateOCR } from "redux/ocr/actions";
import { closeAllAttribute } from "redux/shape/actions";
import { setCurrentShape } from "redux/shape/actions";
function OCR({
  properties,
  ratio,
  isSelected,
  onSelect,
  isBoxMode,
  updateOCR,
  closeAllAttribute,
}) {
  const dispatch = useDispatch();
  const handleChange = (newAttrs) => {
    const { x, y, width, height, rotation } = newAttrs;
    dispatch(
      setCurrentShape({
        ...properties,
        dataPoint: {
          ...properties.dataPoint,
          x: x,
          y: y,
          width: width,
          height: height,
          rotation: rotation,
        },
      })
    );
    updateOCR({
      ...properties,
      dataPoint: {
        ...properties.dataPoint,
        x: x,
        y: y,
        width: width,
        height: height,
        rotation: rotation,
      },
    });
  };

  let labelName =
    properties.dataAttribute &&
    properties.dataAttribute.find((x) => x.dataType === "text");
  labelName = labelName ? labelName.attributeValue : "";

  return (
    <>
      <Rectangle
        rectangleProps={{
          ...properties.dataPoint,
          labelId: properties.labelId,
          label: labelName,
          stroke: properties.color,
          strokeWidth: 4,
          visible: true,
          id: properties.id,
        }}
        opacity={0.8}
        isBoxMode={isBoxMode}
        onSelect={onSelect}
        isSelected={isSelected}
        ratio={ratio}
        onChange={handleChange}
        labeled
        closeAllAttribute={closeAllAttribute}
        rotateEnabled={true}
      />
    </>
  );
}

OCR.propTypes = {
  properties: object,
  isBoxMode: bool,
  ratio: number,
  isSelected: bool,
  onSelect: func,
  updateOCR: func,
  closeAllAttribute: func,
};

const mapStateToProps = () => (state) => ({
  isBoxMode: selectIsBoxMode(state),
});

export default connect(mapStateToProps, {
  updateOCR,
  closeAllAttribute,
})(OCR);
