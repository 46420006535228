export function joinRoom(socket, payload) {
  socket && socket.emit("join-room", payload);
}

export function leaveRoom(socket, payload) {
  socket &&
    socket.emit("leave-room", {
      roomId: payload,
    });
}
