import { SEND_MOUSE_POSITION, RECEIVE_MOUSE_POSITION } from "./constants";

export const sendMousePosition = (payload) => ({
  type: SEND_MOUSE_POSITION,
  payload,
});

export const receiveMousePosition = (payload) => ({
  type: RECEIVE_MOUSE_POSITION,
  payload,
});
