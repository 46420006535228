/* eslint-disable no-self-assign */
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { func, bool, number, arrayOf, object, string } from "prop-types";
import Notifications from "containers/Notifications";
import ToolBar from "containers/ToolBar";
import TopBar from "containers/TopBar";
import Reporter from "containers/Reporter";
import ImageAnnotation from "containers/ImageAnnotation";
import InputDataPicker from "components/InputDataPicker";
import LoadingDialog from "containers/LoadingDialog";
import { selectIsBootingFinished, selectLocale } from "redux/app/selectors";
import { adminApp, env } from "api/constants";
import { selectLabelsForDropDown } from "redux/points/selectors";
import { loadRepo, loadRepoError } from "redux/app/actions";
import { getCookie, getParameterByName } from "helpers";
import BottomHotkeys from "containers/BottomHotkeys";
import packageJson from "../package.json";
import { isProduction, GATrackingId } from "api/constants";
import { selectLabel } from "redux/points/actions";
import * as allTools from "redux/tools/enums";
import { selectTool } from "redux/tools/actions";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";
import style from "./style.module.scss";
import Joyride from "react-joyride";
import steps from "./tourSteps";
import { jumpToStep } from "redux/tourGuide/actions";
import Tooltip from "components/toolTipTour";
import { endCounting, startCounting } from "redux/timing/actions";
import { selectIsCountingTime, selectRemainTime } from "redux/timing/selectors";
import LimitTimeModal from "components/LimitTimeModal";
import GeneralModal from "components/Modal/Modal-v1";
import { selectModalObject } from "redux/canvas/selectors";
import { setModalItem } from "redux/canvas/actions";
import { initialState as canvasInitialState } from "redux/canvas/reducer";
import AppLocale from "./lang";
import { IntlProvider } from "react-intl";
import moment from "moment";
require("moment/locale/ja");

function App({
  locale,
  loadRepo,
  loadRepoError,
  jumpToStep,
  stepIndex,
  isTourOpen,
  labels,
  selectLabel,
  selectTool,
  remainTime,
  modalItem,
  setModalItem,
}) {
  const dispatch = useDispatch();
  const isCountingTime = useSelector(selectIsCountingTime);
  const initGA = () => {
    ReactGA.initialize(GATrackingId);
    ReactGA.pageview(`${window.location.pathname}${window.location.search}`);
  };
  // const initSentry = () => {
  //   Sentry.init({
  //     dsn: sentryDSN,
  //     integrations: [new Integrations.BrowserTracing()],
  //     environment,
  //     // Set tracesSampleRate to 1.0 to capture 100%
  //     // of transactions for performance monitoring.
  //     // We recommend adjusting this value in production
  //     tracesSampleRate: 1.0,
  //   });
  // };

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // // delete browser cache and hard reload
    window.location.href = window.location.href;
  };

  const handleListenFocusEvent = () => {
    const tokenInCookie = getCookie("Bearer");
    if (tokenInCookie && !isCountingTime && isProduction) {
      dispatch(startCounting());
    }
  };

  const handleListenFocusOutEvent = () => {
    isProduction && dispatch(endCounting());
  };

  useEffect(() => {
    if (isProduction) {
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          if (meta.buildDate !== packageJson.buildDate) {
            refreshCacheAndReload();
          }
        });

      if (GATrackingId) {
        initGA();
      }
      // if (sentryDSN) {
      //   initSentry();
      // }
    }
    const isTourOpen = getParameterByName("isTourOpen");
    if (!isCountingTime && !isTourOpen && isProduction) {
      dispatch(startCounting());
    }

    window.addEventListener("focus", handleListenFocusEvent);
    window.addEventListener("blur", handleListenFocusOutEvent);

    return () => {
      window.removeEventListener("focus", handleListenFocusEvent);
      window.removeEventListener("blur", handleListenFocusOutEvent);
    };
  }, []);

  useEffect(() => {
    // TODO: find a solution to get token
    const tokenInCookie = getCookie("Bearer");
    if (tokenInCookie) {
      loadRepo({ tokenInCookie });
    } else {
      loadRepoError("Error when fetching the repository.");
      if (env !== "development") {
        window.location.href = adminApp;
      }
    }
  }, [loadRepo, loadRepoError]);

  useEffect(() => {
    const isTourOpenParams = getParameterByName("isTourOpen");
    // const isOldInImage = localStorage.getItem("isOldInImage");
    if (
      labels.length > 0 &&
      isTourOpenParams
      // && !isOldInImage
    ) {
      jumpToStep &&
        jumpToStep({
          stepIndex: stepIndex + 1,
          isOpen: !isTourOpen,
        });
      // localStorage.setItem("isOldInImage", true);
    }
  }, [labels.length]);

  // const isDisableOverlay = interactTourStep.includes(stepIndex);
  const handleJoyrideCallback = (event) => {
    const { action, index, lifecycle } = event;

    switch (stepIndex) {
      case 1: {
        const boundingBoxLabel = labels.find((label) =>
          label.tools.includes("box-image")
        );
        boundingBoxLabel && selectLabel(boundingBoxLabel);
        break;
      }
      case 2: {
        const toolName = allTools.BOX_MODE;
        selectTool(toolName);
        break;
      }
      default: {
        break;
      }
    }

    if (action === "next" && lifecycle === "complete" && stepIndex === index) {
      jumpToStep &&
        jumpToStep({
          stepIndex: stepIndex + 1,
          isOpen: isTourOpen,
        });
    }

    if (action === "close" && lifecycle === "complete") {
      jumpToStep &&
        jumpToStep({
          stepIndex,
          isOpen: !isTourOpen,
        });
    }
  };

  const handleToogleGeneralModal = () => {
    setModalItem(canvasInitialState.modalItem);
  };
  const handleSubmitAction = () => {
    const { fnAction, payload: modalPayload } = modalItem;
    dispatch(fnAction(modalPayload));
  };

  const handleSubmitFn = () => {
    const { fn, payload: modalPayload } = modalItem;
    fn(modalPayload);
  };

  const currentAppLocale = AppLocale[locale];
  const localStorageAppLocale = localStorage.getItem("currentLanguage");

  if (currentAppLocale && moment.locale() !== currentAppLocale.locale) {
    moment.locale(currentAppLocale.locale);
  }

  if (
    currentAppLocale &&
    !currentAppLocale.locale.includes(localStorageAppLocale)
  ) {
    localStorage.setItem("currentLanguage", locale);
  }

  return (
    <div>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <>
          <TopBar />
          <Notifications />
          <div className={style.container} id="container">
            <ToolBar />
            <InputDataPicker />
            <ImageAnnotation />
            <Reporter />
          </div>
          <BottomHotkeys />
          <LoadingDialog />
          <LimitTimeModal open={remainTime === 0} />
          <Joyride
            callback={handleJoyrideCallback}
            continuous={true}
            run={isTourOpen}
            showProgress={true}
            showSkipButton={false}
            hideBackButton={true}
            hideCloseButton={true}
            disableOverlayClose={true}
            stepIndex={stepIndex}
            steps={steps}
            styles={{
              options: {
                zIndex: 10000,
              },
            }}
            disableOverlay={false}
            tooltipComponent={Tooltip}
          />
          <GeneralModal
            title={modalItem.title}
            titleId={modalItem.titleId}
            content={modalItem.content}
            contentId={modalItem.contentId}
            isOpen={modalItem.id}
            toggle={handleToogleGeneralModal}
            handleSubmit={modalItem.fn ? handleSubmitFn : handleSubmitAction}
          />
        </>
      </IntlProvider>
    </div>
  );
}

App.propTypes = {
  locale: string,
  isBootingFinished: bool,
  loadRepo: func,
  loadRepoError: func,
  isTourOpen: bool,
  stepIndex: number,
  jumpToStep: func,
  labels: arrayOf(object),
  selectLabel: func,
  selectTool: func,
  remainTime: number,
  modalItem: object,
  setModalItem: func,
};

const mapPropsToState = (state) => {
  const { tourGuide } = state;
  const { isOpen: isTourOpen, stepIndex } = tourGuide;

  return {
    locale: selectLocale(state),
    labels: selectLabelsForDropDown()(state),
    isBootingFinished: selectIsBootingFinished(state),
    isTourOpen,
    stepIndex,
    remainTime: selectRemainTime(state),
    modalItem: selectModalObject(state),
  };
};

export default connect(mapPropsToState, {
  loadRepo,
  loadRepoError,
  jumpToStep,
  selectLabel,
  selectTool,
  setModalItem,
})(App);
