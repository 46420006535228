import {
  commonHeader,
  baseURL,
  baseURLAdmin,
  commonHeaderAdmin,
} from "./constants";

export function getRole({ projectId, userId }) {
  return fetch(
    `${baseURL}/module-code?projectId=${projectId}&userId=${userId}`,
    {
      method: "GET",
      ...commonHeader,
    }
  )
    .then((res) => res.json().then((data) => ({ status: res.status, ...data })))
    .catch((error) => ({ error: true, ...error }));
}

export function sendEmailToSystemAdminAPI(payload) {
  return fetch(`${baseURLAdmin}/members/send-mail-attach-message`, {
    method: "POST",
    ...commonHeaderAdmin,
    body: JSON.stringify(payload),
  })
    .then((res) => res.json().then((data) => ({ status: res.status, data })))
    .catch((error) => ({ error: true, ...error }));
}
