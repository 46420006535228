import React, { useState } from "react";
import { Drawer, IconButton } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { isDesktop } from "react-device-detect";
import useStyles from "./styles";
import { func, bool, any } from "prop-types";

export default function DrawerModal({ title, open, toggle, children }) {
  const paperWidth = isDesktop ? 39 : 75;
  const classes = useStyles({ paperWidth });

  return (
    <Drawer
      disableEnforceFocus
      open={open}
      onClose={toggle}
      anchor="right"
      classes={classes}
    >
      <div className={classes.header}>
        {title}
        <IconButton onClick={toggle}>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <div className={classes.body}>{children}</div>
    </Drawer>
  );
}

export function useToggle(initState = false) {
  const [open, setOpen] = useState(initState);

  const toggle = () => setOpen(!open);

  return [open, toggle];
}

DrawerModal.propTypes = {
  title: any,
  open: bool,
  toggle: func,
  children: any,
};
