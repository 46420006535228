import React, { useState } from "react";

import Panels from "./Panels";
import Tabs from "./Tabs";

import styles from "./style.module.scss";

export default function Reporter() {
  const [tabObj, setTabObj] = useState({
    countSelectedTab: 2,
    box: true,
    "facial landmarks": false,
    issues: true,
  });

  return (
    <div className={styles.reporterContainer}>
      <Panels tabObj={tabObj} />
      <Tabs setTabObj={setTabObj} tabObj={tabObj} />
    </div>
  );
}
