import * as React from "react";

function PaintBrush(props) {
  return (
    <svg
      width={15}
      height={15}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 404.24 404.24"
      style="enable-background:new 0 0 404.24 404.24;"
      space="preserve"
      fill="currentColor"
      {...props}
    >
      <g>
        <g>
          <path
            d="M402.216,0c-16.933,15.714-38.99,24.752-62.08,25.44h-4.48h-0.96c-19.52,0-29.92,35.28-33.04,52.24l22.4,22.4h4.88
			c18.981,0.355,37.259-7.177,50.48-20.8C395.416,62.24,402.936,35.6,402.216,0z"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M251.096,124.96C219.816,152,24.136,323.52,1.976,404.24c80-22.48,251.92-218.08,278.96-249.44L251.096,124.96z" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="290.136,83.04 252.376,109.28 296.616,153.52 322.856,115.76 		" />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}
export default PaintBrush;
