import React, { useEffect, useState, useRef } from "react";
import { array, bool, func, number, object } from "prop-types";
import { Line, Circle } from "react-konva";
import { connect } from "react-redux";
import { selectIsPolygonMode } from "redux/tools/selectors";
import { changeOpacityOfColor } from "helpers";
function Polygon({
  polygon = {},
  handleDragStartPoint,
  onChange,
  isFinished = true,
  ratio,
  curMousePos,
  onSelect,
  isSelected,
  currentPoint,
  onChangeCurrentPoint,
  ...rest
}) {
  const polygonRef = useRef();
  const [polygonProps, setPolygonProps] = useState(polygon);
  const [tempPoint1st, setTempPoint1st] = useState([0, 0]);
  const [tempPoint2nd, setTempPoint2nd] = useState([0, 0]);
  const [currentPointState, setCurrentPointState] = useState(-1);

  const [dragPoints, setDragPoints] = useState([]);

  useEffect(() => {
    setPolygonProps(polygon);
  }, [polygon]);

  useEffect(() => {
    if (currentPoint !== currentPointState) {
      setCurrentPointState(currentPoint);
    }
  }, [currentPoint]);

  const handleSetCurrentPoint = (index) => {
    setCurrentPointState(index);
    onChangeCurrentPoint && onChangeCurrentPoint(index);
  };

  const handleDragMovePoint = (event) => {
    const pos = [event.target.attrs.x / ratio, event.target.attrs.y / ratio];
    const index = event.target.attrs.index;
    setPolygonProps({
      ...polygon,
      points: [
        ...polygon.points.slice(0, index),
        pos,
        ...polygon.points.slice(index + 1),
      ],
    });

    // if user choose one point of current polygon
    // update coordinate of two temp point
    if (isSelected && currentPointState > 0) {
      handleClickPoint(currentPointState)();
    }
  };

  const handleDragEndPoint = (index) => (event) => {
    const stage = event.target.getStage();
    const { x, y } = stage.getPointerPosition();
    const listPoint = [...polygonProps.points];
    listPoint.splice(index, 1, [x / ratio, y / ratio]);

    onChange && onChange({ ...polygonProps, dataPoint: listPoint });
  };

  const getCenterPointOfTwoPoints = (point1, point2) => {
    const [x1, y1] = point1;
    const [x2, y2] = point2;
    return [(x1 + x2) / 2, (y1 + y2) / 2];
  };

  const handleClickPoint = (index) => () => {
    if (isFinished) {
      const listPoint = polygonProps.points;
      const beforeIndex = index - 1 >= 0 ? index - 1 : listPoint.length - 1;
      const afterIndex = index + 1 < listPoint.length ? index + 1 : 0;

      setTempPoint1st(
        getCenterPointOfTwoPoints(listPoint[index], listPoint[beforeIndex])
      );
      setTempPoint2nd(
        getCenterPointOfTwoPoints(listPoint[index], listPoint[afterIndex])
      );
      if (!checkCurrentPointOfPolygon(index)) {
        handleSetCurrentPoint(index);
      }
    }
  };

  const checkCurrentPointOfPolygon = (index) =>
    isSelected && currentPointState === index;

  const width = 3;

  const handleClickTempPoint = (point) => () => {
    const listPoint = [...polygonProps.points];
    const currentIndex = currentPointState;
    const beforeIndex =
      currentIndex - 1 >= 0 ? currentIndex - 1 : listPoint.length - 1;
    const realIndex =
      JSON.stringify(
        getCenterPointOfTwoPoints(
          listPoint[currentIndex],
          listPoint[beforeIndex]
        )
      ) === JSON.stringify(point)
        ? beforeIndex + 1
        : currentIndex + 1;
    listPoint.splice(realIndex, 0, point);
    onChange &&
      onChange({
        ...polygonProps,
        points: listPoint,
      });
    setTempPoint1st([0, 0]);
    setTempPoint2nd([0, 0]);
    handleSetCurrentPoint(-1);
  };

  const flattenedPoints = (points) =>
    points
      .concat(
        isFinished
          ? []
          : curMousePos
          ? curMousePos.map((item) => item / ratio)
          : []
      )
      .reduce((a, b) => a.concat(b), []);

  const handleDragMoveLine = () => {
    const node = polygonRef.current;
    const newPoints = dragPoints.map((item) => [
      item[0] + node.x() / ratio,
      item[1] + node.y() / ratio,
    ]);
    node.x(0);
    node.y(0);

    setPolygonProps({
      ...polygonProps,
      points: [...newPoints],
    });
  };

  const handleDragStartLine = () => {
    setDragPoints(polygonProps.points);
  };

  const handleDragEndLine = () => {
    onChange && onChange(polygonProps);
    setDragPoints([]);
  };

  return (
    <>
      <Line
        points={flattenedPoints(polygonProps.points).map(
          (item) => item * ratio
        )}
        onClick={onSelect}
        stroke={`${changeOpacityOfColor(
          polygonProps.color,
          isSelected || !isFinished ? 0.8 : 0.5
        )}`}
        fill={`${changeOpacityOfColor(polygonProps.color, 0.3)}`}
        strokeWidth={2}
        closed
        onDragMove={handleDragMoveLine}
        onDragStart={handleDragStartLine}
        onDragEnd={handleDragEndLine}
        ref={polygonRef}
        {...rest}
      />
      {(isSelected || !isFinished) &&
        polygonProps.points.map((point, index) => {
          const x = point[0];
          const y = point[1];
          return (
            <Circle
              index={index}
              key={index}
              x={x * ratio}
              y={y * ratio}
              width={width}
              height={width}
              fill={checkCurrentPointOfPolygon(index) ? "white" : polygon.color}
              stroke={
                checkCurrentPointOfPolygon(index) ? "white" : polygon.color
              }
              radius={3}
              onDragStart={handleDragStartPoint}
              onDragMove={handleDragMovePoint}
              onDragEnd={handleDragEndPoint(index)}
              draggable
              onClick={handleClickPoint(index)}
            />
          );
        })}

      {isSelected && currentPointState >= 0 && (
        <>
          <Circle
            x={tempPoint1st[0] * ratio}
            y={tempPoint1st[1] * ratio}
            stroke="grey"
            fill="white"
            width={width}
            height={width}
            radius={4}
            onClick={handleClickTempPoint(tempPoint1st, -1)}
          />
          <Circle
            x={tempPoint2nd[0] * ratio}
            y={tempPoint2nd[1] * ratio}
            stroke="grey"
            fill="white"
            width={width}
            height={width}
            radius={4}
            onClick={handleClickTempPoint(tempPoint2nd, 1)}
          />
        </>
      )}
    </>
  );
}
Polygon.propTypes = {
  ratio: number,
  polygon: array,
  isFinished: bool,
  handleDragStartPoint: func,
  handleDragMovePoint: func,
  onChange: func,
  curMousePos: array,
  isPolygonMode: bool,
  setCurrentPolygon: func,
  currentPolygon: object,
  onSelect: func,
  isSelected: false,
  currentPoint: number,
  onChangeCurrentPoint: func,
};

const mapState = (state) => ({
  isPolygonMode: selectIsPolygonMode(state),
});

export default connect(mapState, {})(Polygon);
