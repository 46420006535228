import * as React from "react";

function Logout(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.11335 7.33329L6.39335 5.60663L7.33335 4.66663L10.6667 7.99996L7.33335 11.3333L6.39335 10.3933L8.11335 8.66663L1.66669 8.66663L1.66669 7.33329L8.11335 7.33329Z"
        fill="#555F7C"
      />
      <path
        d="M8 3.33333L12.6667 3.33333L12.6667 12.6667L8 12.6667L8 14L12.6667 14C13.4 14 14 13.4 14 12.6667L14 3.33333C14 2.6 13.4 2 12.6667 2L8 2L8 3.33333Z"
        fill="#555F7C"
      />
    </svg>
  );
}

export default Logout;
