/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Modal, Backdrop, Button, Fade } from "@material-ui/core";
import { func, bool, string } from "prop-types";
import { selectRoleCode } from "redux/app/selectors";
import { connect } from "react-redux";
import { Group } from "icons";
import { adminApp } from "api/constants";
import IntlMessage from "../../helpers/IntlMessage";

import useStyles from "./style";

function LimitationModal({ open, roleCode }) {
  const classes = useStyles();

  const handleUpgradePlan = () => {
    window.open(`${adminApp}/app/upgrade`, "_blank");
    window.focus();
  };

  const body = (
    <div className={classes.paper}>
      <p className={classes.textWarning}>
        <IntlMessage id="limit-time-modal.limit-reached" />
      </p>
      <div className={classes.containerImage}>
        <img className={classes.image} src={Group} alt="" />
        <p className={classes.textInform}>
          <IntlMessage id="limit-time-modal.you-have-reached-the-limit" />
        </p>
        <p className={classes.textIntro}>
          <IntlMessage id="limit-time-modal.please-upgrade-plan" />
        </p>
        {roleCode === "admin" && (
          <div className={classes.footerButton}>
            <Button
              onClick={handleUpgradePlan}
              className={classes.buttonMember}
              variant="contained"
              color="primary"
            >
              <IntlMessage id="limit-time-modal.upgrade-plan" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>{body}</Fade>
      </Modal>
    </div>
  );
}

LimitationModal.propTypes = {
  open: bool,
  onClose: func,
  roleCode: string,
};

const mapStateToProps = (state) => ({
  roleCode: selectRoleCode(state),
});

export default connect(mapStateToProps)(LimitationModal);
