import * as React from "react";

function Polygon(props) {
  return (
    <svg style={{ width: 10, height: 10 }} viewBox="0 0 24 24" {...props}>
      <path d="M23.804 9.388L12.304.603a.5.5 0 00-.607 0L.197 9.388a.5.5 0 00-.171.555l4.393 13.215c.068.204.26.342.475.342h14.215a.496.496 0 00.473-.342l4.393-13.215a.502.502 0 00-.171-.555zM18.748 22.5H5.255L1.089 9.965l10.913-8.336 10.913 8.337L18.748 22.5z" />
    </svg>
  );
}

export default Polygon;
