import React from "react";
import IrisCircle from "./IrisCircle";
import { func, string, array, number, bool, shape } from "prop-types";

function IrisCirclesLayer({
  isIrisCirclesShown,
  linesData,
  leftIrisRadius,
  dispatchLeftRadius,
  resizeLeftIrisCircle,
  ratio,
  dispatchRightRadius,
  resizeRightIrisCircle,
  rightIrisRadius,
}) {
  return (
    <>
      {isIrisCirclesShown && linesData.LeftIrisCenter && (
        <IrisCircle
          x={linesData.LeftIrisCenter.points[0]}
          y={linesData.LeftIrisCenter.points[1]}
          radius={leftIrisRadius * ratio}
          onDragEnd={dispatchLeftRadius}
          onDragMove={resizeLeftIrisCircle}
        />
      )}
      {isIrisCirclesShown && linesData.RightIrisCenter && (
        <IrisCircle
          x={linesData.RightIrisCenter.points[0]}
          y={linesData.RightIrisCenter.points[1]}
          radius={rightIrisRadius * ratio}
          onDragEnd={dispatchRightRadius}
          onDragMove={resizeRightIrisCircle}
        />
      )}
    </>
  );
}

IrisCirclesLayer.propTypes = {
  isIrisCirclesShown: bool,
  linesData: shape({
    LeftIrisCenter: shape({
      points: array,
      colour: string,
    }),
    RightIrisCenter: shape({
      points: array,
      colour: string,
    }),
  }),
  leftIrisRadius: number,
  dispatchLeftRadius: func,
  resizeLeftIrisCircle: func,
  ratio: number,
  dispatchRightRadius: func,
  resizeRightIrisCircle: func,
  rightIrisRadius: number,
};

export default IrisCirclesLayer;
