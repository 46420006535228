import {
  FETCH_INPUT_DATA,
  FETCH_INPUT_DATA_SUCCESS,
  NO_MORE_TO_FETCH,
  SELECT_ONE_SOURCE,
  SELECT_ONE_SOURCE_SUCCESS,
  GET_SOURCE_CSV_FILE,
  GET_SOURCE_CSV_FILE_ERROR,
  GET_SOURCE_CSV_FILE_SUCCESS,
  SUBMIT_IMAGE,
  SUBMIT_IMAGE_SUCCESS,
  SUBMIT_IMAGE_ERROR,
  FIT_IMAGE_SIZE,
  SAVE_EDITED_IMAGE,
  UPDATE_CURRENT_PAGE,
  CLEAN_SELECTED_IMAGE,
  REFETCH_INPUT_DATA,
  START_FETCHING_MORE,
  CHANGE_FILTER_BTN_STATUS,
  ROTATE_IMAGE,
  ROTATE_IMAGE_FINISH,
  MARK_BENCHMARK,
  MARK_BENCHMARK_SUCCESS,
  MARK_BENCHMARK_FAILURE,
  SCROLL_IMAGE,
  GET_NEXT_IMAGE_ID,
  SEARCH_PROJECT_TAG_BY_TEXT,
  CREATE_TAG_AND_ADD_TO_SOURCE,
  DELETE_TAG_OF_SOURCE,
  ADD_TAG_TO_SOURCE,
} from "./constants";

export const markBenchmarkSuccess = (payload) => ({
  type: MARK_BENCHMARK_SUCCESS,
  payload,
});

export const markBenchmarkFailure = (payload) => ({
  type: MARK_BENCHMARK_FAILURE,
  payload,
});

export const markBenchmark = (payload) => ({
  type: MARK_BENCHMARK,
  payload,
});

export function fetchInputData(payload) {
  return {
    type: FETCH_INPUT_DATA,
    payload,
  };
}

export function fetchInputDataSuccess(payload) {
  return {
    type: FETCH_INPUT_DATA_SUCCESS,
    payload,
  };
}

export function noMoreToFetch(payload) {
  return { type: NO_MORE_TO_FETCH, payload };
}

export const selectOneSource = (payload) => ({
  type: SELECT_ONE_SOURCE,
  payload,
});

export const selectOneSourceSuccess = (payload) => ({
  type: SELECT_ONE_SOURCE_SUCCESS,
  payload,
});

export const getSourceCsvFile = (payload) => ({
  type: GET_SOURCE_CSV_FILE,
  payload,
});

export const getSourceCsvFileSuccess = (payload) => ({
  type: GET_SOURCE_CSV_FILE_SUCCESS,
  payload,
});

export const getSourceCsvFileError = (payload) => ({
  type: GET_SOURCE_CSV_FILE_ERROR,
  payload,
});

export const submitImage = (payload) => ({
  type: SUBMIT_IMAGE,
  payload,
});

export const submitImageSuccess = (payload) => ({
  type: SUBMIT_IMAGE_SUCCESS,
  payload,
});

export const submitImageError = (payload) => ({
  type: SUBMIT_IMAGE_ERROR,
  payload,
});

export const actionFitImageSize = (payload) => ({
  type: FIT_IMAGE_SIZE,
  payload,
});

export const saveEditedImage = (payload) => ({
  type: SAVE_EDITED_IMAGE,
  payload,
});

export const cleanSelectedImage = (payload) => ({
  type: CLEAN_SELECTED_IMAGE,
  payload,
});

export const refetchInputData = (payload) => ({
  type: REFETCH_INPUT_DATA,
  payload,
});

export const updateCurrentPage = (payload) => ({
  type: UPDATE_CURRENT_PAGE,
  payload,
});

export const startFetchingMore = (payload) => ({
  type: START_FETCHING_MORE,
  payload,
});

export const changeFilterBtnStatus = (payload) => ({
  type: CHANGE_FILTER_BTN_STATUS,
  payload,
});

export const rotateImage = (payload) => ({
  type: ROTATE_IMAGE,
  payload,
});

export const rotateImageFinish = (payload) => ({
  type: ROTATE_IMAGE_FINISH,
  payload,
});

export const setScrollImage = (payload) => ({
  type: SCROLL_IMAGE,
  payload,
});

export const getNextImageId = (payload) => ({
  type: GET_NEXT_IMAGE_ID,
  payload,
});

export const searchProjectTagByText = ({ payload, callback }) => ({
  type: SEARCH_PROJECT_TAG_BY_TEXT,
  payload,
  callback,
});

export const createTagAndAddTagToSource = ({ payload, callback }) => ({
  type: CREATE_TAG_AND_ADD_TO_SOURCE,
  payload,
  callback,
});

export const deleteTagOfSource = ({ payload, callback }) => ({
  type: DELETE_TAG_OF_SOURCE,
  payload,
  callback,
});

export const addTagToSource = ({ payload, callback }) => ({
  type: ADD_TAG_TO_SOURCE,
  payload,
  callback,
});
