import { baseURL, commonHeader } from "api/constants";

export function getClassifications(sourceId, projectId) {
  return fetch(
    `${baseURL}/classify?sourceId=${sourceId}&projectId=${projectId}`,
    {
      method: "GET",
      ...commonHeader,
    }
  )
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}

export function updateClassificationData(payload) {
  return fetch(`${baseURL}/classify/update-classify-image`, {
    method: "POST",
    ...commonHeader,
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}

export function removeClassificationData(issueId) {
  return fetch(`${baseURL}/issue/${issueId}`, {
    method: "DELETE",
    ...commonHeader,
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}
