/**
 * The global state selectors
 */

import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectGlobal = (state) => state.app || initialState;

const selectUserFullname = (state) => state.app.currentUser.fullName;

const selectUserEmail = (state) => state.app.currentUser.email;

const selectUserId = (state) => state.app.currentUser.userId;

const selectUserImage = (state) => state.app.currentUser.imagePreviewPath;

const selectIsBootingFinished = (state) => state.app.isBootingFinished;

const selectRoleCode = (state) => state.app.roleCode;

const selectDatasetId = (state) => state.app.sourceInfo.datasetId;

const selectProjectId = (state) => state.app.sourceInfo.projectId;

const selectSourceId = (state) => state.app.sourceInfo.sourceId;

const selectCustomerReview = (state) => state.app.sourceInfo.customerReview;

const selectIsChangingDropdown = (state) => state.app.isChangingDropdown;

const selectListUser = (state) => state.app.listUser;
const selectLabelerId = (state) => state.app.labelerId;

const selectDialogOpen = (state) => state.app.dialogOpen;

export const selectPermissionComponent = (module) =>
  createSelector(selectGlobal, (global) => global.moduleCode.includes(module));

export const checkIsCustomerReviewRole = createSelector(
  selectGlobal,
  (globalState) =>
    globalState.moduleCode.some((module) => {
      return module.match(/customer/g);
    })
);

export const selectIsCustomerApproved = (state) =>
  state.app.moduleCode.includes("customer_approved");

export const selectIsCustomerDeclined = (state) =>
  state.app.moduleCode.includes("customer_declined");

const selectCurrentDropdownStatus = (state) => state.app.currDropDownStatus;

export const selectCurrentUser = (state) => state.app.currentUser;

export const selectLocale = (state) => state.app.locale;

export {
  selectIsChangingDropdown,
  selectCurrentDropdownStatus,
  selectGlobal,
  selectUserFullname,
  selectUserImage,
  selectUserId,
  selectIsBootingFinished,
  selectDatasetId,
  selectProjectId,
  selectRoleCode,
  selectCustomerReview,
  selectSourceId,
  selectListUser,
  selectLabelerId,
  selectDialogOpen,
  selectUserEmail,
};
