import { baseURL, commonHeader, userLogCommonHeader } from "api/constants";
import { BOX_MODE } from "redux/tools/enums";

export function saveBox({
  sourceId,
  boxItem,
  color,
  settingQuality,
  isBenchmark,
}) {
  return fetch(`${baseURL}/labelBox`, {
    method: "POST",
    ...userLogCommonHeader,
    headers: {
      ...userLogCommonHeader.headers,
      type: BOX_MODE,
    },
    body: JSON.stringify({
      sourceId,
      boxItem,
      color,
      settingQuality,
      isBenchmark,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function updateBox({ id, sourceId, boxItem, settingQuality }) {
  return fetch(`${baseURL}/labelBox/${id}`, {
    method: "PUT",
    ...commonHeader,
    body: JSON.stringify({
      sourceId,
      boxItem,
      settingQuality,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}
