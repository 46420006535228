import {
  delay,
  all,
  fork,
  select,
  call,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { get, some } from "lodash";
import { toCamelCase, measureLength } from "helpers";

import { updateImage } from "api/image";
import { selectImageId } from "redux/image/selectors";
import {
  GET_SOURCE_CSV_FILE_SUCCESS,
  SELECT_ONE_SOURCE_SUCCESS,
} from "redux/image/constants";
import { dispatchIrisRadius } from "redux/points/actions";
import { ADD_ISSUE_SUCCESS, EDIT_ISSUE_SUCCESS } from "redux/issues/actions";
import { setPointsCanvas } from "redux/canvas/actions";
import { CHANGE_IMAGE_RATIO, CHANGE_DRAW_COUNT } from "redux/canvas/constants";
import {
  selectImageRatio,
  selectPointsCanvas,
  selectDrawCount,
  selectIrisCenterAndEyeCornerPoints,
} from "redux/canvas/selectors";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { SELECT_TOOL } from "redux/tools/constants";
import { deselectTool } from "redux/tools/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import { onePointOnIrisCircle, drawIrisCircle } from "./autoGenerateIrisCircle";
import {
  selectPoints,
  makeSelectedPoint,
  selectDisplayingRegions,
  selectLeftRadius,
  selectRightRadius,
} from "./selectors";
import {
  submitPointsError,
  submitPointsSuccess,
  submitPoints,
  setPoints,
  setSelectedPoints,
  changeDisplayRegions,
  dispatchIrisRadiusSuccess,
} from "./actions";
import { SUBMIT_POINTS, DISPATCH_IRIS_RADIUS } from "./constants";
import { savePoints } from "api/image";
import { selectImageAnnotationType } from "redux/image/selectors";

function* watchSubmitPoints() {
  yield takeEvery(SUBMIT_POINTS, function* ({ payload }) {
    const sourceId = yield select(selectImageId);
    const points = yield select(selectPoints);

    const convertedData = points.map((point) => {
      const { x, y, isChanged, issued, region } = point;
      return {
        x,
        y,
        region,
        changed: !!isChanged,
        issues: !!issued,
      };
    });

    const response = yield call(savePoints, {
      sourceId,
      dataPoint: convertedData,
    });

    if (response.success) {
      yield put(submitPointsSuccess());
      if (payload === "save") {
        yield put(showNotification({
          type: "success",
          msg: "Points saved!",
          msgId: "notification.points-saved",
        }));
      } else {
        yield put(
          showNotification({
            type: "success",
            msg: "Submit points success!",
            msgId: "notification.submit-points-success",
          })
        );
      }
      const id = yield select(selectCurrentNotiId);
      delayRemoveNotification(id);
    } else {
      yield put(submitPointsError({msg: "Submit points Error. Please try again!", msgId: "notification.submit-points-error"}));
    }
  });
}

function* watchIssueSuccess() {
  const annotationType = yield select(selectImageAnnotationType);
  if (annotationType && annotationType === "image-facial") {
    yield takeEvery(ADD_ISSUE_SUCCESS, doSaveIssueByPoint);
    yield takeEvery(EDIT_ISSUE_SUCCESS, doSaveIssueByPoint);
  }
}

function* doSaveIssueByPoint(action) {
  const { content } = action.payload;
  const points = yield select(selectPoints);
  const ratio = yield select(selectImageRatio);
  if (content.includes("#")) {
    yield saveIssueSubmitForm(content);
    return;
  }
  const selectedPoints = yield select(makeSelectedPoint);

  const { id, x, y, isChanged, issued, index, region } = selectedPoints;
  const payload = {
    id,
    x: x / ratio,
    y: y / ratio,
    issued: !issued,
    index,
    isChanged,
    region,
  };
  const payloadCanvas = { ...payload, x, y };

  yield put(setSelectedPoints(payloadCanvas));
  const newPointsCanvas = [];
  const newPoints = points.reduce((acc, point, index) => {
    const { id } = point;
    const newPoint = { ...point };
    const newPointCanvas = { ...point, x: point.x * ratio, y: point.y * ratio };
    if (id === payload.id) {
      acc[index] = payload;
      newPointsCanvas.push(payloadCanvas);
    } else {
      acc.push(newPoint);
      newPointsCanvas.push(newPointCanvas);
    }
    return acc;
  }, []);

  yield put(setPoints(newPoints));
  yield put(setPointsCanvas(newPointsCanvas));
  // Call submitPoints to update the issue status of each point in the database.
  yield put(submitPoints());
}

function* saveIssueSubmitForm(content) {
  const regexp = /#\d+/gm;
  const allPointIndice = content
    .match(regexp)
    .map((point) => Number(point.split("#").join("").trim()));

  const points = yield select(selectPoints);
  const pointsCanvas = yield select(selectPointsCanvas);

  const newPointsCanvas = [...pointsCanvas];
  const newPointStartId = newPointsCanvas[0].id;

  const newPoints = points.reduce((acc, point, index) => {
    const { id } = point;
    if (allPointIndice.includes(id)) {
      acc[index] = { ...point, issued: true };

      const newIndex = index - newPointStartId;
      if (newIndex) newPointsCanvas[newIndex].issued = true;
    } else {
      acc.push(point);
    }

    return acc;
  }, []);

  yield put(setPoints(newPoints));
  yield put(setPointsCanvas(newPointsCanvas));
  // Call submitPoints to update the issue status of each point in the database.
  yield put(submitPoints());
}

function* watchChangeImageRatio() {
  yield takeEvery(CHANGE_IMAGE_RATIO, function* (action) {
    const sourceId = yield select(selectImageId);
    const valueRatio = action.payload;
    var imageRadio = { id: sourceId, radio: valueRatio };
    var localRadio = "saveRadio";
    var saveRadio = JSON.parse(localStorage.getItem(localRadio)) || [];
    if (saveRadio.length === 0) {
      saveRadio.push(imageRadio);
    } else {
      const checkImage = some(saveRadio, ["id", sourceId]);
      if (checkImage) {
        saveRadio = saveRadio.filter((image) => image.id !== sourceId);
        saveRadio.push(imageRadio);
      } else {
        saveRadio.push(imageRadio);
      }
    }
    localStorage.setItem(localRadio, JSON.stringify(saveRadio));

    const points = yield select(selectPoints);
    const displayingRegions = yield select(selectDisplayingRegions);
    const newPoints = points.reduce((acc, point) => {
      if (displayingRegions.has(point.region)) {
        acc.push({
          ...point,
          x: point.x * action.payload,
          y: point.y * action.payload,
        });
      }
      return acc;
    }, []);
    yield put(setPointsCanvas(newPoints));
  });
}

function* watchGetCsvSuccess() {
  yield takeEvery(GET_SOURCE_CSV_FILE_SUCCESS, function* (action) {
    const points = get(action, "payload.points", []);
    const ratio = yield select(selectImageRatio);
    // const currLeftRadius = yield select(selectLeftRadius);
    // const currRightRadius = yield select(selectRightRadius);
    const regions = new Set();
    const newPoints = points.map((point) => {
      regions.add(point.region);
      return {
        ...point,
        x: point.x * Number(ratio),
        y: point.y * Number(ratio),
      };
    });
    regions.delete("LeftIris");
    regions.delete("RightIris");
    yield put(setPoints(points));
    yield put(setPointsCanvas(newPoints));
    yield put(changeDisplayRegions(regions));

    let holder;
    const irisCirclesRadius = points.reduce(
      (acc, point) => {
        if (point.id === 95 || point.id === 108) {
          holder = { ...point };
        }
        if (point.id === 103) {
          acc.left = measureLength(point.x, point.y, holder.x, holder.y);
        }

        if (point.id === 112) {
          acc.right = measureLength(point.x, point.y, holder.x, holder.y);
        }

        return acc;
      },
      { left: null, right: null }
    );

    if (!irisCirclesRadius.left || !irisCirclesRadius.right) return;

    yield put(
      dispatchIrisRadius({ type: "left", radius: irisCirclesRadius.left })
    );
    yield put(
      dispatchIrisRadius({ type: "right", radius: irisCirclesRadius.right })
    );
  });
}

function* watchSelectOneSourceSuccess() {
  yield takeLatest(SELECT_ONE_SOURCE_SUCCESS, function* () {
    yield put(setSelectedPoints({}));
  });
}

function* watchDrawCountToSavePoint() {
  yield takeEvery(CHANGE_DRAW_COUNT, function* () {
    const count = yield select(selectDrawCount);
    if (count % 50 === 0) {
      yield delay(2000);
      yield put(submitPoints("save"));
    }
  });
}

function* watchAutoCreateIrisCircles() {
  yield takeEvery(SELECT_TOOL, function* ({ payload }) {
    if (payload !== "autoIris") return;
    const eyePoints = yield select(selectIrisCenterAndEyeCornerPoints);
    const leftRadius = yield select(selectLeftRadius);
    const rightRadius = yield select(selectRightRadius);
    const points = yield select(selectPoints);
    const pointsCanvas = yield select(selectPointsCanvas);
    const ratio = yield select(selectImageRatio);
    const { LeftEye, LeftIrisCenter, RightEye, RightIrisCenter } = eyePoints;
    if (!leftRadius || !rightRadius) {
      yield put(deselectTool());
      yield put(
        showNotification({
          type: "error",
          msg: "Please adjust iris circles!",
          msgId: "notification.please-adjust-iris-circles",
        })
      );
      return;
    }
    const leftStartEndPoints = onePointOnIrisCircle(
      LeftIrisCenter,
      LeftEye[71],
      LeftEye[77],
      leftRadius * ratio
    );
    const leftPoint = leftStartEndPoints.reduce((acc, point) => {
      if (point.start) {
        acc = { ...point, id: 95 };
      }
      return acc;
    });
    const leftSlope =
      (LeftEye[71].y - LeftEye[77].y) / (LeftEye[71].x - LeftEye[77].x);
    const leftIrisCircle = drawIrisCircle(
      LeftIrisCenter,
      leftRadius * ratio,
      leftPoint,
      leftSlope
    );

    const rightStartEndPoints = onePointOnIrisCircle(
      RightIrisCenter,
      RightEye[83],
      RightEye[89],
      rightRadius * ratio
    );
    const rightPoint = rightStartEndPoints.reduce((acc, point) => {
      if (point.start) {
        acc = { ...point, id: 108 };
      }
      return acc;
    });
    const rightSlope =
      (RightEye[89].y - RightEye[83].y) / (RightEye[89].x - RightEye[83].x);
    const rightIrisCircle = drawIrisCircle(
      RightIrisCenter,
      rightRadius * ratio,
      rightPoint,
      rightSlope
    );

    const newPoints = points.reduce((acc, point) => {
      const { id } = point;
      const newPoint = { ...point };
      if (leftIrisCircle[id]) {
        const { x, y } = leftIrisCircle[id];
        newPoint.x = x / ratio;
        newPoint.y = y / ratio;
        newPoint.isChanged = true;
      }
      if (rightIrisCircle[id]) {
        const { x, y } = rightIrisCircle[id];
        newPoint.x = x / ratio;
        newPoint.y = y / ratio;
        newPoint.isChanged = true;
      }
      acc.push(newPoint);
      return acc;
    }, []);

    const newPointsCanvas = pointsCanvas.reduce((acc, point) => {
      const { id } = point;
      const newPoint = { ...point };
      if (leftIrisCircle[id]) {
        const { x, y } = leftIrisCircle[id];
        newPoint.x = x;
        newPoint.y = y;
        newPoint.isChanged = true;
      }
      if (rightIrisCircle[id]) {
        const { x, y } = rightIrisCircle[id];
        newPoint.x = x;
        newPoint.y = y;
        newPoint.isChanged = true;
      }
      acc.push(newPoint);
      return acc;
    }, []);

    yield delay(1000);

    yield put(setPoints(newPoints));
    yield put(setPointsCanvas(newPointsCanvas));
    yield put(deselectTool());
  });
}

function* watchDispatchIrisRadius() {
  yield takeEvery(DISPATCH_IRIS_RADIUS, function* ({ payload }) {
    const { type, radius } = payload;
    const imageId = yield select(selectImageId);
    const sending =
      type === "left" ? { leftRadius: radius } : { rightRadius: radius };

    const response = yield call(updateImage, {
      imageId,
      payload: { ...sending },
    });
    if (response.success) {
      const camelcaseData = toCamelCase(response.data);
      yield put(dispatchIrisRadiusSuccess(camelcaseData));
      yield put(
        showNotification({
          type: "success",
          msg: "Iris circle radius changed",
          msgId: "notification.iris-circle-radius-changed",
        })
      );
    } else {
      yield put(
        showNotification({
          type: "error",
          msg: "Fail to save circle radius",
          msgId: "notification.fail-to-save-circle-radius",
        })
      );
    }
    const id = yield select(selectCurrentNotiId);
    delayRemoveNotification(id);
  });
}

export default function* saga() {
  yield all([
    fork(watchDrawCountToSavePoint),
    fork(watchSelectOneSourceSuccess),
    fork(watchGetCsvSuccess),
    fork(watchChangeImageRatio),
    fork(watchSubmitPoints),
    fork(watchIssueSuccess),
    fork(watchAutoCreateIrisCircles),
    fork(watchDispatchIrisRadius),
  ]);
}
