import React, { Fragment } from "react";
import { string, bool, func, any, arrayOf } from "prop-types";
import { connect } from "react-redux";

import {
  selectRoleCode,
  selectCustomerReview,
  selectPermissionComponent,
} from "redux/app/selectors";
import { selectImageStatus } from "redux/image/selectors";
import ExportSegmentation from "components/ExportSegmentation";
import { getParameterByName } from "helpers";
import { getAllShapes } from "redux/shape/selector";
import IntlMessage from "../../helpers/IntlMessage";

import style from "./style.module.scss";

function ButtonGroup({
  isCustomerReviewRole,
  handleOpenDialog,
  imageStatus,
  enableCustomerApprove,
  enableApprove,
  enableDecline,
  enableSubmit,
  enableSkip,
  enableDoubleCheck,
  roleCode,
  allShapes,
}) {
  const isViewResult = getParameterByName("isViewResult");
  const isActionHistory = getParameterByName("isActionHistory");
  const isBenchmark = isViewResult || isActionHistory;
  const isAdminOrProjectManager =
    roleCode === "admin" || roleCode === "project_manager";

  function isNew(status) {
    return ["new", "assigned", "inprogress"].includes(status);
  }

  function enableBenchmarkSubmit() {
    return allShapes.length > 0;
  }

  function isReopenEnabled(status) {
    const checkArr = roleCode.includes("labeler")
      ? ["skipped", "submitted"]
      : ["approved", "skipped"];
    return checkArr.includes(status);
  }

  function isReopen(status) {
    return ["reopen"].includes(status);
  }

  function renderCustomerReviewButtons() {
    if (isNew(imageStatus) && !isBenchmark) {
      return (
        <>
          {enableCustomerApprove && (
            <button
              className={`${style.actionButton} ${style.approveButton}`}
              type="submit"
              data-content="approve"
              onClick={handleOpenDialog}
            >
              <IntlMessage id="common.approve" />
            </button>
          )}
          {enableDecline && (
            <button
              className={`${style.actionButton} ${style.declineButton}`}
              type="submit"
              data-content="decline"
              onClick={handleOpenDialog}
            >
              <IntlMessage id="common.decline" />
            </button>
          )}
        </>
      );
    }
  }

  function renderButtonsForOtherRoles() {
    if (
      (isNew(imageStatus) ||
        !(imageStatus === "skipped" || imageStatus === "approved") ||
        isReopen(imageStatus)) &&
      !isBenchmark
    ) {
      return (
        <>
          {enableSkip && (
            <button
              className={`${style.actionButton} ${style.skipButton}`}
              data-content="skip"
              onClick={handleOpenDialog}
            >
              <IntlMessage id="common.skip" />
            </button>
          )}
          {enableApprove && (
            <button
              className={`${style.actionButton} ${style.approveButton}`}
              type="submit"
              data-content="approve"
              onClick={handleOpenDialog}
            >
              <IntlMessage id="common.approve" />
            </button>
          )}
          {enableSubmit && imageStatus !== "submitted" && (
            <button
              className={`${style.actionButton} ${style.submitButton}`}
              data-content="submit"
              onClick={handleOpenDialog}
            >
              <IntlMessage id="common.submit" />
            </button>
          )}
        </>
      );
    }
  }

  function renderReopenButton() {
    if (isReopenEnabled(imageStatus) && !isBenchmark) {
      return (
        <button
          className={`${style.actionButton} ${style.reopenButton}`}
          data-content="reopen"
          onClick={handleOpenDialog}
        >
          <IntlMessage id="common.reopen" />
        </button>
      );
    }
  }

  function renderDoubleCheckButton() {
    return enableDoubleCheck &&
      (imageStatus === "approved" || imageStatus === "submitted") &&
      isAdminOrProjectManager ? (
      <button
        className={`${style.actionButton} ${style.doubleCheckButton}`}
        data-content="doublecheck"
        onClick={handleOpenDialog}
      >
        <IntlMessage id="common.doublecheck" />
      </button>
    ) : null;
  }

  function renderBenchmarkButton() {
    if (isBenchmark && isNew(imageStatus) && enableBenchmarkSubmit()) {
      return (
        <button
          className={`${style.actionButton} ${style.reopenButton}`}
          data-content="submit"
          onClick={handleOpenDialog}
        >
          Submit
        </button>
      );
    }
  }

  return (
    <div className={style.buttonGroupContainer}>
      {isCustomerReviewRole ? (
        renderCustomerReviewButtons()
      ) : (
        <Fragment>
          {renderButtonsForOtherRoles()}
          {renderReopenButton()}
          {renderDoubleCheckButton()}
          {renderBenchmarkButton()}
        </Fragment>
      )}
      <ExportSegmentation
        classList={`${style.actionButton} ${style.exportImageButton}`}
      />
    </div>
  );
}

ButtonGroup.propTypes = {
  isCustomerReviewRole: bool,
  handleOpenDialog: func,
  isShowAprroveDelineButtons: bool,
  roleCode: string,
  imageStatus: string,
  enableCustomerApprove: bool,
  enableApprove: bool,
  enableDecline: bool,
  enableSubmit: bool,
  enableSkip: bool,
  enableDoubleCheck: bool,
  allShapes: arrayOf(any),
};

const mapStateToProps = (state) => ({
  imageStatus: selectImageStatus(state),
  isCustomerReviewRole: selectCustomerReview(state),
  roleCode: selectRoleCode(state),
  enableCustomerApprove: selectPermissionComponent("customer_approved")(state),
  enableDecline: selectPermissionComponent("customer_declined")(state),
  enableSubmit: selectPermissionComponent("labeling_submit")(state),
  enableSkip: selectPermissionComponent("labeling_skip")(state),
  enableApprove: selectPermissionComponent("labeling_approve")(state),
  enableDoubleCheck: selectPermissionComponent("labeling_doublecheck")(state),
  allShapes: getAllShapes(state),
});

export default connect(mapStateToProps, null)(ButtonGroup);
