import {
  all,
  fork,
  put,
  select,
  takeEvery,
  call,
  cancel,
} from "redux-saga/effects";
import {
  UNDO_ANNOTATION,
  REDO_ANNOTATION,
  SET_TOOGLE_UNDO_REDO_FLAG,
} from "redux/annotationHistory/constants";
import {
  selectAnnotationHistory,
  selectCurrentTarget,
} from "redux/annotationHistory/selectors";
import { setCurrentTarget } from "redux/annotationHistory/actions";
import {
  prepareUndoData,
  prepareRedoData,
} from "redux/annotationHistory/prepareData";
import { showNotification } from "redux/notification/actions";

function* watchUndoAnnotationSaga() {
  yield takeEvery(UNDO_ANNOTATION, function* () {
    const history = yield select(selectAnnotationHistory);
    const currentTarget = yield select(selectCurrentTarget);
    if (history.length === 0) yield cancel();
    if (!currentTarget) yield cancel();
    const currentIndex = history.findIndex((item) => item.id === currentTarget);
    const action = yield call(prepareUndoData, history[currentIndex]);
    if (!action.type) yield cancel();
    yield put(action);
    yield put(
      showNotification({
        type: "success",
        msg: "Undo annotation.",
        msgId: "Undo annotation",
      })
    );
    const nextCurrentTarget =
      currentIndex - 1 < 0 ? null : history[currentIndex - 1].id;
    yield put(setCurrentTarget(nextCurrentTarget));
    yield put({ type: SET_TOOGLE_UNDO_REDO_FLAG });
  });
}

function* watchRedoAnnotationSaga() {
  yield takeEvery(REDO_ANNOTATION, function* () {
    const history = yield select(selectAnnotationHistory);
    const currentTarget = yield select(selectCurrentTarget);
    const currentIndex = currentTarget
      ? history.findIndex((item) => item.id === currentTarget)
      : -1;
    if (history.length === 0) yield cancel();
    if (currentIndex === history.length - 1) yield cancel();
    const action = yield call(prepareRedoData, history[currentIndex + 1]);
    if (!action.type) yield cancel();
    yield put(action);
    yield put(
      showNotification({
        type: "success",
        msg: "Redo annotation.",
        msgId: "notification.auto-anno-error",
      })
    );
    const nextCurrentTarget =
      currentIndex === history.length - 1
        ? currentIndex
        : history[currentIndex + 1].id;

    yield put(setCurrentTarget(nextCurrentTarget));
    yield put({ type: SET_TOOGLE_UNDO_REDO_FLAG });
  });
}

export default function* boxSaga() {
  yield all([fork(watchUndoAnnotationSaga)]);
  yield all([fork(watchRedoAnnotationSaga)]);
}
