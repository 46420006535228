import { get } from "lodash";

import {
  baseURL,
  adminBE,
  commonHeader,
  payloadToCamelCase,
  baseURLAdmin,
  commonHeaderAdmin,
  userLogCommonHeader,
} from "api/constants";
import { POINT_MODE } from "redux/tools/enums";

export async function uploadImageToS3(path, file) {
  const dirName = path;

  const fileName = `${dirName}/${file.name}`;

  const response = await getUploadUrl(fileName);
  const { fetchUrl, uploadUrl } = get(response, "data", {});

  if (fetchUrl && uploadUrl) {
    await uploadFile(uploadUrl, file);
    return fetchUrl;
  }
}

export const uploadFile = (url, file) => {
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
    },
    body: file,
  });
};

export const getUploadUrl = (fileName) => {
  const url = `${adminBE}/storage/get-url?fileName=${fileName}`;
  return fetch(url, {
    ...commonHeaderAdmin,
  })
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};

export const getImageBlob = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .catch((error) => console.log(`Failed because: ${error}`));

export const updateMetadataImage = (sourceId, metadata) => {
  let url = `${baseURL}/source/${sourceId}`;
  return fetch(url, {
    method: "PUT",
    ...commonHeader,
    body: JSON.stringify({
      metadata,
    }),
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
};

export function fetchImage(
  id,
  isCustomerReview,
  alterUserId,
  isBenchmark,
  status,
  type
) {
  let url = `${baseURL}/source/${id}`;
  if (isCustomerReview) url += `?customerReview=${isCustomerReview}`;
  if (alterUserId) {
    if (url.includes("?")) url += `&alterUserId=${alterUserId}`;
    else url += `?alterUserId=${alterUserId}`;
  }
  if (isBenchmark) {
    if (url.includes("?")) url += `&isBenchmark=${isBenchmark}&type=${type}`;
    else url += `?isBenchmark=${isBenchmark}&type=${type}`;

    if (status) {
      url += `&status=${status}`;
    }
  }

  return fetch(url, {
    method: "GET",
    ...commonHeader,
  })
    .then((res) => res.json())
    .then((payload) => payloadToCamelCase(payload))
    .catch((error) => ({ error: true, ...error }));
}

export function fetchImages(payload) {
  const {
    projectId,
    limit,
    offset,
    status,
    datasetId,
    customerReview,
    labelId,
    imageInitial,
    tagIds,
  } = payload;
  let url = `${baseURL}/source?projectId=${projectId}&limit=${limit}&offset=${offset}`;
  if (status) url += `&status=${status}`;
  if (datasetId) url += `&datasetId=${datasetId}`;
  if (customerReview) url += `&customerReview=${customerReview}`;
  if (labelId) url += `&labelId=${labelId}`;
  if (imageInitial) url += `&imageInitial=${imageInitial}`;
  if (tagIds) {
    tagIds.forEach((tag) => {
      url += `&tagIds[]=${tag}`;
    });
  }
  return fetch(url, {
    method: "GET",
    ...commonHeader,
  })
    .then((res) => res.json())
    .then((payload) => {
      const sortData = payloadToCamelCase(payload);
      return sortData;
    })
    .catch((error) => console.error(error)); // eslint-disable-line no-console
}

export function fetchRecentImages(offset, limit, status) {
  try {
    return fetch(
      `${baseURL}/source?limit=${limit}&offset=${offset}&status=${status}`,
      {
        method: "GET",
        ...commonHeader,
      }
    )
      .then((res) => res.json())
      .catch((error) => console.error(error)); // eslint-disable-line no-console
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
}

export function updateImage({ imageId, payload = {} }) {
  return fetch(`${baseURL}/source/${imageId}`, {
    method: "PUT",
    ...commonHeader,
    body: JSON.stringify({
      ...payload,
    }),
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}

export function fetchSourceCsvFile(sourceId) {
  return fetch(`${baseURL}/source/GetSourceCsvFile/${sourceId}`, {
    method: "GET",
    ...commonHeader,
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}

export function savePoints({ sourceId, dataPoint }) {
  return fetch(`${baseURL}/source/submit-new-csv-file`, {
    method: "POST",
    ...userLogCommonHeader,
    headers: {
      ...userLogCommonHeader.headers,
      type: POINT_MODE,
    },
    body: JSON.stringify({
      sourceId,
      dataPoint,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function apiSaveEditedImg({ sourceId, dataImage }) {
  return fetch(`${baseURL}/source/submit-new-image`, {
    method: "POST",
    ...commonHeader,
    body: JSON.stringify({
      sourceId,
      dataImage,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function getUserInProject({ projectId }) {
  return fetch(
    `${baseURLAdmin}/dataset/get-user-in-project?projectId=${projectId}`,
    {
      method: "GET",
      ...commonHeaderAdmin,
    }
  )
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function markBenchmarkApi({ sourceId, isBenchmark }) {
  return fetch(`${baseURLAdmin}/dataset/mark-benchmark-file`, {
    method: "POST",
    ...commonHeaderAdmin,
    body: JSON.stringify({
      sourceId,
      isBenchmark,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function getViewResultBenchmarking({
  sourceId,
  pageSize = 10,
  currentPage = 1,
  status = "new",
  alterUserId,
}) {
  let query = `${baseURLAdmin}/dataset/get-source-labeling-for-benchmarking?sourceId=${sourceId}&pageSize=${pageSize}&currentPage=${currentPage}&status=${status}`;
  if (alterUserId) {
    query += `&alterUserId=${alterUserId}`;
  }
  return fetch(query, {
    method: "GET",
    ...commonHeaderAdmin,
  })
    .then((res) => res.json())
    .then((payload) => {
      const sortData = payloadToCamelCase(payload);
      return sortData;
    })
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function getActionHistoryBenchmarking({
  projectId,
  pageSize = 10,
  currentPage = 1,
  status = "new",
  alterUserId,
  imageInitial,
}) {
  let query = `${baseURLAdmin}/dataset/get-source-labeling-for-action-history?projectId=${projectId}&pageSize=${pageSize}&currentPage=${currentPage}&status=${status}`;
  if (alterUserId) {
    query += `&alterUserId=${alterUserId}`;
  }
  if (imageInitial) {
    query += `&imageInitial=${imageInitial}`;
  }
  return fetch(query, {
    method: "GET",
    ...commonHeaderAdmin,
  })
    .then((res) => res.json())
    .then((payload) => {
      const sortData = payloadToCamelCase(payload);
      return sortData;
    })
    .catch((error) => {
      return { error: true, ...error };
    });
}

export function submitBenchmarkImageApi({ sourceId }) {
  return fetch(
    `${baseURLAdmin}/dataset/submit-benchmark-image?sourceId=${sourceId}`,
    {
      method: "GET",
      ...commonHeaderAdmin,
    }
  )
    .then((res) => res.json())
    .catch((error) => {
      return { error: true, ...error };
    });
}

export function searchProjectTagByTextAPI({ projectId, textSearch }) {
  return fetch(`${baseURLAdmin}/tag/searchProjectTagByName`, {
    method: "POST",
    ...commonHeaderAdmin,
    body: JSON.stringify({
      textSearch,
      projectId: Number(projectId),
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function deleteTagOfSourceAPI({ projectId, ...payload }) {
  return fetch(`${baseURLAdmin}/tag/removeTagFromLabelSource`, {
    method: "POST",
    ...commonHeaderAdmin,
    body: JSON.stringify({
      ...payload,
      projectId: Number(projectId),
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function createProjectTagAPI({ projectId, newtag }) {
  return fetch(`${baseURLAdmin}/tag/addNewTag`, {
    method: "POST",
    ...commonHeaderAdmin,
    body: JSON.stringify({
      newtag,
      projectId: Number(projectId),
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function addTagToSourceAPI({ projectId, tags, ...payload }) {
  return fetch(`${baseURLAdmin}/tag/addTagToLabelSource`, {
    method: "POST",
    ...commonHeaderAdmin,
    body: JSON.stringify({
      ...payload,
      projectId: Number(projectId),
      tags: tags?.map((item) => Number(item)),
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}
