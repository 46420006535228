function authenticatedHandler(data, emitter) {
  return emitter({ event: "authenticated" });
}

function unauthorizedHandler(data, emitter) {
  data.event = "unauthorized";
  return emitter(data);
}

export function authenticationOn(socket, emitter, payload) {
  socket.emit("authenticate", { token: payload });
  socket.on("authenticated", (data) => authenticatedHandler(data, emitter));
  socket.on("unauthorized", (data) => unauthorizedHandler(data, emitter));
}

export function authenticationOff(socket, emitter) {
  socket.off("authenticated", (data) => authenticatedHandler(data, emitter));
  socket.off("unauthorized", (data) => unauthorizedHandler(data, emitter));
}
