import React from "react";
import { connect } from "react-redux";
import {
  string,
  arrayOf,
  objectOf,
  array,
  object,
  number,
  func,
  any,
} from "prop-types";
import ItemGroup from "./ItemGroup";
import { makePointData, selectPoints } from "redux/points/selectors";
import {
  selectDisplayingRegions,
  selectAllRegions,
} from "redux/points/selectors";
import { changeDisplayRegions } from "redux/points/actions";
import { selectImageRatio } from "redux/canvas/selectors";
import {
  showCheckedRegions,
  hideUncheckedRegions,
  changeDrawCount,
  toggleDisplayAllRegions,
} from "redux/canvas/actions";
import styles from "./style.module.scss";
import IntlMessage from "../../helpers/IntlMessage";

const dataHead = [
  {
    name: "Index",
    accessor: "index",
  },
  {
    name: "X - Y",
    accessor: "position",
  },
  {
    name: "Issue",
    accessor: "issue",
  },
  {
    name: "Edited",
    accessor: "isChanged",
  },
];

function toDisplay(text) {
  const arr = text.split("");
  const result = arr.reduce((acc, char, index) => {
    if (index !== 0 && char === char.toUpperCase()) {
      acc.push(" ");
    }
    acc.push(char);
    return acc;
  }, []);

  return result.join("");
}

function arrayToSet(array) {
  const newSet = new Set();
  for (let i = 0; i < array.length; i++) {
    newSet.add(array[i]);
  }
  return newSet;
}

function ListContainer(props) {
  const gridHead =
    "27px minmax(30px, 0.5fr) minmax(80px, 1fr) minmax(50px, 0.5fr) 50px";

  const gridBody =
    "25px minmax(30px, 0.5fr) minmax(80px, 1fr) minmax(50px, 0.5fr) 40px";

  const regions = Object.keys(props.dataBody);

  function handleCheckHeader(event) {
    const value = event.target.checked;

    const newPoints = props.points.map((point) => ({
      ...point,
      x: point.x * props.ratio,
      y: point.y * props.ratio,
    }));
    if (value) {
      const newRegions = arrayToSet(props.regions);
      props.changeDisplayRegions(newRegions);
      props.toggleDisplayAllRegions(newPoints);
    }
    if (!value) {
      const newRegions = new Set();
      props.changeDisplayRegions(newRegions);
      props.toggleDisplayAllRegions([]);
      props.changeDrawCount();
    }
  }

  return (
    <>
      {props.dataBody && regions.length !== 0 ? (
        <div>
          <div
            className={styles.listHead}
            style={{
              gridTemplateColumns: gridHead,
            }}
          >
            <span className={styles.inputWrapper}>
              <input
                type="checkbox"
                checked={props.displayingRegions.size !== 0}
                onChange={handleCheckHeader}
              />
            </span>
            {dataHead.map((item, index) => (
              <span
                style={index === 0 || index === 1 ? { textAlign: "left" } : {}}
                key={item.name}
              >
                {item.name}
              </span>
            ))}
          </div>
          <hr />
          <div>
            {regions.map((item) => (
              <ItemGroup
                key={item}
                regionTypeCode={item}
                region={toDisplay(item)}
                points={props.dataBody[item]}
                gridBody={gridBody}
                displayingRegions={props.displayingRegions}
              />
            ))}
          </div>
        </div>
      ) : (
        <span className={styles.noData}>
          <IntlMessage id="common.no-data-to-show" />
        </span>
      )}
    </>
  );
}

ListContainer.propTypes = {
  dataBody: objectOf(array),
  points: arrayOf(object),
  ratio: number,
  showCheckedRegions: func,
  hideUncheckedRegions: func,
  changeDrawCount: func,
  changeDisplayRegions: func,
  toggleDisplayAllRegions: func,
  regions: arrayOf(string),
  displayingRegions: any,
};

const mapStateToProps = (state) => ({
  dataBody: makePointData(state),
  points: selectPoints(state),
  ratio: selectImageRatio(state),
  displayingRegions: selectDisplayingRegions(state),
  regions: selectAllRegions(state),
});

export default connect(mapStateToProps, {
  showCheckedRegions,
  hideUncheckedRegions,
  changeDrawCount,
  changeDisplayRegions,
  toggleDisplayAllRegions,
})(ListContainer);
