import React, { useRef, useEffect } from "react";
import { number, string, func } from "prop-types";
import Konva from "konva";
import { Stage, Layer, Image } from "react-konva";
import useImage from "use-image";

function EditedImg({
  brightness,
  contrast,
  saveUrl,
  width,
  height,
  saveCount,
  saveEditedImage,
}) {
  const imageRef = useRef();
  const [image] = useImage(saveUrl, "Anonimus");

  useEffect(() => {
    if (image && imageRef && imageRef.current) {
      // you many need to reapply cache on some props changes like shadow, stroke, etc.
      imageRef.current.cache();
      // since this update is not handled by "react-konva" and we are using Konva methods directly
      // we have to redraw layer manually
      imageRef.current.getLayer().batchDraw();
    }
  }, [image]);

  useEffect(() => {
    if (!saveCount) return;
    const dataURL = imageRef.current.toDataURL();
    saveEditedImage(dataURL);
  }, [saveCount]);

  return (
    <div>
      <Stage
        style={{ zIndex: -300, display: "none" }}
        width={width}
        height={height}
      >
        <Layer>
          <Image
            image={image}
            ref={imageRef}
            filters={[Konva.Filters.Brighten, Konva.Filters.Contrast]}
            brightness={brightness - 1}
            contrast={(contrast - 1) * 100}
          />
        </Layer>
      </Stage>
    </div>
  );
}

EditedImg.propTypes = {
  brightness: number,
  contrast: number,
  saveUrl: string,
  width: number,
  height: number,
  saveCount: number,
  resetCount: number,
  saveEditedImage: func,
  originalUrl: string,
};

export default EditedImg;
