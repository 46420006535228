/* eslint-disable no-case-declarations */
import produce from "immer";
import { RECEIVE_MOUSE_POSITION } from "./constants";
import { RECEIVE_USER_LEAVE_ROOM } from "redux/socket/constants";
export const initialState = {
  mousePosition: [],
};
export function randomColor() {
  const color = "#" + Math.floor(Math.random() * 16777215).toString(16);
  if (color.length === 7) return color;
  return randomColor();
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RECEIVE_MOUSE_POSITION:
        const data = action.payload;
        const index = state.mousePosition.findIndex(
          (item) => item.userId === data.userId
        );
        if (index === -1) draft.mousePosition.push(data);
        else draft.mousePosition[index] = data;
        break;
      case RECEIVE_USER_LEAVE_ROOM:
        draft.mousePosition = state.mousePosition.filter(
          (item) => item.userId !== action.payload.userId
        );
        break;
      default:
        break;
    }
  });

export default reducer;
