const namespace = "issues";

export const FETCH_ISSUES = `${namespace}/FETCH_ISSUES`;
export const FETCH_ISSUES_SUCCESS = `${namespace}/FETCH_ISSUES_SUCCESS`;
export const FETCH_ISSUES_FAILURE = `${namespace}/FETCH_ISSUES_FAILURE`;

export const FETCH_CHILD_ISSUE = `${namespace}/FETCH_CHILD_ISSUE`;
export const FETCH_CHILD_ISSUE_SUCCESS = `${namespace}/FETCH_CHILD_ISSUE_SUCCESS`;
export const FETCH_CHILD_ISSUE_FAILURE = `${namespace}/FETCH_CHILD_ISSUE_FAILURE`;

export const OPEN_ISSUE_FORM = `${namespace}/OPEN_ISSUE_FORM`;
export const CLOSE_ISSUE_FORM = `${namespace}/CLOSE_ISSUE_FORM`;

export const ADD_ISSUE = `${namespace}/ADD_ISSUE`;
export const ADD_ISSUE_SUCCESS = `${namespace}/ADD_ISSUE_SUCCESS`;
export const ADD_ISSUE_FAILURE = `${namespace}/ADD_ISSUE_FAILURE`;

export const ADD_CHILD_ISSUE = `${namespace}/ADD_CHILD_ISSUE`;
export const ADD_CHILD_ISSUE_SUCCESS = `${namespace}/ADD_CHILD_ISSUE_SUCCESS`;
export const ADD_CHILD_ISSUE_FAILURE = `${namespace}/ADD_CHILD_ISSUE_FAILURE`;

export const RESOLVE_ISSUE = "issues/RESOLVE_ISSUE";
export const RESOLVE_ISSUE_SUCCESS = "issues/RESOLVE_ISSUE_SUCCESS";
export const RESOLVE_ISSUE_FAILURE = "issues/RESOLVE_ISSUE_FAILURE";

export const REOPEN_ISSUE = "issues/REOPEN_ISSUE";
export const REOPEN_ISSUE_SUCCESS = "issues/REOPEN_ISSUE_SUCCESS";
export const REOPEN_ISSUE_FAILURE = "issues/REOPEN_ISSUE_FAILURE";

export const DELETE_ISSUE = "issues/DELETE_ISSUE";
export const DELETE_ISSUE_SUCCESS = "issues/DELETE_ISSUE_SUCCESS";
export const DELETE_ISSUE_FAILURE = "issues/DELETE_ISSUE_FAILURE";

export const EDIT_ISSUE = "issues/EDIT_ISSUE";
export const EDIT_ISSUE_SUCCESS = "issues/EDIT_ISSUE_SUCCESS";
export const EDIT_ISSUE_FAILURE = "issues/EDIT_ISSUE_FAILURE";

export const EXTRA_INFO_FOR_SPATIAL = "issues/EXTRA_INFO_FOR_SPATIAL";

export const SEEK_BY_ISSUE = "issues/SEEK_BY_ISSUE";
export const CLICK_HASH_TAG = "issues/CLICK_HASH_TAG";

export const clickHashTag = (payload) => ({
  type: CLICK_HASH_TAG,
  payload,
});

export const fetchIssues = () => ({
  type: FETCH_ISSUES,
});

export const fetchIssuesSuccess = (payload) => ({
  type: FETCH_ISSUES_SUCCESS,
  payload,
});

export const fetchIssuesFailure = (payload) => ({
  type: FETCH_ISSUES_FAILURE,
  payload,
});

export const fetchChildIssue = (payload) => ({
  type: FETCH_CHILD_ISSUE,
  payload,
});

export const fetchChildIssueSuccess = (payload) => ({
  type: FETCH_CHILD_ISSUE_SUCCESS,
  payload,
});

export const fetchChildIssueFailure = (payload) => ({
  type: FETCH_CHILD_ISSUE_FAILURE,
  payload,
});

export const openIssueForm = (payload) => ({
  type: OPEN_ISSUE_FORM,
  payload,
});

export const closeIssueForm = (payload) => ({
  type: CLOSE_ISSUE_FORM,
  payload,
});

export const addIssue = (payload) => ({
  type: ADD_ISSUE,
  payload,
});

export const addIssueSuccess = (payload) => ({
  type: ADD_ISSUE_SUCCESS,
  payload,
});

export const addIssueFailure = (payload) => ({
  type: ADD_ISSUE_FAILURE,
  payload,
});

export const addChildIssue = (payload) => ({
  type: ADD_CHILD_ISSUE,
  payload,
});

export const addChildIssueSuccess = (payload) => ({
  type: ADD_CHILD_ISSUE_SUCCESS,
  payload,
});

export const addChildIssueFailure = (payload) => ({
  type: ADD_CHILD_ISSUE_FAILURE,
  payload,
});

export const resolveIssue = (payload) => ({
  type: RESOLVE_ISSUE,
  payload,
});

export const resolveIssueSuccess = (payload) => ({
  type: RESOLVE_ISSUE_SUCCESS,
  payload,
});

export function resolveIssueFailure(payload) {
  return {
    type: RESOLVE_ISSUE_FAILURE,
    payload,
  };
}

export const reopenIssue = (payload) => ({
  type: REOPEN_ISSUE,
  payload,
});

export const reopenIssueSuccess = (payload) => ({
  type: REOPEN_ISSUE_SUCCESS,
  payload,
});

export const reopenIssueFailure = (payload) => ({
  type: REOPEN_ISSUE_FAILURE,
  payload,
});

export function deleteIssue(payload) {
  return {
    type: DELETE_ISSUE,
    payload,
  };
}

export function deleteIssueSuccess(payload) {
  return {
    type: DELETE_ISSUE_SUCCESS,
    payload,
  };
}

export function deleteIssueFailure(payload) {
  return {
    type: DELETE_ISSUE_FAILURE,
    payload,
  };
}

export function editIssue(payload) {
  return {
    type: EDIT_ISSUE,
    payload,
  };
}

export function editIssueSuccess(payload) {
  return {
    type: EDIT_ISSUE_SUCCESS,
    payload,
  };
}

export function editIssueFailure(payload) {
  return {
    type: EDIT_ISSUE_FAILURE,
    payload,
  };
}

export function extraInfoForSpatial(payload) {
  return {
    type: EXTRA_INFO_FOR_SPATIAL,
    payload,
  };
}

export function seekByIssue(payload) {
  return {
    type: SEEK_BY_ISSUE,
    payload,
  };
}
