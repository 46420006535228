import React, { useState, useEffect } from "react";
import { string, func, arrayOf, object, array } from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";

import { selectLabels } from "redux/points/selectors";
import { getDisplayingShapes, getCurrentShapes } from "redux/shape/selector";
import { setDisplayingShapeByLabelId } from "redux/shape/actions";
import ListItem from "./ListItem";

import styles from "./style.module.scss";
import { number } from "prop-types";

function ItemGroup({
  intl,
  shapes,
  gridBody,
  setDisplayingShapeByLabelId,
  displayingShapes,
  labelId,
  labels,
  currentShape,
}) {
  useEffect(() => {
    if (currentShape?.labelId === (shapes && shapes[0].labelId)) {
      setIsCollapsed(true);
    }
  }, [currentShape]);

  const [isOpen, setIsCollapsed] = useState(false);

  function handleToggleOpen() {
    setIsCollapsed(!isOpen);
  }

  function handleInputChecked() {
    setDisplayingShapeByLabelId(labelId);
  }
  const index = labels.findIndex((item) => Number(item.id) === Number(labelId));

  return (
    <>
      <div className={styles.itemGroupContainer}>
        <div style={{ margin: "0 10px" }}>
          <input
            type="checkbox"
            onChange={handleInputChecked}
            checked={
              displayingShapes.findIndex(
                (item) => Number(item.labelId) === Number(labelId)
              ) !== -1
            }
          />
        </div>
        <button type="button" onClick={handleToggleOpen}>
          {isOpen ? <ExpandMore /> : <ChevronRight />}
        </button>
        <span
          className={styles.groupDot}
          style={{
            backgroundColor:
              (labels[index] && labels[index].color) || "#e5e5e5",
          }}
        />
        <div className={styles.regionName} onClick={handleToggleOpen}>
          {labelId === "undefined"
            ? intl.formatMessage({ id: "point-list.all-points" })
            : labels[index] && labels[index].name}{" "}
          &nbsp; ({shapes?.length})
        </div>
      </div>
      {isOpen && (
        <div className={styles.listBody}>
          {shapes
            .sort((a, b) => Number(a.id) - Number(b.id))
            .map((item, index) => (
              <ListItem
                key={item.id}
                gridTemplateColumns={gridBody}
                item={item}
                isOpen={isOpen}
                index={index + 1}
              />
            ))}
        </div>
      )}
    </>
  );
}

ItemGroup.propTypes = {
  intl: object,
  shapes: arrayOf(object),
  gridBody: string,
  setDisplayingShapeByLabelId: func,
  labelId: number,
  labels: array,
  displayingShapes: array,
  currentShape: object,
};

function mapState(state) {
  return {
    labels: selectLabels(state),
    displayingShapes: getDisplayingShapes(state),
    currentShape: getCurrentShapes(state),
  };
}

export default connect(mapState, {
  setDisplayingShapeByLabelId,
})(injectIntl(ItemGroup));
