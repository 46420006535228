export const SET_CURRENT_SHAPE = "shape/SET_CURRENT_SHAPE";
export const SET_CURRENT_SHAPE_SUCCESS = "shape/SET_CURRENT_SHAPE_SUCCESS";

export const SET_DISPLAYING_SHAPE = "shape/SET_DISPLAY_SHAPE";

export const SET_DISPLAYING_SHAPE_BY_ID = "shape/SET_DISPLAYING_SHAPE_BY_ID";
export const SET_DISPLAYING_SHAPE_BY_LABEL_ID =
  "shape/SET_DISPLAYING_SHAPE_BY_LABEL_ID";

export const GET_SHAPE = "shape/GET_SHAPE";
export const GET_SHAPE_SUCCESS = "shape/GET_SHAPE_SUCCESS";
export const GET_SHAPE_ERROR = "shape/GET_SHAPE_ERROR";

export const DELETE_SHAPE = "shape/DELETE_SHAPE";
export const DELETE_SHAPE_SUCCESS = "shape/DELETE_SHAPE_SUCCESS";
export const DELETE_SHAPE_ERROR = "shape/DELETE_SHAPE_ERROR";

export const ADD_SHAPE_SUCCESS = "shape/ADD_SHAPE_SUCCESS";

export const UPDATE_SHAPE_SUCCESS = "shape/UPDATE_SHAPE_SUCCESS";

export const DELETE_ALL_SHAPE = "shape/DELETE_ALL_SHAPE";
export const DELETE_ALL_SHAPE_SUCCESS = "shape/DELETE_ALL_SHAPE_SUCCESS";
export const DELETE_ALL_SHAPE_ERROR = "shape/DELETE_ALL_SHAPE_ERROR";

/** Shape Attribute */

export const UPDATE_SHAPE_ATTRIBUTE = "shape/UPDATE_SHAPE_ATTRIBUTE";
export const UPDATE_SHAPE_ATTRIBUTE_SUCCESS =
  "shape/UPDATE_SHAPE_ATTRIBUTE_SUCCESS";
export const UPDATE_SHAPE_ATTRIBUTE_ERROR =
  "shape/UPDATE_SHAPE_ATTRIBUTE_ERROR";
export const CLOSE_ALL_ATTRIBUTE = "shape/CLOSE_ALL_ATTRIBUTE";
export const SET_COPY_SHAPE = "shape/SET_COPY_SHAPE";
export const SET_COPY_SHAPE_MODE = "shpae/SET_COPY_SHAPE_MODE";

export const SHAPE_SOCKET_RECEIVE = "shape/SHAPE_SOCKET_RECEIVE";

// Update shape labelId

export const UPDATE_SHAPE_LABEL = "shape/UPDATE_SHAPE_LABEL";
export const UPDATE_SHAPE_LABEL_SUCCESS = "shape/UPDATE_SHAPE_LABEL_SUCCESS";
export const UPDATE_SHAPE_LABEL_ERROR = "shape/UPDATE_SHAPE_LABEL_ERROR";
export const SET_DRAWING_POLYGON = "shape/SET_DRAWING_POLYGON";
export const SET_CURRENT_POLYGON_POINT_INDEX =
  "shape/SET_CURRENT_POLYGON_POINT_INDEX";
