import { createMuiTheme } from "@material-ui/core/styles";
import "fontsource-nunito/300.css";
import "fontsource-nunito/400.css";
import "fontsource-nunito/600.css";
import "fontsource-dosis/600.css";

const colors = {
  primaryMain: "#1871E8",
  secondaryMain: "#555F7C",
  textPrimary: "#2A323C",
  textSecondary: "#5C6269",
  tableheadColor: "#465364",
  tableRowSelectedBackground: "#F2F5F9",
  buttonContainedPrimaryHoverBackground: "#0D62D2",
  buttonDisabledColor: "#9CA8B4",
  buttonDisabledBackground: "#D5DFE9",
  buttonOutlinedBackground: "#EEF3F9",
  linkColor: "#6098D1",
  iconRootColor: "#555F7C",
  iconRootHover: "#1B1D1F",
  iconPrimaryHover: "#0D62D2",
  backgroundDefault: "#FFF",
  backgroundPaper: "#F2F5F9",
  divider: "#D5DFE9",
};

export default createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: colors.primaryMain,
    },
    secondary: {
      main: colors.secondaryMain,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
    background: {
      default: colors.backgroundDefault,
    },
    divider: colors.divider,
  },
  spacing: 5,
  typography: {
    h1: {
      fontSize: "1.125rem",
      fontFamily: "Dosis",
      fontStyle: "normal",
      lineHeight: "22px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.125rem",
      fontFamily: "Nunito",
      fontStyle: "normal",
      lineHeight: "24px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 400,
    },
    h3: {
      fontSize: "1rem",
      fontFamily: "Nunito",
      fontStyle: "normal",
      lineHeight: "22px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 600,
    },
    h4: {
      fontSize: ".975rem",
      fontFamily: "Nunito",
      fontStyle: "normal",
      lineHeight: "20px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 600,
    },
    h5: {
      fontSize: ".95rem",
      fontFamily: "Nunito",
      fontStyle: "normal",
      lineHeight: "20px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 400,
    },
    h6: {
      fontSize: ".925rem",
      fontFamily: "Nunito",
      fontStyle: "normal",
      lineHeight: "18px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: ".90rem",
      fontFamily: "Nunito",
      fontStyle: "normal",
      lineHeight: "18px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 300,
    },
    subtitle2: {
      fontSize: ".90rem",
      fontFamily: "Nunito",
      fontStyle: "normal",
      lineHeight: "18px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem",
      fontFamily: "Nunito",
      fontStyle: "normal",
      lineHeight: "22px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 300,
    },
    body2: {
      fontSize: ".875rem",
      fontFamily: "Nunito",
      fontStyle: "Normal",
      lineHeight: "20px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 300,
    },
    button: {
      fontSize: ".875rem",
      fontFamily: "Nunito",
      fontStyle: "Normal",
      lineHeight: "16px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 600,
    },
    caption: {
      fontSize: ".75rem",
      fontFamily: "Nunito",
      fontStyle: "Normal",
      lineHeight: "16px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 300,
    },
    overline: {
      fontSize: ".75rem",
      fontFamily: "Nunito",
      fontStyle: "Normal",
      lineHeight: "16px",
      letterSpacing: "-1.30385e-09px",
      fontWeight: 300,
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        color: colors.tableheadColor,
      },
    },
    MuiTableSortLabel: {
      root: {
        color: colors.tableheadColor,
      },
    },
    MuiTableRow: {
      root: {
        "&$selected": {
          backgroundColor: colors.tableRowSelectedBackground,
        },
      },
    },
    MuiIcon: {
      root: {
        color: colors.iconRootColor,
        "&:hover": {
          color: colors.iconRootHover,
        },
      },
      colorPrimary: {
        color: colors.primaryMain,
        "&:hover": {
          color: colors.iconPrimaryHover,
        },
      },
      // need secondary color here
    },
    MuiButton: {
      root: {
        "&$disabled": {
          color: colors.buttonDisabledColor,
        },
        textTransform: "capitalize",
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: colors.buttonContainedPrimaryHoverBackground,
        },
      },
      contained: {
        "&$disabled": {
          backgroundColor: colors.buttonDisabledBackground,
          color: colors.buttonDisabledColor,
        },
      },
      outlinedPrimary: {
        "&:hover": {
          backgroundColor: colors.buttonOutlinedBackground,
        },
      },
      outlined: {
        "&$disabled": { backgroundColor: colors.buttonDisabledBackground },
      },
    },
    MuiStepIcon: {
      root: {
        fill: colors.buttonDisabledColor,
        "&$active": {
          fill: colors.secondaryMain,
        },
        "&$completed": {
          fill: colors.secondaryMain,
        },
      },
    },
    MuiStepLabel: {
      label: {
        color: colors.textSecondary,
        fontSize: ".875rem",
        fontWeight: 600,
        "&$active": {
          color: colors.textPrimary,
          fontSize: ".875rem",
          fontWeight: 600,
        },
        "&$completed": {
          color: colors.textPrimary,
          fontSize: ".875rem",
          fontWeight: 600,
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: "capitalize",
      },
    },
    MuiLink: {
      root: {
        color: colors.linkColor,
      },
    },
    MuiMenu: {
      paper: {
        paddingLeft: "5px",
        paddingRight: "5px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
      },
    },
    MuiMenuItem: {
      root: {
        color: colors.textPrimary,
        fontSize: ".875rem",
        borderRadius: "5px",
        fontWeight: 400,
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: colors.buttonOutlinedBackground,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "36px",
      },
    },
    MuiListItemText: {
      root: {
        color: colors.textPrimary,
        fontSize: ".875rem",
        fontWeight: 400,
      },
      primary: {
        color: colors.textPrimary,
        fontSize: ".875rem",
        fontWeight: 400,
      },
      secondary: {
        color: colors.textSecondary,
        fontSize: ".875rem",
        fontWeight: 400,
      },
    },
    MuiTypography: {
      h1: {
        textTransform: "uppercase",
      },
      caption: {
        color: colors.textSecondary,
      },
    },
  },
});
