import {
  all,
  fork,
  takeEvery,
  put,
  select,
  call,
  takeLatest,
} from "redux-saga/effects";
import {
  SELECT_TOOL,
  DESELECT_TOOL,
  LIMITATION_SEGMENTATION_TOOL,
} from "./constants";
import {
  selectToolSuccess,
  deselectToolSuccess,
  limitationSegmentationToolSuccess,
  limitationSegmentationToolFail,
} from "./actions";
import { getIsUnsaveSegmentationSelector } from "redux/segmentation/selector";
import { resetSegmentationMode } from "redux/segmentation/actions";
// import { SEGMENTATION_MODE } from "./enums";
// import { setCurrentSegmentationMode } from "redux/segmentation/actions";
// import { segMode } from "redux/segmentation/enums";
import { setCurrentShapeSuccess } from "redux/shape/actions";
import { getLimitationSegmentationTool } from "../../api/segmentation";
import { getParameterByName } from "helpers";

export default function* toolsSaga() {
  function* watchSetCurrentTool() {
    yield takeEvery(SELECT_TOOL, function* ({ payload }) {
      const isUnsaveSegmentation = yield select(
        getIsUnsaveSegmentationSelector
      );
      if (isUnsaveSegmentation) {
        if (
          !window.confirm(
            "Your drawing is not saved. Do you want to leave the page?"
          )
        )
          return;
      }
      yield put(resetSegmentationMode());
      yield put(setCurrentShapeSuccess(null));
      yield put(selectToolSuccess(payload));
      // if (payload === SEGMENTATION_MODE) {
      //   yield put(setCurrentSegmentationMode(segMode.SMART_MODE));
      // }
    });
  }

  function* watchDeSelectCurrentTool() {
    yield takeEvery(DESELECT_TOOL, function* ({ payload }) {
      const isUnsaveSegmentation = yield select(
        getIsUnsaveSegmentationSelector
      );
      if (isUnsaveSegmentation) {
        if (
          !window.confirm(
            "Your drawing is not saved. Do you want to leave the page?"
          )
        )
          return;
      }
      yield put(resetSegmentationMode());
      yield put(setCurrentShapeSuccess(null));

      yield put(deselectToolSuccess(payload));
    });
  }

  function* watchLimitation() {
    yield takeLatest(LIMITATION_SEGMENTATION_TOOL, function* ({ payload }) {
      try {
        const isViewResult = getParameterByName("isViewResult");
        const isActionHistory = getParameterByName("isActionHistory");
        if (isViewResult || isActionHistory) {
          return;
        }
        const limitation = yield call(getLimitationSegmentationTool);
        yield put(
          limitationSegmentationToolSuccess(
            limitation?.data?.usage[0]?.remainRequest
          )
        );
        if (payload) {
          const { callback } = payload;
          callback && callback(limitation?.data?.usage[0]?.remainRequest);
        }
      } catch (error) {
        yield put(limitationSegmentationToolFail(error.toString()));
      }
    });
  }

  yield all([
    fork(watchSetCurrentTool),
    fork(watchDeSelectCurrentTool),
    fork(watchLimitation),
  ]);
}
