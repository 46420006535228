import React from "react";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { string, bool, func } from "prop-types";
import { connect } from "react-redux";
import { selectPermissionComponent } from "redux/app/selectors";
import { makeSelectedRowId } from "redux/points/selectors";
import { openIssueForm } from "redux/issues/actions";
import styles from "./style.module.scss";

function TittleWrapper(props) {
  return (
    <div className={styles.titleWrapper} tabIndex="0" onKeyPress={() => {}}>
      <button
        className={styles.collapse}
        type="button"
        onClick={props.handleCollapse}
      >
        {props.isCollapsed ? <ChevronRight /> : <ExpandMore />}
      </button>
      <span>{props.title}</span>
      <button
        className={styles.deleteAllLabel}
        type="button"
        onClick={props.handleDeleleAllLabel}
      >
        <span>{props.deleteAllLabel}</span>
      </button>
    </div>
  );
}

TittleWrapper.propTypes = {
  selectedRowId: string,
  verifyPermission: bool,
  handleVerifyIssue: func,
  handleDeleteAnnotation: func,
  handleAddIssue: func,
  addIssuePermission: bool,
  deleteIssuePermission: bool,
  isLoading: bool,
};

TittleWrapper.propTypes = {
  title: string,
  deleteAllLabel: string,
  isCollapsed: bool,
  handleCollapse: func,
  handleDeleleAllLabel: func,
};

const mapStateTopProps = (state) => ({
  selectedRowId: makeSelectedRowId(state),
  verifyPermission: selectPermissionComponent("labeling_verify")(state),
  addIssuePermission: selectPermissionComponent("labeling_create_issue")(state),
  deleteIssuePermission: selectPermissionComponent("labeling_modify")(state),
});

export default connect(mapStateTopProps, { openIssueForm })(TittleWrapper);
