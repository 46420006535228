/* eslint-disable react/jsx-key */
import React, { Fragment, useState, useRef } from "react";
import { Rect, Transformer } from "react-konva";
import { checkRectangleIsOutOfStage } from "../../helpers/rectangle";
import { func, bool, object, number } from "prop-types";
import { connect } from "react-redux";
import { selectImageMetadata } from "redux/image/selectors";
function Rectangle({
  rectangleProps,
  ratio,
  isSelected,
  onSelect,
  onChange,
  opacity,
  metadata,
  onTransform,
  onDragMove,
}) {
  const rectangleRef = useRef();
  const trRef = React.useRef();

  const [rectangle, setRectangle] = useState({
    ...rectangleProps,
    x: rectangleProps.x * ratio,
    y: rectangleProps.y * ratio,
    width: rectangleProps.width * ratio,
    height: rectangleProps.height * ratio,
  });

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([rectangleRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  React.useEffect(() => {
    setRectangle({
      ...rectangleProps,
      x: rectangleProps.x * ratio,
      y: rectangleProps.y * ratio,
      width: rectangleProps.width * ratio,
      height: rectangleProps.height * ratio,
    });
  }, [rectangleProps, ratio]);

  const handleDragMove = (e) => {
    onDragMove({
      ...rectangleProps,
      x: e.target.x() / ratio,
      y: e.target.y() / ratio,
    });
  };

  const handleOnTransform = () => {
    const node = rectangleRef.current;
    const { x, y, scaleX, scaleY } = node.getTransform().decompose();
    if (onTransform)
      onTransform({
        x: x / ratio,
        y: y / ratio,
        width: Math.max(5, rectangleProps.width * scaleX),
        height: Math.max(rectangleProps.height * scaleY),
      });
  };

  const handleOnTransformEnd = () => {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const node = rectangleRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    onChange({
      ...rectangleProps,
      x: node.x() / ratio,
      y: node.y() / ratio,
      // set minimal value
      width: Math.max(5, rectangleProps.width * scaleX),
      height: Math.max(rectangleProps.height * scaleY),
    });
  };

  const handleDragEnd = (e) => {
    if (onChange)
      onChange({
        ...rectangleProps,
        x: e.target.x() / ratio,
        y: e.target.y() / ratio,
      });
  };

  const handleBoundDraggable = (pos) => {
    const node = rectangleRef.current;
    const dataPos = { ...pos };
    if (pos.x < 0) dataPos.x = 0;
    if (pos.x + node.getAttr("width") > metadata.width * ratio)
      dataPos.x = metadata.width * ratio - node.getAttr("width");
    if (pos.y < 0) dataPos.y = 0;
    if (pos.y + node.getAttr("height") > metadata.height * ratio)
      dataPos.y = metadata.height * ratio - node.getAttr("height");
    return dataPos;
  };

  return (
    <Fragment>
      <Rect
        ref={rectangleRef}
        opacity={opacity || 0.4}
        // onClick={onSelect}
        // onTap={onSelect}
        onMouseDown={onSelect}
        draggable={isSelected}
        onDragMove={handleDragMove}
        onDragEnd={handleDragEnd}
        onTransform={handleOnTransform}
        dragBoundFunc={handleBoundDraggable}
        onTransformEnd={handleOnTransformEnd}
        strokeScaleEnabled={false}
        {...rectangle}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          ignoreStroke
          boundBoxFunc={(oldBox, newBox) => {
            const isOutsideOfStage = checkRectangleIsOutOfStage(newBox, {
              ...metadata,
              ratio,
            });
            // limit resize
            if (isOutsideOfStage || newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
          rotateEnabled={false}
        />
      )}
    </Fragment>
  );
}

Rectangle.propTypes = {
  rectangleProps: object,
  ratio: number,
  isSelected: bool,
  onSelect: func,
  onChange: func,
  opacity: number,
  metadata: object,
  onTransform: func,
  onDragMove: func,
};

const mapState = (state) => ({
  metadata: selectImageMetadata(state),
});
export default connect(mapState)(Rectangle);
