import { number, object } from "prop-types";
import React from "react";
import { Rect } from "react-konva";
import { selectImageUrl } from "redux/image/selectors";
import Image from "./Image";

import { useSelector } from "react-redux";

export default function SelectedZone({ metadata, ratio, rectangleProps }) {
  const { width, height } = rectangleProps;
  const imageURL = useSelector(selectImageUrl);

  return (
    <>
      {Boolean(width && height) && (
        <Rect
          x={0}
          y={0}
          width={metadata.width * ratio}
          height={metadata.height * ratio}
          fill="#000"
          opacity={0.4}
        />
      )}
      {Boolean(imageURL) && (
        <Image
          url={imageURL}
          metadata={metadata}
          ratio={ratio}
          rectangleProps={rectangleProps}
        />
      )}
    </>
  );
}

SelectedZone.propTypes = {
  metadata: object,
  rectangleProps: object,
  ratio: number,
};
