/**
 * All the tools are here
 */

import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { func, string, bool, object } from "prop-types";
import { injectIntl } from "react-intl";
import FilterTiltShift from "@material-ui/icons/FilterTiltShift";
import ToolContainer from "components/ToolContainer";
import { selectTool, deselectTool } from "redux/tools/actions";
import {
  makeCurrTool,
  selectAutoIrisCircleLoading,
  selectIsDisableMultipleSelection,
  selectIsMoveMode,
} from "redux/tools/selectors";
import {
  selectIsIrisCirclesShown,
  selectAreIrisCentersShown,
} from "redux/canvas/selectors";
import { selectPermissionComponent } from "redux/app/selectors";
import { selectCurrLabel } from "redux/points/selectors";
import { connect } from "react-redux";
import {
  Box,
  Point,
  Polygon,
  Polyline,
  Cuboid,
  ObjectTracking,
  FormatShapes,
  MoveIcon,
} from "icons";
import {
  selectImageId,
  selectImageAnnotationType,
} from "redux/image/selectors";
import { getDrawingSegmentations } from "redux/segmentation/selector";
import * as allTools from "redux/tools/enums";
import SegmentationToolButton from "components/SegmentationToolButton";
import AutomaticAnnotationToolButton from "components/AutomaticAnnotationToolButton";
import style from "./style.module.scss";
import { getParameterByName } from "helpers";

function ToolBar({
  intl,
  selectTool,
  currTool,
  deselectTool,
  irisCircleLoading,
  isIrisCirclesShown,
  areIrisPointsSelected,
  currLabel,
  currImageId,
  drawingSegmentation,
  annotationType,
  isMoveMode,
}) {
  const alterUserId = getParameterByName("alterUserId");
  const isViewResult = getParameterByName("isViewResult");
  const isActionHistory = getParameterByName("isActionHistory");
  const disableOtherTools = isActionHistory || isViewResult;
  const enableEditting = !(alterUserId || isViewResult);

  const disableMoveTool = false;
  function handlePickTool(event) {
    const toolName = event.target.getAttribute("name");
    const disabledClass = event.target.getAttribute("data-disabled");
    if (disabledClass === `true`) {
      return;
    }
    if (currTool === toolName) {
      deselectTool();
      return;
    }
    if (!enableEditting) {
      return;
    }

    selectTool(toolName);
  }

  const checkDisableTool = (type) => {
    if (annotationType && annotationType.includes("ocr")) return true;
    return (
      !(
        currLabel &&
        currLabel.tools &&
        currLabel.tools.includes(`${type}-image`)
      ) ||
      !currImageId ||
      drawingSegmentation
    );
  };

  return (
    <div className={style.toolBarContainer}>
      <PerfectScrollbar
        options={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <ToolContainer
          currTool={isMoveMode ? allTools.MOVE_MODE : ""}
          onClick={handlePickTool}
          disabledClass={disableMoveTool || !enableEditting}
          tooltipText={`${intl.formatMessage({
            id: "tool-bar.move-the-image",
          })} (1)`}
          name={allTools.MOVE_MODE}
        >
          <MoveIcon style={{ pointerEvents: "none" }} />
        </ToolContainer>
        <ToolContainer
          currTool={currTool}
          name={allTools.AUTO_IRIS_MODE}
          onClick={handlePickTool}
          loading={irisCircleLoading}
          disabledClass={
            !isIrisCirclesShown || areIrisPointsSelected || disableOtherTools
          }
          tooltipText={`${intl.formatMessage({
            id: "tool-bar.auto-position-iris-circles",
          })} (2)`}
        >
          <FilterTiltShift className={style.disablePointerEvent} />
        </ToolContainer>
        <ToolContainer
          currTool={currTool}
          name={allTools.BOX_MODE}
          onClick={handlePickTool}
          disabledClass={!enableEditting || checkDisableTool(allTools.BOX_MODE)}
          tooltipText={`${intl.formatMessage({
            id: "tool-bar.bounding-box",
          })} (3)`}
        >
          <Box style={{ pointerEvents: "none" }} />
        </ToolContainer>

        <ToolContainer
          currTool={currTool}
          name={allTools.LANDMARK_POINT_MODE}
          disabledClass
          tooltipText={intl.formatMessage({ id: "tool-bar.landmark-point" })}
        >
          <Point style={{ pointerEvents: "none" }} />
        </ToolContainer>
        <ToolContainer
          currTool={currTool}
          disabledClass={
            checkDisableTool(allTools.POLYGON_MODE) || disableOtherTools
          }
          name={allTools.POLYGON_MODE}
          tooltipText={`${intl.formatMessage({
            id: "tool-bar.polygonal-segmentation",
          })} (4)`}
          onClick={handlePickTool}
        >
          <Polygon style={{ pointerEvents: "none" }} />
        </ToolContainer>
        <ToolContainer
          currTool={currTool}
          name={allTools.POLYLINE_MODE}
          disabledClass={
            checkDisableTool(allTools.POLYLINE_MODE) || disableOtherTools
          }
          tooltipText={`${intl.formatMessage({ id: "tool-bar.polyline" })} (8)`}
          onClick={handlePickTool}
        >
          <Polyline style={{ pointerEvents: "none" }} />
        </ToolContainer>
        <ToolContainer
          currTool={currTool}
          name={allTools.CUBOID_MODE}
          disabledClass={
            checkDisableTool(allTools.CUBOID_MODE) || disableOtherTools
          }
          tooltipText={`${intl.formatMessage({ id: "tool-bar.cuboid" })} (6)`}
          onClick={handlePickTool}
        >
          <Cuboid style={{ pointerEvents: "none" }} />
        </ToolContainer>
        <SegmentationToolButton
          checkDisableTool={checkDisableTool}
          name={allTools.SEGMENTATION_MODE}
          handlePickTool={handlePickTool}
          disabledClass={disableOtherTools}
        />

        <AutomaticAnnotationToolButton
          checkDisableTool={false}
          name={allTools.AUTOMATIC_ANNOTATION_MODE}
          handlePickTool={handlePickTool}
          disabledClass={!enableEditting}
        />

        <ToolContainer
          currTool={currTool}
          name={allTools.OBJECT_TRACKING_MODE}
          disabledClass
          arrow
          tooltipText={intl.formatMessage({
            id: "tool-bar.smart-tool-object-tracking",
          })}
        >
          <ObjectTracking style={{ pointerEvents: "none" }} />
        </ToolContainer>
        <ToolContainer
          currTool={currTool}
          name={allTools.OCR}
          disabledClass={
            !(annotationType && annotationType.includes("ocr") && currLabel) ||
            disableOtherTools
          }
          tooltipText={intl.formatMessage({ id: "tool-bar.text-box" })}
          onClick={handlePickTool}
        >
          <FormatShapes style={{ pointerEvents: "none" }} />
        </ToolContainer>
      </PerfectScrollbar>
    </div>
  );
}

ToolBar.propTypes = {
  intl: object,
  selectTool: func,
  currTool: string,
  deselectTool: func,
  // allowTools: arrayOf(string),
  modifyPermission: bool,
  irisCircleLoading: bool,
  isIrisCirclesShown: bool,
  areIrisPointsSelected: bool,
  currLabel: object,
  isDisableMultipleSelection: bool,
  currImageId: string,
  drawingSegmentation: object,
  annotationType: string,
  isMoveMode: bool,
};

const mapStateToProps = () => (state) => {
  return {
    currTool: makeCurrTool(state),
    irisCircleLoading: selectAutoIrisCircleLoading(state),
    isIrisCirclesShown: selectIsIrisCirclesShown(state),
    modifyPermission: selectPermissionComponent("labeling_modify")(state),
    areIrisPointsSelected: selectAreIrisCentersShown(state),
    currLabel: selectCurrLabel(state),
    isDisableMultipleSelection: selectIsDisableMultipleSelection(state),
    currImageId: selectImageId(state),
    drawingSegmentation: getDrawingSegmentations(state),
    annotationType: selectImageAnnotationType(state),
    isMoveMode: selectIsMoveMode(state),
  };
};

export default connect(mapStateToProps, {
  selectTool,
  deselectTool,
})(injectIntl(ToolBar));
