import React, { useState } from "react";
import { connect } from "react-redux";
import { arrayOf, string } from "prop-types";

import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { selectListUser } from "redux/app/selectors";

import styles from "./style.module.scss";
import IntlMessage from "../../helpers/IntlMessage";

function Member({ listUser, labelerUserId, reviewerUserId }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const labeler = listUser.find((user) => user.user_id === labelerUserId);
  const reviewer = listUser.find((user) => user.user_id === reviewerUserId);
  return (
    <div className={styles.memberContainer}>
      <div
        className={styles.memberHeader}
        role="button"
        tabIndex="0"
        onKeyPress={() => {}}
        onClick={handleCollapse}
      >
        <button type="button">
          {isCollapsed ? <ChevronRight /> : <ExpandMore />}
        </button>
        <span>
          <IntlMessage id="common.member" />
        </span>
      </div>
      {!isCollapsed ? (
        <div className={styles.memberBody}>
          {labeler && (
            <div className={styles.memberRow}>
              <div className={styles.memberRole}>
                <IntlMessage id="common.labeler" />:
              </div>
              <div className={styles.memberName}>{labeler.full_name}</div>
            </div>
          )}
          {reviewer && (
            <div className={styles.memberRow}>
              <div className={styles.memberRole}>
                <IntlMessage id="common.reviewer" />:
              </div>
              <div className={styles.memberName}>{reviewer.full_name}</div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

Member.propTypes = {
  listUser: arrayOf(Object),
  labelerUserId: string,
  reviewerUserId: string,
};

const mapStateToProps = (state) => ({
  listUser: selectListUser(state),
});

export default connect(mapStateToProps)(Member);
