/*
 * SocketReducer
 */

import produce from "immer";

import { JOIN_ROOM, LEAVE_ROOM } from "./constants";

export const initialState = {
  connected: null,
  serverStatus: "",
  currentRoomId: null,
};

/* eslint-disable default-case, no-param-reassign */
const socketReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case JOIN_ROOM:
        draft.currentRoomId = action.payload;
        break;
      case LEAVE_ROOM:
        if (state.currentRoomId === action.payload) draft.currentRoomId = null;
        break;
      default:
        break;
    }
  });

export default socketReducer;
