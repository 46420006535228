import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllShapes } from "redux/shape/selector";
import * as allTools from "redux/tools/enums";
import { selectImageMetadata } from "redux/image/selectors";
import { string } from "prop-types";
import { showNotification } from "redux/notification/actions";
import { CircularProgress } from "@material-ui/core";
import { exportSegmentationImageProject } from "api/constants";
import { selectProjectId } from "redux/app/selectors";

export default function ExportSegmentation({ classList }) {
  const shapes = useSelector(getAllShapes);
  const projectId = useSelector(selectProjectId);
  const dispatch = useDispatch();
  const metadata = useSelector(selectImageMetadata);
  const [loading, setLoading] = useState(false);

  const loadImageToCanvasByURL = (context, dataImage) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        context.drawImage(img, dataImage.x || 0, dataImage.y || 0);
        resolve();
      };
      img.onerror = () =>
        reject(new Error(`load ${dataImage.base64Data} fail`));
      img.src = dataImage.base64Data;
    });
  };

  const handleExportSegmentationImage = (segmentations) => {
    if (!segmentations?.length > 0) {
      dispatch(
        showNotification({
          type: "error",
          msg: "Please add segmentation to export image",
        })
      );
      return;
    }
    setLoading(true);
    const listDataImages = segmentations.map((item) => item.dataPoint);

    const canvas = document.createElement("canvas");
    canvas.width = metadata.width;
    canvas.height = metadata.height;
    const context = canvas.getContext("2d");
    const loadImageToCanvas = listDataImages.map((segmentation) =>
      loadImageToCanvasByURL(context, segmentation)
    );
    Promise.all(loadImageToCanvas)
      .then(() => {
        const link = document.createElement("a");
        link.download = `export-segmentation-${new Date().getTime()}.jpeg`;
        link.href = canvas.toDataURL("image/jpeg", 1.0);
        setLoading(false);
        link.click();
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        dispatch(
          showNotification({
            type: "error",
            msg: "Cannot export segmentation image",
          })
        );
      });
  };

  const handleClickExportButton = () => {
    const segmentationList = shapes.filter(
      (shape) => shape.classesTypeCode === `${allTools.SEGMENTATION_MODE}-image`
    );
    handleExportSegmentationImage(segmentationList);
  };

  return (
    Number(projectId) === Number(exportSegmentationImageProject) && (
      <>
        <button
          className={classList}
          data-content="submit"
          onClick={handleClickExportButton}
        >
          Export Image
        </button>
        {loading && <CircularProgress size={16} />}
      </>
    )
  );
}

ExportSegmentation.propTypes = {
  classList: string,
};
