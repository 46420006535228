import React, { useState } from "react";
import {
  objectOf,
  string,
  number,
  func,
  arrayOf,
  object,
  bool,
  any,
} from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {
  showCheckedRegions,
  hideUncheckedRegions,
  changeDrawCount,
} from "redux/canvas/actions";
import { addDisplayRegion, removeDisplayRegion } from "redux/points/actions";
import { selectColourRegionByPoints } from "redux/points/selectors";
import { selectImageRatio } from "redux/canvas/selectors";
import ListItem from "./ListItem";

import styles from "./style.module.scss";

function ItemGroup({
  intl,
  region,
  points = [],
  gridBody,
  ratio,
  showCheckedRegions,
  hideUncheckedRegions,
  changeDrawCount,
  removeDisplayRegion,
  addDisplayRegion,
  displayingRegions,
  regionTypeCode,
  coloursByRegion,
}) {
  const [isOpen, setIsCollapsed] = useState(false);

  function handleToggleOpen() {
    setIsCollapsed(!isOpen);
  }

  function handleInputChecked(event) {
    const value = event.target.checked;

    const newPoints = points.map((point) => ({
      ...point,
      x: point.x * ratio,
      y: point.y * ratio,
    }));
    if (value) {
      showCheckedRegions(newPoints);
      addDisplayRegion(regionTypeCode);
    }
    if (!value) {
      hideUncheckedRegions(newPoints);
      removeDisplayRegion(regionTypeCode);
      changeDrawCount();
    }
  }

  return (
    <>
      <div className={styles.itemGroupContainer}>
        <div style={{ margin: "0 10px" }}>
          <input
            type="checkbox"
            onChange={handleInputChecked}
            checked={displayingRegions.has(regionTypeCode)}
          />
        </div>
        <button type="button" onClick={handleToggleOpen}>
          {isOpen ? <ChevronRight /> : <ExpandMore />}
        </button>
        <span
          className={styles.groupDot}
          style={{
            backgroundColor: coloursByRegion[regionTypeCode] || "#e5e5e5",
          }}
        />
        <div className={styles.regionName} onClick={handleToggleOpen}>
          {region === "undefined"
            ? intl.formatMessage({ id: "point-list.all-points" })
            : region}
        </div>
      </div>
      {isOpen && (
        <div className={styles.listBody}>
          {points.map((item) => (
            <ListItem
              key={item.id}
              gridTemplateColumns={gridBody}
              item={item}
              isOpen={isOpen}
            />
          ))}
        </div>
      )}
    </>
  );
}

ItemGroup.propTypes = {
  intl: object,
  region: string,
  points: arrayOf(object),
  gridBody: string,
  ratio: number,
  showCheckedRegions: func,
  hideUncheckedRegions: func,
  isCheckedHeader: bool,
  changeDrawCount: func,
  removeDisplayRegion: func,
  addDisplayRegion: func,
  displayingRegions: any,
  regionTypeCode: string,
  coloursByRegion: objectOf(string),
};

function mapState(state) {
  return {
    ratio: selectImageRatio(state),
    coloursByRegion: selectColourRegionByPoints(state),
  };
}

export default connect(mapState, {
  showCheckedRegions,
  hideUncheckedRegions,
  changeDrawCount,
  addDisplayRegion,
  removeDisplayRegion,
})(injectIntl(ItemGroup));
