export const BOOTING_FINISHED = "app/BOOTING__FINISHED";
export const CLEAN_ANNOTATIONS_DATA = "app/CLEAN_ANNOTATIONS_DATA";
export const LOAD_REPO = "app/LOAD_REPO";
export const LOAD_REPO_ERROR = "app/LOAD_REPO_ERROR";
export const SET_USER_DATA = "app/SET_USER_DATA";
export const SET_SOURCE_INFO = "app/SET_SOURCE_INFO";

export const GET_MODULE_CODE = "app/GET_MODULE_CODE";
export const GET_MODULE_CODE_SUCCESS = "app/GET_MODULE_CODE_SUCCESS";
export const GET_MODULE_CODE_ERROR = "app/GET_MODULE_CODE_ERROR";
export const UPDATE_ROLE_CODE = "app/UPDATE_ROLE_CODE";

export const FETCH_FIREBASE_TOKEN = "app/FETCH_FIREBASE_TOKEN";
export const FETCH_FIREBASE_TOKEN_SUCCESS = "app/FETCH_FIREBASE_TOKEN_SUCCESS";

export const SET_CURRENT_DROPDOWN_STATUS = "app/SET_CURRENT_DROPDOWN_STATUS";
export const CHANGE_CURRENT_DROPDOWN_STATUS =
  "app/CHANGE_CURRENT_DROPDOWN_STATUS";

export const FETCH_INITIAL_DATA = "app/FETCH_INITIAL_DATA";
export const FETCH_INITIAL_DATA_SUCCESS = "app/FETCH_INITIAL_DATA_SUCCESS";
export const FETCH_USER_IN_PROJECT = "app/FETCH_USER_IN_PROJECT";
export const FETCH_USER_IN_PROJECT_SUCCESS =
  "app/FETCH_USER_IN_PROJECT_SUCCESS";
export const SET_LABELER_ID = "app/SET_LABELER_ID";

export const SET_DIALOG_OPEN = "app/SET_DIALOG_OPEN";
export const SEND_EMAIL_TO_SYSTEM_ADMIN = "app/SEND_EMAIL_TO_SYSTEM_ADMIN";

export const CHANGE_LANGUAGE = "app/CHANGE_LANGUAGE";

export const defaultLanguage = "en";
