import React from "react";
import { connect } from "react-redux";
import { bool, func, number, object } from "prop-types";
import PolygonPrototype from "../Polygon";
import { updatePolygon } from "redux/polygon/actions";

function Polygon({
  properties,
  ratio,
  isSelected,
  onSelect,
  updatePolygon,
  ...rest
}) {
  const handleChange = (data) => {
    updatePolygon({
      ...data,
      dataPoint: data.points,
    });
  };

  return (
    <>
      <PolygonPrototype
        ratio={ratio}
        onSelect={onSelect}
        isSelected={isSelected}
        key={properties.id}
        polygon={{
          ...properties,
          points: properties.dataPoint.map((item) => [item.x, item.y]),
        }}
        onChange={handleChange}
        {...rest}
      />
    </>
  );
}

Polygon.propTypes = {
  properties: object,
  ratio: number,
  isSelected: bool,
  onSelect: func,
  updatePolygon: func,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { updatePolygon })(Polygon);
