import * as React from "react";

function User(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 8C9.47333 8 10.6667 6.80667 10.6667 5.33334C10.6667 3.86001 9.47333 2.66667 8 2.66667C6.52666 2.66667 5.33333 3.86001 5.33333 5.33334C5.33333 6.80667 6.52666 8 8 8ZM8 9.33334C6.22 9.33334 2.66666 10.2267 2.66666 12V13.3333H13.3333V12C13.3333 10.2267 9.78 9.33334 8 9.33334Z"
        fill="#555F7C"
      />
    </svg>
  );
}

export default User;
