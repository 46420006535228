export default [
  {},
  {
    target: '[data-tour="choose-class-input"]',
    contentId: "tour-guide.choose-class-step.content",
    titleId: "tour-guide.choose-class-step.title",
    content: `Choose a class that you've created on tab "Class". This class will define your annotation type.`,
    title: "Select a class for labeling",
    placement: "right",
  },
  {
    target: '[data-tour="menu-box"]', // need click to continue - 2
    contentId: "tour-guide.choose-tool-step.content",
    titleId: "tour-guide.choose-tool-step.title",
    content:
      "Choose the tool that you expect annotation. You can use hotkey beside the tool icon to hit this tool.",
    title: " Choose annotation tool",
    placement: "right",
  },
  {
    target: '[data-tour="main-image"]', // need click to continue - 2
    contentId: "tour-guide.start-labeling-step.content",
    titleId: "tour-guide.start-labeling-step.title",
    content:
      "Label the object that matches with the selected class and annotation type.",
    title: "Start labeling",
  },
  {
    target: '[data-tour="box-list"]',
    contentId: "tour-guide.review-step.content",
    titleId: "tour-guide.review-step.title",
    content:
      "You can review, edit or delete all created annotations from this table.",
    title: "Review your labeling result",
    placement: "left",
  },
];
