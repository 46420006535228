import { all, fork, takeEvery, call, put, select } from "redux-saga/effects";

import { savePolylineAPI, updatePolylineAPI } from "api/polyline";

import { savePolylineError, updatePolylineError } from "redux/polyline/actions";
import { addShapesSuccess, updateShapesSuccess } from "redux/shape/actions";
import { selectLabels } from "redux/points/selectors";

import { SAVE_POLYLINE, UPDATE_POLYLINE } from "./constants";
import { selectImageId } from "../image/selectors";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import cloneDeep from "lodash.clonedeep";
// import { endCounting } from "redux/timing/actions";

function* watchSavePolyline() {
  yield takeEvery(SAVE_POLYLINE, function* ({ payload, callback }) {
    const { labelId, dataPoint } = payload;
    const tmpDataPoint = dataPoint.map((item, index) => ({
      uid: index + 1,
      x: item[0],
      y: item[1],
    }));
    const imageId = yield select(selectImageId);
    const response = yield call(savePolylineAPI, {
      sourceId: imageId,
      labelId,
      dataPoint: tmpDataPoint,
    });
    if (response.success) {
      yield put(
        showNotification({
          type: "success",
          msg: "New polyline added successfully.",
          msgId: "notification.new-polyline-added",
        })
      );
      const labelList = yield select(selectLabels);
      const { data } = response;
      const labelInfo = labelList.find(
        (label) => Number(label.id) === data.labelId
      );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
      yield put(
        addShapesSuccess({
          ...data,
          id: data.labelPointId,
          color: labelInfo && labelInfo.color,
          labelName: labelInfo && labelInfo.name,
          classesTypeCode: "polyline-image",
        })
      );
    } else {
      yield put(savePolylineError("Save polyline error!"));
    }
    // yield put(endCounting());
    if (callback) callback();
  });
}

function* watchUpdatePolyline() {
  yield takeEvery(UPDATE_POLYLINE, function* ({ payload }) {
    const { isHideNotification, dataPoint, id, labelId } = payload;
    const imageId = yield select(selectImageId);
    const tmpDataPoint = cloneDeep(dataPoint).map((item, index) => ({
      uid: index + 1,
      x: item[0],
      y: item[1],
    }));
    const response = yield call(updatePolylineAPI, {
      id,
      sourceId: imageId,
      dataPoint: tmpDataPoint,
      labelId,
    });
    if (response.success) {
      yield put(updateShapesSuccess({ ...payload, dataPoint: tmpDataPoint }));
      if (!isHideNotification)
        yield put(
          showNotification({
            type: "success",
            msg: "New polyline updated successfully",
            msgId: "notification.new-polyline-updated-success",
          })
        );

      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(updatePolylineError("Update box error!"));
    }
  });
}

export default function* boxSaga() {
  yield all([fork(watchSavePolyline), fork(watchUpdatePolyline)]);
}
