import {
  Box,
  Polygon,
  Segmentation,
  Cuboid,
  OCR,
  Polyline,
} from "components/LabellingShape";
import * as allTools from "redux/tools/enums";

export const mapShapeToComponent = {
  [`${allTools.BOX_MODE}-image`]: Box,
  [`${allTools.POLYGON_MODE}-image`]: Polygon,
  [`${allTools.SEGMENTATION_MODE}-image`]: Segmentation,
  [`${allTools.CUBOID_MODE}-image`]: Cuboid,
  [`${allTools.OCR}-image`]: OCR,
  [`${allTools.POLYLINE_MODE}-image`]: Polyline,
};
