import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { arrayOf, object, bool, func } from "prop-types";
import Select, { components } from "react-select";

import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  selectAllClassifications,
  selectIsFormOpened,
  selectClassificationLoading,
} from "redux/classification/selectors";
import { updateClassificationsData } from "redux/classification/actions";

import styles from "./style.module.scss";
import IntlMessage from "../../helpers/IntlMessage";

function Classification({
  allClassifications,
  updateClassificationsData,
  isLoading,
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [data, setData] = useState([]);
  const [arraySelected, setArraySelected] = useState([]);

  useEffect(() => {
    setData(allClassifications);
    setArraySelected(setSelectedValue([...allClassifications]));
  }, [allClassifications]);

  const setSelectedValue = (allData) => {
    const arr = [];
    if (!allData || !allData.length || allData.length <= 0) return arr;

    allData.map((item) => {
      const options = item.classify_items.map((cItem) => {
        return {
          label: cItem.item_name,
          value: cItem.classify_item_id,
        };
      });
      const selectedValue = item.classify_items.filter(
        (x) => x.selected === true
      );
      const arraySelectedOption = options.filter(
        (op) =>
          selectedValue &&
          selectedValue.length > 0 &&
          selectedValue.some((x) => x.classify_item_id === op.value)
      );
      arr.push({
        classify_id: item.classify_id,
        options: options,
        selected: arraySelectedOption,
      });
    });

    return arr;
  };

  const handleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const radioOption = ({ ...props }) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="radio"
            checked={props.isSelected}
            name="rdSelect"
            style={{ transform: "scale(1.2)" }}
          />{" "}
          <label>{props.label} </label>
        </components.Option>
      </div>
    );
  };

  const checkboxOption = ({ ...props }) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            style={{ transform: "scale(1.2)" }}
            name="rdSelect"
          />{" "}
          <label>{props.label} </label>
        </components.Option>
      </div>
    );
  };

  const handleSaveClassificationData = (e, classificationId, isCheckBox) => {
    const tempData = [...arraySelected];
    const newData = tempData.map((data) => {
      if (data.classify_id === classificationId) {
        const newD = { ...data };
        newD.selected = e;
        return newD;
      }
      return { ...data };
    });

    setArraySelected(newData);

    let arrItem = [];
    if (e) {
      if (!isCheckBox) {
        arrItem.push({ classify_items_id: e.value });
      } else if (e.length > 0) {
        arrItem = e.map((item) => {
          return { classify_items_id: item.value };
        });
      }
    }

    updateClassificationsData({
      classificationId: classificationId,
      classify_items: arrItem,
    });
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled, isFocused }) => {
      const color = "#F2F5F9";
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isFocused ? color : undefined,
        color: isDisabled ? "#ccc" : "black",
        cursor: isDisabled ? "not-allowed" : "pointer",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled ? color : undefined,
        },
      };
    },
  };

  return (
    <div className={styles.issueContainer}>
      <div
        className={styles.issueHeader}
        role="button"
        tabIndex="0"
        onKeyPress={() => {}}
        onClick={handleCollapse}
      >
        <button type="button">
          {isCollapsed ? <ChevronRight /> : <ExpandMore />}
        </button>
        <span>
          <IntlMessage id="img-anno-header.classification" />
        </span>
        {isLoading && <CircularProgress size={17} style={{ marginLeft: 10 }} />}
      </div>

      {!isCollapsed ? (
        <div className={styles.classificationBody}>
          <div className={styles.cardContainer}>
            {data && data.length && data.length > 0 ? (
              data.map((item, index) => {
                const selectedObj = arraySelected.filter(
                  (x) => x.classify_id == item.classify_id
                )[0];
                const options = selectedObj.options;
                const isCheckBox = item.data_type === "checkbox";
                const arraySelectedOption = selectedObj.selected;
                return (
                  <div key={item.classify_id} style={{ padding: "5px 10px" }}>
                    <h4 className={""} style={{ margin: "5px 0px" }}>
                      {index + 1}. {item.name}
                    </h4>
                    <Select
                      options={options}
                      isClearable={true}
                      components={{
                        Option: isCheckBox ? checkboxOption : radioOption,
                      }}
                      isMulti={isCheckBox}
                      closeMenuOnSelect={!isCheckBox}
                      hideSelectedOptions={false}
                      backspaceRemovesValue={false}
                      onChange={(e) =>
                        handleSaveClassificationData(
                          e,
                          item.classify_id,
                          isCheckBox
                        )
                      }
                      menuPosition={"fixed"}
                      value={arraySelectedOption}
                      styles={colourStyles}
                    />
                  </div>
                );
              })
            ) : (
              <span className={styles.noData}>
                <IntlMessage id="common.no-data-to-show" />
              </span>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

Classification.propTypes = {
  allClassifications: arrayOf(object),
  updateClassificationsData: func,
  isFormOpened: bool,
  isLoading: bool,
  isSelected: bool,
  label: bool,
};

const mapStateToProps = (state) => ({
  allClassifications: selectAllClassifications(state),
  isFormOpened: selectIsFormOpened(state),
  isLoading: selectClassificationLoading(state),
});

export default connect(mapStateToProps, {
  updateClassificationsData,
})(Classification);
