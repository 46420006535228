import {
  all,
  fork,
  select,
  call,
  put,
  takeEvery,
  takeLatest,
  cancel,
} from "redux-saga/effects";
import get from "lodash/get";

import {
  getClassifications,
  updateClassificationData,
  removeClassificationData,
} from "api/classification";
import { selectImageId } from "redux/image/selectors";
import {
  selectUserId,
  selectProjectId,
  selectDatasetId,
} from "redux/app/selectors";
import {
  FETCH_CLASSIFICATIONS,
  UPDATE_CLASSIFICATIONS,
  REMOVE_CLASSIFICATIONS,
  fetchClassificationsSuccess,
  fetchClassificationsFailure,
  updateClassificationsDataSuccess,
  updateClassificationsDataFailure,
  removeClassificationsDataSuccess,
  removeClassificationsDataFailure,
} from "./actions";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import { getParameterByName } from "helpers";

function* watchFetchClassifications() {
  yield takeEvery(FETCH_CLASSIFICATIONS, function* () {
    const sourceId = yield select(selectImageId);
    const projectId = yield select(selectProjectId);
    const isViewResult = getParameterByName("isViewResult");
    if (isViewResult) {
      yield put(fetchClassificationsFailure());

      return;
    }

    const response = yield call(getClassifications, sourceId, projectId);

    if (response.success) {
      let payload = get(response, "data", []);

      yield put(fetchClassificationsSuccess(payload));
    } else {
      yield put(
        fetchClassificationsFailure("Error when fetch issues. Try again.")
      );
      yield cancel();
    }
  });
}

function* watchUpdateClassificationData() {
  yield takeLatest(UPDATE_CLASSIFICATIONS, function* ({ payload }) {
    const sourceId = yield select(selectImageId);
    const updatedBy = yield select(selectUserId);
    const datasetId = yield select(selectDatasetId);
    const projectId = yield select(selectProjectId);

    const params = {
      ...payload,
      sourceId,
      updatedBy,
      datasetId,
      projectId,
    };

    const response = yield call(updateClassificationData, params);

    if (response.success) {
      yield put(updateClassificationsDataSuccess(payload));
      yield put(
        showNotification({
          type: "success",
          msg: "Update classification successfully.",
          msgId: "notification.update-class-success",
        })
      );
      const id = yield select(selectCurrentNotiId);
      delayRemoveNotification(id);
    } else {
      yield put(updateClassificationsDataFailure(payload));
      yield put(
        showNotification({
          type: "error",
          msg: "Error when update classification. Try again.",
          msgId: "notification.update-class-error",
        })
      );
    }
  });
}

function* watchRemoveClassification() {
  yield takeLatest(REMOVE_CLASSIFICATIONS, function* ({ payload }) {
    const { parentId, issueId } = payload;
    const sourceId = yield select(selectImageId);

    const response = yield call(removeClassificationData, issueId);

    if (response.success) {
      yield put(
        removeClassificationsDataSuccess({ issueId, parentId, sourceId })
      );
    } else {
      yield put(
        removeClassificationsDataFailure("Error when delete issue. Try again.")
      );
    }
  });
}

export default function* issuesSaga() {
  yield all([
    fork(watchFetchClassifications),
    fork(watchUpdateClassificationData),
    fork(watchRemoveClassification),
  ]);
}
