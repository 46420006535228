import React from "react";
import { connect } from "react-redux";
import PropTypes, { number } from "prop-types";
import clsx from "clsx";
import FlagIcon from "@material-ui/icons/Flag";
import style from "./style.module.scss";
import { selectImageId } from "redux/image/selectors";
import { getParameterByName } from "helpers";
import { BenchmarkStart } from "icons";

function ImageContainer({
  issue,
  id,
  url,
  handlePickImage,
  index,
  currImageId,
  qualityData = 15,
  rotation,
}) {
  const imageClassName = clsx({
    [style.imageContainer]: true,
    [style.selectedImg]: currImageId === id,
  });
  const isViewResult = getParameterByName("isViewResult");
  const displayQualityData =
    qualityData === 100 ? (
      <span className={style.benchmarkIcon}>
        <BenchmarkStart />
      </span>
    ) : (
      <div className={style.benchmarkWrapper}>{qualityData}%</div>
    );

  return (
    <div
      data-id={id}
      className={imageClassName}
      index={index}
      id={`imageContainer_${id}`}
    >
      {issue && issue.total !== 0 && (
        <div className={style.issueWrapper}>
          <FlagIcon
            color={issue.status ? "error" : "primary"}
            fontSize="small"
          />
          <span style={{ whiteSpace: "nowrap", marginLeft: 2 }}>
            {issue.open} / {issue.total}
          </span>
        </div>
      )}
      {isViewResult && displayQualityData}
      <img
        data-id={id}
        index={index}
        onClick={handlePickImage}
        src={url}
        className={style.previewImage}
        style={{
          backgroundImage: `url(${url})`,
          transform: `rotate(${(rotation - 1) * 90}deg)`,
        }}
      />
    </div>
  );
}

ImageContainer.propTypes = {
  issue: PropTypes.object,
  status: PropTypes.bool,
  id: PropTypes.string,
  handlePickImage: PropTypes.func,
  index: PropTypes.number,
  currImageId: PropTypes.string,
  url: PropTypes.string,
  qualityData: PropTypes.number,
  rotation: number,
};

const mapStateToProps = (state) => ({
  currImageId: selectImageId(state),
});

export default connect(mapStateToProps)(ImageContainer);
