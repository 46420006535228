import React, { useState } from "react";
// import { get } from "lodash";
import {
  connect,
  useDispatch,
  // useSelector
} from "react-redux";
import { bool, number, string, arrayOf, object, func } from "prop-types";
import { injectIntl } from "react-intl";
import KeyboardModal from "components/KeyboardModal";
import PushNotification from "components/PushNotification";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
// import HelpIcon from "@material-ui/icons/Help";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import NotificationsIcon from "@material-ui/icons/Notifications";
import InfoIcon from "@material-ui/icons/Info";
import { UserDefaultAvatar, Home } from "icons";
import {
  selectUserId,
  selectUserFullname,
  selectUserImage,
  selectUserEmail,
  selectIsCustomerApproved,
  selectIsCustomerDeclined,
  // selectCurrentUser,
  selectIsBootingFinished,
  selectLocale,
} from "redux/app/selectors";
import {
  selectPushNotifications,
  selectTotalNewNotifications,
} from "redux/notification/selectors";
import { getCookie, deleteAllCookies, clearLocalStorage } from "helpers";
import { adminApp } from "api/constants";
import style from "./style.module.scss";
import LabelPicker from "components/LabelPicker";
import SegmentationTopBar from "components/SegmentationTopBar";
// import Users from "components/Users";
import { Avatar } from "@material-ui/core";
// import { projectConfig } from "api/constants";
import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Tooltip from "@material-ui/core/Tooltip";
import {
  selectDisplayTiming,
  selectCountingTime,
  selectIsCountingTime,
} from "redux/timing/selectors";

import LogoutConfirm from "components/Modal";

import {
  User,
  // Plan, Upgrade, Payment,
  Logout,
} from "icons";
import LanguageSelector from "../../components/LanguageSelector";
import { changeLanguage } from "../../redux/app/actions";
import IntlMessage from "../../helpers/IntlMessage";

function TopBar({
  intl,
  locale,
  userId,
  fullName,
  email,
  imageSrc,
  pushNotifications,
  notReadNotis,
  isCustomerApproved,
  isCustomerDeclined,
  displayTime,
  countingTime,
  isBootingFinished,
  isCountingTime,
}) {
  const dispatch = useDispatch();
  // const currentUser = useSelector(selectCurrentUser);
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  // const isAdmin =
  //   get(currentUser, "isSiteAdmin", false) &&
  //   get(currentUser, "roleCode", "") === "admin";
  const [isOpenKeyboardModal, setOpenKeyboardModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notiAnchorEl, setNotiAnchorEl] = useState(null);

  const toggleKeyboardModal = () => {
    setOpenKeyboardModal(!isOpenKeyboardModal);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const logout = () => {
    deleteAllCookies();
    clearLocalStorage();
    window.location.replace(adminApp);
  };

  const handleClickPushNoti = (event) => {
    setNotiAnchorEl(event.currentTarget);
  };

  const handleClosePushNoti = () => {
    setNotiAnchorEl(null);
  };

  const handleSelectLocale = (event) => {
    dispatch(changeLanguage(event.target.value));
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  //componentDidMount = () => {
  window.addEventListener("focus", function () {
    if (!isBootingFinished) return;
    // get current user name on header page
    let lastedLoginUserId =
      document.getElementById("lasted_login_user_id") === null
        ? ""
        : document.getElementById("lasted_login_user_id").value;

    // get user info on cookie
    let userInfo = getCookie("userInfo");
    let userId =
      userInfo === null || userInfo.length === 0
        ? ""
        : JSON.parse(userInfo).userId;

    if (userId !== lastedLoginUserId) {
      console.log("call reload current page");
      window.close();
    } else {
      console.log("focus page for current user: ", lastedLoginUserId, userId);
    }
  });
  //};
  const getAvatarName = (fullName) => {
    const words = fullName.split(" ");
    const firstChar = words[0] ? words[0].substring(0, 1) : "";
    const secondChar = words[1] ? words[1].substring(0, 1) : "";
    const name = firstChar + secondChar;
    return name.toUpperCase();
  };
  return (
    <>
      <nav className={style.navbar}>
        <a href={adminApp}>
          <Home className={style.homeIcon} />
        </a>
        <div className={style.labelPicker}>
          <LabelPicker />
        </div>
        <SegmentationTopBar />
        <div className={style.funcButtons}>
          <div className={style.language}>
            <LanguageSelector
              locale={locale}
              onSelectLocale={handleSelectLocale}
            />
          </div>
          <div className={style.timing}>
            <div className={style.timingContainer}>
              {countingTime || isCountingTime ? (
                <PauseIcon className={style.timingIcon} />
              ) : (
                <PlayIcon className={style.timingIcon} />
              )}{" "}
              {displayTime}
            </div>
            <Tooltip
              title={
                <div className={style.timeTooltip}>
                  <p>
                    <IntlMessage id="top-bar.total-annotation-session-time" />
                  </p>
                  <p>
                    <IntlMessage id="top-bar.clock-starts-counting" />
                  </p>
                </div>
              }
            >
              <InfoIcon />
            </Tooltip>
          </div>
          {/* <Users /> */}
          {/* <a
            className={style.help}
            href={projectConfig.helpURL}
            target="_blank"
            rel="noreferrer"
          >
            <HelpIcon classes={{ root: style.icon }} />
          </a> */}
          <KeyboardIcon
            classes={{ root: style.icon }}
            onClick={toggleKeyboardModal}
          />
          <Badge
            badgeContent={notReadNotis}
            spanor="secondary"
            invisible={notReadNotis === 0}
            className={style.notificationIcon}
            classes={{ badge: style.customBadge }}
            max={20}
            onClick={handleClickPushNoti}
          >
            {/* <img
              src={NotificationIcon}
              alt="notification"
              onClick={handleClickPushNoti}
            /> */}
            <NotificationsIcon
              classes={{ root: style.icon }}
              onClick={handleClickPushNoti}
            />
          </Badge>

          <div
            className={style.userAvatar}
            spanor="default"
            startIcon={imageSrc}
            onClick={handleClick}
            style={{ textTransform: "none" }}
          >
            {!imageSrc && fullName ? (
              <Avatar className={style["avatar-name"]}>
                {getAvatarName(fullName)}
              </Avatar>
            ) : (
              <img
                alt="user-avatar"
                src={imageSrc || UserDefaultAvatar}
                className={style.userAvatar}
              />
            )}
            <input type="hidden" id="lasted_login_user_id" value={userId} />
          </div>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={{ paper: style.rootPopOver }}
          >
            <div className={style.userInfo}>
              <span>
                {!imageSrc && fullName ? (
                  <Avatar className={style["avatar-name"]}>
                    {getAvatarName(fullName)}
                  </Avatar>
                ) : (
                  <img
                    alt="user-avatar"
                    src={imageSrc || UserDefaultAvatar}
                    className={style.userAvatar}
                  />
                )}
              </span>
              <div className={style.userDetail}>
                <p className={style.userName}>{fullName}</p>
                <p className={style.userEmail}>{email}</p>
              </div>
              {/* <a className={style.editUser} href="#">
                Edit User Profile
              </a> */}
            </div>
            <a
              className={style.dropdownItemLink}
              target="_blank"
              rel="noreferrer"
              href={`${adminApp}/app/edit-profile`}
            >
              <Button className={style.logoutButton}>
                <User />
                <span>
                  <IntlMessage id="top-bar.user-profile" />
                </span>
              </Button>
            </a>
            {/* {isAdmin && (
              <>
                <a
                  className={style.dropdownItemLink}
                  target="_blank"
                  rel="noreferrer"
                  href={`${adminApp}/app/my-plan`}
                >
                  <Button className={style.logoutButton}>
                    <Plan />
                    <span>
                      <IntlMessage id="top-bar.my-plan" />
                    </span>
                  </Button>
                </a>
                <a
                  className={style.dropdownItemLink}
                  target="_blank"
                  rel="noreferrer"
                  href={`${adminApp}/app/upgrade`}
                >
                  <Button className={style.logoutButton}>
                    <Upgrade />
                    <span>
                      <IntlMessage id="top-bar.upgrade" />
                    </span>
                  </Button>
                </a>
                <a
                  className={style.dropdownItemLink}
                  target="_blank"
                  rel="noreferrer"
                  href={`${adminApp}/app/my-billing-list`}
                >
                  <Button className={style.logoutButton}>
                    <Payment />
                    <span>
                      <IntlMessage id="top-bar.payment-history" />
                    </span>
                  </Button>
                </a>
              </>
            )} */}
            <div className={style.dropdownItemLink}>
              <Button
                onClick={() => setIsOpenLogout(true)}
                className={style.logoutButton}
              >
                <Logout />
                <span>
                  <IntlMessage id="top-bar.log-out" />
                </span>
              </Button>
            </div>
          </Popover>
          {/* {pushNotifications.length !== 0 && ( */}
          <PushNotification
            notifications={pushNotifications}
            notiAnchorEl={notiAnchorEl}
            handleClosePushNoti={handleClosePushNoti}
          />
          {/* )} */}
        </div>
      </nav>
      <LogoutConfirm
        title={intl.formatMessage({ id: "img-anno-header.confirmation" })}
        content={intl.formatMessage({ id: "top-bar.confirm-log-out" })}
        isOpen={isOpenLogout}
        setIsOpen={(value) => setIsOpenLogout(value)}
        handleSubmit={logout}
      />
      <KeyboardModal
        isCustomerApproved={isCustomerApproved}
        isCustomerDeclined={isCustomerDeclined}
        open={isOpenKeyboardModal}
        toggle={toggleKeyboardModal}
      />
    </>
  );
}

TopBar.propTypes = {
  intl: object,
  locale: string,
  currTool: string,
  userId: string,
  fullName: string,
  email: string,
  imageSrc: string,
  pushNotifications: arrayOf(object),
  notReadNotis: number,
  isCustomerApproved: bool,
  isCustomerDeclined: bool,
  displayTime: string,
  countingTime: number,
  startCounting: func,
  endCounting: func,
  isBootingFinished: bool,
  isCountingTime: bool,
};

const mapStateToProps = (state) => ({
  locale: selectLocale(state),
  userId: selectUserId(state),
  fullName: selectUserFullname(state),
  email: selectUserEmail(state),
  imageSrc: selectUserImage(state),
  pushNotifications: selectPushNotifications(state),
  notReadNotis: selectTotalNewNotifications(state),
  isCustomerApproved: selectIsCustomerApproved(state),
  isCustomerDeclined: selectIsCustomerDeclined(state),
  displayTime: selectDisplayTiming(state),
  countingTime: selectCountingTime(state),
  isBootingFinished: selectIsBootingFinished(state),
  isCountingTime: selectIsCountingTime(state),
});

export default connect(mapStateToProps)(injectIntl(TopBar));
