export const segMode = {
  SMART_MODE: "smart_segmentation",
  MANNUAL_MODE: "mannual_segmentation",
};

export const segTool = {
  PEN_TOOL: "pen",
  ERASER_TOOL: "eraser",
};

export const engines = [
  "f-BRS-Resnet34-dh128 (SBD)",
  "f-BRS-Resnet50-dh128 (SBD)",
  "f-BRS-Resnet101-dh256 (SBD)",
];
