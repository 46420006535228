import React from "react";
import Modal from "@material-ui/core/Modal";
import { bool, func } from "prop-types";
import { defaultHotkeys } from "redux/keyboard/defaultHotkeys";
import TextContainer from "./TextContainer";
import style from "./style.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import IntlMessage from "../../helpers/IntlMessage";
function KeyboardModal({
  open,
  toggle,
  // isCustomerApproved,
  // isCustomerDeclined,
}) {
  let hotKeys = JSON.parse(localStorage.getItem("SALF-hotKeys"));
  if (!hotKeys) {
    hotKeys = defaultHotkeys;
  }

  return (
    <Modal open={open} onClose={toggle}>
      <div
        className={style.modalBody}
        style={{
          width: 500,
          margin: "0 auto",
          backgroundColor: "white",
        }}
      >
        <h3
          style={{
            padding: 10,
            borderBottom: "1px solid hsl(0,0%,90%)",
            marginBottom: 0,
          }}
          toggle={toggle}
        >
          <IntlMessage id="keyboard-modal.hotkeys" />
          <ClearIcon
            onClick={toggle}
            color="disabled"
            fontSize="small"
            className={style.clearIcon}
          />
        </h3>
        <main
          style={{
            padding: "0 20px 20px 20px",
            overflowY: "scroll",
            maxHeight: "800px",
          }}
        >
          <p className={style.titleHotkey}>
            <IntlMessage id="keyboard-modal.general" />
          </p>
          <div className={style.eachRowContainer}>
            <div className={style.rowLeft}>
              <TextContainer>
                <span>{hotKeys.Control.name}</span>
              </TextContainer>
              <span className={style.iconPlus}>+</span>
              <TextContainer>
                <span>{hotKeys.a.name}</span>
              </TextContainer>
            </div>
            <span>
              <IntlMessage id="keyboard-modal.go-to-previous-photo" />
            </span>
          </div>
          <div className={style.eachRowContainer}>
            <div className={style.rowLeft}>
              <TextContainer>
                <span>{hotKeys.Control.name}</span>
              </TextContainer>
              <span className={style.iconPlus}>+</span>
              <TextContainer>
                <span>{hotKeys.d.name}</span>
              </TextContainer>
            </div>
            <span>
              <IntlMessage id="keyboard-modal.go-to-next-photo" />
            </span>
          </div>
          <div className={style.eachRowContainer}>
            <div className={style.rowLeft}>
              <TextContainer>
                <span>{hotKeys.Control.name}</span>
              </TextContainer>
              <span className={style.iconPlus}>+</span>
              <TextContainer>
                <span>{hotKeys.showIrisCircles.name}</span>
              </TextContainer>
              <span className={style.iconPlus}>/</span>
              <TextContainer>
                <span>{hotKeys.v.name}</span>
              </TextContainer>
            </div>
            <span>
              <IntlMessage id="keyboard-modal.copy-paste-box" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <div className={style.rowLeft}>
              <TextContainer>
                <span>{hotKeys.m.name}</span>
              </TextContainer>
            </div>
            <span>
              <IntlMessage id="keyboard-modal.go-to-next-class" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <div className={style.rowLeft}>
              <TextContainer>
                <span>{hotKeys.n.name}</span>
              </TextContainer>
            </div>
            <span>
              <IntlMessage id="keyboard-modal.go-to-previous-class" />
            </span>
          </div>

          <p className={style.titleHotkey}>
            <IntlMessage id="common.tools" />
          </p>
          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.move.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.move-tool" />
            </span>
          </div>
          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.autoIrisCircle.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.auto-put-points-into-iris-circle" />
            </span>
          </div>
          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.box.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.box-tool" />
            </span>
          </div>
          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.polygon.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.polygonal-segmentation-tool" />
            </span>
          </div>
          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.segmentation.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.binary-mask-segmentation-tool" />
            </span>
          </div>
          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.cuboid.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.cuboid-tool" />
            </span>
          </div>
          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.ocr.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.text-box-tool" />
            </span>
          </div>
          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.Escape.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.deselect-tool" />
            </span>
          </div>
          <p className={style.titleHotkey}>
            <IntlMessage id="keyboard-modal.point-edit" />
          </p>

          <div className={style.eachRowContainer}>
            <div className={style.rowLeft}>
              <TextContainer>
                <span>{hotKeys.Control.name}</span>
              </TextContainer>
              <span className={style.iconPlus}>+</span>
              <TextContainer>
                <span>{hotKeys.s.name}</span>
              </TextContainer>
            </div>
            <span>
              <IntlMessage id="keyboard-modal.save-points" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.s.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.go-to-previous-point" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.f.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.go-to-next-point" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.ArrowLeft.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.point-moves-left" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.ArrowRight.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.point-moves-right" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.ArrowUp.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.point-moves-up" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.ArrowDown.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.point-moves-down" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>
                <IntlMessage id="keyboard-modal.mouse-wheel" />
              </span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.zoom-in-out" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.showIndice.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.show-hide-point-index" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.showIrisCircles.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.show-hide-iris-circles" />
            </span>
          </div>

          <div className={style.eachRowContainer}>
            <TextContainer>
              <span>{hotKeys.showLines.name}</span>
            </TextContainer>
            <span>
              <IntlMessage id="keyboard-modal.show-hide-line-connect-points" />
            </span>
          </div>

          {/*{isCustomerApproved ||*/}
          {/*(isCustomerDeclined && <p>Change image status</p>)}*/}

          {/*{isCustomerApproved && (*/}
          {/*<div className={style.eachRowContainer}>*/}
          {/*<TextContainer>*/}
          {/*<span>{hotKeys.approve.name}</span>*/}
          {/*</TextContainer>*/}
          {/*<span>Approve the image</span>*/}
          {/*</div>*/}
          {/*)}*/}

          {/*{isCustomerDeclined && (*/}
          {/*<div className={style.eachRowContainer}>*/}
          {/*<TextContainer>*/}
          {/*<span>{hotKeys.decline.name}</span>*/}
          {/*</TextContainer>*/}
          {/*<span>Decline the image</span>*/}
          {/*</div>*/}
          {/*)}*/}
        </main>
      </div>
    </Modal>
  );
}

KeyboardModal.propTypes = {
  open: bool.isRequired,
  toggle: func.isRequired,
  isCustomerApproved: bool,
  isCustomerDeclined: bool,
};

export default KeyboardModal;
