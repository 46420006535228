import React from "react";
import { func, arrayOf, object, string, bool } from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import style from "./style.module.scss";
import {
  selectListUser,
  selectLabelerId,
  selectCurrentDropdownStatus,
} from "redux/app/selectors";
import { changeCurrentDropdownStatus, setLabelerId } from "redux/app/actions";
import { fetchInputData, changeFilterBtnStatus } from "redux/image/actions";
import {
  selectPaginationByStatus,
  selectFilterBtnStatus,
  selectFilterTags,
} from "redux/image/selectors";
import { ROLE_CODE_PROJECT_LABELER } from "helpers/constants";
import IntlMessage from "../../helpers/IntlMessage";

import { TagAutoComplete } from "components/TagAutoComplete";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    paddingLeft: 3,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  styleOfTags: {
    margin: theme.spacing(1),
    width: "95%",
  },
}));

function FiltersTab({
  intl,
  setTab,
  listUser,
  labelerId,
  setLabelerId,
  changeCurrentDropdownStatus,
  selectedStatus,
  fetchInputData,
  filterBtnStatus,
  filterTags,
  changeFilterBtnStatus,
}) {
  // const [listTag, setListTag] = useState([]);
  const classes = useStyles();
  const handleChange = (event) => {
    changeFilterBtnStatus({ filterBtnStatus: false });
    setLabelerId(event.target.value);
  };

  const handleClick = () => {
    changeFilterBtnStatus({ filterBtnStatus: true });
    changeCurrentDropdownStatus(selectedStatus);
    if (selectedStatus === "New") {
      fetchInputData({ status: "new", page: 0 });
    } else {
      const status = selectedStatus.toLowerCase();
      fetchInputData({ status, page: 0 });
    }

    setTab("images");
  };

  const renderListLabeler = () => {
    const tmpListUser =
      listUser.length > 0
        ? listUser
            .map((member) => {
              return {
                label: member.full_name,
                value: member.user_id,
                key: member.user_id,
                role_code: member.role_code,
              };
            })
            .filter((member) => member.role_code === ROLE_CODE_PROJECT_LABELER)
        : [];

    const listMember = [
      { label: "All", value: "", key: 0, role_code: "labeler" },
      ...tmpListUser,
    ];

    return listMember.map((member) => (
      // eslint-disable-next-line react/jsx-key
      <option key={member.key} value={member.value}>
        {intl.formatMessage({ id: member.label })}
      </option>
    ));
  };
  const handleTagFromAutoComplete = (selectedItem) => {
    changeFilterBtnStatus({
      filterBtnStatus: false,
      filterTagIds: selectedItem,
    });
  };

  return (
    <div className={style.filterTab}>
      <span className={style.listDataTitle}>
        <IntlMessage id="common.labeler" />
      </span>
      <FormControl className={classes.margin}>
        <NativeSelect
          id="demo-customized-select-native"
          value={labelerId}
          onChange={handleChange}
          input={<BootstrapInput />}
          classes={{
            select: style.nativeSelect,
          }}
        >
          {renderListLabeler()}
        </NativeSelect>
      </FormControl>
      <span className={style.listDataTitle}>
        <IntlMessage id="common.tags" />
      </span>
      <FormControl className={classes.styleOfTags}>
        <TagAutoComplete
          value={filterTags}
          isMulti
          onChange={handleTagFromAutoComplete}
          placeholder={intl.formatMessage({
            id: "common.placeholder-tag",
          })}
          className="tw-rounded"
        />
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        classes={{
          root: style.button,
        }}
        disabled={filterBtnStatus}
      >
        <IntlMessage id="input-data-picker.apply-filters" />
      </Button>
    </div>
  );
}

FiltersTab.propTypes = {
  intl: object,
  setTab: func,
  listUser: arrayOf(object),
  labelerId: string,
  setLabelerId: func,
  changeCurrentDropdownStatus: func,
  selectedStatus: string,
  fetchInputData: func,
  filterBtnStatus: bool,
  filterTags: object,
  changeFilterBtnStatus: func,
};

const mapStateToProps = (state) => ({
  listUser: selectListUser(state),
  labelerId: selectLabelerId(state),
  paginationByStatus: selectPaginationByStatus(state),
  selectedStatus: selectCurrentDropdownStatus(state),
  filterBtnStatus: selectFilterBtnStatus(state),
  filterTags: selectFilterTags(state),
});

export default connect(mapStateToProps, {
  setLabelerId,
  changeCurrentDropdownStatus,
  changeFilterBtnStatus,
  fetchInputData,
})(injectIntl(FiltersTab));
