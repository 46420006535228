/* eslint-disable no-case-declarations */
import * as allTools from "redux/tools/enums";

const initialRectangleProps = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

export const initialState = {
  imgPosition: { x: 0, y: 0 },
  originalRetanglePoint: initialRectangleProps,
  rectangleProps: {
    ...initialRectangleProps,
    visible: false,
    id: `rectagleProps`,
  },
  selectedRect: {
    ...initialRectangleProps,
    fill: "rgba(0,0,255,0.5)",
    id: "",
    visible: false,
  },
  polygon: {
    id: -1,
    visible: false,
    points: [],
  },
};

export const getMinHeightMaxWidth = (shape, metadata, ratio) => {
  let position = {
    left: 0,
    top: 0,
  };
  if (!shape) return position;

  const dataPoint = shape.dataPoint;
  if (!dataPoint) return position;
  switch (shape.classesTypeCode) {
    case `${allTools.BOX_MODE}-image`:
      position.left =
        (dataPoint.x - metadata.width / 2 + dataPoint.width) * ratio + 125 + 10;
      position.top = dataPoint.y * ratio;
      break;
    case `${allTools.OCR}-image`:
      position.left =
        (dataPoint.x - metadata.width / 2 + dataPoint.width) * ratio + 125 + 10;
      position.top = dataPoint.y * ratio;
      break;
    case `${allTools.POLYGON_MODE}-image`:
    case `${allTools.POLYLINE_MODE}-image`:
      let maxX = dataPoint[0].x;
      let minY = dataPoint[0].y;
      for (let i = 2; i < dataPoint.length; i++) {
        if (maxX < dataPoint[i].x) maxX = dataPoint[i].x;
        if (minY > dataPoint[i].y) minY = dataPoint[i].y;
      }
      position.left = (maxX - metadata.width / 2) * ratio + 125 + 10;
      position.top = minY * ratio;
      break;
    case `${allTools.SEGMENTATION_MODE}-image`:
      position.left =
        (dataPoint.x + dataPoint.width - metadata.width / 2) * ratio + 125 + 10;
      position.top = dataPoint.y * ratio;
      break;
    case `${allTools.CUBOID_MODE}-image`:
      const boxIndex = dataPoint[0]?.x < dataPoint[1]?.x ? 1 : 0;
      position.left =
        (dataPoint[boxIndex].x -
          metadata.width / 2 +
          dataPoint[boxIndex].width) *
          ratio +
        125 +
        10;
      position.top = dataPoint[boxIndex].y * ratio;
      break;
    default:
      break;
  }

  return position;
};
