import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  paper: ({ paperWidth }) => ({
    width: `${paperWidth}vw`,
    marginTop: "55px",
    boxShadow: "0px 0px 15px #0000002e",
    borderBottom: "solid 60px #4D81B7",
  }),
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "55px",
    padding: "15px 30px",
    borderBottom: "1px solid #e5e7eb",
    color: "#5c6269",
    fontSize: "1rem",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  body: {
    padding: "30px 30px",
    marginBottom: "55px",

    "& label": {
      "&.heading": {
        display: "block",
        color: "#212121",
        fontWeight: 600,
        fontSize: "1rem",
        marginBottom: "5px",
      },
    },
    "& input, textarea, select": {
      display: "block",
      width: "100%",
      padding: "14px 15px",
      marginTop: "5px",
      marginBottom: "20px",
      borderRadius: "4px",
      border: "1px solid #D5DFE9",
      fontSize: "0.85rem",
      lineHeight: "22px",
      color: "#2A323C",

      "&:focus": {
        outline: "none",
        border: "1px solid #1871E8",
      },
    },
    "& input:disabled": {
      backgroundColor: "#E1E6ED",
      cursor: "not-allowed",
    },
    "& .form-check-inline": {
      alignItems: "baseline",

      "& .form-check-input": {
        width: "20px",
      },
      "& .form-check-label": {
        position: "relative",
        top: "-2px",
        paddingRight: "15px",
        fontSize: "0.85rem",
        lineHeight: "22px",
        color: "#2A323C",
      },
    },
    "& .react-select__value-container": {
      height: "52px",
      padding: "11px 15px",
      fontSize: "0.85rem",
      lineHeight: "22px",
      color: "#2A323C",
      whiteSpace: "nowrap",
      borderRadius: "4px",
    },
    "& .info": {
      marginBottom: "30px",
      color: "#5C6269",
      fontSize: "0.85rem",
      fontWeight: 400,
    },
    "& .label-description": {
      marginBottom: "20px",

      "& p": {
        lineHeight: "1.3 rem",
      },
    },
    "& img": {
      borderRadius: "4px",
    },
    "& button": {
      fontSize: "0.875rem",
      padding: "8px 15px",
      fontWeight: "600",
      minWidth: "110px",
      marginLeft: ".6rem !important",
      borderRadius: "4px",

      "&.disabled": {
        cursor: "not-allowed !important",
      },
    },
    "& .custom-radio.custom-control, .custom-file-label": {
      fontSize: "0.85rem",
    },
    "& div.react-select-creatable__control": {
      padding: 0,
      "& div.react-select-creatable__value-container": {
        paddingTop: 0,
        paddingBottom: 0,
        "& .react-select-creatable__input input": {
          marginTop: "10px",
          marginBottom: "12px",
        },
      },
    },
  },
});

export default useStyles;
