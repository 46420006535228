import { baseURL, commonHeader, userLogCommonHeader } from "api/constants";
import { OCR } from "redux/tools/enums";

export function saveOCR({ sourceId, dataPoint, labelId }) {
  return fetch(`${baseURL}/labelOcr`, {
    method: "POST",
    ...userLogCommonHeader,
    headers: {
      ...userLogCommonHeader.headers,
      type: OCR,
    },
    body: JSON.stringify({
      sourceId,
      dataPoint,
      labelId,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function updateOCR({ id, sourceId, dataPoint, labelId }) {
  return fetch(`${baseURL}/labelOcr/${id}`, {
    method: "PUT",
    ...commonHeader,
    body: JSON.stringify({
      sourceId,
      dataPoint,
      labelId,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}
