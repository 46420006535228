/**
 * The box state selectors
 */

import { createSelector } from "reselect";
import { selectColourRegionByPoints } from "redux/points/selectors";
import isEmpty from "lodash.isempty";

import { initialState } from "./reducer";

export const selectCanvasState = (state) => state.canvas || initialState;

export const selectImageRatio = (state) => state.canvas.ratio;

export const selectPointsCanvas = (state) => state.canvas.pointsCanvas;

export const selectIsIndiceShown = (state) => state.canvas.isIndiceShown;

export const selectIsLinesShown = (state) => state.canvas.isLinesShown;

export const selectIsIrisCirclesShown = (state) =>
  state.canvas.isIrisCirclesShown;

export const selectIsKeyPointShown = (state) => state.canvas.isKeyPointShown;

export const selectDrawCount = (state) => state.canvas.drawCount;

export const selectPointsCanvasByRegion = createSelector(
  selectPointsCanvas,
  selectColourRegionByPoints,
  (points, colourRegions) => {
    if (!points.length) return {};
    const outerLip = points.filter((p) => {
      return p.region === "OuterLip";
    });
    const innerLip = points.filter((p) => {
      return p.region === "InnerLip";
    });
    const linesData = points.reduce((acc, point) => {
      const newPoint = [point.x, point.y];

      const firstOuterLip = outerLip.length > 0 ? outerLip[0] : null;
      const firstOuterLipPoint = firstOuterLip
        ? [firstOuterLip.x, firstOuterLip.y]
        : null;

      const middleOuterLip =
        outerLip.length > 0 ? outerLip[outerLip.length / 2] : null;
      const middleOuterLipPoint = middleOuterLip
        ? [middleOuterLip.x, middleOuterLip.y]
        : null;

      const firstInnerLip = innerLip.length > 0 ? innerLip[0] : null;
      const middleInnerLip =
        innerLip.length > 0 ? innerLip[innerLip.length / 2] : null;

      if (!acc[point.region]) {
        acc[point.region] = {};
        acc[point.region].colour = colourRegions[point.region];
      }
      if (point.region === "InnerLip") {
        // connect point between inner lip and outer to have full lips.
        if (point.index === firstInnerLip.index && firstOuterLipPoint)
          acc[point.region].points = firstOuterLipPoint;
        if (point.index === middleInnerLip.index && middleOuterLipPoint) {
          acc[point.region].points = acc[point.region].points.concat(
            middleOuterLipPoint
          );
        }
      }
      if (Array.isArray(acc[point.region].points)) {
        acc[point.region].points = acc[point.region].points.concat(newPoint);
      }
      if (!acc[point.region].points) acc[point.region].points = newPoint;

      return acc;
    }, {});

    for (let i in linesData) {
      if (!["LeftNose", "RightNose", "FaceContour"].includes(i)) {
        const temp = linesData[i].points.slice(0, 2);
        linesData[i].points.push(...temp);
      }
    }

    return linesData;
  }
);

export const selectIrisCenterAndEyeCornerPoints = createSelector(
  selectPointsCanvas,
  (points) => {
    // select points for auto position Iris circles
    return points.length
      ? points.reduce((collection, point) => {
          const { id, x, y } = point;
          if (!collection.LeftEye) collection.LeftEye = {};
          if (!collection.RightEye) collection.RightEye = {};
          if (point && point.region && point.region.includes("IrisCenter")) {
            collection[point.region] = { id, x, y };
          }
          if (point.region === "LeftEye" && [71, 77].includes(Number(id))) {
            collection.LeftEye[id] = { x, y };
          }
          if (point.region === "RightEye" && [83, 89].includes(Number(id))) {
            collection.RightEye[id] = { x, y };
          }
          return collection;
        }, {})
      : {};
  }
);

export const selectAreIrisCentersShown = createSelector(
  selectIrisCenterAndEyeCornerPoints,
  (area) => {
    const { LeftEye, LeftIrisCenter, RightEye, RightIrisCenter } = area;
    return (
      !LeftEye ||
      isEmpty(LeftEye) ||
      !LeftIrisCenter ||
      isEmpty(LeftIrisCenter) ||
      !RightEye ||
      isEmpty(RightEye) ||
      !RightIrisCenter ||
      isEmpty(RightIrisCenter)
    );
  }
);

export const selectModalObject = (state) => state.canvas.modalItem;
