import { baseURL, commonHeader, userLogCommonHeader } from "api/constants";
import { CUBOID_MODE } from "redux/tools/enums";

export function saveCuboid({ sourceId, labelId, dataPoint }) {
  return fetch(`${baseURL}/labelCuboid`, {
    method: "POST",
    ...userLogCommonHeader,
    headers: {
      ...userLogCommonHeader.headers,
      type: CUBOID_MODE,
    },
    body: JSON.stringify({
      sourceId,
      labelId,
      dataPoint,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}

export function updateCuboid({ id, sourceId, labelId, dataPoint }) {
  return fetch(`${baseURL}/labelCuboid/${id}`, {
    method: "PUT",
    ...commonHeader,
    body: JSON.stringify({
      sourceId,
      labelId,
      dataPoint,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error, "error");
      return { error: true, ...error };
    });
}
