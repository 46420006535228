import {
  baseURL,
  commonHeader,
  adminBE,
  commonHeaderAdmin,
} from "api/constants";

export function fetchNotification(payload) {
  const { userId, offset, limit } = payload;
  let url = `${baseURL}/notification?userId=${userId}&limit=${limit}&offset=${offset}`;
  if (payload.datasetId) url += `&datasetId=${payload.datasetId}`;
  if (payload.customerReview)
    url += `&customerReview=${payload.customerReview}`;

  return fetch(url, {
    method: "GET",
    ...commonHeader,
  })
    .then((res) => res.json())
    .catch((error) => console.error(error)); // eslint-disable-line no-console
}

export function updateOneNotification(id, payload) {
  let url = `${baseURL}/notification/${id}`;
  return fetch(url, {
    method: "PUT",
    ...commonHeader,
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => console.error(error)); // eslint-disable-line no-console
}

export const markAllNotificationAsReadAPI = () => {
  const url = `${adminBE}/notification/read-all-notification`;
  return fetch(url, {
    method: "POST",
    ...commonHeaderAdmin,
  })
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
};
