import {
  SET_POINTS,
  SET_SELECTED_POINTS,
  SUBMIT_POINTS,
  SUBMIT_POINTS_SUCCESS,
  SUBMIT_POINTS_ERROR,
  AUTO_FILL_POINT_INDEX,
  AUTO_FILL_OBJECT_INDEX,
  FETCH_LABELS_SUCCESS,
  FETCH_LABELS_FAILURE,
  CHANGE_DISPLAY_REGIONS,
  ADD_DISPLAY_REGION,
  REMOVE_DISPLAY_REGION,
  DISPATCH_IRIS_RADIUS,
  DISPATCH_IRIS_RADIUS_SUCCESS,
  SELECT_LABEL,
} from "./constants";

export function fetchLabelsSuccess(payload) {
  return {
    type: FETCH_LABELS_SUCCESS,
    payload,
  };
}

export function fetchLabelsFailure(payload) {
  return {
    type: FETCH_LABELS_FAILURE,
    payload,
  };
}

export function autoFillPointIndex(payload) {
  return {
    type: AUTO_FILL_POINT_INDEX,
    payload,
  };
}

export function autoFillObjectIndex(payload) {
  return {
    type: AUTO_FILL_OBJECT_INDEX,
    payload,
  };
}

export function setPoints(payload) {
  return {
    type: SET_POINTS,
    payload,
  };
}

export function setSelectedPoints(payload) {
  return {
    type: SET_SELECTED_POINTS,
    payload,
  };
}

export const submitPoints = (payload) => ({
  type: SUBMIT_POINTS,
  payload,
});

export const submitPointsSuccess = (payload) => ({
  type: SUBMIT_POINTS_SUCCESS,
  payload,
});

export const submitPointsError = (payload) => ({
  type: SUBMIT_POINTS_ERROR,
  payload,
});

export const changeDisplayRegions = (payload) => ({
  type: CHANGE_DISPLAY_REGIONS,
  payload,
});

export const addDisplayRegion = (payload) => ({
  type: ADD_DISPLAY_REGION,
  payload,
});

export const removeDisplayRegion = (payload) => ({
  type: REMOVE_DISPLAY_REGION,
  payload,
});

export const dispatchIrisRadius = (payload) => ({
  type: DISPATCH_IRIS_RADIUS,
  payload,
});

export const dispatchIrisRadiusSuccess = (payload) => ({
  type: DISPATCH_IRIS_RADIUS_SUCCESS,
  payload,
});

export const selectLabel = (payload) => ({
  type: SELECT_LABEL,
  payload,
});
