/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { Modal, Backdrop, Button, Fade } from "@material-ui/core";
// import InputBase from "components/InputBase";
import { func, bool, string } from "prop-types";
import { selectRoleCode } from "redux/app/selectors";
import { connect /*, useDispatch*/ } from "react-redux";
import { Group } from "icons";
// import { sendEmailToSystemAdmin } from "redux/app/actions";
import SendContactEmailSuccessModal from "components/SendContactEmailSuccessModal";

import useStyles from "./style";
import { adminApp } from "api/constants";
import IntlMessage from "../../helpers/IntlMessage";

function LimitationModal({ open, onClose, roleCode }) {
  // const dispatch = useDispatch();
  const classes = useStyles();

  // const [message, setMessage] = useState("");
  const [openContactModal, setOpenContactModal] = useState(false);

  // const handleChangeMessageValue = (e) => {
  //   setMessage(e.target.value);
  // };

  const handleCloseSendEmailModal = () => {
    setOpenContactModal(false);
  };

  // const handleContactAdmin = () => {
  //   dispatch(
  //     sendEmailToSystemAdmin({ message }, () => {
  //       onClose();
  //       setOpenContactModal(true);
  //     })
  //   );
  // };

  const body = (
    <div className={classes.paper}>
      <p className={classes.textWarning}>
        <IntlMessage id="limit-time-modal.limit-reached" />
      </p>
      <img className={classes.image} src={Group} alt="" />
      <p className={classes.textInform}>
        <IntlMessage id="segmentation-limit-modal.you-have-reached-the-limit" />
      </p>
      {roleCode !== "admin" ? (
        <>
          <p>
            <IntlMessage id="segmentation-limit-modal.please-contact-your-admin" />
          </p>
          <Button
            className={classes.buttonMember}
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            <IntlMessage id="segmentation-limit-modal.understood" />
          </Button>
        </>
      ) : (
        <>
          <p className={classes.textIntro}>
            <IntlMessage id="segmentation-limit-modal.please-upgrade-your-plan" />
          </p>
          {/* <InputBase
            value={message}
            onChange={handleChangeMessageValue}
          /> */}
          <div className={classes.footerButton}>
            <Button className={classes.buttonReject} onClick={onClose}>
              <IntlMessage id="segmentation-limit-modal.no-thanks" />
            </Button>
            <a
              href={`${adminApp}/app/upgrade`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Button
                // onClick={handleContactAdmin}
                className={classes.buttonAccept}
                variant="contained"
                color="primary"
              >
                <IntlMessage id="limit-time-modal.upgrade-plan" />
              </Button>
            </a>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>{body}</Fade>
      </Modal>
      <SendContactEmailSuccessModal
        open={openContactModal}
        onClose={handleCloseSendEmailModal}
      />
    </div>
  );
}

LimitationModal.propTypes = {
  open: bool,
  onClose: func,
  roleCode: string,
};

const mapStateToProps = (state) => ({
  roleCode: selectRoleCode(state),
});

export default connect(mapStateToProps)(LimitationModal);
