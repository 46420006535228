import React from "react";
import { Line } from "react-konva";

function ConnectLines({ linesData, displayingRegions }) {
  const regions = Array.from(displayingRegions);
  return (
    regions &&
    regions.reduce((acc, region) => {
      if (linesData[region]) {
        acc.push(
          <Line
            key={region}
            points={linesData[region].points}
            stroke={linesData[region].colour}
            strokeWidth={0.7}
          />
        );
      }
      return acc;
    }, [])
  );
}

export default ConnectLines;
