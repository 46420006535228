import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import debounce from "lodash.debounce";
import CircularProgress from "@material-ui/core/CircularProgress";
import { number, bool, object, arrayOf, func } from "prop-types";
import Popover from "@material-ui/core/Popover";
import {
  selectPushNotifications,
  selectCurrentNotiPage,
  selectNotiFetchMore,
  selectTotalNewNotifications,
} from "redux/notification/selectors";
import { selectCustomerReview } from "redux/app/selectors";
import {
  fetchPushNotifications,
  readOneNotification,
  markAllNotificationAsRead,
} from "redux/notification/actions";
import NotificationCard from "./NotificationCard";
import IntlMessage from "../../helpers/IntlMessage";
import style from "./style.module.scss";

function PushNotification({
  notifications,
  notiAnchorEl,
  handleClosePushNoti,
  pushNotifications,
  fetchMore,
  currPage,
  fetchPushNotifications,
  readOneNotification,
  customerReview,
  notReadNotis,
  markAllNotificationAsRead,
}) {
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setHasMore(false);
  }, [pushNotifications.length]);

  function loadFunc() {
    if (fetchMore && currPage !== 0) fetchPushNotifications(currPage);
  }

  function handleScroll() {
    debounceFunc();
  }

  const debounceFunc = debounce(() => setHasMore(true), 300);

  const open = Boolean(notiAnchorEl);
  return (
    <Popover
      anchorEl={notiAnchorEl}
      open={open}
      onClose={handleClosePushNoti}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div>
        <div className={style.notificationHeader}>
          <span className={style.notificationTitle}>
            <IntlMessage id="push-notification.notification" />:{" "}
          </span>
          <span className={style.markAllAsRead}>
            {notReadNotis > 0 && (
              <span onClick={markAllNotificationAsRead}>
                <IntlMessage id="push-notification.mark-all-as-read" />
              </span>
            )}
          </span>{" "}
        </div>
        <InfiniteScroll
          className={style.notificationContainer}
          onScroll={handleScroll}
          pageStart={currPage}
          loadMore={loadFunc}
          hasMore={hasMore}
          loader={
            fetchMore ? (
              <div key={0}>
                <CircularProgress />
              </div>
            ) : (
              ""
            )
          }
        >
          {notifications.length > 0 ? (
            notifications.map((noti) => (
              <NotificationCard
                key={noti.notificationId}
                readOneNotification={readOneNotification}
                customerReview={customerReview}
                {...noti}
              />
            ))
          ) : (
            <div className={style.notificationCard}>
              <IntlMessage id="push-notification.no-notifications" />
            </div>
          )}
        </InfiniteScroll>
      </div>
    </Popover>
  );
}

PushNotification.propTypes = {
  notifications: arrayOf(object),
  notiAnchorEl: object,
  handleClosePushNoti: func,
  pushNotifications: arrayOf(object),
  fetchMore: bool,
  currPage: number,
  fetchPushNotifications: func,
  readOneNotification: func,
  customerReview: bool,
  notReadNotis: number,
  markAllNotificationAsRead: func,
};

function mapState(state) {
  return {
    pushNotifications: selectPushNotifications(state),
    currPage: selectCurrentNotiPage(state),
    fetchMore: selectNotiFetchMore(state),
    customerReview: selectCustomerReview(state),
    notReadNotis: selectTotalNewNotifications(state),
  };
}

export default connect(mapState, {
  fetchPushNotifications,
  readOneNotification,
  markAllNotificationAsRead,
})(PushNotification);
