import React from "react";
import { useSelector } from "react-redux";
import OriginalImage from "../OriginalImage";
import { selectImageInfo, selectAllImgIds } from "redux/image/selectors";
import { selectCanvasState } from "redux/canvas/selectors";
import { number } from "prop-types";
import style from "./style.module.scss";
import { CircularProgress } from "@material-ui/core";
import { getCookie } from "helpers";
import IntlMessage from "../../../helpers/IntlMessage";

export default function Smallmage({ brightness, contrast }) {
  const allImg = useSelector(selectAllImgIds);
  const {
    metadata,
    thumbnailURL,
    imageUrl,
    status,
    originalUrl,
    imageRotationDirection,
  } = useSelector(selectImageInfo);
  const { ratio } = useSelector(selectCanvasState);
  const sourceInFoInCookie = getCookie("sourceInfo");
  const sourceId =
    sourceInFoInCookie && JSON.parse(sourceInFoInCookie).sourceId;

  if (!sourceId) {
    return (
      <span style={{ marginTop: 50 }}>
        <IntlMessage id="main-image.no-image-selected" />
      </span>
    );
  } else {
    if (!imageUrl) {
      if (allImg.length === 0) {
        if (status) {
          return (
            <span style={{ marginTop: 50 }}>
              <IntlMessage id="main-image.no-image-selected" />
            </span>
          );
        }

        if (typeof originalUrl == "undefined" || originalUrl.length === 0) {
          return (
            <span style={{ marginTop: 50 }}>
              <IntlMessage id="main-image.no-image-selected" />
            </span>
          );
        }

        return (
          <span style={{ marginTop: 50 }}>
            <CircularProgress />
          </span>
        );
      }

      return (
        <span style={{ marginTop: 50 }}>
          <CircularProgress />
        </span>
      );
    }
  }

  const finalWidth =
    imageRotationDirection === 2 || imageRotationDirection === 4
      ? metadata.height * ratio
      : metadata.width * ratio;

  const finalHeight =
    imageRotationDirection === 2 || imageRotationDirection === 4
      ? metadata.width * ratio
      : metadata.height * ratio;

  return (
    <div
      style={{
        // width: finalWidth,
        // height: finalHeight,
        width:
          thumbnailURL === originalUrl ? finalWidth : metadata.width * ratio,
        height:
          thumbnailURL === originalUrl ? finalHeight : metadata.height * ratio,
        position: "relative",
        transform: `rotate(${(imageRotationDirection - 1) * 90}deg)`,
      }}
    >
      {/* <OriginalImage
        width={metadata.width * ratio}
        height={metadata.height * ratio}
        src={thumbnailURL}
        contrast={contrast}
        brightness={brightness}
        className={style.image}
      /> */}
      <OriginalImage
        width={finalWidth}
        height={finalHeight}
        src={originalUrl}
        contrast={contrast}
        brightness={brightness}
        className={style.image}
      />
    </div>
  );
}

Smallmage.propTypes = {
  contrast: number,
  brightness: number,
};
