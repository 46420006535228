import React from "react";
import { connect } from "react-redux";
import { array, func, object } from "prop-types";
import ItemGroup from "./ItemGroup";
import { setDisplayingShape } from "redux/shape/actions";
import {
  getLabelsOfShapes,
  makeShapeByLabel,
  getDisplayingShapes,
  getAllShapes,
} from "redux/shape/selector";
import IntlMessage from "../../helpers/IntlMessage";
import styles from "./style.module.scss";

const dataHead = [
  {
    name: "shape-list.index",
    accessor: "index",
  },

  {
    name: "shape-list.issue",
    accessor: "issue",
  },
];

function ListContainer(props) {
  const {
    displayingShapes,
    shapeLabels,
    shapesByLabel,
    shapes,
    setDisplayingShape,
  } = props;

  const gridHead = "27px minmax(90px, 1fr) minmax(80px, 0.5fr)  50px";

  const gridBody = "25px 25px minmax(90px, 1fr) minmax(80px, 0.5fr)  40px ";

  function handleCheckDisplayAllShapes() {
    const allShapesDisplaying = shapes.map(({ id, labelId }) => ({
      id,
      labelId,
    }));
    setDisplayingShape(
      displayingShapes.length !== 0 ? [] : allShapesDisplaying
    );
  }

  return (
    <>
      {shapesByLabel ? (
        <div>
          <div
            className={styles.listHead}
            style={{
              gridTemplateColumns: gridHead,
            }}
          >
            <span className={styles.inputWrapper}>
              <input
                type="checkbox"
                checked={displayingShapes.length !== 0}
                onChange={handleCheckDisplayAllShapes}
              />
            </span>
            {dataHead.map((item, index) => (
              <span
                style={
                  index === 0 || index === 1
                    ? { textAlign: "left", width: item.width }
                    : { width: item.width }
                }
                key={item.name}
              >
                <IntlMessage id={item.name} />
              </span>
            ))}
          </div>
          <hr />
          <div className={styles.itemContainer}>
            {shapeLabels
              .sort((a, b) => Number(a) - Number(b))
              .map((item) => {
                return (
                  <ItemGroup
                    key={item}
                    labelId={item}
                    shapes={shapesByLabel[item]}
                    gridBody={gridBody}
                  />
                );
              })}
          </div>
        </div>
      ) : (
        <span className={styles.noData}>No data to show.</span>
      )}
    </>
  );
}

ListContainer.propTypes = {
  shapeLabels: array,
  shapesByLabel: object,
  displayingShapes: array,
  shapes: array,
  setDisplayingShape: func,
};

const mapStateToProps = (state) => ({
  shapeLabels: getLabelsOfShapes()(state),
  shapesByLabel: makeShapeByLabel(state),
  displayingShapes: getDisplayingShapes(state),
  shapes: getAllShapes(state),
});

export default connect(mapStateToProps, {
  setDisplayingShape,
})(ListContainer);
