import React from "react";
import { func, bool, string, number } from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { fetchLabels } from "api";
import { TextareaAutosize } from "@material-ui/core";
import { setUserTypingStatus } from "redux/keyboard/action";
import { selectIsUserTypingStatus } from "redux/keyboard/selectors";
import { selectProjectId } from "redux/app/selectors";
import { connect } from "react-redux";
import { submitPoints } from "redux/points/actions";
import { submitImage } from "redux/image/actions";
import { selectImageAnnotationType } from "redux/image/selectors";
import { deselectTool } from "redux/tools/actions";
import IntlMessage from "../../helpers/IntlMessage";
import IntlMessageWithHTML from "../../helpers/IntlMessageWithHTML";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          style={{ padding: "0px" }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedDialogs(props) {
  const { annotationType } = props;
  const [label, setLabel] = React.useState("");
  const [labels, setLabels] = React.useState([]);
  const canSubmitPoints =
    props.typeOfDialog === "submit" || props.typeOfDialog === "decline";

  const onChange = () => {
    if (!props.isUserTyping) props.setUserTypingStatus(true);
  };

  React.useEffect(() => {
    if (props.projectId && props.open && props.typeOfDialog === "decline") {
      fetchLabels(props.projectId).then((res) => {
        if (res.success) setLabels(res.data);
      });
    }
  }, [props.projectId, props.open, props.typeOfDialog]);

  const handleChange = (event) => {
    setLabel(event.target.value);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const onClick = () => {
    const { deselectTool } = props;
    let newImageStatus = "";

    // TODO: add message when user decline a source
    // const input = document.getElementById("customer-decline") || {};
    switch (props.typeOfDialog) {
      case "approve":
        newImageStatus = "approved";
        break;
      case "decline":
        newImageStatus = "declined";
        break;
      case "skip":
        newImageStatus = "skipped";
        break;
      case "reopen":
        newImageStatus = "reopen";
        break;
      case "doublecheck":
        newImageStatus = "doublecheck";
        break;
      default:
        newImageStatus = "submitted";
    }

    props.submitImage({
      status: newImageStatus,
      // message: input.value || "",
    });

    // Submit points feature only for Labeler
    if (annotationType === "image-facial" && canSubmitPoints) {
      props.submitPoints();
    }

    handleClose();

    deselectTool && deselectTool();
  };

  const renderValue = () =>
    labels.length ? (
      label || <IntlMessage id="img-anno-header.please-choose-a-class" />
    ) : (
      <IntlMessage id="img-anno-header.no-option-to-choose" />
    );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <IntlMessage id="img-anno-header.confirmation" />
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          <IntlMessageWithHTML
            id={`img-anno-header.${props.typeOfDialog}-confirm-question`}
          />
        </Typography>
        {props.typeOfDialog === "decline" && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControl>
              <Typography>
                <IntlMessage id="img-anno-header.reason" />:
              </Typography>
              <TextareaAutosize
                aria-label="customer-decline"
                variant="outlined"
                id="customer-decline"
                rowsMin={3}
                onChange={onChange}
                style={{ padding: 5 }}
              />
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
              <Typography id="select-error">
                <IntlMessage id="img-anno-header.classification" />:
              </Typography>
              <Select
                labelId="select-error"
                id="select-error"
                value={label}
                onChange={handleChange}
                displayEmpty={true}
                renderValue={renderValue}
                disabled={!labels.length}
              >
                {labels.map((item) => (
                  <MenuItem key={item.id} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClick} color="primary" variant="contained">
          <IntlMessage id="common.yes" />
        </Button>
        <Button onClick={handleClose} variant="outlined">
          <IntlMessage id="common.no" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CustomizedDialogs.propTypes = {
  setOpen: func,
  onClick: func,
  open: bool,
  typeOfDialog: string,
  projectId: number,
  isUserTyping: bool,
  setUserTypingStatus: func,
  submitImage: func,
  submitPoints: func,
  annotationType: string,
  deselectTool: func,
};

const mapStateToProps = (state) => ({
  isUserTyping: selectIsUserTypingStatus(state),
  projectId: selectProjectId(state),
  annotationType: selectImageAnnotationType(state),
});

export default connect(mapStateToProps, {
  setUserTypingStatus,
  submitImage,
  submitPoints,
  deselectTool,
})(CustomizedDialogs);
