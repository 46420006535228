import React, { useState, useRef } from "react";
import clsx from "clsx";
import { func, string, arrayOf, object, bool } from "prop-types";
import { connect } from "react-redux";

import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RefreshIcon from "@material-ui/icons/Refresh";

import { refetchInputData, setScrollImage } from "redux/image/actions";
import { selectIsFetchingData } from "redux/image/selectors";

import style from "./style.module.scss";
import IntlMessage from "../../helpers/IntlMessage";
import { injectIntl } from "react-intl";

function DropDown({
  status,
  setSelectedStatus,
  selectedStatus,
  refetchInputData,
  isFetchingData,
  setScrollImage,
  intl,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef();

  function handleClick() {
    setOpen(!open);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSelectedStt(event) {
    const currStt =
      event.target.getAttribute("data-text") ??
      event.currentTarget.getAttribute("data-text");
    setOpen(false);
    if (selectedStatus !== currStt) {
      setSelectedStatus(currStt);
      setScrollImage(false);
    }
  }

  function handleListKeyDown() {
    setOpen(false);
  }

  function handleRefresh(event) {
    event.stopPropagation();
    refetchInputData(0);
  }

  const areaClassName = clsx({
    [style.selectedArea]: true,
    [style.opening]: open,
  });

  return (
    <div ref={anchorRef} onClick={handleClick}>
      <div className={areaClassName}>
        <div className={style.componentTitle}>
          <div className={style.dropdownIcon}>
            {open ? <ChevronRight /> : <ExpandMore />}
          </div>
          &nbsp; &nbsp;
          <div className={style.selectedText}>
            {selectedStatus
              ? intl.formatMessage({
                  id: `common.${selectedStatus.toLowerCase()}`,
                })
              : ""}
          </div>
        </div>
        <div className={style.refreshBtnContainer}>
          <button
            type="button"
            className={style.refresh}
            onClick={handleRefresh}
            disabled // TODO: enable this button when dealing with refetching data
          >
            <div className={style.refreshBtnContainer}>
              {isFetchingData ? (
                <CircularProgress size={18} disableShrink />
              ) : (
                <RefreshIcon width={22} height={22} />
              )}
            </div>
          </button>
        </div>
      </div>
      <Divider />
      <Popper
        className={style.popover}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  style={{ zIndex: 100 }}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {status &&
                    status.length &&
                    status.map((stt) => (
                      <MenuItem
                        className={style.item}
                        key={stt.name}
                        data-text={stt.name}
                        onClick={handleSelectedStt}
                      >
                        {" "}
                        <IntlMessage id={`common.${stt.name.toLowerCase()}`} />
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

DropDown.propTypes = {
  status: arrayOf(object),
  selectedStatus: string,
  setSelectedStatus: func,
  refetchInputData: func,
  isFetchingData: bool,
  setScrollImage: func,
  intl: object,
};

function mapState(state) {
  return {
    isFetchingData: selectIsFetchingData(state),
  };
}

export default injectIntl(
  connect(mapState, {
    refetchInputData,
    setScrollImage,
  })(DropDown)
);
