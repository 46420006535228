import { baseURL, commonHeader } from "api/constants";

export function getIssues(sourceId, parentId = null) {
  return fetch(`${baseURL}/issue?sourceId=${sourceId}&parentId=${parentId}`, {
    method: "GET",
    ...commonHeader,
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}

export function getChildIssue(parentId) {
  return fetch(`${baseURL}/issue/${parentId}`, {
    method: "GET",
    ...commonHeader,
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}

export function createIssue(payload) {
  return fetch(`${baseURL}/issue`, {
    method: "POST",
    ...commonHeader,
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}

export function updateIssue(issueId, payload) {
  return fetch(`${baseURL}/issue/${issueId}`, {
    method: "PUT",
    ...commonHeader,
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}

export function removeIssue(issueId) {
  return fetch(`${baseURL}/issue/${issueId}`, {
    method: "DELETE",
    ...commonHeader,
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}

export function fetchLabels(projectId) {
  return fetch(`${baseURL}/label?projectId=${projectId}&limit=-1`, {
    method: "GET",
    ...commonHeader,
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}

export function fetchDefaultModels({ projectId }) {
  return fetch(`${baseURL}/model/get-default-model?projectId=${projectId}`, {
    method: "GET",
    ...commonHeader,
  })
    .then((res) => res.json())
    .catch((error) => ({ error: true, ...error }));
}
