import React from "react";
import { Group, Circle } from "react-konva";
import { number, func } from "prop-types";

function IrisCircle({ x, y, radius, onDragMove, onDragEnd }) {
  function handleMouseOver() {
    document.body.style.cursor = "pointer";
  }

  function handleMouseLeave() {
    document.body.style.cursor = "default";
  }

  return (
    <Group
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      x={x}
      y={y}
      zIndex={0}
    >
      <Circle
        stroke="blue"
        strokeWidth={0.8}
        radius={radius}
        x={0}
        y={0}
        zIndex={0}
      />
      <Circle
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        draggable
        fill="white"
        radius={3}
        x={radius - 1}
        y={0}
        zIndex={0}
      />
    </Group>
  );
}

IrisCircle.propTypes = {
  x: number,
  y: number,
  radius: number,
  onDragMove: func,
  onDragEnd: func,
};

export default IrisCircle;
