import React from "react";
import { connect } from "react-redux";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { func, bool, string, object, number, shape } from "prop-types";

import { actionFitImageSize } from "redux/image/actions";
import { changeImageRatio } from "redux/canvas/actions";
import { selectImageId, selectImageMetadata } from "redux/image/selectors";
import { selectImageRatio } from "redux/canvas/selectors";

import style from "./style.module.scss";
import IntlMessage from "../../helpers/IntlMessage";

function Resizer(props) {
  const { width = 0, height = 0 } = props.imageMetadata || {};
  const [dropdownOpen, setOpen] = React.useState(false);
  const anchorRef = React.useRef();

  const onChangeImageRatio = (val) => () => {
    props.changeImageRatio(val);
    closeDropDown();
  };

  const fitImage = () => {
    props.actionFitImageSize();
  };

  function openDropDown() {
    setOpen(true);
  }

  function closeDropDown() {
    setOpen(false);
  }

  return (
    <>
      <div className={style.imgSize}>
        <span>{`${width} x ${height}`}</span>
      </div>
      <Button
        ref={anchorRef}
        variant="outlined"
        onClick={openDropDown}
        className={`${style.dropdownToggle} ${
          dropdownOpen ? style.menuOpen : ""
        }`}
      >
        {Math.round(props.ratio * 100)}%
        <ArrowDropDown className={style.customDropDownIcon} />
      </Button>
      <Popper
        className={style.menuContainer}
        open={!!props.imageId && dropdownOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={closeDropDown}>
                <MenuList autoFocusItem={openDropDown} id="menu-list-grow">
                  <MenuItem
                    className={`${style.dropdownItem} ${style.dropdownItemFirst}`}
                    onClick={fitImage}
                  >
                    <IntlMessage id="common.fit" />
                  </MenuItem>
                  {[0.25, 0.5, 0.75, 1, 1.5, 2, 3].map((item) => (
                    <MenuItem
                      key={item}
                      className={style.dropdownItem}
                      onClick={onChangeImageRatio(item)}
                    >
                      {item * 100}%
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

Resizer.propTypes = {
  imageMetadata: shape({
    width: number,
    height: number,
  }),
  closeDropDown: func,
  openDropDown: bool,
  anchorRef: object,
  imageId: string,
  dropdownOpen: bool,
  changeImageRatio: func,
  actionFitImageSize: func,
  ratio: number,
};

export default connect(
  (state) => ({
    imageMetadata: selectImageMetadata(state),
    ratio: selectImageRatio(state),
    imageId: selectImageId(state),
  }),
  {
    actionFitImageSize,
    changeImageRatio,
  }
)(Resizer);
