/* eslint-disable no-case-declarations */
import produce from "immer";
import { RECEIVE_USERS, RECEIVE_USER, REMOVE_USER } from "./constants";
export const initialState = {
  users: [],
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RECEIVE_USERS:
        draft.users = action.payload;
        break;
      case RECEIVE_USER:
        draft.users.push(action.payload);
        break;
      case REMOVE_USER:
        draft.users = state.users.filter(
          (item) => item.userId !== action.payload.userId
        );
        break;
      default:
        break;
    }
  });

export default reducer;
