export const POINT_MODE = "point";
export const MOVE_MODE = "move";
export const AUTO_IRIS_MODE = "autoIris";
export const BOX_MODE = "box";
export const LANDMARK_POINT_MODE = "landmarkPoint";
export const POLYGON_MODE = "polygon";
export const POLYLINE_MODE = "polyline";
export const CUBOID_MODE = "cuboid";
export const SEGMENTATION_MODE = "segmentation";
export const OBJECT_TRACKING_MODE = "objectTracking";
export const AUTOMATIC_ANNOTATION_MODE = "automaticAnnotation";
export const OCR = "ocr";
