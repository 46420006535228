import produce from "immer";
import {
  KEYBOARD_EVENT,
  SET_USER_TYPING_STATUS,
  SET_OFFSET_COPY_PASTE,
  SET_CTRL_IS_PRESSING,
} from "./constants";

export const initialState = {
  currKey: "",
  isUserTyping: false,
  keyCount: 0,
  offsetCopyPaste: 100,
  keyCtrlIsPressing: false,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case KEYBOARD_EVENT:
        draft.currKey = action.payload.key;
        if (action.payload.type === "keyup") {
          draft.keyCount += 1;
        }
        break;

      case SET_USER_TYPING_STATUS:
        draft.isUserTyping = action.payload;
        break;
      case SET_OFFSET_COPY_PASTE:
        draft.offsetCopyPaste = action.payload;
        break;
      case SET_CTRL_IS_PRESSING:
        draft.keyCtrlIsPressing = action.payload;
        break;
      default:
    }
  });

export default reducer;
