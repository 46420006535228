export const checkRectangleIsOutOfStage = (rectangleProps, metadata) => {
  const props = {
    ...rectangleProps,
    x: Number(rectangleProps.x),
    y: Number(rectangleProps.y),
    width: Number(rectangleProps.width),
    height: Number(rectangleProps.height),
  };
  if (props.x < 0 || props.y < 0) return true;
  if (
    props.x + props.width > metadata.width * metadata.ratio ||
    props.y + props.height > metadata.height * metadata.ratio
  )
    return true;
  return false;
};
