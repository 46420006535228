import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OriginalImage from "../OriginalImage";
import {
  selectLargeImagePreprocessor,
  selectImageInfo,
  selectLargeImagePreprocessorZoomLevels,
} from "redux/image/selectors";
import { selectCanvasState } from "redux/canvas/selectors";
import style from "./style.module.scss";
import { number } from "prop-types";

export default function LargeImage({ brightness, contrast }) {
  const imagesByPreprocessor = useSelector(selectLargeImagePreprocessor);
  const largeImageZoomLevels = useSelector(
    selectLargeImagePreprocessorZoomLevels
  );
  const { metadata, thumbnailURL } = useSelector(selectImageInfo);
  const { ratio } = useSelector(selectCanvasState);

  const [images, setImages] = useState([]);
  const [lastestZoomLevel, setLastestZoomLevel] = useState(1);

  useEffect(() => {
    const findNearestZoomLevel =
      largeImageZoomLevels.find((level) => ratio <= level) ?? 1;
    const listImagesByZoomLevel = imagesByPreprocessor.filter(
      (image) => image.zoomLevel === findNearestZoomLevel
    );
    setImages(listImagesByZoomLevel);

    if (lastestZoomLevel !== findNearestZoomLevel) {
      setLastestZoomLevel(findNearestZoomLevel);
    }
  }, [ratio]);

  const listImages = images.map(
    ({
      fileName,
      absX = 0,
      absY = 0,
      absW = metadata.width,
      absH = metadata.height,
      url,
    }) => (
      <OriginalImage
        key={`image${fileName}`}
        width={absW * ratio}
        height={absH * ratio}
        src={url}
        className={style.imgStyle}
        style={{
          left: absX * ratio,
          top: absY * ratio,
        }}
        contrast={contrast}
        brightness={brightness}
      />
    )
  );

  return (
    <div
      style={{
        width: metadata.width * ratio,
        height: metadata.height * ratio,
        position: "relative",
      }}
    >
      <OriginalImage
        width={metadata.width * ratio}
        height={metadata.height * ratio}
        src={thumbnailURL}
        className={style.imgStyle}
        contrast={contrast}
        brightness={brightness}
      />
      {listImages}
    </div>
  );
}

LargeImage.propTypes = {
  contrast: number,
  brightness: number,
};
