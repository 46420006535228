import React from "react";
import { connect } from "react-redux";
import { bool, func, number, object } from "prop-types";
import PolygonPrototype from "../Polygon";
import { updatePolyline } from "redux/polyline/actions";

function Polyline({
  properties,
  ratio,
  isSelected,
  onSelect,
  updatePolyline,
  ...rest
}) {
  const handleChange = (data) => {
    updatePolyline({
      ...data,
      dataPoint: data.points,
    });
  };

  return (
    <>
      <PolygonPrototype
        ratio={ratio}
        onSelect={onSelect}
        isSelected={isSelected}
        key={properties.id}
        polygon={{
          ...properties,
          points: properties.dataPoint.map((item) => [item.x, item.y]),
        }}
        onChange={handleChange}
        closed={false}
        strokeWidth={3}
        {...rest}
      />
    </>
  );
}

Polyline.propTypes = {
  properties: object,
  ratio: number,
  isSelected: bool,
  onSelect: func,
  updatePolyline: func,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { updatePolyline })(Polyline);
