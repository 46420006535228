import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={22}
      height={20}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.9}
        d="M22.117 0L8.403 23.991h9.193L26 9.226 22.117 0z"
        {...props}
      />
      <path
        opacity={0.36}
        d="M3.884 0L0 9.23 8.407 24l4.6-8.04L3.884 0z"
        {...props}
      />
    </svg>
  );
}

export default SvgComponent;
