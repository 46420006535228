import { all, fork, takeEvery, call, put, select } from "redux-saga/effects";

import { savePolygonAPI, updatePolygonAPI } from "api/polygon";

import {
  savePolygonError,
  updatePolygon,
  updatePolygonError,
} from "redux/polygon/actions";
import {
  addShapesSuccess,
  setCurrentPolygonPointIndex,
  updateShapesSuccess,
} from "redux/shape/actions";
import { selectLabels } from "redux/points/selectors";
import { addAnnotationHistory } from "redux/annotationHistory/actions";
import {
  SAVE_POLYGON,
  UPDATE_POLYGON,
  DELETE_POLYGON_POINT,
} from "./constants";
import { selectImageId } from "../image/selectors";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import { v4 as uuidv4 } from "uuid";
import { getAllShapes, selectShapeById } from "redux/shape/selector";
import cloneDeep from "lodash.clonedeep";
// import { endCounting } from "redux/timing/actions";
import * as allTools from "redux/tools/enums";
import { updatePolyline } from "redux/polyline/actions";
import { setModalItem } from "redux/canvas/actions";

function* watchSavePolygon() {
  yield takeEvery(SAVE_POLYGON, function* ({
    payload,
    callback,
    noSaveAnnotationHistory,
    callbackSaga,
  }) {
    const { labelId, dataPoint } = payload;
    const tmpDataPoint = dataPoint.map((item, index) => ({
      uid: index + 1,
      x: item[0],
      y: item[1],
    }));
    const imageId = yield select(selectImageId);
    const response = yield call(savePolygonAPI, {
      sourceId: imageId,
      labelId,
      dataPoint: tmpDataPoint,
    });
    if (response.success) {
      const labelList = yield select(selectLabels);
      const { data } = response;
      const labelInfo = labelList.find(
        (label) => Number(label.id) === data.labelId
      );

      const successPayload = {
        ...data,
        id: data.labelPointId,
        color: labelInfo && labelInfo.color,
        labelName: labelInfo && labelInfo.name,
        classesTypeCode: "polygon-image",
      };

      yield put(addShapesSuccess(successPayload));
      if (callbackSaga) yield fork(callbackSaga, successPayload);
      if (noSaveAnnotationHistory) return;
      const historyID = uuidv4();
      // save annotation history
      yield put(
        addAnnotationHistory({
          id: historyID,
          type: SAVE_POLYGON,
          payload: successPayload,
        })
      );
      yield put(
        showNotification({
          type: "success",
          msg: "New polygon added successfully.",
          msgId: "notification.new-polygon-added-successfully",
        })
      );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(savePolygonError("Save polygon error!"));
    }
    // yield put(endCounting());
    if (callback) callback();
  });
}

function* watchUpdatePolygon() {
  yield takeEvery(UPDATE_POLYGON, function* ({
    payload,
    noSaveAnnotationHistory,
  }) {
    const { isHideNotification, dataPoint, id, labelId } = payload;
    const annotation = yield select(selectShapeById(id));

    const imageId = yield select(selectImageId);
    const tmpDataPoint = cloneDeep(dataPoint).map((item, index) => ({
      uid: index + 1,
      x: item[0],
      y: item[1],
    }));
    const response = yield call(updatePolygonAPI, {
      id,
      sourceId: imageId,
      dataPoint: tmpDataPoint,
      labelId,
    });
    if (response.success) {
      yield put(updateShapesSuccess({ ...payload, dataPoint: tmpDataPoint }));
      if (noSaveAnnotationHistory) return;
      const historyID = uuidv4();
      // save annotation history
      yield put(
        addAnnotationHistory({
          id: historyID,
          type: UPDATE_POLYGON,
          payload,
          sourcePayload: {
            ...annotation,
            dataPoint: annotation.dataPoint.map((item) => [item.x, item.y]),
          },
        })
      );

      if (!isHideNotification)
        yield put(
          showNotification({
            type: "success",
            msg: "New polygon updated successfully",
            msgId: "notification.new-polygon-updated-successfully",
          })
        );

      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(updatePolygonError("Update box error!"));
    }
  });
}

function* watchDeletePolygonPoint() {
  yield takeEvery(DELETE_POLYGON_POINT, function* ({ payload }) {
    const { shapeId, index } = payload;
    const shapeList = yield select(getAllShapes);
    const shape = shapeList.find((item) => Number(item.id) === shapeId);
    const isDeletePolygon =
      shape.classesTypeCode === `${allTools.POLYGON_MODE}-image`;
    const isDeletePolyline =
      shape.classesTypeCode === `${allTools.POLYLINE_MODE}-image`;
    let tempDataPoint = cloneDeep(shape?.dataPoint || []).sort(
      (a, b) => Number(a.uid) - Number(b.uid)
    );
    tempDataPoint.splice(index, 1);
    if (
      (isDeletePolygon && tempDataPoint.length === 2) ||
      (isDeletePolyline && tempDataPoint.length === 1)
    ) {
      yield put(
        setModalItem({
          fn: () => {},
          title: "Delete polygon points",
          titleId: "polygon.delete-polygon-points",
          content: "Cannot delete polygon point.",
          contentId: "polygon.cannot-delete-polygon-points",
          id: shape.id,
          fnAction: null,
        })
      );
      return;
    }
    const deleteAction = isDeletePolygon ? updatePolygon : updatePolyline;

    yield put(
      deleteAction({
        ...shape,
        dataPoint: tempDataPoint.map((item) => [item.x, item.y]),
      })
    );

    yield put(setCurrentPolygonPointIndex(-1));
  });
}

export default function* boxSaga() {
  yield all([
    // fork(watchGetPolygon),
    fork(watchSavePolygon),
    fork(watchUpdatePolygon),
    fork(watchDeletePolygonPoint),
  ]);
}
