import { func, object } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { injectIntl } from "react-intl";
import { selectLabelsForDropDown } from "redux/points/selectors";
import { getCurrentShapes } from "redux/shape/selector";
import Select, { components } from "react-select";
import FloatingComponent from "components/FloatingComponent";
import { updateShapeLabel } from "redux/shape/actions";
import * as allTools from "redux/tools/enums";
import { setUserTypingStatus } from "redux/keyboard/action";
import { selectIsUserTypingStatus } from "redux/keyboard/selectors";
import SearchIcon from "@material-ui/icons/Search";
import style from "./style.module.scss";

const CustomSelectInput = (props) => {
  const isUserTyping = useSelector(selectIsUserTypingStatus);
  const dispatch = useDispatch();

  const customProps = Object.assign({}, props);
  delete customProps.autoCorrect;
  delete customProps.autoCapitalize;

  const handleFocus = () => {
    if (!isUserTyping) {
      dispatch(setUserTypingStatus(true));
    }
  };

  const handleBlur = () => {
    if (isUserTyping) {
      dispatch(setUserTypingStatus(false));
    }
  };

  return (
    <components.Input
      {...customProps}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

function ShapeLabelForm({ intl, formPosition = { top: 0, left: 0 }, onClose }) {
  const isUserTyping = useSelector(selectIsUserTypingStatus);

  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const currentShape = useSelector(getCurrentShapes);
  const labels = useSelector(selectLabelsForDropDown());
  const options = labels?.filter((option) =>
    option?.tools?.includes(currentShape?.classesTypeCode)
  );

  useEffect(() => {
    const currentLabel = options.find(
      (option) => Number(option.id) === Number(currentShape.labelId)
    );
    if (isUserTyping) {
      dispatch(setUserTypingStatus(false));
    }
    setValue(currentLabel);
  }, [currentShape?.id]);

  const handleChange = (event) => {
    if (event.value === value.value) return;
    setValue(event);
    dispatch(
      updateShapeLabel({
        payload: {
          id: currentShape.id,
          labelId: event.value,
          color:
            options.find(({ value }) => value === event.value)?.color || "red",
        },
        callback: () => {
          onClose && onClose();
        },
      })
    );
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      minWidth: "150px",
      cursor: "pointer",
      width: 190,
    }),
    option: (styles, { data, isDisabled, isSelected, isFocused }) => ({
      ...styles,
      backgroundColor:
        !isDisabled && (isSelected || isFocused) ? "#e5e5e5" : null,
      color: isDisabled ? "#ccc" : data.color,
      cursor: isDisabled ? "not-allowed" : "pointer",
      fontSize: "15px",
      minWidth: "150px",
    }),
    menu: (styles) => ({
      ...styles,
      minWidth: "150px",
      position: "relative",
    }),
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "15px",
      fontWeight: 600,
      color: "#000",
    }),
  };

  return (
    options.length >= 2 && (
      <FloatingComponent
        formPosition={formPosition}
        openProps={
          currentShape?.classesTypeCode !==
            `${allTools.SEGMENTATION_MODE}-image` && currentShape?.id
        }
        onClose={onClose}
        title={intl.formatMessage({ id: "common.choose-class" })}
      >
        <Select
          placeholder={intl.formatMessage({ id: "common.choose-a-class" })}
          className={style.container}
          components={{
            Input: CustomSelectInput,
            DropdownIndicator,
          }}
          name="form-field-name"
          onChange={handleChange}
          options={options}
          styles={colourStyles}
          blurInputOnSelect
          menuIsOpen
          value={value}
        />
      </FloatingComponent>
    )
  );
}

ShapeLabelForm.propTypes = {
  intl: object,
  formPosition: object,
  onClose: func,
};

export default injectIntl(ShapeLabelForm);
