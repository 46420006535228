import { all, fork, takeEvery, call, put, select } from "redux-saga/effects";
import { saveOCR, updateOCR } from "api/ocr";
import { saveOCRError, updateOCRError } from "redux/ocr/actions";
import {
  addShapesSuccess,
  updateShapesSuccess,
  setCurrentShapeSuccess,
} from "redux/shape/actions";
import { SAVE_OCR, UPDATE_OCR } from "./constants";
import { selectImageId } from "redux/image/selectors";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import { selectLabels } from "redux/points/selectors";
import { toCamelCase } from "helpers";
// import { endCounting } from "redux/timing/actions";

function* watchSaveOCR() {
  yield takeEvery(SAVE_OCR, function* ({ payload, callback }) {
    const {
      x,
      y,
      width,
      height,
      editableTextValue,
      isVisibleTex,
      labelId,
      rotation,
      isHideNotification,
    } = payload;
    const imageId = yield select(selectImageId);
    const response = yield call(saveOCR, {
      sourceId: imageId,
      dataPoint: {
        x,
        y,
        width,
        height,
        editableTextValue,
        isVisibleTex,
        rotation: rotation || 0,
      },
      labelId: Number(labelId),
    });
    if (response.success) {
      const labelList = yield select(selectLabels);
      const camelcaseData = toCamelCase(response.data);
      const labelInfo = labelList.find(
        (label) => Number(label.id) === camelcaseData.labelId
      );
      const dataResponse = {
        dataPoint: camelcaseData.dataPoint,
        id: camelcaseData.labelPointId,
        visible: true,
        color: labelInfo && labelInfo.color,
        labelName: labelInfo && labelInfo.name,
        classesTypeCode: "ocr-image",
        labelId: camelcaseData.labelId,
        dataAttribute: camelcaseData.dataAttribute,
      };
      yield put(addShapesSuccess(dataResponse));
      if (!isHideNotification)
        yield put(
          showNotification({
            type: "success",
            msg: "OCR saved successfully.",
            msgId: "notification.ocr-saved-success",
          })
        );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
      yield put(setCurrentShapeSuccess(dataResponse));
    } else {
      yield put(
        saveOCRError({
          msg: "Save ocr error!",
          msgId: "notification.save-ocr-error",
        })
      );
    }
    // yield put(endCounting());
    if (callback) callback();
  });
}

function* watchUpdateOCR() {
  yield takeEvery(UPDATE_OCR, function* ({ payload }) {
    const {
      // dataPoint: { x, y, width, height, rotation },
      dataPoint,
      labelId,
      id,
      isHideNotification,
    } = payload;
    const imageId = yield select(selectImageId);

    const response = yield call(updateOCR, {
      id: id,
      sourceId: imageId,
      dataPoint: { ...dataPoint, rotation: dataPoint.rotation || 0 },
      labelId: Number(labelId),
    });
    if (response.success) {
      yield put(updateShapesSuccess(payload));
      if (!isHideNotification)
        yield put(
          showNotification({
            type: "success",
            msg: "OCR updated successfully.",
            msgId: "notification.ocr-updated-success",
          })
        );
      const notiId = yield select(selectCurrentNotiId);
      delayRemoveNotification(notiId);
    } else {
      yield put(
        updateOCRError({
          msg: "Update ocr error!",
          msgId: "notification.update-ocr-error",
        })
      );
    }
  });
}

export default function* boxSaga() {
  yield all([fork(watchSaveOCR), fork(watchUpdateOCR)]);
}
