import {
  START_COUNTING,
  END_COUNTING,
  SAVE_COUNTING,
  END_COUNTING_FINISH,
  SAVE_REMAIN_TIME,
  SET_IS_COUNTING_TIME,
} from "./constants";

export function startCounting(payload) {
  return {
    type: START_COUNTING,
    payload,
  };
}

export function endCounting(payload) {
  return {
    type: END_COUNTING,
    payload,
  };
}

export function finishCounting(payload) {
  return {
    type: END_COUNTING_FINISH,
    payload,
  };
}

export function saveCounting(payload) {
  return {
    type: SAVE_COUNTING,
    payload,
  };
}

export function saveRemainTime(payload) {
  return {
    type: SAVE_REMAIN_TIME,
    payload,
  };
}

export function setIsCountingTime(payload) {
  return {
    type: SET_IS_COUNTING_TIME,
    payload,
  };
}
