import { createSelector } from "reselect";
import { selectIsSegmentationMode } from "redux/tools/selectors";
import get from "lodash.get";

export const getSegmentations = (state) => state.segmentation.segmentations;

export const getDrawingSegmentations = (state) =>
  state.segmentation.drawingSegmentation;

export const getCurrentSegConfig = (state) => ({
  mode: state.segmentation.mode,
  tool: state.segmentation.tool,
});

export const getIsBrush = (state) => state.segmentation.tool === "pen";

export const getIsEraser = (state) => state.segmentation.tool === "eraser";

export const getSegmentationLineWidth = (state) => state.segmentation.lineWidth;

export const checkDrawSegmentationArea = createSelector(
  selectIsSegmentationMode,
  getDrawingSegmentations,
  (isSegmentationMode, drawingSegmentation) => {
    const x = Number(drawingSegmentation?.x);
    const y = Number(drawingSegmentation?.y);
    const width = get(drawingSegmentation, "width", 0);
    const height = get(drawingSegmentation, "height", 0);
    const checkHasArea =
      typeof x === "number" && typeof y === "number" && width && height;
    return Boolean(isSegmentationMode && !checkHasArea);
  }
);

export const isReadyToDrawSegmentation = createSelector(
  getDrawingSegmentations,
  (drawingSegmentation) => {
    const x = Number(drawingSegmentation?.x);
    const y = Number(drawingSegmentation?.y);
    const width = get(drawingSegmentation, "width", 0);
    const height = get(drawingSegmentation, "height", 0);
    return typeof x === "number" && typeof y === "number" && width && height;
  }
);

export const getIsUnsaveSegmentationSelector = (state) =>
  state.segmentation.isUnsaveSegmentation;

export const getSegmentationEnginesName = (state) =>
  state.segmentation.engineName;

export const getOpenLimitationSegmentationModal = (state) =>
  state.segmentation.isOpenLimitationModal;
