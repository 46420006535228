import {
  all,
  fork,
  select,
  call,
  put,
  takeEvery,
  takeLatest,
  cancel,
} from "redux-saga/effects";
import moment from "moment";
import get from "lodash/get";

import {
  getIssues,
  getChildIssue,
  createIssue,
  updateIssue,
  removeIssue,
} from "api/issues";
import { selectImageId } from "redux/image/selectors";
import { selectPointsCanvas } from "redux/canvas/selectors";
import {
  selectUserFullname,
  selectUserImage,
  selectUserId,
} from "redux/app/selectors";
import { toCamelCase, replacePropertyName } from "helpers";
import {
  FETCH_ISSUES,
  FETCH_CHILD_ISSUE,
  ADD_ISSUE,
  ADD_CHILD_ISSUE,
  RESOLVE_ISSUE,
  REOPEN_ISSUE,
  DELETE_ISSUE,
  EDIT_ISSUE,
  fetchIssuesSuccess,
  fetchIssuesFailure,
  fetchChildIssueSuccess,
  fetchChildIssueFailure,
  addIssueSuccess,
  addIssueFailure,
  addChildIssueFailure,
  addChildIssueSuccess,
  resolveIssueSuccess,
  resolveIssueFailure,
  deleteIssueFailure,
  deleteIssueSuccess,
  editIssueSuccess,
  editIssueFailure,
  reopenIssueFailure,
  reopenIssueSuccess,
} from "./actions";
import { selectIssueId } from "./selectors";
import { setSelectedPoints } from "redux/points/actions";
import { CLICK_HASH_TAG } from "./actions";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import { selectClickedFlagIndex } from "../points/selectors";
import { getParameterByName } from "helpers";

function* watchFetchIssues() {
  yield takeEvery(FETCH_ISSUES, function* () {
    const sourceId = yield select(selectImageId);
    const isViewResult = getParameterByName("isViewResult");
    if (isViewResult) {
      yield put(fetchIssuesFailure());
      return;
    }
    const response = yield call(getIssues, sourceId);

    if (response.success) {
      let payload = get(response, "data.result", []);

      payload = payload.map((issue) => {
        let camelCaseIssue = toCamelCase(issue);
        camelCaseIssue = replacePropertyName(camelCaseIssue, [
          ["annotationKey", "annotationId"],
        ]);
        const {
          createdDate,
          updatedDate,
          lastChildrenCreatedDate,
        } = camelCaseIssue;
        return {
          ...camelCaseIssue,
          createdDate: createdDate ? moment(createdDate).from() : createdDate,
          updatedDate: updatedDate ? moment(updatedDate).from() : updatedDate,
          lastChildrenCreatedDate: lastChildrenCreatedDate
            ? moment(lastChildrenCreatedDate).from()
            : lastChildrenCreatedDate,
        };
      });

      yield put(fetchIssuesSuccess(payload));
    } else {
      yield put(fetchIssuesFailure("Error when fetch issues. Try again."));
      yield cancel();
    }
  });
}

function* watchFetchChildIssue() {
  yield takeLatest(FETCH_CHILD_ISSUE, function* ({ payload }) {
    const { issueId } = payload;
    if (!issueId) {
      yield put(fetchChildIssueFailure());
      yield cancel();
    }
    const response = yield call(getChildIssue, issueId);
    const data = get(response, "data.result", []);

    if (response.error) {
      yield put(fetchChildIssueFailure());
      yield cancel();
    } else {
      // Convert time and sort item followed by time
      const childIssues = data.reduce((issues, issue) => {
        let camelCaseIssue = toCamelCase(issue);
        camelCaseIssue = replacePropertyName(camelCaseIssue, [
          ["annotationKey", "annotationId"],
        ]);
        const { createdDate, updatedDate } = camelCaseIssue;
        return [
          {
            ...camelCaseIssue,
            createdDate: createdDate ? moment(createdDate).from() : createdDate,
            updatedDate: updatedDate ? moment(updatedDate).from() : updatedDate,
          },
          ...issues,
        ];
      }, []);

      yield put(fetchChildIssueSuccess({ childIssues, parentId: issueId }));
    }
  });
}

function* addIssueWatcher() {
  yield takeLatest(ADD_ISSUE, function* ({ payload }) {
    const sourceId = yield select(selectImageId);
    const createdBy = yield select(selectUserId);

    const params = {
      ...payload,
      sourceId,
      createdBy,
      type: "comment",
    };

    const flagIndex = yield select(selectClickedFlagIndex);
    if (flagIndex) {
      const labelAnnotationInfos = flagIndex.split("#");
      params.annotationKey = labelAnnotationInfos[2];
    }

    const response = yield call(createIssue, params);

    if (response.success) {
      let camelCaseData = toCamelCase(response.data);
      camelCaseData = replacePropertyName(camelCaseData, [
        ["annotationKey", "annotationId"],
      ]);
      const fullName = yield select(selectUserFullname);
      const imagePreviewPath = yield select(selectUserImage);
      const { createdDate, updatedDate } = camelCaseData;

      yield put(
        addIssueSuccess({
          ...camelCaseData,
          createdDate: createdDate ? moment(createdDate).from() : createdDate,
          updatedDate: updatedDate ? moment(updatedDate).from() : updatedDate,
          fullName,
          imagePreviewPath,
        })
      );
      yield put(
        showNotification({ type: "success", msg: "Issue added successfully.", msgId: "notification.issue-add-success" })
      );
      const id = yield select(selectCurrentNotiId);
      delayRemoveNotification(id);
    } else {
      yield put(addIssueFailure("Error when add new issue. Try again."));
    }
  });
}

function* addChildIssueWatcher() {
  yield takeEvery(ADD_CHILD_ISSUE, function* ({ payload }) {
    payload.annotationKey = payload.annotationId;
    delete payload.annotationId;

    const newPayload = replacePropertyName(payload, [
      ["annotationId", "annotationKey"],
    ]);
    const noNullPayload = {};
    for (let item in newPayload) {
      if (newPayload[item] !== null) {
        noNullPayload[item] = newPayload[item];
      }
    }
    const response = yield call(createIssue, noNullPayload);

    if (response.success) {
      const data = get(response, "data", {});
      delete data.label_name;
      delete data.frame;

      let camelCaseData = toCamelCase(data);
      camelCaseData = replacePropertyName(camelCaseData, [
        ["annotationKey", "annotationId"],
      ]);

      const fullName = yield select(selectUserFullname);
      const imagePreviewPath = yield select(selectUserImage);
      const { createdDate, updatedDate } = camelCaseData;

      yield put(
        addChildIssueSuccess({
          ...camelCaseData,
          annotationId: camelCaseData.annotationKey,
          createdDate: createdDate ? moment(createdDate).from() : createdDate,
          updatedDate: updatedDate ? moment(updatedDate).from() : updatedDate,
          fullName,
          imagePreviewPath,
        })
      );
      yield put(
        showNotification({ type: "success", msg: "Replied successfully.", msgId: "notification.replied-success" })
      );
    } else {
      yield put(
        addChildIssueFailure({msg: "Error when reply to the issue. Try again.", msgId: "notification.error-when-reply"})
      );
    }
  });
}

function* watchResolveIssue() {
  yield takeEvery(RESOLVE_ISSUE, function* ({ payload }) {
    delete payload.imagePreviewPath;
    delete payload.parentId;
    delete payload.userName;
    payload.status = "resolved";

    const response = yield call(updateIssue, payload.issueId, payload);

    if (response.success) {
      const data = get(response, "data");
      let camelCaseData = toCamelCase(data);
      camelCaseData = replacePropertyName(camelCaseData, [
        ["annotationKey", "annotationId"],
      ]);

      const { createdDate, updatedDate } = camelCaseData;
      yield put(
        resolveIssueSuccess({
          ...camelCaseData,
          createdDate: createdDate ? moment(createdDate).from() : createdDate,
          updatedDate: updatedDate ? moment(updatedDate).from() : updatedDate,
        })
      );
    } else {
      yield put(resolveIssueFailure());
    }
  });
}

function* watchReopenIssue() {
  yield takeEvery(REOPEN_ISSUE, function* ({ payload }) {
    delete payload.imagePreviewPath;
    delete payload.parentId;
    delete payload.userName;
    payload.status = "open";

    const response = yield call(updateIssue, payload.issueId, payload);
    if (response.success) {
      const data = get(response, "data");
      let camelCaseData = toCamelCase(data);
      camelCaseData = replacePropertyName(camelCaseData, [
        ["annotationKey", "annotationId"],
      ]);

      const { createdDate, updatedDate } = camelCaseData;
      yield put(
        reopenIssueSuccess({
          ...camelCaseData,
          createdDate: createdDate ? moment(createdDate).from() : createdDate,
          updatedDate: updatedDate ? moment(updatedDate).from() : updatedDate,
        })
      );
    } else {
      yield put(reopenIssueFailure());
    }
  });
}

function* watchDeleteIssue() {
  yield takeLatest(DELETE_ISSUE, function* ({ payload }) {
    const { parentId, issueId } = payload;
    const sourceId = yield select(selectImageId);

    const response = yield call(removeIssue, issueId);

    if (response.success) {
      yield put(deleteIssueSuccess({ issueId, parentId, sourceId }));
    } else {
      yield put(deleteIssueFailure({msg: "Error when delete issue. Try again.", msgId: "notification.error-when-delete-issue"}));
    }
  });
}

function* watchEditIssue() {
  yield takeEvery(EDIT_ISSUE, function* ({ payload }) {
    const issueId = yield select(selectIssueId);

    const response = yield call(updateIssue, issueId, payload);

    if (response.success) {
      let camelCaseData = toCamelCase(response.data);
      camelCaseData = replacePropertyName(camelCaseData, [
        ["annotationKey", "annotationId"],
      ]);

      const { createdDate, updatedDate } = camelCaseData;

      yield put(
        editIssueSuccess({
          ...camelCaseData,
          createdDate: createdDate ? moment(createdDate).from() : createdDate,
          updatedDate: updatedDate ? moment(updatedDate).from() : updatedDate,
        })
      );
      yield put(
        showNotification({ type: "success", msg: "Edit issue success.", msgId: "notification.edit-issue-success" })
      );
    } else {
      yield put(editIssueFailure({msg: "Error when edit issue. Try again.", msgId: "notification.error-edit-issue"}));
    }
  });
}

function* wacthClickHashTag() {
  yield takeEvery(CLICK_HASH_TAG, function* (action) {
    const pointsCanvas = yield select(selectPointsCanvas);
    const selectedPoint = pointsCanvas.find(
      (point) => point.index === action.payload
    );
    if (selectedPoint) {
      const targetEl = document.getElementById(selectedPoint.id);
      if (targetEl) targetEl.scrollIntoView();
      const payload = { [selectedPoint.id]: { ...selectedPoint } };
      yield put(setSelectedPoints(payload));
    }
  });
}

export default function* issuesSaga() {
  yield all([
    fork(wacthClickHashTag),
    fork(watchFetchIssues),
    fork(watchFetchChildIssue),
    fork(addIssueWatcher),
    fork(addChildIssueWatcher),
    fork(watchResolveIssue),
    fork(watchReopenIssue),
    fork(watchDeleteIssue),
    fork(watchEditIssue),
  ]);
}
