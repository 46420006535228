export const CHANGE_IMAGE_RATIO = "canvas/CHANGE_IMAGE_RATIO";
export const SET_POINTS_CANVAS = "canvas/SET_POINTS_CANVAS";
export const SHOW_POINTS_INDICE = "canvas/SHOW_POINTS_INDICE";
export const CHANGE_DRAW_COUNT = "canvas/CHANGE_DRAW_COUNT";
export const SHOW_CHECKED_REGIONS = "canvas/SHOW_CHECKED_REGIONS";
export const HIDE_UNCHECKED_REGIONS = "canvas/HIDE_UNCHECKED_REGIONS";
export const TOGGLE_DISPLAY_ALL_REGIONS = "canvas/TOGGLE_DISPLAY_ALL_REGIONS";
export const SHOW_CONNECT_LINES = "canvas/SHOW_CONNECT_LINES";
export const SHOW_IRIS_CIRCLES = "canvas/SHOW_IRIS_CIRCLES";
export const SHOW_KEY_POINT = "canvas/SHOW_KEY_POINT";
export const SET_MODAL_ITEM = "canvas/SET_MODAL_ITEM";
