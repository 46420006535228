import produce from "immer";

import {
  SAVE_COUNTING,
  END_COUNTING_FINISH,
  SAVE_REMAIN_TIME,
  SET_IS_COUNTING_TIME,
  START_COUNTING,
} from "./constants";

const initialState = {
  countingTime: 0,
  displayCountingTime: 0,
  displayTime: "00: 00",
  remainTime: -1,
  isCountingTime: false,
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SAVE_COUNTING: {
        const {
          countingTime,
          displayTime,
          displayCountingTime,
          remainTime,
        } = action.payload;
        draft.displayTime = displayTime;
        draft.countingTime = countingTime;
        draft.displayCountingTime = displayCountingTime;
        draft.remainTime = remainTime;

        break;
      }
      case END_COUNTING_FINISH: {
        draft.countingTime = initialState.countingTime;
        draft.remainTime = action?.payload?.remainTime;
        draft.isCountingTime = false;
        break;
      }
      case SAVE_REMAIN_TIME: {
        draft.remainTime = action.payload;
        break;
      }
      case SET_IS_COUNTING_TIME: {
        draft.isCountingTime = action.payload;
        break;
      }
      case START_COUNTING: {
        draft.isCountingTime = true;
        break;
      }
      default:
        break;
    }
  });

export default reducer;
