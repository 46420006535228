import React from "react";
import InnerSegmentation from "components/Segmentation";
import { connect } from "react-redux";
import { bool, func, number, object } from "prop-types";
import { getDrawingSegmentations } from "redux/segmentation/selector";
import { selectIsMoveMode } from "redux/tools/selectors";

function Segmentation({
  properties,
  ratio,
  isSelected,
  onSelect,
  drawingSegmentation,
  isMoveMode,
}) {
  const handleSelect = () => {
    if (isMoveMode) return;
    if (onSelect) onSelect();
  };
  return (
    <>
      {drawingSegmentation &&
      Number(drawingSegmentation.id) === Number(properties.id) ? null : (
        <InnerSegmentation
          segmentation={{
            ...properties.dataPoint,
            labelId: properties.labelId,
            label: properties.labelName,
            visible: true,
            id: properties.id,
          }}
          onSelect={handleSelect}
          isSelected={isSelected}
          ratio={ratio}
        />
      )}
    </>
  );
}

Segmentation.propTypes = {
  properties: object,
  ratio: number,
  isSelected: bool,
  onSelect: func,
  drawingSegmentation: object,
  isMoveMode: bool,
};

const mapState = (state) => ({
  drawingSegmentation: getDrawingSegmentations(state),
  isMoveMode: selectIsMoveMode(state),
});

export default connect(mapState)(Segmentation);
