import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: `${theme.spacing(1)}px  ${theme.spacing(3)}px`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "500px",
    borderRadius: "4px",
    "&:focus": {
      outline: "none",
    },
  },
  textWarning: {
    fontSize: "16px",
    fontWeight: "700",
    fontFamily: "Nunito",
    marginTop: "20px",
    marginLeft: "24px",
  },
  image: {
    marginLeft: "150px",
    marginTop: "10px",
  },
  textInform: {
    fontSize: "16px",
    fontWeight: "700",
    fontFamily: "Nunito",
    textAlign: "center",
  },
  textIntro: {
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Nunito",
    textAlign: "center",
  },
  buttonMember: {
    width: "151 px",
    height: "36px",
    marginLeft: "168.5px",
    padding: "5px 15px",
    background: "#1871E8",
    borderRadius: "4px",
    fontFamily: "Nunito",
    fontStyle: "none",
    textTransform: "none",
    fontWeight: "600",
    fontSize: "14px",
    color: "#FFFFFF",
  },
  buttonReject: {
    width: "106px",
    height: "36px",
    marginLeft: "85px",
    padding: "5px 15px",
    background: "#F2F5F9",
    borderRadius: "4px",
    fontFamily: "Nunito",
    fontStyle: "none",
    textTransform: "none",
    fontWeight: "600",
    fontSize: "14px",
    color: "#9CA8B4",
  },
  buttonAccept: {
    width: "152px",
    height: "36px",
    marginLeft: "50px",
    background: "#1871E8",
    borderRadius: "4px",
    fontFamily: "Nunito",
    fontStyle: "none",
    textTransform: "none",
    fontWeight: "600",
    fontSize: "14px",
    color: "#FFFFFF",
  },

  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
  },
  thanks: {
    color: "#1871E8",
    fontWeight: 600,
  },
  header: {
    fontSize: "16px",
    fontWeight: 700,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerButton: {
    marginTop: 16,
  },
}));

export default useStyles;
