import React, { useEffect, useState, useRef } from "react";
import { Image as KonvaImage, Circle, Layer } from "react-konva";
import { connect, useSelector } from "react-redux";
import {
  getSegmentations,
  getIsEraser,
  getCurrentSegConfig,
} from "redux/segmentation/selector";
import { object, array, bool, number, func, string } from "prop-types";
import { selectImageRatio } from "redux/canvas/selectors";
import { selectImageMetadata } from "redux/image/selectors";
import {
  setDrawingSegmentation,
  drawSegmentationByEngine,
  setIsUnsaveSegmentation,
  confirmSmartToolRequest,
} from "redux/segmentation/actions";
import { selectIsSegmentationMode } from "redux/tools/selectors";
import { selectCurrLabel } from "redux/points/selectors";
import { getCurrentShapes } from "redux/shape/selector";
import { segTool, segMode } from "redux/segmentation/enums";
import {
  getDrawingSegmentations,
  getSegmentationLineWidth,
  isReadyToDrawSegmentation,
  getIsUnsaveSegmentationSelector,
} from "redux/segmentation/selector";
import { selecOriginalImageUrl } from "redux/image/selectors";
import ListSmartPoints from "./ListSmartPoints";
import Rectangle from "components/Rectangle";
import { selectToogleUndoRedoSelector } from "redux/annotationHistory/selectors";

function SegmentationLayer({
  ratioProps,
  drawConfig,
  currLabel,
  setDrawingSegmentation,
  drawSegmentationByEngine,
  drawingSegmentation,
  lineWidth,
  originalUrl,
  isReadyToDrawSegmentation,
  metadata,
  setIsUnsaveSegmentation,
  isUnsaveSegmentation,
  // setDialogOpen,
  checkMouseUp,
  confirmSmartToolRequest,
}) {
  const imageRef = useRef();
  const segmentationLayerRef = useRef();

  const undoRedoFlag = useSelector(selectToogleUndoRedoSelector);

  const { tool, mode } = drawConfig;

  const [canvas, setCanvas] = useState(null);
  const [ratio, setRatio] = useState(ratioProps);
  const [lastPointerPosition, setLastPointerPosition] = useState({
    x: 0,
    y: 0,
  });

  const [isPaint, setIsPaint] = useState(false);
  const [smartPoints, setSmartPoints] = useState([]);

  const [cursorX, setCursorX] = useState(null);
  const [cursorY, setCursorY] = useState(null);

  const [segmentationBounding, setSegmentationBounding] = useState(
    drawingSegmentation
  );

  useEffect(() => {
    if (undoRedoFlag) {
      handleDrawBySegmentation(drawingSegmentation);
    }
  }, [undoRedoFlag]);

  useEffect(() => {
    handleMouseUp();
  }, [checkMouseUp]);

  const handleDrawSegmentationBySmartTool = (payload, segmentation) => {
    const { pointArr } = payload;

    drawSegmentationByEngine({
      payload: {
        ...payload,
        pointArr: [
          ...pointArr?.map(({ x, y, isPositive }) => ({
            coords: [x / segmentation.width, y / segmentation.height],
            isPositive,
          })),
        ],
      },

      callback: (res) => {
        confirmSmartToolRequest(res.sessionId);
        const base64Data = `data:image/png;base64,${res.binaryMaskBase64}`;
        const image = document.createElement("img");
        image.onload = () => {
          // fill color
          const fillColorCanvas = document.createElement("canvas");
          fillColorCanvas.width = segmentation.width;
          fillColorCanvas.height = segmentation.height;
          const fillColorContext = fillColorCanvas.getContext("2d");
          fillColorContext.drawImage(image, 0, 0);
          fillColorContext.globalCompositeOperation = "source-in";
          fillColorContext.fillStyle =
            segmentation && segmentation.id
              ? segmentation.color
              : currLabel.color;
          fillColorContext.fillRect(
            0,
            0,
            fillColorCanvas.width,
            fillColorCanvas.height
          );
          fillColorContext.scale(ratio, ratio);
          setCanvas(fillColorCanvas);
          segmentationLayerRef &&
            segmentationLayerRef.current &&
            segmentationLayerRef.current.batchDraw();
          if (!isUnsaveSegmentation) {
            setIsUnsaveSegmentation(true);
          }

          setDrawingSegmentation({
            ...segmentation,
            url: fillColorCanvas.toDataURL(),
            pointArr,
            isSaveAnnotationHistory: true,
          });

          setSmartPoints(pointArr);
        };
        image.src = base64Data;
      },
    });
  };

  const drawSegmentationFromURL = (url) => {
    const image = document.createElement("img");
    image.onload = () => {
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = drawingSegmentation.width;
      tempCanvas.height = drawingSegmentation.height;
      const tempContext = tempCanvas.getContext("2d");
      tempContext.drawImage(image, 0, 0);
      tempContext.scale(ratio, ratio);
      setCanvas(tempCanvas);
    };
    image.src = url;
  };

  const handleDrawBySegmentation = (segmentation) => {
    if (!segmentation) return;
    setSegmentationBounding(segmentation);
    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = segmentation.width;
    tempCanvas.height = segmentation.height;
    const context = tempCanvas.getContext("2d");
    context.lineJoin = "round";
    context.globalCompositeOperation = "source-over";
    context.scale(ratio, ratio);
    setCanvas(tempCanvas);
    setSmartPoints(segmentation.pointArr || []);
    if (segmentation.url) {
      drawSegmentationFromURL(segmentation.url);
    }
  };

  useEffect(() => {
    if (!drawingSegmentation) return;
    handleDrawBySegmentation(drawingSegmentation);
  }, [drawingSegmentation?.id]);

  useEffect(() => {
    settingCursor();
  }, [mode]);

  useEffect(() => {
    if (canvas) {
      const tempCanvas = canvas;
      const context = tempCanvas.getContext("2d");
      context.scale(ratioProps / ratio, ratioProps / ratio);
      setCanvas(tempCanvas);
    }
    setRatio(ratioProps);
  }, [ratioProps]);

  const handleMouseDown = (event) => {
    if (!tool) return;
    if (mode === segMode.MANNUAL_MODE) {
      if (isPaint) {
        setIsPaint(!isPaint);
        return;
      }
      setIsPaint(true);
      const transform = segmentationLayerRef.current
        .getAbsoluteTransform()
        .copy();
      transform.invert();
      const pos = event.target.getStage().getPointerPosition();
      const realPos = transform.point(pos);

      setLastPointerPosition({
        y: realPos / ratio,
        x: realPos / ratio,
      });
    }
  };

  const settingCursor = () => {
    if (mode === segMode.MANNUAL_MODE) {
      document.body.style.cursor = "none";
    } else if (mode === segMode.SMART_MODE) {
      document.body.style.cursor = !isReadyToDrawSegmentation
        ? "crosshair"
        : "pointer";
    } else {
      document.body.style.cursor = "default";
    }
  };

  const handleMouseMove = (event) => {
    settingCursor();
    const transform = segmentationLayerRef.current
      .getAbsoluteTransform()
      .copy();
    transform.invert();
    const pos = event.target.getStage().getPointerPosition();
    const realPos = transform.point(pos);
    // change cursor
    setCursorX(realPos.x);
    setCursorY(realPos.y);

    if (!canvas || !tool || !isPaint) {
      return;
    }
    if (mode === segMode.MANNUAL_MODE) {
      const tempCanvas = canvas;
      const context = tempCanvas.getContext("2d");
      context.lineJoin = "round";
      context.strokeStyle =
        drawingSegmentation && drawingSegmentation.id
          ? drawingSegmentation.color
          : currLabel.color;
      context.globalCompositeOperation =
        tool === segTool.PEN_TOOL ? "source-over" : "destination-out";
      context.lineWidth = lineWidth / ratio;
      context.save();
      context.scale(1 / ratio, 1 / ratio);
      context.beginPath();
      let localPos = {
        x: lastPointerPosition.x,
        y: lastPointerPosition.y,
      };
      context.moveTo(localPos.x, localPos.y);
      localPos = {
        x: realPos.x / ratio,
        y: realPos.y / ratio,
      };
      context.lineTo(localPos.x, localPos.y);
      context.closePath();

      context.stroke();
      context.scale(ratio, ratio);
      setCanvas(tempCanvas);
      setLastPointerPosition(localPos);
    }
  };

  const handleMouseUp = (event) => {
    if (event?.evt) {
      event.evt.stopPropagation();
    }

    if (!canvas || !tool) return;
    if (
      mode === segMode.MANNUAL_MODE &&
      canvas.toDataURL() !== drawingSegmentation.url
    ) {
      setLastPointerPosition({ x: 0, y: 0 });
      setIsPaint(false);
      if (!isUnsaveSegmentation) {
        setIsUnsaveSegmentation(true);
      }

      setDrawingSegmentation({
        ...drawingSegmentation,
        url: canvas.toDataURL(),
        isSaveAnnotationHistory: true,
      });
    }
  };

  const handleMouseLeave = () => {
    document.body.style.cursor = "default";

    setCursorY(null);
    setCursorX(null);
  };

  const handleClick = (event) => {
    if (isReadyToDrawSegmentation && mode === segMode.SMART_MODE) {
      const transform = segmentationLayerRef.current
        .getAbsoluteTransform()
        .copy();
      transform.invert();

      const pos = event.target.getStage().getPointerPosition();
      const realPos = transform.point(pos);
      const localPos = {
        x: realPos.x / ratio,
        y: realPos.y / ratio,
      };

      const payload = {
        labelId: currLabel.id,
        pointArr: [
          ...smartPoints,
          { ...localPos, isPositive: tool === segTool.PEN_TOOL },
        ],
        fileUrl: originalUrl,
        boundingBox: [
          drawingSegmentation.x / metadata.width,
          drawingSegmentation.y / metadata.height,
          drawingSegmentation.width / metadata.width,
          drawingSegmentation.height / metadata.height,
        ],
      };
      handleDrawSegmentationBySmartTool(payload, drawingSegmentation);
    }
  };

  const handleRedrawSegmentationWhenChangingBox = (srcSeg, desSeg) => {
    const { x: x1, y: y1, width: width1, height: height1 } = srcSeg;
    const { x: x2, y: y2, width: width2, height: height2 } = desSeg;

    // cut image
    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = width2;
    tempCanvas.height = height2;
    const context = tempCanvas.getContext("2d");
    context.drawImage(
      canvas,
      x2 < x1 ? 0 : x2 - x1,
      y2 < y1 ? 0 : y2 - y1,
      width2 < width1 ? width2 : width1,
      height2 < height1 ? height2 : height1,
      x2 < x1 ? x1 - x2 : 0,
      y2 < y1 ? y1 - y2 : 0,
      width2 < width1 ? width2 : width1,
      height2 < height1 ? height2 : height1
    );
    context.scale(ratio, ratio);
    setCanvas(tempCanvas);
    setSmartPoints([]);
    if (!isUnsaveSegmentation) {
      setIsUnsaveSegmentation(true);
    }
    setDrawingSegmentation({
      ...desSeg,
      pointArr: [],
      url: tempCanvas.toDataURL(),
      isSaveAnnotationHistory: true,
    });
  };

  const handleChangeDrawingArea = (rectangle) => {
    const tempDrawingSegmentation = {
      ...drawingSegmentation,
      x: rectangle.x,
      y: rectangle.y,
      width: rectangle.width,
      height: rectangle.height,
    };

    handleRedrawSegmentationWhenChangingBox(
      drawingSegmentation,
      tempDrawingSegmentation
    );

    setSegmentationBounding(tempDrawingSegmentation);

    // handleDrawSegmentationBySmartTool(payload, tempDrawingSegmentation);
  };

  if (!drawingSegmentation) return null;

  return (
    <>
      <Layer
        x={0}
        y={0}
        width={(metadata.width || 0) * ratio}
        height={(metadata.height || 0) * ratio}
      >
        <Rectangle
          rectangleProps={{
            x: segmentationBounding.x || 0,
            y: segmentationBounding.y || 0,
            width: segmentationBounding.width,
            height: segmentationBounding.height,
            stroke: drawingSegmentation?.id
              ? drawingSegmentation.color
              : currLabel.color,
            strokeWidth: 3,
          }}
          opacity={1}
          ratio={ratio}
          isSelected
          onChange={handleChangeDrawingArea}
        />
      </Layer>
      <Layer
        ref={segmentationLayerRef}
        x={(drawingSegmentation.x || 0) * ratio}
        y={(drawingSegmentation.y || 0) * ratio}
        width={(drawingSegmentation.width || 0) * ratio}
        height={(drawingSegmentation.height || 0) * ratio}
      >
        {cursorX !== null &&
          cursorY !== null &&
          mode === segMode.MANNUAL_MODE && (
            <Circle
              x={cursorX}
              y={cursorY}
              fill={tool === segTool.PEN_TOOL ? "green" : "#CD5C5C"}
              stroke="#DCDCDC"
              strokeWidth={2}
              radius={lineWidth / 2}
              shadowColor="black"
              shadowOpacity={0.5}
              shadowOffset={{ x: 5, y: 5 }}
              shadowBlur={10}
            />
          )}
        <KonvaImage
          image={canvas}
          ref={imageRef}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onClick={handleClick}
          onMouseLeave={handleMouseLeave}
          opacity={0.7}
          x={0}
          y={0}
          width={(drawingSegmentation.width || 0) * ratio}
          height={(drawingSegmentation.height || 0) * ratio}
        />

        <ListSmartPoints points={smartPoints} ratio={ratio} />
      </Layer>
    </>
  );
}

SegmentationLayer.propTypes = {
  segmentations: array,
  cssSetting: object,
  ratioProps: number,
  isEraser: bool,
  drawConfig: object,
  isSegmentationMode: bool,
  currLabel: object,
  setDrawingSegmentation: func,
  currentShape: object,
  segementationConfig: object,
  drawSegmentationByEngine: func,
  drawingSegmentation: object,
  lineWidth: number,
  originalUrl: string,
  metadata: object,
  isReadyToDrawSegmentation: number,
  setIsUnsaveSegmentation: func,
  isUnsaveSegmentation: bool,
  setDialogOpen: func,
  checkMouseUp: bool,
  confirmSmartToolRequest: func,
};

const mapState = (state) => ({
  isEraser: getIsEraser(state),
  segmentations: getSegmentations(state),
  ratioProps: selectImageRatio(state),
  drawConfig: getCurrentSegConfig(state),
  isSegmentationMode: selectIsSegmentationMode(state),
  currLabel: selectCurrLabel(state),
  currentShape: getCurrentShapes(state),
  drawingSegmentation: getDrawingSegmentations(state),
  lineWidth: getSegmentationLineWidth(state),
  originalUrl: selecOriginalImageUrl(state),
  metadata: selectImageMetadata(state),
  isReadyToDrawSegmentation: isReadyToDrawSegmentation(state),
  isUnsaveSegmentation: getIsUnsaveSegmentationSelector(state),
});

export default connect(mapState, {
  setDrawingSegmentation,
  drawSegmentationByEngine,
  setIsUnsaveSegmentation,
  // setDialogOpen,
  confirmSmartToolRequest,
})(SegmentationLayer);
