module.exports = {
  // Special cases:
  All: "全て",

  // Common phrases
  "common.ai-tool": "AIツール",
  "common.ai-tools": "AIツール",
  "common.automatic-annotation": "自動注釈",
  "common.model": "モデル",
  "common.class-name": "クラス名",
  "common.annotate": "注釈",
  "common.close": "閉じる",
  "common.approve": "承認済み",
  "common.decline": "やめる",
  "common.skip": "スキップ",
  "common.doublecheck": "ダブルチェック",
  "common.submit": "登録",
  "common.reopen": "再開済み",
  "common.yes": "はい",
  "common.no": "いいえ",
  "common.labeler": "ラベラー",
  "common.reviewer": "レビュアー",
  "common.member": "メンバー",
  "common.tag": "タグ",
  "common.placeholder-tag": "タグを選択",
  "common.smart-tag": "スマートタグ",
  "common.selected-tags-title": "現在選択されたタグ ",
  "common.selected-tags-subtitle": "選択されたデータセットの現在のタグ",
  "common.create-smart-tag-title": "新しいスマートタグを作成する",
  "common.create-smart-tag-subtitle":
    "色を選んで、選択されたデータセットのために新しいスマートタグの名前を入力してください",
  "common.color-pattern-title": "カラーパターン ",
  "common.search-or-create-tag": "タグを検索または作成する",
  "common.tag-name-title": "タグ名",
  "common.search-project-tag-by-text-error": "タグの取得に失敗しました。",
  "common.create-tag": "タグを作成する",
  "common.no-options": "オプションがありません",
  "common.add-tag-to-project-successfully": "タグをプロジェクトに追加しました ",
  "common.add-tag-to-project-error": "プロジェクトにタグを追加：エラー",
  "common.add-tag-to-source-successfully": "ソースにタグを追加：成功",
  "common.add-tag-to-source-error": "ソースにタグを追加：失敗",
  "common.fit": "フィット",
  "common.save": "保存",
  "common.reset": "リセット",
  "common.message-us": "私たちにメッセージ",
  "common.new": "新しい",
  "common.submitted": "登録済み",
  "common.approved": "承認済み",
  "common.skipped": "スキップ済み",
  "common.reopened": "再開済み",
  "common.tools": "ツール",
  "common.cancel": "キャンセル",
  "common.ok": "はい",
  "common.no-data-to-show": "表示するためのデータはありません。",
  "common.start": "開始",
  "common.done": "完了",
  "common.choose-class": "クラス選択",
  "common.choose-a-class": "クラス選択",
  "common.open": "開く",
  "common.resolved": "解決済み",
  "common.update": "更新",
  "common.add": "追加",

  // AdsPopup
  "ads-popup.hello": "やあ、こんにちは",
  "ads-popup.question-text":
    "あなたのAIモデルを使って、自動ラベル付しませんか？",
  "ads-popup.not-now": "今ではない",
  "ads-popup.lets-talk": "はい、話しましょう",

  // AutomaticAnnoTool
  "auto-anno-tool.please-select-model":
    "注釈を付ける前にモデルを選択してください！",
  "auto-anno-tool.map-the-classes": "注釈するにはクラスを紐づいてください。",
  "auto-anno-tool.interactors": "インタラクター",
  "auto-anno-tool.detectors": "検出器",
  "auto-anno-tool.trackers": "追跡者",
  "auto-anno-tool.select-a-model": "モデルを選択",
  "auto-anno-tool.delete-mapping": "マッピング削除",
  "auto-anno-tool.model-default-label": "モデルデフォルトラベル",
  "auto-anno-tool.clean-old-annotations": "古い注釈をクリーン",

  "img-anno-header.please-choose-a-class": "クラスを選択してください。",
  "img-anno-header.no-option-to-choose": "選択するオプションはありません。",
  "img-anno-header.skip-confirm-question": "画像を<b>スキップ</b>しますか？",
  "img-anno-header.approve-confirm-question": "画像を<b>承認</b>しますか？",
  "img-anno-header.submit-confirm-question": "画像を<b>登録</b>しますか？",
  "img-anno-header.doublecheck-confirm-question":
    "画像を<b>再確認する</b>しますか？",
  "img-anno-header.confirmation": "確認",
  "img-anno-header.reason": "理由",
  "img-anno-header.classification": "分類 ",
  "img-anno-header.connect-lines": "接続線",
  "img-anno-header.iris-circles": "アイリスサイクル",
  "img-anno-header.key-point": "キーポイント",
  "img-anno-header.reopen-confirm-question":
    "画像を再度開いてもよろしいですか？",

  "img-settings.opacity": "不透明度",
  "img-settings.contrast": "コントラスト",
  "img-settings.brightness": "明るさ",

  "input-data-picker.apply-filters": "フィルタを適用",
  "input-data-picker.leave-page-warning":
    "作業はまだ保存されていないです。このページを離れてもいいですか？",
  "input-data-picker.no-image": "画像ありません。",
  "input-data-picker.images": "画像",
  "input-data-picker.filter": "フィルター",

  "keyboard-modal.hotkeys": "ホットキー",
  "keyboard-modal.general": "一般",
  "keyboard-modal.go-to-previous-photo": "前の画像に戻る",
  "keyboard-modal.go-to-previous-class": "前のクラスに移動します",
  "keyboard-modal.go-to-next-photo": "次の画像に行く",
  "keyboard-modal.go-to-next-class": "次のクラスに行く",
  "keyboard-modal.copy-paste-box": "ボックスコピー・ペースト",
  "keyboard-modal.move-tool": "移動ツール",
  "keyboard-modal.auto-put-points-into-iris-circle":
    "アイリスサイクルの中にポイントの自動配置",
  "keyboard-modal.box-tool": "バウンディングボックスツール",
  "keyboard-modal.polygonal-segmentation-tool":
    "ポリゴンセグメンテーションツール",
  "keyboard-modal.binary-mask-segmentation-tool":
    "バイナリマスクセグメンテーションツール",
  "keyboard-modal.cuboid-tool": "直方体ツール",
  "keyboard-modal.text-box-tool": "テキストボックスツール",
  "keyboard-modal.deselect-tool": "取消ツール",
  "keyboard-modal.point-edit": "ポイント編集",
  "keyboard-modal.save-points": "ポイントを保存",
  "keyboard-modal.go-to-previous-point": "前のポイントに戻る",
  "keyboard-modal.go-to-next-point": "次のポイントに行く",
  "keyboard-modal.point-moves-left": "ポイントを左に移動",
  "keyboard-modal.point-moves-right": "ポイントを右に移動",
  "keyboard-modal.point-moves-up": "ポイントを上に移動",
  "keyboard-modal.point-moves-down": "ポイントを下に移動",
  "keyboard-modal.mouse-wheel": "マウスホイール",
  "keyboard-modal.zoom-in-out": "画像拡大・縮小",
  "keyboard-modal.show-hide-point-index": "ポイントインデックスを表示・非表示",
  "keyboard-modal.show-hide-iris-circles": "アイリスサークルの表示/非表示",
  "keyboard-modal.show-hide-line-connect-points":
    "ポイントを結ぶ線を表示/非表示",

  "limit-time-modal.limit-reached": "制限に達しました",
  "limit-time-modal.you-have-reached-the-limit": "注釈プランの制限に達しました",
  "limit-time-modal.please-upgrade-plan":
    "もっと強力なツールでより多くの価値を生み出すためにプランをアップグレードしてください。",
  "limit-time-modal.upgrade-plan": "プランアップグレード",

  "main-image.no-image-selected": "選択した画像はありません。",

  "point-list.add-an-issue": "課題を追加",
  "point-list.facial-landmarks": "顔上の目印",
  "point-list.all-points": "全てのポイント",

  "push-notification.notification": "通知",
  "push-notification.mark-all-as-read": "全て既読としてマーク",
  "push-notification.no-notifications": "通知はありません。",

  "segmentation-limit-modal.you-have-reached-the-limit":
    "補助ツール利用プランの制限に達しました",
  "segmentation-limit-modal.please-contact-your-admin":
    "プランをアップグレードするには管理者まで連絡してください。",
  "segmentation-limit-modal.understood": "OK、理解しました。",
  "segmentation-limit-modal.please-upgrade-your-plan":
    "もっと強力なツールでより多くの価値を生み出すためにプランをアップグレードしてください。",
  "segmentation-limit-modal.no-thanks": "いいえ",

  "segmentation-tool-button.binary-mask-segmentation":
    "2分マスクセグメンテーションスマートツール",
  "segmentation-tool-button.choose-ai-model": "AIモデル選択",
  "segmentation-tool-button.engine": "エンジン",

  "segmentation-top-bar.draw": "描画",
  "segmentation-top-bar.draw-by-smart-tool": "スマートツールで描画",
  "segmentation-top-bar.draw-manually": "手動で描画",
  "segmentation-top-bar.eraser": "消しゴム",

  "send-contact-email-success-modal.upgrade-request-sent":
    "アップグレード要求が送信されました",
  "send-contact-email-success-modal.thank-you":
    "サービスのご利用、ありがとうございました。",
  "send-contact-email-success-modal.we-will-be-in-touch":
    "あなたの要求を受付ました。処理まだ暫くお待ちください",

  "shape-list.item-list": "項目一覧",
  "shape-list.delete-all-labels": "全て削除",
  "shape-list.index": "索引",
  "shape-list.issue": "課題",
  "shape-list.delete-object": "オブジェクト削除",
  "shape-list.confirm-deletion":
    "このオブジェクトを削除してよろしいでしょうか？",

  "polygon.delete-polygon-points": "ポリゴン削除",
  "polygon.cannot-delete-polygon-points": "ポリゴンを削除できません",

  "spinner.no-data": "データはありません。",

  "tour-guide.choose-class-step.content":
    "タブで作成したクラスを選択してください。このクラスは注釈種別を定義します",
  "tour-guide.choose-class-step.title": "ラベル付のためのクラスを選択",
  "tour-guide.choose-tool-step.content":
    "注釈対象のツールを選択してください。アイコンの下側のホットキーでツールを選択することができます",
  "tour-guide.choose-tool-step.title": "注釈ツール選択",
  "tour-guide.start-labeling-step.content":
    "選択したクラスと注釈種別に一致するオブジェクトにラベルを付けます",
  "tour-guide.start-labeling-step.title": "ラベル付開始",
  "tour-guide.review-step.content":
    "このテーブルの注釈を作成・閲覧・修正・削除が可能です",
  "tour-guide.review-step.title": "ラベル付結果をレビューする",

  "undo-redo-top-bar.undo": "元に戻す",
  "undo-redo-top-bar.redo": "もう一度行う",

  "bottom-hotkeys.cancel-draw-annotation": "画像注釈をキャンセル",

  "issues.reply": "返信",
  "issues.replies": "返信",
  "issues.last-reply": "最後の返信",
  "issues.delete-issue": "課題削除",
  "issues.confirm-delete-issue": "この課題を削除してよろしいでしょうか？",
  "issues.resolve": "解決",
  "issues.selected-object": "オブジェクトを選択",
  "issues.issues": "課題",

  "loading-dialog.please-wait":
    "スマートなツールが魔法をかけるまでお待ちください",

  "reporter.must-have-open-tab-alert":
    "少なくとも1つのタブを開く必要があります",
  "reporter.expand": "開く",
  "reporter.collapse": "コラプス",

  "tool-bar.move-the-image": "画像を移動",
  "tool-bar.auto-position-iris-circles": "アイリスサイクルの自動配置",
  "tool-bar.bounding-box": "バウンディングボックス",
  "tool-bar.landmark-point": "フェイシャルランドマークポイント",
  "tool-bar.polygonal-segmentation": "ポリゴンセグメンテーション",
  "tool-bar.polyline": "ポリライン",
  "tool-bar.cuboid": "直方体",
  "tool-bar.smart-tool-object-tracking": "スマートツールオブジェクト追跡",
  "tool-bar.text-box": "テキストボックス",

  "top-bar.total-annotation-session-time": "注釈セッションの合計時間",
  "top-bar.clock-starts-counting":
    "ラベル付ツールを開くと、時間をカウントし始めます。この合計時間が毎月の注釈時間に差し引かれます",
  "top-bar.user-profile": "ユーザプロフィール更新完了",
  "top-bar.my-plan": "マイプラン",
  "top-bar.upgrade": "アップグレード",
  "top-bar.payment-history": "支払い履歴",
  "top-bar.log-out": "ログアウト",
  "top-bar.confirm-log-out": "BlueEye Data Platformからログアウトしますか？",

  "notification.auto-anno-error": "自動注釈が失敗しました。",
  "notification.auto-anno-success": "自動注釈が成功しました",
  "notification.redo-anno": "やり直し注釈。",
  "notification.does-not-have-permission-to-access-this-link":
    "このリンクにアクセスする権限がありません。",
  "notification.error-fetching-repo":
    "リポジトリをフェッチするときにエラーが発生しました。",
  "notification.cannot-fetch-firebase-token":
    "FireBaseトークンをフェッチできません。",
  "notification.send-contact-email-error": "連絡先メールエラーを送る！",
  "notification.box-saved-success": "ボックスは正常に保存されました。",
  "notification.save-box-error": "保存ボックスエラー！",
  "notification.box-updated-success": "ボックスが正常に更新されました。",
  "notification.box-updated-error": "更新ボックスエラー！",
  "notification.update-class-success": "分類を正常に更新します。",
  "notification.update-class-error":
    "分類を更新するとエラーが発生しました。再試行。",
  "notification.cuboid-saved-success": "中央系は正常に保存されました。",
  "notification.cuboid-saved-error": "完全oidエラーを節約！",
  "notification.update-cuboid-success": "中央源の成功を更新します。",
  "notification.update-cuboid-error": "更新中央oidエラー",
  "notification.error-when-get-csv-file":
    "CSVファイルを取得するとエラーが発生しました。",
  "notification.submit-image-success": "画像{status}正常に。",
  "notification.submit-image-error": "{status}画像エラー！再試行。",
  "notification.save-new-image-success": "新しいイメージを正常に保存します",
  "notification.fail-to-save-edited-image": "編集した画像を保存できません。",
  "notification.fail-to-mark-image": "画像をマークしません！",
  "notification.issue-add-success": "問題は正常に追加されました。",
  "notification.replied-success": "正常に返信しました。",
  "notification.error-when-reply":
    "問題に返信するとエラーが発生します。再試行。",
  "notification.error-when-delete-issue":
    "問題を削除するとエラーが発生します。再試行。",
  "notification.edit-issue-success": "問題の成功を編集します。",
  "notification.cannot-fetch-notifications": "通知を取得できません",
  "notification.fail-to-update-notification": "通知を更新できません",
  "notification.ocr-saved-success": "OCRは正常に保存されました。",
  "notification.save-ocr-error": "OCRエラーを保存してください。",
  "notification.ocr-updated-success": "OCRは正常に更新されました。",
  "notification.update-ocr-error": "OCRエラーを更新します。",
  "notification.points-saved": "保存されたポイント！",
  "notification.submit-points-success": "ポイントを送信する",
  "notification.submit-points-error":
    "ポイントの誤りを送信してください。もう一度やり直してください！",
  "notification.please-adjust-iris-circles":
    "アイリスサークルを調整してください！",
  "notification.iris-circle-radius-changed":
    "アイリスサークル半径が変わりました",
  "notification.fail-to-save-circle-radius": "サークル半径を節約できません",
  "notification.new-polygon-updated-successfully":
    "新しいポリゴンが正常に更新されました",
  "notification.new-polygon-added-successfully":
    "新しいポリゴンが正常に追加されました。",
  "notification.new-polyline-added": "新しいポリラインが正常に追加されました。",
  "notification.new-polyline-updated-success":
    "新しいポリラインが正常に更新されました",
  "notification.save-segmentation-success":
    "セグメンテーションの成功を保存します。",
  "notification.save-segmentation-error":
    "セグメンテーションエラーを保存します。",
  "notification.gpu-not-available":
    "GPUは利用できません。 adminにお問い合わせください。",
  "notification.segmentation-updated-success":
    "セグメンテーションは正常に更新されました。",
  "notification.update-segmentation-error":
    "セグメンテーションエラーを更新します。",
  "notification.get-shape-error": "形状の誤りを得る！",
  "notification.deleted-success": "正常に削除されました。",
  "notification.delete-shape-error": "シェイプエラーを削除します。",
  "notification.shape-attribute-updated-success":
    "Shape属性が正常に更新されました。",
  "notification.update-shape-attribute-error": "形状属性の更新エラー！",
  "notification.update-shape-label-success": "形状ラベルの成功を更新します。",
  "notification.update-shape-label-error": "シェイプラベルエラーを更新します。",
  "notification.shape-copied": "形状がコピーされました。",
  "notification.image-reopen-successfully": "画像が正常に再開します",
  "notification.image-skipped-successfully": "画像が正常にスキップされました",
  "notification.image-approved-successfully": "画像が正常に承認されました",
  "notification.image-submitted-successfully": "画像が正常に送信されました",
  "notification.deleted-all-shapes-success": "全ての形状が正常に削除されました",
  "notification.delete-all-shapes-error": "全ての形状を削除できません",
  "shape-list.delete-all": "全て削除",
  "shape-list.confirm-delete-all": "全て削除してよろしいでしょうか？",

  box: "ボックス",
  "facial landmarks": "顔上の目印",
  issues: "課題",
  "Copy/Paste (Bounding Box only)": "ボックスコピー・ペースト",
  "Remove benchmark": "ベンチマークを削除する",
  "Add benchmark": "ベンチマークを追加",
  "Data have been updated": "データセットが更新できました",
  "An error occurred. Please try again.":
    "予期せぬエラーが発生しました。再度試してみてください。",
  "Mark image successfully": "画像を正常にマークする",
  "Remove mark image successfully": "マーク画像を正常に削除",
  "Undo annotation": "注釈を元に戻す",
};
