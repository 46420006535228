import * as React from "react";

function Move(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 3L3 10.53V11.51L9.84 14.16L12.48 21H13.46L21 3Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Move;
