import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    color: "white",
  },
  icon: {
    color: "#e5e7eb",
  },
}));

export default useStyles;
