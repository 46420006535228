import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash.isempty";
import { func, object } from "prop-types";
import Divider from "@material-ui/core/Divider";
import ImgAnnotationHeader from "components/ImgAnnotationHeader";
import { setSelectedPoints } from "redux/points/actions";
import ImageCanvas from "./ImageCanvas";
import style from "./style.module.scss";
import Settings from "components/ImgSettings";
import { selectImageMetadata } from "redux/image/selectors";
import LimitationModal from "components/SegmentationLimitModal";
import { setOpenSegmentationLimitationModal } from "redux/segmentation/actions";
import { getOpenLimitationSegmentationModal } from "redux/segmentation/selector";
function ImageAnnotation({ setSelectedPoints, imageMetadata }) {
  const dispatch = useDispatch();
  const open = useSelector(getOpenLimitationSegmentationModal);
  const [contrast, setContrast] = React.useState(
    Number(imageMetadata.contrast) || 1
  );
  const [brightness, setBrightness] = React.useState(
    Number(imageMetadata.brightness) || 1
  );
  const [opacity, setOpacity] = React.useState(
    Number(imageMetadata.opacity) || 1
  );

  React.useEffect(() => {
    const { opacity, brightness, contrast } = imageMetadata;
    opacity ? setOpacity(opacity) : setOpacity(1);
    brightness ? setBrightness(brightness) : setBrightness(1);
    contrast ? setContrast(contrast) : setContrast(1);
  }, [imageMetadata]);

  const handleCloseModal = () => {
    dispatch(setOpenSegmentationLimitationModal(false));
  };

  function handleRightClick(event) {
    event.preventDefault();
    document.body.style.cursor = "default";
    if (!window.currPoint || isEmpty(window.currPoint)) return;
    const pointArr = window.currPoint
      .getChildren((point) => point.getAttr("id"))
      .toArray();
    if (!pointArr.length) return;
    const point = pointArr[0];
    const { colour, isChanged } = point.getAttrs();
    if (!isChanged) point.fill(colour);
    window.currPoint = {};
    setSelectedPoints({});
  }

  return (
    <div className={style.container} onContextMenu={handleRightClick}>
      <ImgAnnotationHeader />
      <Divider />
      <ImageCanvas
        setSelectedPoints={setSelectedPoints}
        brightness={brightness}
        contrast={contrast}
        opacity={opacity}
      />
      <Settings
        contrast={contrast}
        setContrast={setContrast}
        brightness={brightness}
        setBrightness={setBrightness}
        opacity={opacity}
        setOpacity={setOpacity}
      />
      <LimitationModal open={open} onClose={handleCloseModal}></LimitationModal>
    </div>
  );
}

ImageAnnotation.propTypes = {
  setSelectedPoints: func,
  imageMetadata: object,
};

export default connect(
  (state) => ({
    imageMetadata: selectImageMetadata(state),
  }),
  {
    setSelectedPoints,
  }
)(ImageAnnotation);
