import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  InputBaseRoot: {
    border: "1px solid #D5DFE9",
    borderRadius: "8px",
    width: "100%",
    padding: "16px",
    fontSize: "14px",
  },
}));

export default useStyles;
