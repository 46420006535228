import React, { useEffect, useState } from "react";
import { number, bool, string, arrayOf, object, func, shape } from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ImageContainer from "components/ImageContainer";
import {
  dropdownItemsByStatus,
  benchmarkDropdownStatus,
} from "helpers/constants";
import {
  selectFetchMore,
  selectCurrPage,
  selectImages,
  selectAllImgIds,
  selectPaginationByStatus,
  selectImageId,
} from "redux/image/selectors";
import {
  selectCurrentDropdownStatus,
  selectCustomerReview,
  selectIsChangingDropdown,
  selectRoleCode,
} from "redux/app/selectors";
import { changeCurrentDropdownStatus } from "redux/app/actions";
import {
  selectOneSource,
  fetchInputData,
  setScrollImage,
} from "redux/image/actions";
import { resetSegmentationMode } from "redux/segmentation/actions";
import { getIsUnsaveSegmentationSelector } from "redux/segmentation/selector";
import { getParameterByName } from "helpers";
import { selectIsScrollImage } from "redux/image/selectors";

import FiltersTab from "./FiltersTab";
import ImageScrollContent from "./ImageScrollContent";
import debounce from "lodash.debounce";

import style from "./style.module.scss";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

function InputDataPicker({
  intl,
  allImages,
  selectOneSource,
  selectedStatus,
  changeCurrentDropdownStatus,
  customerReview,
  allImgIds,
  fetchInputData,
  paginationByStatus,
  isSelectChangeDropdown,
  userRoleCode,
  isCustomerReviewRole,
  resetSegmentationMode,
  isUnsaveSegmentation,
  setScrollImage,
  isScrollImage,
  // imageId,
}) {
  const isViewResult = getParameterByName("isViewResult");
  const isActionHistory = getParameterByName("isActionHistory");
  let allStatus = customerReview
    ? [
        { name: "New", status: ["new"] },
        { name: "Approved", status: ["approved"] },
        { name: "Declined", status: ["declined"] },
      ]
    : dropdownItemsByStatus;
  if (isActionHistory || isViewResult) {
    allStatus = benchmarkDropdownStatus;
  }

  const { page: otherPage, more: moreOther } = paginationByStatus.other;
  const { page: newPage, more: moreNew } = paginationByStatus.new;
  const [tab, setTab] = useState("images");
  // const [isHandleScroll, setIsHandleScroll] = useState(false);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    isSelectChangeDropdown && selectedStatus && loadFunc();
  }, [selectedStatus]);

  // comment code scroll to image when selected image in admin

  // useEffect(() => {
  //   if (imageId && !isHandleScroll) {
  //     if (allImgIds.includes(imageId) !== true) {
  //       loadFunc();
  //     }
  //   }
  // }, [allImgIds, imageId]);

  const handlePickImage = (event) => {
    const id = event.target.getAttribute("data-id");
    const index = event.target.getAttribute("index");
    if (isUnsaveSegmentation) {
      if (
        !window.confirm(
          intl.formatMessage({ id: "input-data-picker.leave-page-warning" })
        )
      )
        return;
    }
    resetSegmentationMode();
    selectOneSource({ id, index });
  };

  const renderAllImages = () => {
    return allImgIds.length
      ? allImgIds.map((id, index) => {
          const _id = isViewResult ? id : allImages[id].sourceId;
          return (
            <ImageContainer
              id={_id}
              index={index}
              key={_id}
              handlePickImage={handlePickImage}
              // url={allImages[id].thumbnailUrl || allImages[id].url}
              url={allImages[id].url}
              issue={allImages[id].issue}
              qualityData={allImages[id].qualityData}
              rotation={allImages[id]?.metadata?.imageRotationDirection || 1}
            />
          );
        })
      : intl.formatMessage({ id: "input-data-picker.no-image" });
  };

  function loadFunc({ isNotNextImage } = {}) {
    if (selectedStatus === "New") {
      moreNew &&
        fetchInputData({ status: "new", page: newPage, isNotNextImage });
    } else {
      const status = selectedStatus.toLowerCase();
      moreOther && fetchInputData({ status, page: otherPage, isNotNextImage });
    }
  }

  const handleScrollDebounce = debounce(() => {
    loadFunc({ isNotNextImage: true });
  }, 300);

  const handleScroll = (event) => {
    const toTop = Math.floor(event.target.scrollTop);
    const isFetchingMore = [-1, 0, 1].includes(
      event.target.scrollHeight - toTop - event.target.clientHeight
    );
    if (!isScrollImage) {
      setScrollImage(true);
    }
    if (isFetchingMore) {
      handleScrollDebounce();
      // setIsHandleScroll(true);
    }
  };

  const imageContainer = (
    <ImageScrollContent
      allStatus={allStatus}
      changeCurrentDropdownStatus={changeCurrentDropdownStatus}
      selectedStatus={selectedStatus}
      handleScroll={handleScroll}
      renderAllImages={renderAllImages}
      paginationByStatus={paginationByStatus}
      // imageId={imageId}
      // isHandleScroll={isHandleScroll}
    />
  );

  if (!isCustomerReviewRole && userRoleCode !== "project_labeler") {
    return (
      <div className={style.container}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="filter tabs"
          classes={{
            indicator: style.indicator,
            root: style.tabWrapper,
          }}
          variant="fullWidth"
        >
          <Tab
            label={intl.formatMessage({ id: "input-data-picker.images" })}
            value={"images"}
          />
          <Tab
            label={intl.formatMessage({ id: "input-data-picker.filter" })}
            value={"filter"}
          />
        </Tabs>
        {tab === "images" ? (
          imageContainer
        ) : (
          <div>
            <FiltersTab setTab={setTab} loadFunc={loadFunc} />
          </div>
        )}
      </div>
    );
  }
  return <div className={style.container}>{imageContainer}</div>;
}

InputDataPicker.propTypes = {
  intl: object,
  // allImages: arrayOf(object) || object,
  allImages: object,
  fetchInputData: func,
  selectOneSource: func,
  fetchMore: bool,
  currPage: number,
  currImageId: string,
  changeCurrentDropdownStatus: func,
  selectedStatus: string,
  customerReview: bool,
  allImgIds: arrayOf(string),
  isSttChanged: bool,
  isSelectChangeDropdown: bool,
  paginationByStatus: shape({
    new: object,
    assigned: object,
    inprogress: object,
    other: object,
  }),
  userRoleCode: string,
  isCustomerReviewRole: bool,
  resetSegmentationMode: func,
  isSegmentationMode: bool,
  isUnsaveSegmentation: bool,
  imageId: string,
  setScrollImage: func,
  isScrollImage: bool,
};

const mapStateToProps = (state) => ({
  allImages: selectImages(state),
  fetchMore: selectFetchMore(state),
  currPage: selectCurrPage(state),
  selectedStatus: selectCurrentDropdownStatus(state),
  customerReview: selectCustomerReview(state),
  allImgIds: selectAllImgIds(state),
  paginationByStatus: selectPaginationByStatus(state),
  isSelectChangeDropdown: selectIsChangingDropdown(state),
  userRoleCode: selectRoleCode(state),
  isCustomerReviewRole: selectCustomerReview(state),
  isUnsaveSegmentation: getIsUnsaveSegmentationSelector(state),
  imageId: selectImageId(state),
  isScrollImage: selectIsScrollImage(state),
});

export default connect(mapStateToProps, {
  selectOneSource,
  changeCurrentDropdownStatus,
  fetchInputData,
  resetSegmentationMode,
  setScrollImage,
})(injectIntl(InputDataPicker));
