/* eslint-disable no-case-declarations */
import { BOX_MODE, POLYGON_MODE } from "redux/tools/enums";
import { SAVE_BOX } from "redux/box/constants";
import { SAVE_POLYGON } from "redux/polygon/constants";
import { select, put } from "redux-saga/effects";
import { selectAnnotationHistory } from "redux/annotationHistory/selectors";
import { setAnnotationHistory } from "redux/annotationHistory/actions";

function ChangeHistoryPayloadIDSaga(shape) {
  return function* (res) {
    const history = yield select(selectAnnotationHistory);
    const tempHistory = history.map((historyItem) => {
      if (Number(historyItem.payload.id) !== Number(shape.id))
        return historyItem;
      let newHistoryItem = {
        ...historyItem,
        payload: {
          ...historyItem.payload,
          id: res.id,
        },
      };
      newHistoryItem = historyItem?.sourcePayload
        ? {
            ...newHistoryItem,
            sourcePayload: {
              ...newHistoryItem.sourcePayload,
              id: res.id,
            },
          }
        : newHistoryItem;
      return newHistoryItem;
    });

    yield put(setAnnotationHistory(tempHistory));
  };
}

export function prepareDataSave(shape) {
  const { dataPoint, labelId, color } = shape;
  let resultPayload = {};
  switch (shape.classesTypeCode) {
    case `${BOX_MODE}-image`:
      resultPayload = {
        type: SAVE_BOX,
        payload: {
          ...dataPoint,
          labelId,
          color,
        },
      };
      break;

    case `${POLYGON_MODE}-image`:
      const payloadDataPoint = dataPoint.map((item) => [item.x, item.y]);
      resultPayload = {
        type: SAVE_POLYGON,
        payload: { dataPoint: payloadDataPoint, labelId },
      };
      break;

    default:
      break;
  }

  resultPayload = {
    ...resultPayload,
    callbackSaga: ChangeHistoryPayloadIDSaga(shape),
    payload: {
      ...resultPayload.payload,
    },
    noSaveAnnotationHistory: true,
  };

  return resultPayload;
}
