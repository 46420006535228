/* eslint-disable no-undef */
import {
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  put,
  cancel,
  select,
  delay,
} from "redux-saga/effects";
import Papa from "papaparse";
import {
  fetchImage,
  fetchSourceCsvFile,
  updateImage,
  apiSaveEditedImg,
  getImageBlob,
  uploadImageToS3,
  updateMetadataImage,
  markBenchmarkApi,
  submitBenchmarkImageApi,
  searchProjectTagByTextAPI,
  deleteTagOfSourceAPI,
  createProjectTagAPI,
  addTagToSourceAPI,
} from "api/image";
import {
  selectOneSourceSuccess,
  getSourceCsvFile,
  getSourceCsvFileSuccess,
  getSourceCsvFileError,
  submitImageError,
  submitImageSuccess,
  actionFitImageSize,
  cleanSelectedImage,
  selectOneSource,
  rotateImageFinish,
  fetchInputData,
  markBenchmarkSuccess,
  markBenchmarkFailure,
  getNextImageId,
} from "redux/image/actions";
import {
  SELECT_ONE_SOURCE,
  GET_SOURCE_CSV_FILE,
  SUBMIT_IMAGE,
  SUBMIT_IMAGE_SUCCESS,
  FIT_IMAGE_SIZE,
  SAVE_EDITED_IMAGE,
  // REFETCH_INPUT_DATA,
  ROTATE_IMAGE,
  MARK_BENCHMARK,
  SEARCH_PROJECT_TAG_BY_TEXT,
  DELETE_TAG_OF_SOURCE,
  CREATE_TAG_AND_ADD_TO_SOURCE,
  ADD_TAG_TO_SOURCE,
} from "./constants";
import { fetchIssues } from "redux/issues/actions";
import { fetchClassifications } from "redux/classification/actions";
import isEmpty from "lodash.isempty";
import { capitalize /*find*/ } from "lodash";
import {
  selectProjectId,
  selectCustomerReview,
  selectCurrentDropdownStatus,
} from "redux/app/selectors";
import { toCamelCase } from "helpers";
import { resizeImage } from "helpers/image";
import {
  selectImageMetadata,
  selectImageId,
  selectAllImgIds,
  selectThumbnailURL,
  selectImgFake,
} from "./selectors";
import { changeImageRatio } from "redux/canvas/actions";
import { selectImageRatio } from "redux/canvas/selectors";
import {
  showNotification,
  delayRemoveNotification,
} from "redux/notification/actions";
import { selectCurrentNotiId } from "redux/notification/selectors";
import { selectLabelColor } from "redux/points/selectors";
import {
  selectPaginationByStatus,
  selectNextImageId,
} from "redux/image/selectors";
import { userInfo } from "api/constants";
import { getParameterByName } from "helpers";
import { setSelectedPoints } from "../points/actions";
import { getShapes } from "redux/shape/actions";

function* watchSelectOneSource() {
  yield takeLatest(SELECT_ONE_SOURCE, function* (action) {
    try {
      const { id } = action.payload;
      if (!id) return;
      let alterUserId = getParameterByName("alterUserId");
      const isViewResult = getParameterByName("isViewResult");
      const isActionHistory = getParameterByName("isActionHistory");
      const isBenchmark = isViewResult || isActionHistory;
      let sendingId = id;
      if (isViewResult && typeof sendingId === "string") {
        if (sendingId.includes("--1")) {
          const splitedId = sendingId.split("--1");
          sendingId = splitedId[0];
          alterUserId = -1;
        } else if (sendingId.includes("-")) {
          const splitedId = sendingId.split("-");
          sendingId = splitedId[0];
          alterUserId = splitedId[1];
        }
      }
      const type = isActionHistory ? "actionHistory" : "viewResult";
      let status = yield select(selectCurrentDropdownStatus);
      status = status.toLowerCase();

      const customerReview = yield select(selectCustomerReview);
      const response = yield call(
        fetchImage,
        sendingId,
        customerReview,
        alterUserId,
        isBenchmark,
        status,
        type
      );
      const ratio = yield select(selectImageRatio);
      if (!response || response.error) {
        console.error("Cannot fetch this source.");
        // yield put(
        //   showNotification({ type: "error", msg: "Cannot fetch this source." })
        // );
        const id = yield select(selectCurrentNotiId);
        delayRemoveNotification(id);
        yield cancel();
      }

      yield put(selectOneSourceSuccess(response));
      yield put(getShapes(`${sendingId}`));

      yield put(setSelectedPoints({}));

      if (ratio === 1) {
        yield put(actionFitImageSize());
      }

      yield put(getSourceCsvFile(id));
      yield put(fetchIssues());
      yield put(fetchClassifications());
    } catch (err) {
      console.log("err", err);
    }
  });
}

function* watchGetCsvFile() {
  yield takeEvery(GET_SOURCE_CSV_FILE, function* ({ payload }) {
    const isViewResult = getParameterByName("isViewResult");
    if (isViewResult) {
      return;
    }
    const response = yield call(fetchSourceCsvFile, payload);
    const coloursByLabel = yield select(selectLabelColor);

    if (response.success) {
      const data = toCamelCase(response.data) || {};
      const { oldCsvPath, newCsvPath } = data;

      // TODO: check the role if it is Customer Review, use newCsvPath
      const csvPath = newCsvPath || oldCsvPath;

      if (csvPath) {
        const points = yield readCsvPath(csvPath, coloursByLabel);
        data.points = points.reduce((acc, point, index) => {
          const { x, y } = point;
          if (
            typeof x === "number" &&
            typeof y === "number" &&
            !isNaN(x) &&
            !isNaN(y)
          ) {
            acc.push({ ...point, index });
          }
          return acc;
        }, []);
      }
      yield put(getSourceCsvFileSuccess(data));
    } else {
      yield put(
        getSourceCsvFileError({
          msg: "Error when get csv file.",
          msgId: "notification.error-when-get-csv-file",
        })
      );
      yield cancel();
    }
  });
}

function readCsvPath(path, colours) {
  return new Promise((resolve) => {
    return Papa.parse(path, {
      download: true,
      header: true,
      complete: function (results) {
        const points = results.data.reduce((list, item, index) => {
          if (!isEmpty(item)) {
            const { x, y, changed, issues, region } = item;
            list.push({
              x: Number(x),
              y: Number(y),
              id: index,
              isChanged: changed === "true",
              issued: issues === "true",
              region,
              colour: colours[region] || "yellow",
            });
          }
          return list;
        }, []);
        resolve(points);
      },
    });
  });
}

function* watchSubmitImage() {
  yield takeEvery(SUBMIT_IMAGE, function* ({ payload }) {
    const { status, isHideNotification } = payload;
    const imageId = yield select(selectImageId);
    const allImgIds = yield select(selectAllImgIds);
    const projectId = yield select(selectProjectId);
    const customerReview = yield select(selectCustomerReview);
    const paginationByStatus = yield select(selectPaginationByStatus);
    const { more: moreNew } = paginationByStatus.new;
    const { more: moreAssigned } = paginationByStatus.assigned;
    const { more: moreInprogress } = paginationByStatus.inprogress;
    const selectedStatus = yield select(selectCurrentDropdownStatus);
    const isViewResult = getParameterByName("isViewResult");
    const isActionHistory = getParameterByName("isActionHistory");
    const isBenchmark = isViewResult || isActionHistory;

    for (let i = 0; i < allImgIds.length; i++) {
      if (allImgIds[i] === imageId) {
        let nextImageId = allImgIds[++i];
        yield put(getNextImageId(nextImageId));
        break;
      }
    }
    const response = !isBenchmark
      ? yield call(updateImage, {
          imageId,
          payload: { status, projectId, customerReview },
        })
      : yield call(submitBenchmarkImageApi, {
          sourceId: imageId,
        });

    if (response.success || response.status) {
      yield put(
        submitImageSuccess({ sourceId: imageId, status: selectedStatus })
      );
      if (selectedStatus === "New") {
        yield moreNew && put(fetchInputData({ status: "new", page: 0 }));
        yield moreAssigned &&
          put(fetchInputData({ status: "assigned", page: 0 }));
        yield moreInprogress &&
          put(fetchInputData({ status: "inprogress", page: 0 }));
      } else {
        const status = selectedStatus.toLowerCase();
        yield put(fetchInputData({ status, page: 0 }));
      }
      if (!isHideNotification)
        yield put(
          showNotification({
            type: "success",
            msg: `Image ${status} successfully.`,
            msgId: `notification.image-${status}-successfully`,
          })
        );
      const id = yield select(selectCurrentNotiId);
      delayRemoveNotification(id);
    } else {
      yield put(
        submitImageError({
          msg: `${capitalize(status)} image error! Try again.`,
          msgId: "An error occurred. Please try again.",
        })
      );
    }
  });
}

function* watchSubmitImageSuccess() {
  yield takeEvery(SUBMIT_IMAGE_SUCCESS, function* () {
    yield put(cleanSelectedImage());

    const nextImageId = yield select(selectNextImageId);
    const allImgIds = yield select(selectAllImgIds);
    yield put(
      selectOneSource({
        id: nextImageId ? nextImageId : allImgIds[0],
      })
    );
  });
}

function* watchFitImageSize() {
  yield takeLatest(FIT_IMAGE_SIZE, function* () {
    if (window.imageWrapper) {
      const { clientWidth, clientHeight } = window.imageWrapper;
      const { width, height } = yield select(selectImageMetadata);
      if (
        width <= 0 ||
        height <= 0 ||
        clientWidth <= 40 ||
        clientHeight <= 40
      ) {
        yield cancel();
      }
      const ratioWidth = width / (clientWidth - 40 - 400); // subtract 200px of change label component in both side left and right
      const ratioHeight = height / (clientHeight - 40);

      yield delay(200);

      if (ratioWidth >= ratioHeight) {
        yield put(changeImageRatio(1 / ratioWidth));
      } else yield put(changeImageRatio(1 / ratioHeight));
    }
  });
}

function* watchSaveEditedImg() {
  yield takeEvery(SAVE_EDITED_IMAGE, function* (action) {
    const sourceId = yield select(selectImageId);
    const dataImage =
      action.payload && action.payload.split("data:image/png;base64,").join("");
    const sending = {
      sourceId,
      dataImage,
    };

    const response = yield call(apiSaveEditedImg, sending);
    if (response.success) {
      yield put(
        showNotification({
          type: "message",
          msg: "Save new image successfully",
          msgId: "notification.save-new-image-success",
        })
      );
      const id = yield select(selectCurrentNotiId);
      delayRemoveNotification(id);
    } else {
      yield put(
        showNotification({
          type: "error",
          msg: "Fail to save edited image!",
          msgId: "notification.fail-to-save-edited-image",
        })
      );
      const id = yield select(selectCurrentNotiId);
      delayRemoveNotification(id);
    }
  });
}

// function* watchRefetchData() {
//   yield takeEvery(REFETCH_INPUT_DATA, doFetchInputData);
// }

function* watchRotateImage() {
  yield takeEvery(ROTATE_IMAGE, function* ({ payload }) {
    try {
      const { imageRotationDirection } = payload;
      const img = yield select(selectImgFake);
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      const { width, height, name } = yield select(selectImageMetadata);
      // Assign width and height.
      canvas.width = height;
      canvas.height = width;

      ctx.translate(canvas.width / 2, canvas.height / 2);

      // Rotate the image and draw it on the canvas.
      // (I am not showing the canvas on the webpage.
      ctx.rotate((Math.PI / 2) * payload.rotation);
      ctx.drawImage(img, -width / 2, -height / 2, width, height);

      const blob = yield call(getImageBlob, img.src);
      const href = canvas.toDataURL(blob.type);
      window.testUrl = href;
      const newBlob = yield call(getImageBlob, href);
      const newImageFile = new File([newBlob], name, {
        type: newBlob.type,
      });
      const thumbUrl = yield select(selectThumbnailURL);
      const thumbName = thumbUrl.slice(
        thumbUrl.indexOf("thumbnail_image_"),
        thumbUrl.indexOf(".jpg") + 4
      );
      const thumbBlob = yield call(resizeImage, newImageFile);
      const newImageThumbFile = new File([thumbBlob], thumbName, {
        type: "image/jpeg",
      });
      const pathS3 = img.src.slice(
        img.src.indexOf(".com") + 5,
        img.src.lastIndexOf("/")
      );
      let metadata = {
        width: height,
        height: width,
        size: newImageFile.size,
        imageRotationDirection: imageRotationDirection,
      };
      const sourceId = yield select(selectImageId);
      const [resMainImg, resThumbImg, resUpdateImg] = yield all([
        call(uploadImageToS3, pathS3, newImageFile),
        call(uploadImageToS3, pathS3, newImageThumbFile),
        call(updateMetadataImage, sourceId, metadata),
      ]);
      if (resMainImg && resThumbImg && !resUpdateImg.error) {
        window.testMain = resMainImg;
        window.testThumbnail = resThumbImg;

        const { source_id } = resUpdateImg.data;
        yield put(
          rotateImageFinish({
            status: true,
            ...metadata,
            src: resMainImg,
            thumbSrc: resThumbImg,
            id: source_id,
          })
        );
      } else {
        yield put(
          rotateImageFinish({
            status: false,
          })
        );
      }
    } catch (exc) {
      console.log("error:", exc);
    }
  });
}

function* watchMarkBenchmarkSaga() {
  yield takeEvery(MARK_BENCHMARK, function* (payload) {
    try {
      const sourceId = yield select(selectImageId);
      const _userInfo = JSON.parse(userInfo);
      const email = _userInfo.email;
      const status = payload.payload.isBenchmark;
      const response = yield call(markBenchmarkApi, {
        sourceId,
        isBenchmark: status,
      });
      if (response) {
        const isBenchmark = response.isBenchmark;
        yield put(markBenchmarkSuccess({ sourceId, email, isBenchmark }));
        yield put(
          showNotification({
            type: "message",
            msg: response.message,
            msgId: response.message,
          })
        );
        const id = yield select(selectCurrentNotiId);
        delayRemoveNotification(id);
      } else {
        yield put(
          showNotification({
            type: "error",
            msg: "Fail to mark image!",
            msgId: "notification.fail-to-mark-image",
          })
        );
      }
    } catch (exc) {
      yield put(
        showNotification({
          type: "error",
          msg: "Fail to mark image!",
          msgId: "notification.fail-to-mark-image",
        })
      );
      const id = yield select(selectCurrentNotiId);
      delayRemoveNotification(id);
      yield put(markBenchmarkFailure());
    }
  });
}

function* watchSearchTagByTextSaga() {
  yield takeEvery(SEARCH_PROJECT_TAG_BY_TEXT, function* (action) {
    try {
      const { projectId, textSearch } = action.payload;
      const { callback } = action;
      const response = yield call(searchProjectTagByTextAPI, {
        projectId,
        textSearch,
      });
      const checkResponse = response?.status;

      if (!checkResponse) {
        yield put(
          showNotification({
            type: "error",
            msgId: "common.search-project-tag-by-text-error",
          })
        );
      }
      callback && callback(response?.data);
    } catch (error) {
      console.log("error: ", error);
    }
  });
}

function* watchDeleteTagOfSource() {
  yield takeEvery(DELETE_TAG_OF_SOURCE, function* (action) {
    const { callback, payload } = action;
    try {
      const response = yield call(deleteTagOfSourceAPI, { ...payload });
      if (response.status) {
        callback && callback(response);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  });
}

function* createProjectTagSaga({ payload, callback }) {
  const { projectId, newtag } = payload;
  const response = yield call(createProjectTagAPI, {
    projectId,
    newtag,
  });
  const checkResponse = response?.status;
  yield put(
    showNotification({
      type: checkResponse ? "success" : "error",
      msgId: checkResponse
        ? "common.add-tag-to-project-successfully"
        : "common.add-tag-to-project-error",
    })
  );

  callback && callback(response?.data);
  return response?.data;
}

function* addTagToSourceSaga(action) {
  const { projectId, tags, sourceIds } = action.payload;
  const { callback } = action;
  const response = yield call(addTagToSourceAPI, {
    projectId,
    sourceIds,
    tags,
  });
  const checkResponse = response?.status;
  yield put(
    showNotification({
      type: checkResponse ? "success" : "error",
      msgId: checkResponse
        ? "common.add-tag-to-source-successfully"
        : "common.add-tag-to-source-error",
    })
  );
  callback && callback(response);
  return response.data;
}

function* watchCreateAndAddTagToSource() {
  yield takeEvery(CREATE_TAG_AND_ADD_TO_SOURCE, function* (action) {
    const { payload, callback } = action;
    const res = yield call(createProjectTagSaga, { payload });
    let addToSourceRes;
    if (res) {
      addToSourceRes = yield call(addTagToSourceSaga, {
        payload: {
          ...payload,
          tags: res.tags?.map((item) => item.tag_id),
        },
      });
    }
    callback && callback(addToSourceRes);
  });
}

function* watchAddTagToSourceSaga() {
  yield takeEvery(ADD_TAG_TO_SOURCE, addTagToSourceSaga);
}

export default function* imageSaga() {
  yield all([
    // fork(watchRefetchData),
    fork(watchSelectOneSource),
    fork(watchGetCsvFile),
    fork(watchSubmitImage),
    fork(watchFitImageSize),
    fork(watchSaveEditedImg),
    fork(watchSubmitImageSuccess),
    fork(watchRotateImage),
    fork(watchMarkBenchmarkSaga),
    fork(watchSearchTagByTextSaga),
    fork(watchDeleteTagOfSource),
    fork(watchCreateAndAddTagToSource),
    fork(watchAddTagToSourceSaga),
  ]);
}
