export const getLinessOfCuboid = (cuboidRectangle) =>
  Array.isArray(cuboidRectangle) && cuboidRectangle.length > 1
    ? [
        [
          cuboidRectangle[0].x,
          cuboidRectangle[0].y,
          cuboidRectangle[1].x,
          cuboidRectangle[1].y,
        ],
        [
          cuboidRectangle[0].x + cuboidRectangle[0].width,
          cuboidRectangle[0].y,
          cuboidRectangle[1].x + cuboidRectangle[1].width,
          cuboidRectangle[1].y,
        ],
        [
          cuboidRectangle[0].x + cuboidRectangle[0].width,
          cuboidRectangle[0].y + cuboidRectangle[0].height,
          cuboidRectangle[1].x + cuboidRectangle[1].width,
          cuboidRectangle[1].y + cuboidRectangle[1].height,
        ],
        [
          cuboidRectangle[0].x,
          cuboidRectangle[0].y + cuboidRectangle[0].height,
          cuboidRectangle[1].x,
          cuboidRectangle[1].y + cuboidRectangle[1].height,
        ],
      ]
    : [];
