import { bool, object, number, func, string, array } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getAllShapes,
  getCurrentShapes,
  getDisplayingShapes,
  selectCurrentPolyonPointIndex,
} from "redux/shape/selector";
import { mapShapeToComponent } from "./mapShapeToComponent";
import { makeCurrTool, selectIsMoveMode } from "redux/tools/selectors";
import { selectImageRatio } from "redux/canvas/selectors";
import {
  setCurrentShape,
  setCurrentPolygonPointIndex,
} from "redux/shape/actions";
import * as allTools from "redux/tools/enums";
import { getParameterByName } from "helpers";

function ShapeLayer({
  shapes,
  ratio,
  currentShape,
  setCurrentShape,
  currTool,
  displayingShapes,
  setCurrentPolygonPointIndex,
  currentPolygonPoint,
}) {
  useEffect(() => {
    currentPolygonPoint !== -1 && setCurrentPolygonPointIndex(-1);
  }, [currentShape]);
  const alterUserId = getParameterByName("alterUserId");
  const isViewResult = getParameterByName("isViewResult");

  const handleSelectShape = (item) => () => {
    if (currTool || alterUserId || isViewResult) return;
    setCurrentShape(item);
  };

  const handleChangeCurrentPolygonPoint = (index) => {
    setCurrentPolygonPointIndex(index);
  };

  return (
    <>
      {shapes
        .sort((a, b) => Number(a.id) - Number(b.id))
        .map((item) => {
          const Component = mapShapeToComponent[item.classesTypeCode];
          if (!Component) return null;
          if (
            displayingShapes.findIndex(
              (shape) =>
                Number(item.id) === Number(shape.id) &&
                Number(item.labelId) === Number(shape.labelId)
            ) !== -1
          ) {
            const isSelected = Number(currentShape?.id) === Number(item.id);

            const polygonExtraProps = [
              `${allTools.POLYGON_MODE}-image`,
              `${allTools.POLYLINE_MODE}-image`,
            ].includes(item.classesTypeCode)
              ? {
                  currentPoint: currentPolygonPoint,
                  onChangeCurrentPoint: handleChangeCurrentPolygonPoint,
                }
              : {};

            return (
              <Component
                key={item.id}
                ratio={ratio}
                isSelected={isSelected}
                onSelect={handleSelectShape(item)}
                properties={item}
                draggable={isSelected}
                {...polygonExtraProps}
              />
            );
          }
        })}
    </>
  );
}

ShapeLayer.propTypes = {
  shapes: array || object,
  isBoxMode: bool,
  ratio: number,
  currentShape: array,
  setCurrentShape: func,
  currTool: string,
  displayingShapes: array,
  isMoveMode: bool,
  currentPolygonPoint: number,
  setCurrentPolygonPointIndex: func,
  dbClickToEditText: func,
};

const mapStateToProps = () => (state) => ({
  shapes: getAllShapes(state),
  ratio: selectImageRatio(state),
  currentShape: getCurrentShapes(state),
  currTool: makeCurrTool(state),
  displayingShapes: getDisplayingShapes(state),
  isMoveMode: selectIsMoveMode(state),
  currentPolygonPoint: selectCurrentPolyonPointIndex(state),
});

export default connect(mapStateToProps, {
  setCurrentShape,
  setCurrentPolygonPointIndex,
})(ShapeLayer);
