import React from "react";
import { Slider as MuiSlider, Tooltip } from "@material-ui/core";
import { func, number, element, bool } from "prop-types";

function Slider({ min = 0, max = 50, value, step = 5, onChange, width = 100 }) {
  return (
    <div style={{ width }}>
      <MuiSlider
        value={value}
        onChange={onChange}
        aria-labelledby="continuous-slider"
        min={min}
        max={max}
        step={step}
        valueLabelDisplay="auto"
        ValueLabelComponent={ValueLabelComponent}
      />
    </div>
  );
}

Slider.propTypes = {
  min: number,
  max: number,
  value: number,
  step: number,
  onChange: func,
  width: number,
};

function ValueLabelComponent({ open, value, children }) {
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: element,
  open: bool,
  value: number,
};

export default Slider;
